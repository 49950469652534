.icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	&--basket {
		width: 19px;
		height: 17px;
		background-image: url('/_images/svg/basket.svg');
	}
	&--personal {
		width: 14px;
		height: 17px;
		background-image: url('/_images/svg/personal.svg');
	}
	&--marker {
		width: 14px;
		height: 19px;
		background-image: url('/_images/svg/marker.svg');
	}
	&--phone {
		width: 17px;
		height: 17px;
		background-image: url('/_images/svg/phone.svg');
	}
	&--arrow-right {
		width: 7px;
		height: 12px;
		background-image: url('/_images/svg/arrow-right.svg');
	}
	&--arrow-left-blue {
		width: 30px;
		height: 24px;
		background-image: url('/_images/svg/arrow-left-blue.svg');
		&:hover{
			background-image: url('/_images/svg/arrow-left-blue-hover.svg');
		}
	}
	&--arrow-right-blue {
		width: 30px;
		height: 24px;
		background-image: url('/_images/svg/arrow-right-blue.svg');
		&:hover{
			background-image: url('/_images/svg/arrow-right-blue-hover.svg');
		}
	}
	&--plus-green {
		width: 12px;
		height: 14px;
		background-image: url('/_images/svg/plus-green.svg');
	}
	&--close {
		width: 18px;
		height: 18px;
		background-image: url('/_images/svg/close.svg');
		&:hover{
			background-image: url('/_images/svg/close-hover.svg');
		}
	}
	&--arrow-down {
		width: 15px;
		height: 8px;
		background-image: url('/_images/svg/arrow-down-blue.svg');
	}
	&--danger {
		width: 30px;
		height: 30px;
		background-image: url('/_images/svg/danger-icon.svg');
	}
}