@charset "UTF-8";
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic';
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Roboto", sans-serif; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.preloader {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #fff;
  transition: visibility .3s, opacity .3s; }
  .preloader.hide-loader {
    visibility: hidden;
    opacity: 0; }
  .preloader__image {
    position: relative;
    height: 150px;
    width: 100%;
    margin-bottom: -40px; }
    .preloader__image svg {
      position: absolute;
      width: 150px;
      animation: preloader-car 3s cubic-bezier(0.42, 0, 0.58, 1) infinite; }
  .preloader__line {
    position: relative;
    width: 100%; }
    .preloader__line:after {
      content: '';
      position: absolute;
      height: 3px;
      background-color: #4185f4;
      animation: preloader-line 3s cubic-bezier(0.42, 0, 0.58, 1) infinite; }

@keyframes preloader-car {
  0% {
    left: -150px; }
  100% {
    left: 100%; } }

@keyframes preloader-line {
  from {
    left: 0;
    width: 0; }
  50% {
    right: 0;
    width: 100%; }
  to {
    right: 0;
    width: 0; } }

.button {
  display: inline-block;
  padding: 0 22px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #4185f4;
  color: #4185f4;
  font-size: 12px;
  line-height: 45px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  transition: all .3s;
  background-color: transparent; }
  .button:hover {
    background-color: #4185f4;
    color: #fff; }
  .button--big {
    min-width: 224px; }

.flat-btn {
  width: 204px;
  height: 45px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 1.71429px;
  text-transform: uppercase;
  color: #4185F4;
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  background: transparent;
  transition: background-color .1s ease-in;
  display: flex;
  justify-content: center;
  align-items: center; }
  .flat-btn:nth-of-type(2) ~ .flat-btn {
    width: 184px; }
    @media (min-width: 767px) {
      .flat-btn:nth-of-type(2) ~ .flat-btn {
        margin-right: 15px; } }
    @media (max-width: 767px) {
      .flat-btn:nth-of-type(2) ~ .flat-btn {
        margin-bottom: 15px; } }
  .flat-btn.red {
    color: #FF6451;
    border: 2px solid #FF6451; }
    .flat-btn.red:hover {
      background-color: #FF6451;
      color: #fff; }
  .flat-btn.blue {
    background: #4185F4;
    border: 2px solid #4185F4;
    color: #fff; }
    .flat-btn.blue:hover {
      background-color: #fff;
      color: #4185F4; }
  .flat-btn.normal {
    border: 2px solid #4185F4;
    color: #4185F4; }
    .flat-btn.normal:hover {
      background-color: #4185F4;
      color: #fff; }

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain; }
  .icon--basket {
    width: 19px;
    height: 17px;
    background-image: url("/_images/svg/basket.svg"); }
  .icon--personal {
    width: 14px;
    height: 17px;
    background-image: url("/_images/svg/personal.svg"); }
  .icon--marker {
    width: 14px;
    height: 19px;
    background-image: url("/_images/svg/marker.svg"); }
  .icon--phone {
    width: 17px;
    height: 17px;
    background-image: url("/_images/svg/phone.svg"); }
  .icon--arrow-right {
    width: 7px;
    height: 12px;
    background-image: url("/_images/svg/arrow-right.svg"); }
  .icon--arrow-left-blue {
    width: 30px;
    height: 24px;
    background-image: url("/_images/svg/arrow-left-blue.svg"); }
    .icon--arrow-left-blue:hover {
      background-image: url("/_images/svg/arrow-left-blue-hover.svg"); }
  .icon--arrow-right-blue {
    width: 30px;
    height: 24px;
    background-image: url("/_images/svg/arrow-right-blue.svg"); }
    .icon--arrow-right-blue:hover {
      background-image: url("/_images/svg/arrow-right-blue-hover.svg"); }
  .icon--plus-green {
    width: 12px;
    height: 14px;
    background-image: url("/_images/svg/plus-green.svg"); }
  .icon--close {
    width: 18px;
    height: 18px;
    background-image: url("/_images/svg/close.svg"); }
    .icon--close:hover {
      background-image: url("/_images/svg/close-hover.svg"); }
  .icon--arrow-down {
    width: 15px;
    height: 8px;
    background-image: url("/_images/svg/arrow-down-blue.svg"); }
  .icon--danger {
    width: 30px;
    height: 30px;
    background-image: url("/_images/svg/danger-icon.svg"); }

.scroll-cursor {
  cursor: pointer; }
  .scroll-cursor__mouse {
    margin-bottom: 6px;
    width: 27px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 13px; }
  .scroll-cursor__wheel {
    margin: 4px auto;
    width: 2px;
    height: 9px;
    background-color: #fff;
    animation: mouse-wheel 1.2s ease infinite; }
  .scroll-cursor__arrow span {
    display: block;
    margin: 0 auto 1px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    animation: mouse-scroll 2s infinite; }
    .scroll-cursor__arrow span:nth-of-type(1) {
      animation-delay: .1s;
      opacity: 0.3; }
    .scroll-cursor__arrow span:nth-of-type(2) {
      animation-delay: .2s;
      opacity: 0.7; }
    .scroll-cursor__arrow span:nth-of-type(3) {
      animation-delay: .3s; }

@keyframes mouse-wheel {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(6px); } }

@keyframes mouse-scroll {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.tooltip {
  background: #fff;
  padding: 15px 20px 12px;
  box-shadow: 0px 2px 33px rgba(65, 133, 244, 0.355044);
  position: absolute;
  font-weight: 300;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  color: #515B6D;
  opacity: 1;
  display: flex;
  align-items: baseline;
  justify-content: center; }
  .tooltip::before {
    content: '';
    display: block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #fff; }
  .tooltip-target {
    cursor: pointer; }

html, body {
  backface-visibility: hidden; }

a :active, a:focus, a:hover {
  text-decoration: none;
  color: inherit; }

h1 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
  color: #000;
  margin-bottom: 18px;
  margin-top: 47px;
  text-align: center; }
  @media (max-width: 767px) {
    h1 {
      font-size: 30px;
      line-height: 38px;
      margin-top: 20px;
      margin-bottom: 20px; } }

h2 {
  margin-bottom: 30px;
  color: #000;
  font-weight: 300;
  font-size: 50px;
  text-align: center; }
  @media (max-width: 991px) {
    h2 {
      font-size: 30px; } }

h3 {
  margin-bottom: 30px;
  color: #000;
  font-weight: 300;
  font-size: 25px;
  text-align: center; }

h4 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
  color: #000;
  margin-bottom: 25px;
  text-align: center; }
  @media (max-width: 991px) {
    h4 {
      font-size: 20px;
      line-height: 26px; } }

@keyframes animation-opacity-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.animation-opacity-in {
  animation: animation-opacity-in 2s forwards; }

@media (max-width: 767px) {
  .container {
    padding-left: 16px;
    padding-right: 16px; } }

* {
  outline: none !important; }

.error {
  border: 1px solid #eb0c0c !important;
  background: url("/_images/svg/warn.svg") no-repeat 95% center; }

/*Так как в bootstrap 3 нет .visible-<pref>-flex*/
@media (max-width: 767px) {
  .visible-xs-flex {
    display: flex !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-flex {
    display: flex !important; } }

@media (min-width: 992px) and (max-width: 1453px) {
  .visible-md-flex {
    display: flex !important; } }

@media (min-width: 1454px) {
  .visible-lg-flex {
    display: flex !important; } }

.headroom--pinned {
  top: 0; }

.headroom--unpinned {
  top: -100% !important; }

html.overflow-hidden {
  overflow: hidden !important; }
  html.overflow-hidden body {
    overflow: hidden !important; }

.header {
  position: fixed;
  z-index: 202;
  left: 0;
  right: 0;
  top: 0;
  color: #fff;
  transition: all .5s; }
  @media (max-width: 991px) {
    .header {
      background-color: #fff; }
      .header svg path, .header svg use {
        fill: #4185f4 !important;
        stroke: #4185f4 !important; } }
  .header--white, .header.is-pinned, .header.is-black {
    background-color: #fff;
    box-shadow: 0 0 23px 2px rgba(81, 91, 109, 0.08);
    color: #515b6d; }
    @media (max-width: 991px) {
      .header--white, .header.is-pinned, .header.is-black {
        box-shadow: 0px 0px 15px rgba(81, 91, 109, 0.2);
        position: static; } }
    .header--white svg path, .header--white svg use, .header.is-pinned svg path, .header.is-pinned svg use, .header.is-black svg path, .header.is-black svg use {
      fill: #4185f4;
      stroke: #4185f4; }
    .header--white a:hover, .header.is-pinned a:hover, .header.is-black a:hover {
      color: #1254ea; }
    .header--white .header-menu--sub, .header.is-pinned .header-menu--sub, .header.is-black .header-menu--sub {
      background-color: #4185f4; }
      .header--white .header-menu--sub:after, .header.is-pinned .header-menu--sub:after, .header.is-black .header-menu--sub:after {
        border-bottom-color: #4185f4; }
      .header--white .header-menu--sub .header-menu__link, .header.is-pinned .header-menu--sub .header-menu__link, .header.is-black .header-menu--sub .header-menu__link {
        color: #fff; }
    .header--white .header-menu__link:hover, .header.is-pinned .header-menu__link:hover, .header.is-black .header-menu__link:hover {
      color: #1254ea; }
    .header--white .header__splitter, .header.is-pinned .header__splitter, .header.is-black .header__splitter {
      background-color: #a7acb6; }
  .header a:hover {
    color: #4185f4; }
  .header svg path, .header svg use {
    fill: #fff;
    stroke: #fff; }
  .header__top {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid rgba(192, 201, 217, 0.3); }
    @media (max-width: 991px) {
      .header__top .container {
        padding: 0;
        width: auto; } }
    @media (max-width: 767px) {
      .header__top {
        height: fit-content !important; } }
  .header__container {
    display: flex;
    flex-flow: row wrap; }
  @media (max-width: 767px) {
    .header__buttons {
      height: 50px; } }
  @media (max-width: 400px) {
    .header__buttons {
      flex-grow: 1; }
      .header__buttons > .custom-dropdown-menu-wrapper {
        width: 100% !important; } }
  .header__button {
    float: left;
    padding: 0 25px;
    border-right: 1px solid rgba(192, 201, 217, 0.3);
    color: inherit;
    font-size: 14px;
    text-align: center;
    line-height: 17px;
    height: 50px;
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .header__button {
        padding: 0 18px 0 18px; } }
    @media (max-width: 767px) {
      .header__button {
        padding: 0 15px 0 15px;
        border-right: 0px; } }
    .header__button:first-child {
      border-left: 1px solid rgba(192, 201, 217, 0.3); }
    .header__button--active {
      background-color: #4185f4;
      border-color: transparent !important;
      color: #fff !important; }
    .header__button svg {
      margin-left: 44px; }
      .header__button svg path, .header__button svg use {
        fill: #fff !important;
        stroke: inherit !important; }
  .header__sections {
    width: 100%;
    order: 3;
    display: none; }
  .header__sections-item {
    display: block;
    padding: 20px 0 20px 17px;
    background-color: #5a99ff;
    color: #fff;
    font-size: 14px;
    line-height: 1; }
    .header__sections-item:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .header__info {
    display: flex;
    justify-content: space-around;
    flex: 1; }
    @media (max-width: 1454px) {
      .header__info {
        display: none; } }
  .header__text {
    font-size: 13px;
    /* убрать после добавления авторизации */
    margin-left: 10px; }
    .header__text svg {
      margin-right: 4px;
      transform: translateY(3px); }
    .header__text a {
      color: inherit; }
  .header__panel {
    margin-left: auto;
    /*@media (max-width: $md-down) and (min-width: $sm) {
      flex: 1;
      margin-left: 0;
    }*/ }
    @media (max-width: 991px) {
      .header__panel {
        height: 60px;
        margin-left: 0;
        width: 100%;
        order: 4;
        background-color: #fff;
        border-bottom: 1px solid rgba(235, 234, 241, 0.3); } }
  .header__panel-item {
    float: right;
    color: inherit;
    font-size: 13px;
    margin-left: 10px; }
    @media (max-width: 991px) {
      .header__panel-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 60px;
        margin-left: 0 !important;
        border-left: 1px solid #ebeef3;
        font-size: 0; } }
    .header__panel-item:nth-of-type(1) {
      /*      margin-left: 55px;*/ }
      .header__panel-item:nth-of-type(1) svg #tp8ya, .header__panel-item:nth-of-type(1) svg #tp8yb {
        stroke: none; }
    .header__panel-item svg {
      margin-right: 15px;
      transform: translateY(3px); }
      @media (max-width: 991px) {
        .header__panel-item svg {
          margin-right: 0;
          transform: translateY(0); } }
    .header__panel-item a {
      color: inherit; }
  .header__splitter {
    display: inline-block;
    margin: 0 12px;
    width: 1px;
    height: 22px;
    background-color: #fff;
    opacity: 0.5;
    transform: translateY(5px); }
    @media (max-width: 991px) {
      .header__splitter {
        display: none; } }
  .header__avatar {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 34px;
    height: 34px;
    border: 1px solid #4185f4;
    border-radius: 50%;
    background: url(/_images/svg/no-photo.svg) no-repeat center;
    background-size: 60%; }
  .header__user {
    vertical-align: middle;
    display: inline-block;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 1454px) {
      .header__user {
        max-width: 60px; } }
  .header__menu-button {
    padding: 0 32px 0 18px;
    border-left: 1px solid #ebeef3;
    color: #4185f4;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.3px; }
    @media (max-width: 991px) {
      .header__menu-button {
        margin-left: auto; } }
    @media (max-width: 767px) {
      .header__menu-button {
        padding: 0 17px;
        border-bottom: 1px solid #ebeef3;
        height: 50px;
        line-height: 53px; } }
    .header__menu-button:hover {
      color: #4185f4; }
    .header__menu-button svg {
      margin-left: 15px;
      transform: translateY(3px); }
  .header__bottom {
    padding: 22px 0; }
  .header__logo {
    transform: translateY(7px); }
    @media (max-width: 991px) {
      .header__logo {
        float: left;
        margin-left: 26px; } }
    @media (max-width: 767px) {
      .header__logo {
        margin-left: 15px;
        transform: translateY(9px); } }
  .header__menu-container {
    display: flex;
    overflow: hidden;
    visibility: hidden; }
    .header__menu-container.is-initialized {
      overflow: visible;
      visibility: visible; }

.header-menu {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  min-width: 100%;
  white-space: nowrap; }
  .header-menu--sub {
    position: absolute;
    display: block;
    padding: 19px;
    min-width: auto;
    top: 100%;
    right: 0;
    transform: translateY(25px);
    background-color: #fff;
    text-align: right;
    opacity: 0;
    visibility: hidden;
    transition: all .3s; }
    .header-menu--sub:after {
      bottom: 100%;
      right: 10px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 7px;
      margin-left: -7px; }
    .header-menu--sub .header-menu__item {
      margin-bottom: 15px;
      padding: 0; }
      .header-menu--sub .header-menu__item:last-child {
        margin-bottom: 0; }
    .header-menu--sub .header-menu__link {
      color: #000;
      white-space: nowrap; }
  .header-menu__item {
    padding: 10px 0;
    flex-shrink: 0; }
    .header-menu__item:hover > .header-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px); }
  .header-menu__link {
    color: inherit;
    font-size: 16px; }

.menu {
  position: fixed;
  z-index: 1003;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(17, 34, 64, 0.8);
  display: none;
  opacity: 0; }
  .menu.is-open .menu__container {
    transform: translateY(0); }
  .menu__container {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: all .4s linear;
    transform: translateY(-100%); }
  .menu__header {
    padding: 15px 20px;
    text-align: right;
    background-color: #fff;
    border-bottom: 1px solid #eceff4; }
  .menu__close {
    color: #4185f4;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    cursor: pointer; }
    .menu__close svg {
      margin-left: 15px;
      transform: translateY(3px); }
  .menu__body {
    position: absolute;
    top: 51px;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto; }
  .menu__item {
    padding: 0 9px;
    background-color: #f0f5f9;
    border-bottom: 1px solid #e0e5eb; }
    .menu__item:last-child {
      border-bottom: 0; }
  .menu__link {
    display: block;
    padding: 20px 0;
    color: #515b6d;
    font-size: 15px; }
    .menu__link span {
      padding-left: 12px; }

/* Content work area */
.content {
  padding-top: 85px; }
  @media (max-width: 767px) {
    .content {
      padding-top: 0 !important; } }

.footer__top {
  padding: 70px 0 90px;
  background-color: #383e50; }
  @media (max-width: 767px) {
    .footer__top {
      padding: 0; }
      .footer__top .container {
        padding-left: 8px !important;
        padding-right: 8px !important; } }

.footer__middle {
  padding: 48px 0;
  background-color: #292e3b;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 767px) {
    .footer__middle {
      padding: 32px 0 43px; } }

.footer__bottom {
  background-color: #292e3b; }

.footer__logo {
  transform: translateY(5px); }
  @media (max-width: 767px) {
    .footer__logo {
      text-align: center; } }

.footer__service {
  padding: 28px 0 18px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 991px) {
    .footer__service {
      padding-bottom: 0;
      border: none; } }

.footer__service-container {
  display: flex;
  overflow: hidden;
  visibility: hidden; }
  .footer__service-container.is-initialized {
    overflow: visible;
    visibility: visible; }

.footer__group-title {
  margin-bottom: 41px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.4px; }
  @media (max-width: 991px) {
    .footer__group-title {
      margin-bottom: 34px; } }
  @media (max-width: 767px) {
    .footer__group-title {
      display: none; } }

.footer__copy {
  padding: 23px 0; }

.footer__text {
  float: left;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px; }
  @media (max-width: 767px) {
    .footer__text {
      margin-bottom: 24px;
      float: none;
      text-align: center; } }

.footer__made {
  float: right;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px; }
  .footer__made a {
    color: #0f81ff;
    transition: color .3s; }
    .footer__made a:hover {
      color: #fff; }
  @media (max-width: 767px) {
    .footer__made {
      float: none;
      text-align: center; } }

.footer-menu.is-open .footer-menu__header {
  background-color: #808899;
  color: #fff; }
  .footer-menu.is-open .footer-menu__header:after {
    transform: rotate(180deg); }

@media (max-width: 767px) {
  .footer-menu {
    margin: 0 -8px;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); } }

@media (min-width: 767px) {
  .footer-menu:not(:first-of-type) {
    margin-top: 42px; } }

.footer-menu__header {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 32px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  transition: all .3s; }
  @media (max-width: 767px) {
    .footer-menu__header {
      display: block;
      margin-bottom: 0;
      padding: 25px 16px;
      background-color: #383e50; } }
  .footer-menu__header:hover, .footer-menu__header:focus {
    color: #336df1; }
    @media (max-width: 767px) {
      .footer-menu__header:hover, .footer-menu__header:focus {
        color: #fff;
        background-color: #808899; } }
  .footer-menu__header:after {
    content: '';
    position: absolute;
    display: none;
    top: 29px;
    right: 17px;
    width: 12px;
    height: 7px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciPjxkZWZzPjxwYXRoIGlkPSIyb2IyYSIgZD0iTTI5NyA0MzIwLjZjLS40MS4wOC0uODYtLjAxLTEuMTktLjMybC00LjQyLTQuMTRhMS4yIDEuMiAwIDAgMSAwLTEuNzcgMS40IDEuNCAwIDAgMSAxLjkgMGwzLjcyIDMuNDkgMy43LTMuNDdhMS40IDEuNCAwIDAgMSAxLjg5IDAgMS4yIDEuMiAwIDAgMSAwIDEuNzdsLTQuNCA0LjEyYy0uMzMuMy0uNzcuNC0xLjIuMzN6Ii8+PC9kZWZzPjxnPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yOTEgLTQzMTQpIj48dXNlIGZpbGw9IiNmZmYiIHhsaW5rOmhyZWY9IiMyb2IyYSIvPjwvZz48L2c+PC9zdmc+");
    transition: all .3s; }
    @media (max-width: 767px) {
      .footer-menu__header:after {
        display: block; } }

@media (max-width: 767px) {
  .footer-menu__list {
    display: none;
    padding: 25px 0;
    background-color: #61687a;
    text-align: center; } }

.footer-menu__list--sub {
  margin: 21px 0 36px 20px; }
  @media (max-width: 991px) {
    .footer-menu__list--sub {
      display: none; } }
  .footer-menu__list--sub .footer-menu__item {
    margin-bottom: 14px; }
  .footer-menu__list--sub .footer-menu__link {
    color: #697386;
    font-size: 14px; }
    .footer-menu__list--sub .footer-menu__link:hover {
      color: #fff; }

.footer-menu__item {
  margin-bottom: 24px; }
  .footer-menu__item:last-child {
    margin-bottom: 0; }

.footer-menu__link {
  color: #969faf;
  font-size: 16px;
  transition: all .3s; }
  .footer-menu__link:hover {
    color: #c2c4c9; }
  @media (max-width: 991px) {
    .footer-menu__link {
      font-size: 14px; } }
  @media (max-width: 767px) {
    .footer-menu__link {
      color: #b5b9c1; } }

.footer-info {
  margin-bottom: 68px; }
  @media (max-width: 991px) {
    .footer-info {
      margin-bottom: 48px; } }
  .footer-info__item {
    margin-bottom: 26px;
    color: #c0c9d9;
    font-size: 16px; }
    @media (max-width: 991px) {
      .footer-info__item {
        font-size: 14px; } }
    .footer-info__item .icon {
      float: left;
      margin-right: 14px;
      transform: translateY(-3px); }
    .footer-info__item a {
      color: #c0c9d9; }

@media (max-width: 767px) {
  .footer-social {
    padding-bottom: 27px;
    margin: 0 -8px 37px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center; } }

.footer-social__item {
  float: left;
  margin-right: 15px;
  margin-bottom: 10px; }
  @media (max-width: 767px) {
    .footer-social__item {
      float: none;
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 479px) {
    .footer-social__item {
      margin-left: 5px;
      margin-right: 5px; } }
  .footer-social__item svg {
    width: 50px;
    height: 50px;
    transition: all .3s; }
    @media (max-width: 479px) {
      .footer-social__item svg {
        width: 40px;
        height: 40px; } }
    .footer-social__item svg:hover path {
      fill: #4185f4; }

.footer-service-menu {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  min-width: 100%;
  white-space: nowrap; }
  @media (max-width: 991px) {
    .footer-service-menu {
      display: block; } }
  @media (max-width: 767px) {
    .footer-service-menu {
      text-align: center; } }
  .footer-service-menu--sub {
    position: absolute;
    display: block;
    padding: 19px;
    bottom: 100%;
    right: 0;
    transform: translateY(-25px);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .3s; }
    .footer-service-menu--sub:after {
      top: 100%;
      right: 10px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 7px;
      margin-left: -7px; }
    .footer-service-menu--sub .footer-service-menu__item {
      margin-bottom: 15px;
      padding: 0; }
      .footer-service-menu--sub .footer-service-menu__item:last-child {
        margin-bottom: 0; }
    .footer-service-menu--sub .footer-service-menu__link {
      color: #000;
      white-space: nowrap; }
  .footer-service-menu__item {
    position: relative;
    padding: 10px 0;
    flex-shrink: 0; }
    @media (max-width: 991px) {
      .footer-service-menu__item {
        padding: 0;
        margin-bottom: 24px; }
        .footer-service-menu__item:last-child {
          margin-bottom: 0; } }
    .footer-service-menu__item:hover > .footer-service-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(5px); }
  .footer-service-menu__link {
    color: #fff;
    font-size: 16px;
    transition: all .3s; }
    .footer-service-menu__link:hover, .footer-service-menu__link:focus {
      color: #4185f4; }
    @media (max-width: 991px) {
      .footer-service-menu__link {
        font-size: 14px; } }

.jq-selectbox {
  z-index: 100; }

.get-consultation {
  position: fixed;
  z-index: 999;
  width: 215px;
  height: 60px;
  border-radius: 5px 0 0 5px;
  background-color: #4184f4;
  bottom: 65px;
  right: -155px;
  transition: all 0.3s; }
  .get-consultation:hover, .get-consultation.-hover {
    right: 0; }
  .get-consultation__link {
    z-index: 1;
    position: relative;
    display: block;
    height: 100%;
    padding: 16px 0 5px 60px;
    color: #fff !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-align: center; }
  .get-consultation:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    left: 0;
    top: 0;
    background: url("/_images/svg/c-consultation-btn.svg") no-repeat center;
    background-size: 60%;
    transition: all 0.3s; }
  .get-consultation.-active:before {
    background-size: 70%; }

.custom-dropdown-menu {
  overflow: hidden; }
  .custom-dropdown-menu.custom-dropdown-active {
    overflow: visible;
    transition: overflow .1s ease-in; }
    .custom-dropdown-menu.custom-dropdown-active > .custom-dropdown-menu-wrapper {
      background: white;
      transform: none !important;
      box-shadow: 0px 0px 100px rgba(65, 133, 244, 0.4); }
      .custom-dropdown-menu.custom-dropdown-active > .custom-dropdown-menu-wrapper .header__button {
        text-align: left; }
      .custom-dropdown-menu.custom-dropdown-active > .custom-dropdown-menu-wrapper::before {
        transform: rotate(180deg);
        top: 19px;
        transform-origin: center; }
  .custom-dropdown-menu-wrapper {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: column; }
    .custom-dropdown-menu-wrapper::before {
      content: '';
      width: 16px;
      height: 10px;
      position: absolute;
      right: 16px;
      top: calc(21px);
      background-image: url("/_images/svg/dropdown-arrow.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: transform .1s ease-in, top .1s ease-in; }
    .custom-dropdown-menu-wrapper > .header__button {
      border-top: 1px solid #ebeef3; }

.promo {
  position: relative;
  height: 100vh;
  background-color: #444; }
  @media (max-width: 991px) {
    .promo {
      margin-top: 59px;
      height: 50vh; } }
  @media (max-width: 767px) {
    .promo {
      margin-top: 85px;
      max-height: 203px; } }
  .promo__slider {
    height: 100%;
    z-index: 100; }
    .promo__slider .slick-list,
    .promo__slider .slick-track {
      height: 100%; }
  .promo__item {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    /*    @media (min-width: $sm) {
          background-image: none !important;
        }*/ }
    .promo__item:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.46+0,0+100 */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.46) 0%, rgba(125, 185, 232, 0) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.46) 0%, rgba(125, 185, 232, 0) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.46) 0%, rgba(125, 185, 232, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#75000000', endColorstr='#007db9e8', GradientType=0);
      /* IE6-9 */ }
    .promo__item:before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
    .promo__item .container {
      position: relative;
      z-index: 100;
      height: 100%; }
    .promo__item.slick-active .promo__content {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.7s linear; }
  .promo__content {
    position: absolute;
    left: 8px;
    bottom: 142px;
    transform: translateY(200%);
    opacity: 0; }
    @media (max-width: 991px) {
      .promo__content {
        bottom: 75px; } }
    @media (max-width: 767px) {
      .promo__content {
        bottom: 55px; } }
  .promo__video {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    display: block;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media (max-width: 767px) {
      .promo__video {
        display: none; } }
  .promo__title {
    margin-bottom: 62px;
    color: #fff;
    font-size: 72px;
    line-height: 82px; }
    @media (max-width: 991px) {
      .promo__title {
        margin-bottom: 30px;
        font-size: 36px;
        line-height: 44px; } }
    @media (max-width: 767px) {
      .promo__title {
        font-size: 22px;
        line-height: 26px; } }
  .promo__link {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all .3s; }
    @media (max-width: 991px) {
      .promo__link {
        font-size: 10px;
        letter-spacing: 1.43px; } }
    @media (max-width: 767px) {
      .promo__link {
        display: none; } }
    .promo__link:hover {
      color: #4185f4; }
      .promo__link:hover svg use {
        stroke: #4185f4; }
    .promo__link svg {
      margin-left: 15px;
      transform: translateY(1px);
      transition: all .3s; }
      @media (max-width: 991px) {
        .promo__link svg {
          width: 5px;
          height: 9px; } }
  .promo__pagination {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .promo__pagination .container {
      position: relative;
      height: 100%; }
      @media (max-width: 991px) {
        .promo__pagination .container {
          position: static; } }
  .promo__pagination-wrap {
    position: absolute;
    z-index: 100;
    width: 40px;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    text-align: center;
    transition: all .5s; }
    @media (max-width: 991px) {
      .promo__pagination-wrap {
        width: 100px;
        top: auto;
        right: 35px;
        bottom: 20px; } }
    @media (max-width: 767px) {
      .promo__pagination-wrap {
        width: auto;
        left: 8px;
        right: 8px; } }
  .promo__buttons .slick-arrow {
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    font-size: 0;
    outline: none; }
    .promo__buttons .slick-arrow:hover:before {
      opacity: 0.69; }
    .promo__buttons .slick-arrow:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      box-shadow: 0 1px 22px #4185f4;
      background-color: #000;
      border-radius: 50%;
      opacity: 0;
      transition: all .3s; }
    .promo__buttons .slick-arrow:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      background: transparent url("/_images/svg/promo-arrow.svg") center no-repeat; }
    .promo__buttons .slick-arrow.slick-prev {
      top: -33px;
      transform: rotate(-90deg); }
      @media (max-width: 991px) {
        .promo__buttons .slick-arrow.slick-prev {
          float: left;
          position: static;
          transform: rotate(-180deg); } }
    .promo__buttons .slick-arrow.slick-next {
      bottom: -33px;
      transform: rotate(90deg); }
      @media (max-width: 991px) {
        .promo__buttons .slick-arrow.slick-next {
          float: right;
          position: static;
          transform: rotate(0); } }
  @media (max-width: 991px) {
    .promo__dots {
      display: none; } }
  .promo__dots .slick-dots li {
    display: block; }
    .promo__dots .slick-dots li.slick-active button {
      opacity: 1; }
    .promo__dots .slick-dots li:first-child button {
      margin-top: 20px; }
    .promo__dots .slick-dots li:last-child button {
      margin-bottom: 20px; }
    .promo__dots .slick-dots li button {
      position: relative;
      margin: 8px 0;
      width: 40px;
      height: 40px;
      border: none;
      background: none;
      opacity: 0.5;
      color: #fff;
      font-size: 20px;
      outline: none;
      transition: all .3s; }
      .promo__dots .slick-dots li button:hover {
        opacity: 1; }
        .promo__dots .slick-dots li button:hover:before {
          opacity: 0.69; }
      .promo__dots .slick-dots li button:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;
        box-shadow: 0 1px 22px #4185f4;
        background-color: #000;
        border-radius: 50%;
        opacity: 0;
        transition: all .3s; }
  .promo__scroll {
    position: absolute;
    z-index: 100;
    bottom: 10px;
    left: 50%;
    margin-left: -20px;
    opacity: 0;
    animation-delay: 1s; }
    @media (max-width: 991px) {
      .promo__scroll {
        display: none; } }

.special {
  padding: 0 0 51px; }
  .special--main {
    padding-top: 46px; }
  .special__nav {
    margin-bottom: 45px;
    border-bottom: 1px solid #ebeaf1; }
  .special__nav-list {
    text-align: center; }
  .special__nav-item {
    display: inline-block;
    position: relative;
    margin: 0 20px; }
    .special__nav-item.active .special__nav-link:after {
      width: 100%; }
  .special__nav-link {
    display: block;
    padding-bottom: 14px;
    color: #515b6d;
    font-size: 20px;
    transition: all .3s; }
    @media (max-width: 1454px) {
      .special__nav-link {
        font-size: 16px; } }
    .special__nav-link:hover {
      color: #6d97f2; }
      .special__nav-link:hover:after {
        left: 0;
        width: 100%;
        background-color: #1f61ed; }
    .special__nav-link:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      width: 0px;
      height: 3px;
      background-color: #515b6d;
      transition: all .3s; }
  .special__tab-item {
    display: none; }
    .special__tab-item.active {
      display: block; }
  .special__item {
    position: relative;
    float: left;
    margin-bottom: 33px;
    padding-top: 69px;
    padding-right: 38px;
    padding-bottom: 54px;
    width: 50%;
    border-right: 1px solid #ebeaf1; }
    @media (max-width: 991px) {
      .special__item {
        margin-bottom: 21px;
        padding: 0 0 28px 0; } }
    @media (max-width: 767px) {
      .special__item {
        margin-bottom: 0;
        padding: 0 0 28px 0 !important;
        width: 100%;
        border-right: none !important; } }
    .special__item.special__item--even {
      padding-left: 38px;
      padding-right: 0;
      border-right: 0; }
  .special__image {
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .special__image {
        float: right;
        width: 200px;
        margin-bottom: 0; } }
    @media (min-width: 1455px) {
      .special__image {
        width: 280px; } }
    .special__image img {
      max-width: 100%; }
  .special__title {
    margin-bottom: 20px;
    color: #515b6d;
    font-size: 28px;
    font-weight: 300;
    line-height: 36px; }
    @media (min-width: 992px) {
      .special__title {
        margin-right: 230px; } }
    @media (min-width: 1455px) {
      .special__title {
        margin-right: 330px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .special__title {
        height: 64px;
        font-size: 24px;
        line-height: 32px; } }
    @media (max-width: 767px) {
      .special__title {
        font-size: 24px;
        line-height: 32px; } }
  .special__text {
    margin-bottom: 28px;
    color: #515b6d;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px; }
    @media (min-width: 992px) {
      .special__text {
        margin-right: 230px; } }
    @media (min-width: 1455px) {
      .special__text {
        margin-right: 330px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .special__text {
        height: 88px; } }
  .special__link {
    color: #4185f4;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.71px; }
    .special__link:hover {
      color: #1254ea; }
      .special__link:hover .icon {
        background-image: url("/_images/svg/arrow-right-hover.svg"); }
    .special__link .icon {
      margin-left: 15px;
      transform: translateY(2px); }

.splitter {
  clear: both;
  width: 100%;
  height: 1px;
  background: #ebeaf1;
  margin-bottom: 33px; }
  @media (max-width: 991px) {
    .splitter {
      margin-bottom: 21px; } }
  @media (max-width: 767px) {
    .splitter {
      margin-bottom: 32px; } }

.clients {
  padding: 46px 0 51px;
  background-color: #f0f5f9; }
  .clients__text {
    margin-bottom: 50px;
    color: #515b6d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center; }
    @media (max-width: 991px) {
      .clients__text {
        font-size: 14px;
        line-height: 22px; } }
    @media (max-width: 479px) {
      .clients__text {
        text-align: left; } }
  .clients__container {
    margin: 0 -8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; }
    @media (max-width: 991px) {
      .clients__container {
        flex-wrap: wrap; } }
    @media (max-width: 479px) {
      .clients__container {
        margin: 0 -10px 20px; } }
  .clients__item {
    padding: 0 8px; }
    @media (max-width: 991px) {
      .clients__item {
        flex-basis: 50%; } }
    @media (max-width: 479px) {
      .clients__item {
        padding: 0 7px; } }
    @media (max-width: 991px) {
      .clients__item:nth-of-type(odd) .clients__item-wrap {
        float: right; } }
  .clients__item-wrap {
    margin-bottom: 10px;
    height: 224px;
    width: 224px;
    flex-shrink: 0; }
    @media (max-width: 479px) {
      .clients__item-wrap {
        margin-bottom: 13px;
        height: 138px;
        width: 138px; } }
  .clients__link {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 32px;
    height: 100%;
    border: 1px solid rgba(65, 133, 244, 0.36);
    background-color: #fff;
    transition: all .3s; }
    .clients__link:hover {
      border-color: transparent;
      box-shadow: 0 0 150px 12px rgba(65, 133, 244, 0.36);
      border-radius: 3px;
      transform: scale(1.1);
      z-index: 200; }
    @media (max-width: 479px) {
      .clients__link {
        padding-bottom: 16px; } }
  .clients__image {
    position: absolute;
    left: 50%;
    top: 41%;
    transform: translate(-50%, -50%); }
    @media (max-width: 479px) {
      .clients__image {
        top: 32%;
        transform: translate(-50%, -50%) scale(0.8); } }
  .clients__title {
    color: #4185f4;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    backface-visibility: hidden; }
    @media (max-width: 479px) {
      .clients__title {
        font-size: 10px;
        line-height: 12px; } }

.loyalty {
  padding: 46px 0 51px; }
  .loyalty__text {
    margin-bottom: 50px;
    color: #515b6d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center; }
    @media (max-width: 991px) {
      .loyalty__text {
        font-size: 14px;
        line-height: 22px; } }
    @media (max-width: 767px) {
      .loyalty__text {
        text-align: left; } }
  .loyalty__nav {
    margin-bottom: 45px;
    border-bottom: 1px solid #ebeaf1; }
    @media (max-width: 767px) {
      .loyalty__nav {
        border: none; } }
  .loyalty__nav-list {
    text-align: center; }
  .loyalty__nav-item {
    display: inline-block;
    position: relative;
    margin: 0 20px; }
    @media (max-width: 767px) {
      .loyalty__nav-item {
        display: block;
        padding: 0;
        margin: 0 0 15px; } }
    .loyalty__nav-item.active .loyalty__nav-link:after {
      width: 100%; }
    @media (max-width: 767px) {
      .loyalty__nav-item.active .loyalty__nav-link {
        background-color: #2ed672;
        border-color: transparent;
        color: #fff; } }
  .loyalty__nav-link {
    display: block;
    padding-bottom: 14px;
    color: #515b6d;
    font-size: 20px;
    transition: all .3s; }
    @media (max-width: 1454px) {
      .loyalty__nav-link {
        font-size: 16px; } }
    @media (max-width: 767px) {
      .loyalty__nav-link {
        display: inline-block;
        border-radius: 5px;
        border: 1px solid rgba(81, 91, 109, 0.3);
        padding: 0 15px;
        height: 32px;
        line-height: 30px; } }
    .loyalty__nav-link:hover {
      color: #6d97f2; }
      .loyalty__nav-link:hover:after {
        left: 0;
        width: 100%;
        background-color: #1f61ed; }
    .loyalty__nav-link:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      width: 0px;
      height: 3px;
      background-color: #515b6d;
      transition: all .3s; }
      @media (max-width: 767px) {
        .loyalty__nav-link:after {
          display: none; } }
  .loyalty__tab-item {
    display: none; }
    .loyalty__tab-item.active {
      display: block; }
  .loyalty__item {
    position: relative; }
    @media (max-width: 767px) {
      .loyalty__item {
        margin-left: -24px;
        margin-right: -24px; } }
  .loyalty__image {
    width: 100%;
    max-width: 100%; }
  .loyalty__button {
    display: inline-block;
    position: absolute;
    left: 73px;
    bottom: 47px;
    padding: 0 24px;
    height: 41px;
    border-radius: 3px;
    border: 2px solid #fff;
    line-height: 39px;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    transition: all .3s; }
    @media (max-width: 767px) {
      .loyalty__button {
        left: 30px;
        bottom: 39px;
        height: 36px;
        line-height: 34px;
        font-size: 13px; } }
    .loyalty__button:hover {
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.5);
      background-color: #fff;
      color: #4185f4; }
  .loyalty--inner {
    padding: 0; }

/* Partners page */
.button-blue {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  outline: none;
  border: 0;
  cursor: pointer;
  color: #fff;
  height: 45px;
  font-size: 12px;
  line-height: 45px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  background-color: #4185F4;
  border-radius: 5px;
  padding: 0 30px;
  transition: .3s;
  position: relative;
  z-index: 2; }
  .button-blue.disable {
    background: rgba(65, 133, 244, 0.2); }
    .button-blue.disable:hover {
      background: rgba(65, 133, 244, 0.2);
      box-shadow: none; }
  .button-blue:hover {
    color: #fff;
    background-color: #3B52EA;
    box-shadow: 0 11px 23px rgba(65, 133, 244, 0.43); }
  .button-blue:focus {
    outline: none;
    color: #fff; }

.partners {
  padding: 0px 0 10px; }
  .partners__text {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 30px;
    color: #515B6D; }
  .partners h2 {
    margin-bottom: 46px; }
  .partners ol {
    padding: 0;
    margin-bottom: 30px;
    counter-reset: li; }
    .partners ol li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 7px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #515B6D; }
      .partners ol li:before {
        content: counter(li) ".";
        counter-increment: li;
        position: absolute;
        top: 0;
        left: 0;
        color: #4185F4;
        font-size: 20px;
        font-weight: 400; }

.become-partner {
  padding: 30px 0;
  background-color: #F0F5F9; }
  .become-partner__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
    color: #515B6D;
    margin-bottom: 40px; }
    .become-partner__text a {
      color: #4185F4;
      border-bottom: 1px dashed rgba(65, 133, 244, 0.5);
      transition: .3s; }
      .become-partner__text a:hover {
        border-color: rgba(18, 84, 234, 0.5);
        color: #1254ea; }

.form__input {
  position: relative;
  margin-bottom: 15px; }

.form__label {
  z-index: 101;
  font-size: 16px;
  font-weight: 400;
  color: rgba(81, 91, 109, 0.6);
  position: absolute;
  left: 17px;
  top: 21px;
  pointer-events: none;
  transition: .3s; }
  .form__label--top {
    z-index: 101; }
  .form__label--selected {
    z-index: 101;
    display: none; }

.form__txt, .form__text {
  display: block;
  width: 100%;
  height: 58px;
  background-color: #fff;
  border: 1px solid #D0D7E2;
  border-radius: 3px;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 17px;
  color: #000;
  font-size: 16px;
  font-weight: 300; }
  .form__txt:focus, .form__text:focus {
    outline: none; }
  .form__txt[disabled], .form__text[disabled] {
    opacity: 0.5; }

.form__text {
  height: 116px;
  padding: 17px;
  padding-top: 25px;
  resize: none; }

.form__button {
  text-align: center;
  padding-top: 11px; }

.form__btn {
  min-width: 220px;
  position: relative; }
  .form__btn--full {
    width: 100%;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.form__note {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: #515B6D;
  text-align: center;
  padding-top: 22px; }
  .form__note a {
    color: #4185F4;
    transition: .3s; }
    .form__note a:hover {
      color: #1254EA; }

.form__input.active .form__label {
  font-size: 12px;
  top: 8px; }

.form__input--file .form__txt {
  padding-right: 35px; }

.form__file .input-file {
  position: absolute;
  top: 15px;
  right: 13px;
  z-index: 2; }
  .form__file .input-file:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: -10px;
    left: -18px;
    width: 50px;
    height: 50px;
    background: radial-gradient(#ffffff 40%, transparent); }
  .form__file .input-file__input {
    display: none; }
  .form__file .input-file__btn {
    display: block;
    width: 30px;
    height: 30px;
    background-color: #2ED672;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    transition: .3s;
    box-shadow: 0 2px 25px #fff; }
    .form__file .input-file__btn:before {
      content: "";
      width: 12px;
      height: 12px;
      background: transparent url(/_images/svg/plus-white.svg) no-repeat 0 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px; }
    .form__file .input-file__btn:hover {
      background-color: #0DBF55;
      box-shadow: 0 2px 21px rgba(46, 214, 114, 0.51); }
    .form__file .input-file__btn.disabled {
      display: none; }
    .form__file .input-file__btn--delete {
      display: none;
      z-index: 2;
      background-color: #FF6451; }
      .form__file .input-file__btn--delete.active {
        display: block; }
      .form__file .input-file__btn--delete:before {
        transform: rotate(45deg); }

.form__file-inner {
  position: relative; }
  .form__file-inner.active .form__label {
    font-size: 12px;
    top: 11px; }

.form__hint {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: rgba(81, 91, 109, 0.65);
  padding-top: 15px; }

.form__error {
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  color: #f00;
  padding-top: 15px; }
  .form__error.-warn {
    color: #ca9d0b; }

.form-forgot {
  float: right;
  padding-top: 13px;
  margin-bottom: 18px; }
  .form-forgot__link {
    display: inline-block;
    vertical-align: top;
    color: #4185F4;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 1px dashed rgba(65, 133, 244, 0.5);
    padding-bottom: 2px;
    transition: .3s; }
    .form-forgot__link:hover {
      color: #1254EA;
      border-color: rgba(18, 84, 234, 0.5); }

.view-password {
  position: absolute;
  top: 21px;
  right: 17px;
  width: 20px;
  height: 15px;
  background: url("/_images/svg/eye_closed.svg") no-repeat center; }
  .view-password--open {
    background-image: url("/_images/svg/eye.svg");
    top: 19px; }

.phones {
  padding-top: 30px; }
  .phones__item {
    margin-bottom: 30px;
    font-weight: 300; }
  .phones__title {
    font-size: 16px;
    line-height: 22px;
    color: #4185F4;
    margin-bottom: 17px; }
  .phones__number {
    font-size: 14px;
    line-height: 20px;
    color: #515B6D;
    margin-bottom: 1px; }
  .phones__additional {
    color: rgba(81, 91, 109, 0.5); }
  .phones__name {
    font-size: 14px;
    line-height: 20px;
    color: #515B6D; }

/* About Page */
.about {
  padding-top: 0; }
  .about__icon {
    text-align: center;
    margin-bottom: 33px; }
  .about__text {
    color: #515B6D;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 30px;
    text-align: center; }
  .about__list {
    margin-bottom: 20px; }
  .about__item {
    text-align: center;
    margin-bottom: 30px; }
  .about__digit {
    color: #FF6451;
    font-size: 70px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 23px; }
  .about__label {
    font-size: 24px;
    font-weight: 300;
    color: #515B6D; }

.about-promo {
  position: relative; }
  .about-promo__sub {
    margin-top: -25px;
    margin-bottom: 30px;
    padding: 0 50px; }
  .about-promo__slider {
    outline: none; }
    .about-promo__slider--main .about-promo__item {
      background: #fff no-repeat center;
      background-size: cover;
      height: 580px; }
    .about-promo__slider--sub {
      max-width: 1220px;
      margin: 0 auto;
      padding: 16px 9px;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0 0 172px 6px rgba(65, 133, 244, 0.36); }
      .about-promo__slider--sub .about-promo__item {
        position: relative;
        cursor: pointer; }
        .about-promo__slider--sub .about-promo__item:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: #4185f4;
          transition: opacity 0.3s;
          opacity: 0; }
        .about-promo__slider--sub .about-promo__item:hover:before {
          opacity: 0.3; }
        .about-promo__slider--sub .about-promo__item.slick-current.slick-active img {
          border: 2px solid #4185F4;
          box-sizing: border-box; }
  .about-promo__item {
    outline: none;
    border: 0;
    max-height: 580px;
    overflow: hidden; }
    .about-promo__item img {
      vertical-align: middle;
      margin: 0 auto;
      max-width: 100%;
      height: auto; }
  .about-promo__slider--main img {
    width: 100%; }
  .about-promo__slider--sub .about-promo__item {
    margin: 0 8px; }
  .about-promo__arrow {
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0; }
    .about-promo__arrow .icon {
      position: absolute;
      bottom: 50px; }
    .about-promo__arrow--prev {
      left: -40px; }
      .about-promo__arrow--prev .icon {
        left: 0; }
    .about-promo__arrow--next {
      right: -40px; }
      .about-promo__arrow--next .icon {
        right: 0; }

.advantages__item {
  margin-bottom: 30px; }

.advantages__icon {
  text-align: center;
  margin-bottom: 20px; }

.advantages__title {
  color: #000;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  margin-bottom: 10px;
  word-wrap: break-word; }

.advantages__desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: #515B6D;
  margin-bottom: 15px; }

.advantages__link {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #4185F4;
  text-transform: uppercase;
  letter-spacing: 1.71px; }
  .advantages__link .icon {
    vertical-align: top;
    margin-left: 18px; }
  .advantages__link:hover {
    color: #1254ea; }
    .advantages__link:hover .icon {
      background-image: url(/_images/svg/arrow-right-hover.svg); }

.condition {
  background-color: #F0F5F9;
  padding: 30px 0; }
  .condition__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #515B6D;
    margin-bottom: 30px;
    text-align: center; }
  .condition__button {
    text-align: center; }
  .condition__btn {
    min-width: 256px; }

/* Contacts page */
.contacts {
  padding: 0px 0 40px; }
  .contacts__icon {
    text-align: center;
    position: relative;
    padding-bottom: 2px;
    margin-top: 6px;
    margin-bottom: 30px; }
    .contacts__icon:after {
      content: "";
      width: 110px;
      height: 2px;
      background: #4185f4;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -55px; }
  .contacts__text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    color: #515B6D;
    margin-bottom: 25px;
    text-align: center; }
  .contacts__phone {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    text-align: center; }
    .contacts__phone a {
      color: #4185F4; }
      .contacts__phone a span {
        color: #515B6D; }

.clients--contacts {
  padding: 17px 0 7px; }
  .clients--contacts .clients__container {
    margin-bottom: 0; }
  .clients--contacts .clients__link {
    border-color: #EBEAF1; }

/* Reviews page */
.reviews {
  padding: 0px 0 50px; }
  .reviews__add {
    height: 290px;
    background: #F0F5F9;
    text-align: center;
    padding: 40px 20px 37px;
    border-radius: 5px;
    margin-bottom: 16px; }
  .reviews__icon {
    margin-bottom: 19px; }
  .reviews__desc {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color: #515B6D;
    margin-bottom: 30px; }
  .reviews__btn {
    min-width: 224px; }
  .reviews__item {
    position: relative;
    margin-bottom: 16px; }
  .reviews__inner {
    border: 2px solid #F0F5F9;
    border-radius: 5px;
    padding: 20px 20px 15px;
    background: #fff;
    position: relative;
    transition: .3s; }
  .reviews__user {
    overflow: hidden;
    margin-bottom: 19px; }
  .reviews__img {
    width: 78px;
    margin: 0 auto 20px; }
    .reviews__img img {
      display: block;
      border-radius: 50%;
      border: 1px solid #4185f4;
      max-width: 100%;
      height: auto; }
  .reviews__name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    color: #4185F4;
    margin-bottom: 9px; }
  .reviews__date {
    font-size: 12px;
    font-weight: 300;
    color: #515B6D; }
  .reviews__user--admin .reviews__name {
    color: #FF6451; }
  .reviews__text {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: #515B6D;
    position: relative; }
  .reviews__entry {
    max-height: 132px;
    overflow: hidden; }
  .reviews__detail {
    display: none; }
  .reviews__action {
    overflow: hidden;
    padding-top: 15px; }
  .reviews__full {
    display: block;
    float: left;
    font-size: 14px;
    font-weight: 300;
    color: #2ED672;
    text-decoration: none; }
    @media (max-width: 479px) {
      .reviews__full {
        float: none;
        margin-bottom: 15px; } }
    .reviews__full:hover {
      color: #14AC77; }
    .reviews__full:focus, .reviews__full:active {
      color: #2ED672; }
    .reviews__full .icon {
      vertical-align: top;
      margin: 0 10px 0 0; }
  .reviews__view {
    display: inline-block;
    float: right;
    font-size: 14px;
    font-weight: 300;
    color: #4185F4;
    text-decoration: none;
    border-bottom: 1px dashed rgba(65, 133, 244, 0.5);
    transition: .3s; }
    @media (max-width: 479px) {
      .reviews__view {
        float: none; } }
    .reviews__view:hover {
      color: #1254ea;
      border-color: rgba(18, 84, 234, 0.5); }
    .reviews__view:focus, .reviews__view:active {
      color: #4185F4; }
  .reviews__more {
    padding: 28px 0 0;
    text-align: center; }
  .reviews__show {
    min-width: 256px;
    border: 2px solid #4185f4; }
  .reviews__response {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 5;
    margin-top: 16px;
    background: #fff;
    border: 2px solid #FF6451;
    border-radius: 5px;
    padding: 20px 20px 15px; }
    .reviews__response:before {
      content: "";
      border-bottom: 11px solid #FF6451;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      position: absolute;
      top: -11px;
      left: 50%;
      margin-left: -9px; }
    .reviews__response:after {
      content: "";
      border-bottom: 11px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      top: -9px;
      left: 50%;
      margin-left: -8px; }
  .reviews__item.active .reviews__full .icon {
    background-image: url(/_images/svg/minus-green.svg); }

/* Modals */
.modal-content {
  border: 0;
  border-radius: 0;
  background-color: #F0F5F9;
  box-shadow: 0 0 172px 6px rgba(65, 133, 244, 0.37); }

.modal-close {
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center; }
  .modal-close i {
    margin-top: 9px; }

.jq-selectbox {
  width: 100%;
  z-index: 100; }
  .jq-selectbox.error .jq-selectbox__select {
    border-color: #eb0c0c; }
  .jq-selectbox__select {
    height: 58px;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #D0D7E2;
    color: #000;
    font-size: 16px;
    line-height: 54px;
    padding-right: 56px;
    padding-left: 17px; }
    .jq-selectbox__select.disable {
      opacity: 0.4; }
  .jq-selectbox__trigger {
    width: 56px;
    height: 100%;
    border-left: 1px solid #D0D7E2;
    position: absolute;
    top: 0;
    right: 0; }
  .jq-selectbox__search input {
    border-radius: 0; }

.popup {
  max-width: 614px;
  margin: 0 auto;
  padding: 0 15px 30px; }
  .popup__container {
    max-width: 614px;
    margin: 0 auto 30px;
    padding-left: 15px;
    padding-right: 15px; }
  .popup__title {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #000;
    padding: 25px 0; }
  .popup__subtitle {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    color: #000;
    padding: 5px 0;
    margin-bottom: 10px; }
  .popup__desc {
    text-align: center;
    color: #515B6D;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 20px; }
  .popup__phone {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    color: #515B6D;
    margin-bottom: 30px; }
    .popup__phone span {
      color: #4185F4; }
  .popup--consult .popup__title {
    padding: 25px 0 15px; }
  .popup--login {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    max-width: 100%; }

.popup-nav {
  padding: 30px 0; }
  .popup-nav__list {
    margin: 0;
    list-style: none;
    text-align: center; }
  .popup-nav__item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 22px 3px;
    border-right: 1px solid #515B6D;
    margin-right: -5px; }
    .popup-nav__item:last-child {
      border-right: 0; }
  .popup-nav__link {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: 300;
    transition: color .3s, border-color .3s; }
    .popup-nav__link:hover {
      color: #1254EA;
      border-color: #1254EA; }
  .popup-nav__item.active .popup-nav__link {
    color: #000;
    border: 0;
    padding-bottom: 1px; }

.popup-content__tab {
  display: none; }
  .popup-content__tab.active {
    display: block; }

.popup-social {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid #EBEAF1;
  border-bottom: 1px solid #EBEAF1;
  padding: 33px 0 18px; }
  .popup-social__title {
    color: #515B6D;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 26px; }
  .popup-social__list {
    padding: 0 20px;
    margin: 0;
    list-style: none; }
  .popup-social__item {
    display: inline-block;
    vertical-align: top;
    margin: 0 7px 7px; }
  .popup-social__link {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%; }
    .popup-social__link--facebook {
      background: url("/_images/svg/fb-icon.svg") no-repeat center; }
      .popup-social__link--facebook:hover {
        background-image: url("/_images/svg/fb-hover.svg"); }
    .popup-social__link--mailruopenid {
      background: url("/_images/svg/ml-icon.svg") no-repeat center; }
    .popup-social__link--vkontakte {
      background: url("/_images/svg/vk-icon.svg") no-repeat center; }
      .popup-social__link--vkontakte:hover {
        background-image: url("/_images/svg/vk-hover.svg"); }
    .popup-social__link--googleoauth {
      background: url("/_images/svg/gl-icon.svg") no-repeat center; }
      .popup-social__link--googleoauth:hover {
        background-image: url("/_images/svg/gl-hover.svg"); }
    .popup-social__link--odnoklassniki {
      background: url("/_images/svg/ok-icon.svg") no-repeat center; }
      .popup-social__link--odnoklassniki:hover {
        background-image: url("/_images/svg/ok-hover.svg"); }

.reviews-form__author {
  text-align: center;
  margin-bottom: 27px; }

.reviews-form__img {
  margin-bottom: 15px; }

.reviews-form__name {
  color: #4185F4;
  font-size: 22px;
  line-height: 29px;
  font-weight: 300; }

/* Vacancy */
.vacancy {
  padding-top: 20px; }
  .vacancy h1 {
    margin-bottom: 0; }
  .vacancy__text {
    text-align: center;
    color: #515B6D;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    margin: 0px 0 22px; }

.vacancy-menu {
  background-color: #F0F5F9;
  padding: 30px 0 15px; }
  .vacancy-menu.active .vacancy-menu__icon {
    float: left;
    margin: 0; }
  .vacancy-menu.active .vacancy-menu__info {
    float: none;
    padding: 15px 0 0 105px; }
  .vacancy-menu.active .vacancy-menu__more {
    display: none; }
  .vacancy-menu__item {
    margin-bottom: 15px; }
  .vacancy-menu__link {
    display: block;
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(65, 133, 244, 0.3);
    border-radius: 4px;
    text-decoration: none;
    transition: all .3s; }
    .vacancy-menu__link--empty {
      cursor: default;
      background-color: transparent; }
    .vacancy-menu__link:focus {
      outline: none; }
  .vacancy-menu__inner {
    position: relative; }
  .vacancy-menu__icon {
    float: left;
    width: 85px;
    height: 85px;
    position: relative;
    border-radius: 50%;
    border: 1px solid #4185F4;
    margin-bottom: 15px;
    transition: all .3s; }
  .vacancy-menu__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transition: opacity .3s; }
    .vacancy-menu__img--hover {
      opacity: 0; }
  .vacancy-menu__info {
    float: none;
    padding-left: 105px; }
  .vacancy-menu__title {
    color: #000;
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 5px; }
  .vacancy-menu__count {
    color: #515B6D;
    font-size: 16px;
    font-weight: 300; }
  .vacancy-menu__more {
    color: #4185F4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    margin-top: 15px;
    padding-left: 105px; }
    .vacancy-menu__more i {
      vertical-align: top;
      margin-left: 10px; }
  .vacancy-menu__link.active {
    background-color: #4185F4;
    border-color: #4185F4;
    color: #fff; }
    .vacancy-menu__link.active .vacancy-menu__icon {
      border-color: #fff; }
    .vacancy-menu__link.active .vacancy-menu__img--default {
      opacity: 0; }
    .vacancy-menu__link.active .vacancy-menu__img--hover {
      opacity: 1; }
    .vacancy-menu__link.active .vacancy-menu__title, .vacancy-menu__link.active .vacancy-menu__count {
      color: inherit; }

.vacancy-content {
  padding: 30px 0 40px;
  display: none;
  opacity: 0;
  transition: opacity .15s linear; }
  .vacancy-content.active {
    display: block;
    opacity: 1; }
  .vacancy-content__pane {
    display: none;
    opacity: 0;
    transition: opacity .15s linear; }
    .vacancy-content__pane.active {
      display: block;
      opacity: 1; }
    .vacancy-content__pane h2 {
      margin-bottom: 15px; }
  .vacancy-content__count {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px; }
  .vacancy-content__item {
    margin-bottom: -1px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #EBEAF1; }
  .vacancy-content__link {
    display: block;
    font-weight: 300;
    font-size: 16px;
    padding: 20px 0 20px 25px; }
    .vacancy-content__link i {
      display: none;
      vertical-align: top;
      margin: 7px 25px 0 0; }
  .vacancy-content__item .vacancy-content__link {
    color: #4185F4;
    transition: ease .3s; }
  .vacancy-content__item.active {
    border-color: #4185F4;
    margin-bottom: 0;
    box-shadow: inset 0 0 1px 1px #4185f4; }
    .vacancy-content__item.active .vacancy-content__link {
      color: #fff;
      background: #4185F4; }
  .vacancy-content__desc {
    padding: 30px 25px;
    color: #515B6D;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300; }
  .vacancy-content__caption {
    padding: 20px 0 18px;
    color: #000103;
    font-size: 16px; }
  .vacancy-content ul {
    margin-bottom: 7px; }
    .vacancy-content ul li {
      padding: 0 0 0 20px;
      margin-bottom: 6px;
      position: relative; }
      .vacancy-content ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #4185f4;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: 0; }
  .vacancy-content__btn {
    padding: 0 10px;
    display: block;
    text-align: center;
    margin-top: 34px; }

.become-job {
  padding: 30px 0;
  background-color: #F0F5F9; }
  .become-job__text {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 30px; }

/* Bonus main */
.bonus {
  padding-top: 30px; }
  .bonus--main h1 {
    margin-bottom: 17px; }
  .bonus__text {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;
    max-width: 950px;
    margin: 0 auto 30px; }
  .bonus__item {
    margin-bottom: 40px; }

/* Bonus detail */
.bonus-detail {
  padding-top: 30px;
  padding-bottom: 30px; }
  .bonus-detail__image {
    margin-bottom: 30px; }
  .bonus-detail__desc {
    color: #515B6D;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px; }
    .bonus-detail__desc h2 {
      line-height: 34px;
      margin: 30px 0; }
    .bonus-detail__desc ul {
      margin-bottom: 30px; }
      .bonus-detail__desc ul li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 25px; }
        .bonus-detail__desc ul li:before {
          content: "";
          width: 26px;
          height: 2px;
          background: #4185f4;
          position: absolute;
          top: 10px;
          left: 0; }
  .bonus-detail__preview {
    margin-bottom: 30px; }
  .bonus-detail__table table {
    width: 100%;
    min-width: 600px; }
    .bonus-detail__table table thead tr td {
      text-align: center;
      font-weight: 400;
      padding: 14px 4px; }
    .bonus-detail__table table tbody tr td {
      text-align: center;
      border: 1px solid #EBEAF1;
      padding: 15px 16px; }
      .bonus-detail__table table tbody tr td:first-child {
        text-align: left; }

.bonus-attention {
  padding: 35px 0 25px;
  background-color: #F0F5F9; }
  .bonus-attention__icon {
    margin-bottom: 5px;
    text-align: center; }
    .bonus-attention__icon i {
      vertical-align: top; }
  .bonus-attention h4 {
    color: #FF6451; }
  .bonus-attention ol {
    color: #515B6D;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    counter-reset: li; }
    .bonus-attention ol li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #515B6D; }
      .bonus-attention ol li a {
        font-weight: 400;
        color: #4185F4; }
        .bonus-attention ol li a span {
          color: #515B6D; }
      .bonus-attention ol li:before {
        content: counter(li) ".";
        counter-increment: li;
        position: absolute;
        top: 0;
        left: 0;
        color: #FF6451;
        font-size: 20px;
        font-weight: 400; }

/* 404 */
.found {
  padding: 20px 0 50px; }
  .found__top {
    border-bottom: 2px solid #F0F5F9;
    margin-bottom: 30px; }
  .found__inner {
    position: relative;
    text-align: center;
    overflow: hidden; }
    .found__inner:before {
      content: "";
      width: 150px;
      height: 47px;
      background: url(../_images/svg/car-b-bg.svg) no-repeat 0 0/contain;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1; }
  .found__label {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    height: 50px;
    text-align: center;
    color: #fff;
    font-size: 26px;
    line-height: 48px;
    background-color: #FF6451;
    margin-bottom: 30px; }
  .found__caption {
    padding-left: 110px; }
    .found__caption svg {
      max-width: 100%;
      height: auto; }
  .found__desc {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300; }
    .found__desc a {
      color: #4185F4; }
      .found__desc a:hover {
        color: #1254ea; }

/* Address */
.address {
  padding: 0 0 40px; }

.address-nav {
  margin-bottom: 30px;
  border-bottom: 1px solid #ebeaf1; }
  .address-nav__list {
    text-align: center; }
  .address-nav__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0 20px 20px; }
  .address-nav__link {
    display: block;
    color: #515b6d;
    font-size: 16px;
    padding-bottom: 14px;
    transition: all .3s;
    opacity: .7; }
    .address-nav__link:hover {
      color: #6d97f2;
      opacity: 1; }
    .address-nav__link:after {
      content: "";
      width: 0px;
      height: 3px;
      background-color: #515b6d;
      position: absolute;
      right: 0;
      bottom: -1px;
      transition: all .3s; }
  .address-nav__item.active .address-nav__link {
    opacity: 1; }
    .address-nav__item.active .address-nav__link:after {
      width: 100%; }

.address-content {
  margin-bottom: 40px; }
  .address-content__tab {
    display: none; }
    .address-content__tab.active {
      display: block; }
  .address-content__thead {
    display: none;
    color: #515B6D;
    font-size: 16px;
    padding: 14px 40px 14px 75px; }
  .address-content__item {
    margin-bottom: -1px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #ebeaf1; }
    .address-content__item .address-content__link {
      display: block;
      color: #4185F4;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      padding: 20px;
      position: relative;
      transition: color ease .3s, background ease .3s; }
      .address-content__item .address-content__link:focus {
        outline: none; }
      .address-content__item .address-content__link i {
        display: none;
        position: absolute;
        left: 37px;
        top: 25px; }
  .address-content__row {
    margin-bottom: 5px;
    white-space: nowrap; }
  .address-content__location {
    border-bottom: 1px dashed rgba(65, 133, 244, 0.5); }
  .address-content__item.active {
    border-width: 2px;
    border-color: #4185f4;
    margin-bottom: 0; }
    .address-content__item.active .address-content__link {
      color: #fff;
      background: #4185F4;
      padding: 19px 19px 20px; }
      .address-content__item.active .address-content__link i {
        background-image: url(/_images/svg/arrow-up-white.svg); }
    .address-content__item.active .address-content__location {
      border-color: #fff; }
  .address-content__desc {
    padding: 20px;
    background-color: rgba(65, 133, 244, 0.05);
    color: #515B6D;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300; }
  .address-content__group {
    border-bottom: 1px solid rgba(65, 133, 244, 0.3);
    padding: 9px 0; }
    .address-content__group:last-child {
      border-bottom: 0;
      padding-bottom: 0; }
  .address-content__phone span {
    color: rgba(81, 91, 109, 0.5); }

.address-map__area {
  width: 100%;
  height: 340px; }

.address-balloon {
  padding: 18px;
  font-weight: 300;
  color: #40485a; }
  .address-balloon__title {
    margin-bottom: 16px;
    color: #4185f4;
    font-size: 22px; }
  .address-balloon__item {
    margin-bottom: 10px;
    font-size: 12px; }
    .address-balloon__item span {
      color: #515b6d;
      font-size: 14px;
      font-weight: 400; }
    .address-balloon__item a {
      color: #336df1;
      border-bottom: 1px dashed #a0c2f9; }

@keyframes fadeInToRight {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  70% {
    opacity: 0.2; }
  99.999% {
    transform: translateX(0px);
    opacity: 1; }
  100% {
    transform: none; } }

@keyframes fadeInToLeft {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  70% {
    opacity: 0.2; }
  99.999% {
    transform: translateX(0px);
    opacity: 1; }
  100% {
    transform: none; } }

@keyframes fadeOutToRight {
  0% {
    transform: translateX(0px);
    opacity: 1; }
  99.999% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    display: none; } }

@keyframes fadeOutToLeft {
  0% {
    transform: translateX(0px);
    opacity: 1; }
  99.999% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    display: none; } }

.cabinet__history-list {
  width: 100%; }

.cabinet__history-item {
  width: 100%;
  border: 1px solid #EBEAF1; }
  .cabinet__history-item:not(:first-of-type) {
    margin-top: 30px; }

.cabinet__history-item-header {
  border-bottom: 1px solid #EBEAF1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  @media (min-width: 767px) {
    .cabinet__history-item-header {
      padding: 9px 20px; } }
  @media (max-width: 767px) {
    .cabinet__history-item-header {
      padding: 12px 20px; } }

.cabinet__history-item-info {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center; }
  @media (max-width: 767px) {
    .cabinet__history-item-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }

.cabinet__history-item-number {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 23px;
  font-size: 16px;
  margin-right: 30px;
  user-select: none;
  color: #000; }
  .cabinet__history-item-number:hover {
    color: #4185F4;
    cursor: pointer; }

.cabinet__history-item-date {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 14px; }
  @media (max-width: 767px) {
    .cabinet__history-item-date {
      margin-top: 5px; } }

.cabinet__history-item-state {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 14px;
  text-align: right; }
  .cabinet__history-item-state.normal {
    color: #4185F4; }
  .cabinet__history-item-state.success {
    color: #2ED672; }
  .cabinet__history-item-state.fail {
    color: #FF6451; }
  @media (max-width: 767px) {
    .cabinet__history-item-state {
      align-self: baseline; } }

.cabinet__history-item-content {
  display: flex;
  justify-content: space-between; }
  @media (min-width: 767px) {
    .cabinet__history-item-content {
      padding: 20px; } }
  @media (max-width: 767px) {
    .cabinet__history-item-content {
      flex-direction: column;
      padding: 20px 15px; } }

.cabinet__history-item-gallery {
  height: 80px;
  display: flex;
  justify-content: flex-start; }
  .cabinet__history-item-gallery a {
    width: fit-content;
    height: fit-content;
    margin-right: 10px; }
    .cabinet__history-item-gallery a img {
      float: left; }

.cabinet__history-item-gallery-img {
  max-height: 80px;
  max-width: 80px;
  padding: 1px;
  transition: border .2s ease-in; }
  .cabinet__history-item-gallery-img:not(.disabled):hover {
    padding: 0px;
    border: 1px solid rgba(81, 91, 109, 0.8);
    cursor: pointer; }
  .cabinet__history-item-gallery-img.disabled {
    cursor: default;
    opacity: .5; }

.cabinet__history-item-gallery.overflowed::after {
  content: attr(data-content);
  display: block;
  width: 80px;
  height: 80px;
  border: 1px solid rgba(208, 215, 226, 0.5);
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  color: #515B6D;
  user-select: none;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  line-height: 80px; }

.cabinet__history-item-right {
  height: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media (max-width: 767px) {
    .cabinet__history-item-right {
      margin-top: 25px; } }

.cabinet__history-item-price {
  text-align: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 22px;
  text-align: right;
  color: #515B6D; }
  @media (max-width: 767px) {
    .cabinet__history-item-price {
      text-align: left;
      margin-bottom: 5px; } }

.cabinet__history-item-buttons {
  display: flex; }
  @media (min-width: 767px) {
    .cabinet__history-item-buttons {
      margin-left: 100px;
      margin-top: 25px; } }
  @media (max-width: 767px) {
    .cabinet__history-item-buttons {
      flex-direction: column;
      margin-top: 20px; } }
  @media (max-width: 767px) {
    .cabinet__history-item-buttons button:not(:only-of-type):first-of-type {
      margin-bottom: 15px; } }
  @media (min-width: 767px) {
    .cabinet__history-item-buttons button:not(:only-of-type):first-of-type {
      margin-right: 20px;
      width: 182px; } }

.cabinet__order-detail {
  border: 1px solid rgba(208, 215, 226, 0.5);
  padding: 30px; }
  @media (max-width: 767px) {
    .cabinet__order-detail {
      padding: 16px;
      margin-bottom: 0px;
      border-bottom: none; } }

.cabinet__back {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 1.71429px;
  text-transform: uppercase;
  color: #4185F4;
  user-select: none;
  cursor: pointer;
  position: relative;
  padding-left: 50px; }
  .cabinet__back::before {
    content: '';
    display: block;
    height: 14px;
    width: 32px;
    position: absolute;
    left: 0px;
    background-image: url("/_images/tyres/arrow-back.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }

.cabinet__order-head {
  width: 100%;
  padding: 33px 0px; }
  @media (max-width: 767px) {
    .cabinet__order-head {
      padding: 20px 0px; } }

.cabinet__order-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  font-size: 26px;
  color: #000000; }
  @media (max-width: 767px) {
    .cabinet__order-num {
      font-size: 20px;
      padding: 10px 0px 6px 0px; } }

.cabinet__order-date {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 16px;
  color: #515B6D;
  margin-top: 30px; }
  @media (max-width: 767px) {
    .cabinet__order-date {
      font-size: 14px;
      margin-top: 20px; } }

.cabinet__order-status {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 16px;
  margin-top: 15px;
  color: #515B6D; }
  .cabinet__order-status span.normal {
    color: #4185F4; }
  .cabinet__order-status span.success {
    color: #2ED672; }
  .cabinet__order-status span.fail {
    color: #FF6451; }
  @media (max-width: 767px) {
    .cabinet__order-status {
      font-size: 14px;
      margin-top: 10px; } }

.cabinet__order-info {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border-color: rgba(208, 215, 226, 0.5);
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  padding-top: 27px;
  padding-bottom: 30px; }
  @media (max-width: 767px) {
    .cabinet__order-info {
      padding-top: 0px;
      padding-bottom: 20px;
      border: 1px solid rgba(208, 215, 226, 0.5); } }

@media (max-width: 767px) {
  .cabinet__order-cost {
    display: flex;
    flex-direction: row !important; }
    .cabinet__order-cost > .cabinet__info-value {
      padding: 0px;
      white-space: nowrap;
      padding-right: 16px !important; }
      .cabinet__order-cost > .cabinet__info-value span {
        line-height: 38px; }
    .cabinet__order-cost > .cabinet__info-title {
      font-size: 16px !important;
      background: #fff;
      white-space: nowrap; } }

.cabinet__info-block {
  display: flex; }
  @media (max-width: 767px) {
    .cabinet__info-block {
      flex-direction: column; } }

.cabinet__info-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 14px !important;
  color: #202020; }
  .cabinet__info-title.bold {
    font-size: 17px;
    color: #000;
    font-weight: 500; }
  @media (min-width: 767px) {
    .cabinet__info-title {
      width: 170px;
      padding-right: 10px; } }
  @media (max-width: 767px) {
    .cabinet__info-title {
      background: #f0f5f9;
      width: 100%;
      line-height: 38px;
      text-indent: 15px; } }

.cabinet__info-value {
  display: flex;
  padding-bottom: 25px; }
  .cabinet__info-value.list {
    flex-direction: column; }
  @media (min-width: 767px) {
    .cabinet__info-value:not(.list) span:not(:first-of-type) {
      margin-left: 30px; } }
  @media (max-width: 767px) {
    .cabinet__info-value {
      flex-direction: column;
      padding: 15px 15px 20px 15px; } }
  .cabinet__info-value span {
    line-height: 24px;
    font-weight: 300;
    font-size: 14px; }
  .cabinet__info-value.bold span {
    font-size: 17px;
    color: #000;
    font-weight: 500; }

.cabinet__show-map {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 1.71429px;
  text-transform: uppercase;
  color: #4185F4;
  user-select: none;
  cursor: pointer; }
  @media (max-width: 767px) {
    .cabinet__show-map {
      font-size: 12px !important; } }

@media (min-width: 767px) {
  .cabinet__order-buttons {
    margin-left: 170px;
    display: flex; }
    .cabinet__order-buttons > *:not(:first-of-type) {
      margin-left: 20px; } }

@media (max-width: 467px) {
  .cabinet__order-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
    .cabinet__order-buttons > *:not(:first-of-type) {
      margin-top: 15px; } }

@media (min-width: 467px) and (max-width: 767px) {
  .cabinet__order-buttons {
    margin-top: 25px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row; }
    .cabinet__order-buttons > button:not(:first-of-type) {
      margin-left: 20px; } }

.cabinet__order-products {
  margin-top: 25px;
  width: 100%; }

.cabinet__order-product {
  margin-top: 30px;
  display: flex;
  justify-content: space-between; }
  .cabinet__order-product .left, .cabinet__order-product .right {
    display: flex;
    justify-content: space-between; }
  @media (max-width: 767px) {
    .cabinet__order-product {
      flex-direction: column; }
      .cabinet__order-product:not(:first-of-type) {
        margin-top: 50px !important; }
      .cabinet__order-product .left {
        justify-content: flex-start; }
      .cabinet__order-product .right {
        padding-top: 25px; } }

.cabinet__order-products-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-weight: 300;
  font-size: 22px;
  color: #000;
  margin-left: 2px; }
  @media (max-width: 767px) {
    .cabinet__order-products-title {
      font-size: 18px; } }

.cabinet__order-product-img {
  text-align: left;
  margin-right: 10px; }
  .cabinet__order-product-img img {
    max-height: 104px;
    max-width: 104px;
    user-select: none;
    background: #fff; }

.cabinet__order-product-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-weight: 300;
  line-height: 20px;
  color: #000;
  text-align: left;
  font-size: 15px; }

.cabinet__order-product-count {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-weight: 300;
  text-align: center;
  color: #515B6D;
  white-space: nowrap;
  min-width: fit-content;
  font-size: 15px; }
  @media (min-width: 1454px) {
    .cabinet__order-product-count {
      padding-left: 50px !important;
      padding-right: 50px !important; } }
  @media (min-width: 991px) and (max-width: 1454px) {
    .cabinet__order-product-count {
      padding-left: 40px !important;
      padding-right: 40px !important; } }
  @media (min-width: 767px) and (max-width: 991px) {
    .cabinet__order-product-count {
      padding-left: 30px !important;
      padding-right: 30px !important; } }
  @media (max-width: 767px) {
    .cabinet__order-product-count {
      width: 90px;
      text-align: center; } }

.cabinet__order-product-cost {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  text-align: right;
  font-size: 15px;
  color: #515B6D;
  white-space: nowrap;
  min-width: fit-content; }
  @media (max-width: 767px) {
    .cabinet__order-product-cost {
      width: calc(100% - 90px);
      padding-left: 20px;
      text-align: left; } }

.cabinet__order-product-state {
  display: flex;
  flex-direction: column; }
  @media (max-width: 768px) {
    .cabinet__order-product-state {
      padding-left: 10px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .cabinet__order-product-state {
      padding-left: 14px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .cabinet__order-product-state {
      padding-left: 16px; } }
  @media (min-width: 1455px) {
    .cabinet__order-product-state {
      padding-left: 20px; } }
  @media (max-width: 768px) {
    .cabinet__order-product-state {
      padding-top: 8px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .cabinet__order-product-state {
      padding-top: 10px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .cabinet__order-product-state {
      padding-top: 12px; } }
  @media (min-width: 1455px) {
    .cabinet__order-product-state {
      padding-top: 14px; } }

.cabinet__order-product-status {
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  margin-top: 10px;
  height: 16px;
  padding-left: 14px;
  position: relative;
  text-transform: lowercase; }
  @media (max-width: 768px) {
    .cabinet__order-product-status {
      font-size: 12px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .cabinet__order-product-status {
      font-size: 12px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .cabinet__order-product-status {
      font-size: 13px; } }
  @media (min-width: 1455px) {
    .cabinet__order-product-status {
      font-size: 13px; } }
  .cabinet__order-product-status::before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    background-size: 100%;
    background-repeat: no-repeat;
    left: 0px;
    top: 7px; }
  .cabinet__order-product-status.in-stock {
    color: #2ED672; }
    .cabinet__order-product-status.in-stock::before {
      background-image: url("/_images/svg/item-status-in_stock.svg"); }
  .cabinet__order-product-status.few {
    color: #FF6451; }
    .cabinet__order-product-status.few::before {
      background-image: url("/_images/svg/item-status-few.svg"); }
  .cabinet__order-product-status.custom {
    color: #515B6D; }
    .cabinet__order-product-status.custom::before {
      background-image: url("/_images/svg/item-custom.svg"); }

.cabinet__order-product-count {
  display: flex;
  align-items: center;
  flex-direction: column; }
  @media (max-width: 768px) {
    .cabinet__order-product-count {
      padding-right: 10px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .cabinet__order-product-count {
      padding-right: 15px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .cabinet__order-product-count {
      padding-right: 20px; } }
  @media (min-width: 1455px) {
    .cabinet__order-product-count {
      padding-right: 20px; } }

.cabinet__order-product-value {
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  color: #515B6D;
  margin-top: 10px; }

.catalog__fullwidth-wrapper {
  background: #F0F5F9;
  width: 100%; }
  .catalog__fullwidth-wrapper.dmtr {
    background-size: 1px 100%;
    background-position: 50% 0%;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .catalog__fullwidth-wrapper.dmtr {
        background-image: none; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__fullwidth-wrapper.dmtr {
        background-image: linear-gradient(rgba(65, 133, 244, 0.05), rgba(65, 133, 244, 0.05)); } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__fullwidth-wrapper.dmtr {
        background-image: linear-gradient(rgba(65, 133, 244, 0.05), rgba(65, 133, 244, 0.05)); } }
    @media (min-width: 1455px) {
      .catalog__fullwidth-wrapper.dmtr {
        background-image: linear-gradient(rgba(65, 133, 244, 0.05), rgba(65, 133, 244, 0.05)); } }

.catalog__search-wrapper {
  margin-top: 18px; }
  @media (max-width: 768px) {
    .catalog__search-wrapper .container {
      padding: 0px !important; } }

.catalog__btn {
  min-width: 200px; }

.catalog__top-search {
  display: flex;
  width: 100%; }
  @media (max-width: 768px) {
    .catalog__top-search {
      padding-top: 0px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search {
      padding-top: 30px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search {
      padding-top: 30px; } }
  @media (min-width: 1455px) {
    .catalog__top-search {
      padding-top: 30px; } }
  @media (max-width: 768px) {
    .catalog__top-search {
      padding-bottom: 5px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search {
      padding-bottom: 35px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search {
      padding-bottom: 35px; } }
  @media (min-width: 1455px) {
    .catalog__top-search {
      padding-bottom: 35px; } }

.catalog__top-search-half {
  width: 50%;
  display: flex; }
  @media (max-width: 768px) {
    .catalog__top-search-half {
      flex-direction: column; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-half {
      flex-direction: column; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-half {
      flex-direction: column; } }
  @media (min-width: 1455px) {
    .catalog__top-search-half {
      flex-direction: row; } }
  @media (max-width: 768px) {
    .catalog__top-search-half.active.left > .catalog__search-panel {
      width: 200%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-half.active.left > .catalog__search-panel {
      width: 100%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-half.active.left > .catalog__search-panel {
      width: 100%; } }
  @media (min-width: 1455px) {
    .catalog__top-search-half.active.left > .catalog__search-panel {
      width: 68%; } }
  @media (max-width: 768px) {
    .catalog__top-search-half.active.left > .catalog__top-search-half-left {
      width: 100%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-half.active.left > .catalog__top-search-half-left {
      width: 100%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-half.active.left > .catalog__top-search-half-left {
      width: 100%; } }
  @media (min-width: 1455px) {
    .catalog__top-search-half.active.left > .catalog__top-search-half-left {
      width: 32%; } }
  @media (max-width: 768px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      width: 200%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      width: 100%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      width: 100%; } }
  @media (min-width: 1455px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      width: 100%; } }
  @media (max-width: 768px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      transform: translateX(-50%); } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      transform: none; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      transform: none; } }
  @media (min-width: 1455px) {
    .catalog__top-search-half.active.right > .catalog__search-panel {
      transform: none; } }
  .catalog__top-search-half:not(.active) {
    height: fit-content; }
    @media (max-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__search-panel {
        display: none; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__search-panel {
        display: flex; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__top-search-half:not(.active) > .catalog__search-panel {
        display: flex; } }
    @media (min-width: 1455px) {
      .catalog__top-search-half:not(.active) > .catalog__search-panel {
        display: flex; } }
    @media (max-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left {
        background: #fff; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left {
        background: unset; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left {
        background: unset; } }
    @media (min-width: 1455px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left {
        background: unset; } }
    @media (max-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-img {
        opacity: 0.5; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-img {
        opacity: 1; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-img {
        opacity: 1; } }
    @media (min-width: 1455px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-img {
        opacity: 1; } }
    @media (max-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.5); } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        border-bottom: none; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        border-bottom: none; } }
    @media (min-width: 1455px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        border-bottom: none; } }
    @media (max-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        opacity: 0.5; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        opacity: 1; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        opacity: 1; } }
    @media (min-width: 1455px) {
      .catalog__top-search-half:not(.active) > .catalog__top-search-half-left > .catalog__top-search-title > span {
        opacity: 1; } }

.catalog__top-search-title {
  font-weight: 300;
  line-height: 30px;
  color: #000000; }
  @media (max-width: 768px) {
    .catalog__top-search-title {
      font-size: 20px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-title {
      font-size: 24px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-title {
      font-size: 26px; } }
  @media (min-width: 1455px) {
    .catalog__top-search-title {
      font-size: 30px; } }
  @media (max-width: 768px) {
    .catalog__top-search-title {
      width: 100%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-title {
      width: 100%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-title {
      width: 100%; } }
  @media (min-width: 1455px) {
    .catalog__top-search-title {
      width: unset; } }
  @media (max-width: 768px) {
    .catalog__top-search-title {
      align-items: center; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-title {
      align-items: center; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-title {
      align-items: center; } }
  @media (min-width: 1455px) {
    .catalog__top-search-title {
      align-items: none; } }
  @media (max-width: 768px) {
    .catalog__top-search-title {
      display: flex; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-title {
      display: flex; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-title {
      display: flex; } }
  @media (min-width: 1455px) {
    .catalog__top-search-title {
      display: block; } }
  @media (max-width: 768px) {
    .catalog__top-search-title {
      padding-left: 0; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-title {
      padding-left: 0px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-title {
      padding-left: 50px; } }
  @media (min-width: 1455px) {
    .catalog__top-search-title {
      padding-left: 0; } }
  @media (max-width: 768px) {
    .catalog__top-search-title {
      padding-bottom: 10px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-title {
      padding-bottom: 0; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-title {
      padding-bottom: 0; } }
  @media (min-width: 1455px) {
    .catalog__top-search-title {
      padding-bottom: 0; } }
  @media (max-width: 768px) {
    .catalog__top-search-title {
      justify-content: center; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-title {
      justify-content: left; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-title {
      justify-content: left; } }
  @media (min-width: 1455px) {
    .catalog__top-search-title {
      justify-content: none; } }

@media (max-width: 768px) {
  .catalog__top-search-half-left.right {
    padding-left: 0; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__top-search-half-left.right {
    padding-left: 15px; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__top-search-half-left.right {
    padding-left: 20px; } }

@media (min-width: 1455px) {
  .catalog__top-search-half-left.right {
    padding-left: 30px; } }

@media (max-width: 768px) {
  .catalog__top-search-half-left.right {
    margin-right: 0; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__top-search-half-left.right {
    margin-right: 0; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__top-search-half-left.right {
    margin-right: 0; } }

@media (min-width: 1455px) {
  .catalog__top-search-half-left.right {
    margin-right: 12px; } }

@media (max-width: 768px) {
  .catalog__top-search-half-left.right ~ .catalog__search-panel {
    padding-left: 0; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__top-search-half-left.right ~ .catalog__search-panel {
    padding-left: 20px; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__top-search-half-left.right ~ .catalog__search-panel {
    padding-left: 25px; } }

@media (min-width: 1455px) {
  .catalog__top-search-half-left.right ~ .catalog__search-panel {
    padding-left: 0; } }

@media (max-width: 768px) {
  .catalog__top-search-half-left {
    width: 100%; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__top-search-half-left {
    width: 100%; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__top-search-half-left {
    width: 100%; } }

@media (min-width: 1455px) {
  .catalog__top-search-half-left {
    width: 33.33%; } }

@media (max-width: 768px) {
  .catalog__top-search-half-left {
    display: flex; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__top-search-half-left {
    display: flex; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__top-search-half-left {
    display: flex; } }

@media (min-width: 1455px) {
  .catalog__top-search-half-left {
    display: block; } }

@media (max-width: 768px) {
  .catalog__top-search-half-left {
    flex-direction: column-reverse; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__top-search-half-left {
    flex-direction: row-reverse; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__top-search-half-left {
    flex-direction: row-reverse; } }

@media (min-width: 1455px) {
  .catalog__top-search-half-left {
    flex-direction: none; } }

@media (max-width: 768px) {
  .catalog__top-search-half-left {
    justify-content: center; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__top-search-half-left {
    justify-content: flex-end; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__top-search-half-left {
    justify-content: flex-end; } }

@media (min-width: 1455px) {
  .catalog__top-search-half-left {
    justify-content: none; } }

.catalog__top-search-img {
  display: flex;
  align-items: center; }
  @media (max-width: 768px) {
    .catalog__top-search-img {
      justify-content: center; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-img {
      justify-content: flex-start; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-img {
      justify-content: flex-start; } }
  @media (min-width: 1455px) {
    .catalog__top-search-img {
      justify-content: flex-start; } }
  @media (max-width: 768px) {
    .catalog__top-search-img {
      width: 100%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-img {
      width: auto; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-img {
      width: auto; } }
  @media (min-width: 1455px) {
    .catalog__top-search-img {
      width: auto; } }
  @media (max-width: 768px) {
    .catalog__top-search-img {
      height: 95px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-img {
      height: 120px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-img {
      height: 120px; } }
  @media (min-width: 1455px) {
    .catalog__top-search-img {
      height: 100%; } }
  @media (max-width: 768px) {
    .catalog__top-search-img {
      padding: 25px 30px 10px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-img {
      padding: 10px 30px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-img {
      padding: 0px 20px; } }
  @media (min-width: 1455px) {
    .catalog__top-search-img {
      padding: 25px 30px 5px 5px; } }
  @media (max-width: 768px) {
    .catalog__top-search-img img {
      width: auto; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-img img {
      width: auto; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-img img {
      width: auto; } }
  @media (min-width: 1455px) {
    .catalog__top-search-img img {
      width: 100%; } }
  @media (max-width: 768px) {
    .catalog__top-search-img img {
      height: 100%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__top-search-img img {
      height: 90px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__top-search-img img {
      height: 100px; } }
  @media (min-width: 1455px) {
    .catalog__top-search-img img {
      height: unset; } }

.catalog__search-param {
  height: 88px;
  padding-top: 50px;
  position: relative; }
  @media (max-width: 768px) {
    .catalog__search-param {
      width: calc(50% - 8px); } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-param {
      width: 45%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-param {
      width: 45%; } }
  @media (min-width: 1455px) {
    .catalog__search-param {
      width: 140px; } }
  @media (max-width: 768px) {
    .catalog__search-param {
      min-width: none; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-param {
      min-width: none; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-param {
      min-width: none; } }
  @media (min-width: 1455px) {
    .catalog__search-param {
      min-width: 136px; } }
  @media (max-width: 768px) {
    .catalog__search-param {
      margin-right: 16px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-param {
      margin-right: 5%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-param {
      margin-right: 5%; } }
  @media (min-width: 1455px) {
    .catalog__search-param {
      margin-right: 14px; } }
  @media (max-width: 768px) {
    .catalog__search-param:nth-of-type(2n) {
      margin-right: 0; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-param:nth-of-type(2n) {
      margin-right: 15px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-param:nth-of-type(2n) {
      margin-right: 15px; } }
  @media (min-width: 1455px) {
    .catalog__search-param:nth-of-type(2n) {
      margin-right: 15px; } }

.catalog__search-param-name {
  font-size: 14px;
  line-height: 16px;
  padding: 8px 0px;
  position: absolute;
  bottom: 38px; }

.catalog__search-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  @media (max-width: 768px) {
    .catalog__search-panel {
      width: 100%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-panel {
      width: 100%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-panel {
      width: 100%; } }
  @media (min-width: 1455px) {
    .catalog__search-panel {
      width: 66.66%; } }
  @media (max-width: 768px) {
    .catalog__search-panel {
      padding-top: 10px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-panel {
      padding-top: 15px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-panel {
      padding-top: 20px; } }
  @media (min-width: 1455px) {
    .catalog__search-panel {
      padding-top: 20px; } }
  .catalog__search-panel-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: start; }
    @media (max-width: 768px) {
      .catalog__search-panel-wrapper {
        justify-content: space-between; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__search-panel-wrapper {
        justify-content: none; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__search-panel-wrapper {
        justify-content: none; } }
    @media (min-width: 1455px) {
      .catalog__search-panel-wrapper {
        justify-content: none; } }
    @media (max-width: 768px) {
      .catalog__search-panel-wrapper {
        padding: 0px 16px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__search-panel-wrapper {
        padding: 0; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__search-panel-wrapper {
        padding: 0; } }
    @media (min-width: 1455px) {
      .catalog__search-panel-wrapper {
        padding: 0; } }

.catalog__search-select {
  height: 38px; }
  .catalog__search-select.opened .jq-selectbox__trigger-arrow {
    transform: translateY(-50%) rotate(180deg) !important; }
  .catalog__search-select .jq-selectbox__trigger-arrow {
    background-image: url("/_images/svg/catalog-select-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    width: 9px;
    height: 4px;
    transition: transform .05s ease-in !important; }
  .catalog__search-select .jq-selectbox__trigger {
    width: 39px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none; }
  .catalog__search-select .jq-selectbox__select-text {
    line-height: 30px;
    font-size: 14px;
    width: 100% !important; }
  .catalog__search-select .jq-selectbox__select {
    padding-left: 13px;
    padding-right: 30px;
    border: none;
    line-height: 34px;
    height: 38px; }
  .catalog__search-select .jq-selectbox__dropdown {
    box-shadow: 0 5px 18px rgba(65, 133, 244, 0.2);
    border: none;
    bottom: unset !important; }
  .catalog__search-select .jq-selectbox__dropdown ul li {
    border-bottom: none;
    padding: 2px 13px;
    line-height: 34px;
    font-size: 14px; }

.catalog__search-btn {
  padding-top: 0px !important;
  width: fit-content; }
  @media (max-width: 768px) {
    .catalog__search-btn {
      margin: 30px auto; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-btn {
      margin: 30px 0px 0px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-btn {
      margin: 30px 0px 0px; } }
  @media (min-width: 1455px) {
    .catalog__search-btn {
      margin: 30px 0px 0px; } }

.catalog .row {
  display: flex; }

.catalog [class*="col-"] {
  float: none; }

.catalog__search-by-car {
  padding-top: 40px; }
  @media (max-width: 768px) {
    .catalog__search-by-car h3 {
      margin-bottom: 10px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-by-car h3 {
      margin-bottom: 20x; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-by-car h3 {
      margin-bottom: 30px; } }
  @media (min-width: 1455px) {
    .catalog__search-by-car h3 {
      margin-bottom: 30px; } }

.catalog__search-type-switcher {
  margin: 10px auto 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  max-width: 420px; }
  @media (max-width: 768px) {
    .catalog__search-type-switcher {
      font-size: 15px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-type-switcher {
      font-size: 16px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-type-switcher {
      font-size: 16px; } }
  @media (min-width: 1455px) {
    .catalog__search-type-switcher {
      font-size: 16px; } }
  @media (max-width: 768px) {
    .catalog__search-type-switcher {
      height: 35px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__search-type-switcher {
      height: 38px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__search-type-switcher {
      height: 42px; } }
  @media (min-width: 1455px) {
    .catalog__search-type-switcher {
      height: 42px; } }
  .catalog__search-type-switcher-half {
    width: 50%;
    height: 100%;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color .2s ease-in;
    border: 2px solid #4185f4;
    position: relative;
    box-sizing: padding-box;
    user-select: none; }
    .catalog__search-type-switcher-half:nth-of-type(1) {
      border-radius: 100px 0px 0px 100px; }
    .catalog__search-type-switcher-half:nth-of-type(2) {
      border-radius: 0px 100px 100px 0px; }
    .catalog__search-type-switcher-half.selected {
      transition: color .2s ease-in, background .2s ease-in;
      background: #4185f4;
      color: #fff; }
      .catalog__search-type-switcher-half.selected::before {
        width: 100%; }
    .catalog__search-type-switcher-half:not(.selected) {
      background: #fff;
      color: #4185f4; }
      .catalog__search-type-switcher-half:not(.selected):hover {
        cursor: pointer; }

.catalog__your-car {
  padding-top: 28px;
  padding-bottom: 35px; }
  .catalog__your-car-form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .catalog__your-car-form .catalog__search-btn {
      margin-bottom: 0px !important; }
  .catalog__your-car-title {
    text-align: left;
    margin-bottom: 0px !important;
    /*@include ap(display, none, block, block, block);*/ }
  .catalog__your-car-detail {
    width: 100%;
    display: flex; }
    @media (max-width: 768px) {
      .catalog__your-car-detail {
        margin-top: 0px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__your-car-detail {
        margin-top: 20px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__your-car-detail {
        margin-top: 20px; } }
    @media (min-width: 1455px) {
      .catalog__your-car-detail {
        margin-top: 20px; } }
    @media (max-width: 768px) {
      .catalog__your-car-detail {
        flex-direction: column; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__your-car-detail {
        flex-direction: row; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__your-car-detail {
        flex-direction: row; } }
    @media (min-width: 1455px) {
      .catalog__your-car-detail {
        flex-direction: row; } }
    .catalog__your-car-detail-param {
      background: #fff;
      position: relative;
      user-select: none; }
      @media (max-width: 768px) {
        .catalog__your-car-detail-param-select {
          display: block; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog__your-car-detail-param-select {
          display: none; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog__your-car-detail-param-select {
          display: none; } }
      @media (min-width: 1455px) {
        .catalog__your-car-detail-param-select {
          display: none; } }
      .catalog__your-car-detail-param-select.validation-error .jq-selectbox__select {
        border: 1px solid #FF6451; }
        .catalog__your-car-detail-param-select.validation-error .jq-selectbox__select .jq-selectbox__trigger .jq-selectbox__trigger-arrow {
          background-image: url("/_images/svg/select-arrow-error.svg"); }
        .catalog__your-car-detail-param-select.validation-error .jq-selectbox__select .jq-selectbox__select-text::after {
          color: #ff6451; }
      .catalog__your-car-detail-param .jq-selectbox__select-text {
        top: 0;
        height: 100%;
        line-height: 78px; }
        .catalog__your-car-detail-param .jq-selectbox__select-text::after {
          display: block;
          width: 100%;
          color: rgba(81, 91, 109, 0.6);
          transition: top .15s ease-in, line-height .15s ease-in; }
        .catalog__your-car-detail-param .jq-selectbox__select-text:empty::after {
          top: 0;
          line-height: 58px;
          content: attr(data-placeholder);
          height: 100%; }
        .catalog__your-car-detail-param .jq-selectbox__select-text:not(:empty) {
          margin-left: -6px;
          transition: margin-left .15s ease-in; }
        .catalog__your-car-detail-param .jq-selectbox__select-text:not(:empty)::after {
          content: attr(data-legend);
          display: block;
          position: absolute;
          height: auto;
          top: 10px;
          line-height: 10px;
          left: 0;
          font-size: 12px; }
      .catalog__your-car-detail-param > input {
        /*@include ap(display, none, block, block, block);*/ }
      @media (max-width: 768px) {
        .catalog__your-car-detail-param:nth-of-type(2) {
          width: 100%; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog__your-car-detail-param:nth-of-type(2) {
          width: calc(50% - 16px); } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog__your-car-detail-param:nth-of-type(2) {
          width: calc(50% - 16px); } }
      @media (min-width: 1455px) {
        .catalog__your-car-detail-param:nth-of-type(2) {
          width: calc(50% - 16px); } }
      @media (max-width: 768px) {
        .catalog__your-car-detail-param:not(:nth-of-type(2)) {
          width: 100%; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog__your-car-detail-param:not(:nth-of-type(2)) {
          width: calc(25% - 7px); } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog__your-car-detail-param:not(:nth-of-type(2)) {
          width: calc(25% - 7px); } }
      @media (min-width: 1455px) {
        .catalog__your-car-detail-param:not(:nth-of-type(2)) {
          width: calc(25% - 7px); } }
      @media (max-width: 768px) {
        .catalog__your-car-detail-param:not(:first-of-type) {
          margin: 16px 0px 0px; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog__your-car-detail-param:not(:first-of-type) {
          margin: 0px 0px 0px 16px; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog__your-car-detail-param:not(:first-of-type) {
          margin: 0px 0px 0px 16px; } }
      @media (min-width: 1455px) {
        .catalog__your-car-detail-param:not(:first-of-type) {
          margin: 0px 0px 0px 16px; } }
      .catalog__your-car-detail-param::after {
        content: '';
        display: block;
        background-image: url("/_images/svg/param-edit.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 16px;
        width: 14px;
        height: 14px;
        top: 11px;
        cursor: pointer; }
      .catalog__your-car-detail-param > input {
        height: 35px;
        width: 100%;
        font-size: 14px;
        line-height: 35px;
        text-indent: 19px;
        font-weight: 300;
        padding-right: 34px;
        user-select: none;
        border: none; }
        .catalog__your-car-detail-param > input:hover {
          cursor: pointer;
          background: #f8f8f8; }
  .catalog__your-car-select-param {
    margin-top: 20px;
    width: 100%;
    background: #F0F5F9;
    border: 1px solid #C0C9D9;
    padding: 0px 24px 26px 24px; }
    .catalog__your-car-select-param.active {
      display: block; }
    .catalog__your-car-select-param:not(.active) {
      display: none; }
    .catalog__your-car-select-param-header {
      padding: 20px 25px;
      display: flex;
      user-select: none; }
      .catalog__your-car-select-param-header-variant {
        font-size: 14px;
        line-height: 22px; }
        .catalog__your-car-select-param-header-variant:not(:first-of-type) {
          margin-left: 20px; }
        .catalog__your-car-select-param-header-variant:not(.active) {
          opacity: .7;
          color: #515B6D;
          border-bottom: 1px dashed rgba(81, 92, 120, 0.5); }
          .catalog__your-car-select-param-header-variant:not(.active):hover {
            opacity: .85;
            border-bottom: 1px dashed rgba(81, 92, 120, 0.7);
            cursor: pointer; }
        .catalog__your-car-select-param-header-variant.active {
          color: #000;
          opacity: 1;
          border: none; }
    .catalog__your-car-select-param-list {
      max-height: 674px;
      border: 1px solid #D0D7E2;
      overflow-y: auto; }
      .catalog__your-car-select-param-list::-webkit-scrollbar-button {
        display: none; }
      .catalog__your-car-select-param-list::-webkit-scrollbar-thumb {
        background: #4185F4;
        border-radius: 53px;
        width: 7px; }
      .catalog__your-car-select-param-list::-webkit-scrollbar {
        background: rgba(41, 85, 244, 0.2);
        mix-blend-mode: normal;
        width: 9px; }
      .catalog__your-car-select-param-list ul {
        background: #FFFFFF;
        box-sizing: border-box;
        width: 100%;
        padding: 7px 24px 25px 10px;
        list-style-type: none;
        column-gap: 6%; }
        .catalog__your-car-select-param-list ul.col-2 {
          columns: 2; }
        .catalog__your-car-select-param-list ul.col-3 {
          columns: 3; }
        .catalog__your-car-select-param-list ul.col-4 {
          columns: 4; }
        .catalog__your-car-select-param-list ul li {
          font-weight: 300;
          line-height: 40px;
          font-size: 14px;
          color: #515B6D;
          margin-right: 10px;
          padding-left: 13px;
          user-select: none;
          position: relative; }
          .catalog__your-car-select-param-list ul li:hover {
            cursor: pointer;
            color: #4185F4; }
            .catalog__your-car-select-param-list ul li:hover::before {
              content: '';
              width: calc(100% - 2px);
              height: calc(100% - 4px);
              top: 2px;
              left: 1px;
              position: absolute;
              border: 1px solid #4185F4;
              border-radius: 3px; }

.order-auth {
  background-color: #f0f5f9;
  margin-bottom: 60px; }
  .order-auth__text {
    line-height: 26px;
    font-weight: 300;
    color: #515B6D;
    text-align: center; }
    @media (max-width: 768px) {
      .order-auth__text {
        font-size: 14px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .order-auth__text {
        font-size: 15px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .order-auth__text {
        font-size: 16px; } }
    @media (min-width: 1455px) {
      .order-auth__text {
        font-size: 18px; } }
    @media (max-width: 768px) {
      .order-auth__text {
        margin-top: 0px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .order-auth__text {
        margin-top: 10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .order-auth__text {
        margin-top: 15px; } }
    @media (min-width: 1455px) {
      .order-auth__text {
        margin-top: 25px; } }
    @media (max-width: 768px) {
      .order-auth__text {
        margin-bottom: 25px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .order-auth__text {
        margin-bottom: 30px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .order-auth__text {
        margin-bottom: 35px; } }
    @media (min-width: 1455px) {
      .order-auth__text {
        margin-bottom: 40px; } }
  @media (max-width: 768px) {
    .order-auth .popup-social__item {
      margin: 0px 4px 7px; } }
  .order-auth .popup-social {
    margin-bottom: 60px; }

.popup {
  max-width: 790px; }
  @media (max-width: 768px) {
    .popup.--order {
      padding: 0px 15px 30px !important; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .popup.--order {
      padding: 0px 25px 30px !important; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .popup.--order {
      padding: 0px 30px 30px !important; } }
  @media (min-width: 1455px) {
    .popup.--order {
      padding: 0px 0px 30px !important; } }
  .popup.-nb {
    padding-bottom: 0px !important; }
  .popup__subtitle-sm {
    line-height: 26px;
    font-weight: 300;
    color: #515B6D;
    text-align: center; }
    @media (max-width: 768px) {
      .popup__subtitle-sm {
        font-size: 14px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .popup__subtitle-sm {
        font-size: 15px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .popup__subtitle-sm {
        font-size: 16px; } }
    @media (min-width: 1455px) {
      .popup__subtitle-sm {
        font-size: 18px; } }
    @media (max-width: 768px) {
      .popup__subtitle-sm {
        margin-top: 0px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .popup__subtitle-sm {
        margin-top: 10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .popup__subtitle-sm {
        margin-top: 15px; } }
    @media (min-width: 1455px) {
      .popup__subtitle-sm {
        margin-top: 25px; } }
    @media (max-width: 768px) {
      .popup__subtitle-sm {
        margin-bottom: 15px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .popup__subtitle-sm {
        margin-bottom: 16px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .popup__subtitle-sm {
        margin-bottom: 20px; } }
    @media (min-width: 1455px) {
      .popup__subtitle-sm {
        margin-bottom: 25px; } }
  .popup__title {
    padding-bottom: 10px !important; }
    @media (max-width: 768px) {
      .popup__title.-bm {
        padding-top: 25px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .popup__title.-bm {
        padding-top: 35px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .popup__title.-bm {
        padding-top: 50px; } }
    @media (min-width: 1455px) {
      .popup__title.-bm {
        padding-top: 65px; } }
    @media (max-width: 768px) {
      .popup__title.-bm {
        margin-bottom: 5px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .popup__title.-bm {
        margin-bottom: 10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .popup__title.-bm {
        margin-bottom: 15px; } }
    @media (min-width: 1455px) {
      .popup__title.-bm {
        margin-bottom: 25px; } }
  @media (max-width: 768px) {
    .popup__product-list {
      padding-bottom: 20px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .popup__product-list {
      padding-bottom: 20px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .popup__product-list {
      padding-bottom: 25px; } }
  @media (min-width: 1455px) {
    .popup__product-list {
      padding-bottom: 25px; } }
  .popup__splitter {
    width: 100%;
    height: 1px;
    background: rgba(208, 215, 226, 0.5);
    display: block;
    position: absolute;
    left: 0; }
  @media (max-width: 768px) {
    .popup__form {
      margin-top: 28px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .popup__form {
      margin-top: 32px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .popup__form {
      margin-top: 36px; } }
  @media (min-width: 1455px) {
    .popup__form {
      margin-top: 40px; } }
  @media (max-width: 768px) {
    .popup__form .form__btn {
      width: 100%; } }
  @media (max-width: 768px) {
    .popup__form .form__btn {
      max-width: 300px; } }
  .popup .cabinet__order-product-cost {
    font-size: 18px; }
    @media (max-width: 768px) {
      .popup .cabinet__order-product-cost {
        padding-top: 15px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .popup .cabinet__order-product-cost {
        padding-top: 15px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .popup .cabinet__order-product-cost {
        padding-top: 14px; } }
    @media (min-width: 1455px) {
      .popup .cabinet__order-product-cost {
        padding-top: 14px; } }
  .popup__footer {
    display: flex;
    padding: 30px 0px;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .popup__footer {
        flex-direction: column; } }
    .popup__footer-cart {
      height: 100%;
      display: flex;
      align-items: flex-start;
      font-weight: 300;
      line-height: 24px;
      font-size: 16px; }
      @media (max-width: 768px) {
        .popup__footer-cart {
          align-items: center; } }
      @media (max-width: 1455px) {
        .popup__footer-cart {
          flex-direction: column; } }
      .popup__footer-cart-count {
        color: #4185F4;
        cursor: pointer;
        padding-right: 6px; }
    .popup__footer-buttons {
      display: flex; }
      @media (max-width: 768px) {
        .popup__footer-buttons {
          flex-direction: column; } }
      @media (max-width: 768px) {
        .popup__footer-buttons {
          margin-top: 20px; } }
      @media (max-width: 768px) {
        .popup__footer-buttons {
          width: 100%; } }
      @media (max-width: 768px) {
        .popup__footer-buttons {
          align-items: center; } }
      @media (max-width: 768px) {
        .popup__footer-buttons .flat-btn {
          width: 100%; } }
      @media (max-width: 768px) {
        .popup__footer-buttons .flat-btn {
          max-width: 320px; } }
      @media (max-width: 768px) {
        .popup__footer-buttons .flat-btn:first-of-type {
          margin-bottom: 15px; } }
      @media (min-width: 768px) {
        .popup__footer-buttons .flat-btn:first-of-type {
          margin-right: 20px; } }

.modal {
  padding-right: 0px !important; }

/* Forms */
@media (min-width: 768px) {
  .form__file {
    padding: 0 190px 0 0; }
  .form__hint {
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
    padding: 0; } }

/* Partners page */
@media (min-width: 768px) {
  .phones__list .row {
    display: flex;
    flex-wrap: wrap; } }

/* About page */
@media (min-width: 768px) {
  .advantages__list .row {
    display: flex;
    flex-wrap: wrap; }
  .advantages__icon {
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center; } }

/* Reviews page */
@media (min-width: 768px) {
  .reviews__add {
    height: 310px; }
  .reviews__item {
    height: 290px; }
    .reviews__item.active {
      z-index: 5; }
      .reviews__item.active .reviews__inner {
        box-shadow: 0 0 113px rgba(65, 133, 244, 0.36); }
  .reviews__inner {
    min-height: 290px; }
  .reviews__img {
    float: left;
    margin: 0; }
  .reviews__info {
    padding: 0 0 0 94px; }
  .reviews__full {
    float: none;
    margin-bottom: 15px; }
  .reviews__view {
    float: none; } }

/* Modals */
@media (min-width: 768px) {
  .modal-dialog {
    width: 720px; }
  .modal-close {
    width: 48px;
    height: 48px; }
    .modal-close i {
      margin-top: 14px; }
  .popup__container {
    margin-bottom: 48px; }
  .popup-nav {
    padding: 40px 0; }
    .popup-nav__link {
      font-size: 24px; } }

/* Vacancy page */
@media (min-width: 768px) {
  .vacancy-content__item .vacancy-content__link {
    font-size: 22px;
    padding: 18px 0 18px 35px; }
    .vacancy-content__item .vacancy-content__link i {
      display: inline-block; }
  .vacancy-content__item.active .vacancy-content__link i {
    background-image: url("/_images/svg/arrow-up-white.svg"); }
  .vacancy-content__btn {
    display: inline-block;
    padding: 0 35px;
    border-width: 2px; } }

/* 404 */
@media (min-width: 768px) {
  .found {
    padding-bottom: 100px; }
    .found__top {
      padding-top: 48px; }
    .found__inner:before {
      width: 305px;
      height: 96px;
      left: 65px; }
    .found__label {
      font-size: 36px;
      line-height: 68px;
      height: 70px;
      position: absolute;
      top: 0;
      left: 0; }
    .found__caption {
      padding-left: 0;
      float: right; }
    .found__desc {
      font-size: 18px;
      line-height: 26px; } }

/* Address */
@media (min-width: 768px) {
  .address-nav__item {
    margin-bottom: 0; }
  .address-content__item .address-content__link {
    padding: 17px 40px 17px 75px; }
    .address-content__item .address-content__link i {
      display: block; }
  .address-content__item.active .address-content__link {
    padding: 16px 39px 17px 74px; }
  .address-content__desc {
    padding: 30px 40px; } }

/* Partners page */
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 82px; }
  h3 {
    font-size: 40px; }
  .content {
    padding-top: 131px; }
  .partners {
    padding: 0px 0 55px; }
    .partners__text {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 71px; }
    .partners ol li {
      padding-left: 45px;
      margin-bottom: 23px; }
      .partners ol li:before {
        font-size: 28px;
        top: -2px; }
  .become-partner {
    padding: 54px 0 42px; }
    .become-partner h3 {
      margin-bottom: 23px; }
  .form__input {
    margin-bottom: 26px; }
  .form__button {
    padding-top: 23px; }
  .phones {
    padding: 60px 0 0; }
    .phones h3 {
      text-align: left; }
    .phones__list {
      padding-top: 17px; }
    .phones__item {
      margin-bottom: 47px; }
    .phones__title {
      font-size: 18px;
      line-height: 24px; } }

/* About page */
@media (min-width: 992px) {
  .about {
    padding-top: 15px; }
    .about h1 {
      margin-bottom: 35px; }
    .about h2 {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px; }
    .about__text {
      margin-bottom: 60px; }
    .about__list {
      margin-bottom: 57px; }
    .about__item {
      margin-bottom: 0; }
  .about-promo__sub {
    margin-top: -50px;
    margin-bottom: 50px; }
  .advantages h3 {
    text-align: left; }
  .advantages__item {
    margin-bottom: 45px; }
  .advantages__icon {
    justify-content: flex-start;
    margin-bottom: 25px; }
  .advantages__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px; }
  .advantages__desc {
    margin-bottom: 20px;
    padding-right: 20px; }
  .condition {
    padding: 66px 0 60px; }
    .condition h3 {
      line-height: 50px;
      margin-bottom: 20px; }
    .condition__text {
      margin-bottom: 35px; } }

/* Contatcs page */
@media (min-width: 992px) {
  .contacts {
    padding: 0px 0 70px; } }

/* Vacancy page */
@media (min-width: 992px) {
  .vacancy {
    padding-top: 0px; }
  .vacancy-menu {
    padding: 35px 0; }
    .vacancy-menu__item {
      margin-bottom: 0; }
    .vacancy-menu__link {
      padding: 37px 20px 33px; }
    .vacancy-menu__icon {
      float: none;
      margin-bottom: 35px; }
    .vacancy-menu__title {
      margin-bottom: 10px; }
    .vacancy-menu__info {
      float: left;
      padding: 0; }
    .vacancy-menu__more {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      padding: 0; }
    .vacancy-menu.active .vacancy-menu__link {
      padding-top: 12px;
      padding-bottom: 11px; }
    .vacancy-menu.active .vacancy-menu__icon {
      float: left;
      margin: 0; }
    .vacancy-menu.active .vacancy-menu__info {
      float: none;
      padding: 15px 0 0 105px; }
    .vacancy-menu.active .vacancy-menu__more {
      display: none; }
  .vacancy-content {
    padding: 36px 0 47px; }
    .vacancy-content__count {
      margin-bottom: 40px; }
    .vacancy-content__desc {
      padding: 30px 75px 54px; }
  .become-job {
    padding: 54px 0 39px; }
    .become-job__text {
      margin-bottom: 44px; } }

/* Reviews page */
@media (min-width: 992px) {
  .reviews__add {
    height: 320px; }
  .reviews__item {
    min-height: 320px; }
  .reviews__inner {
    min-height: 320px; }
  .reviews__action {
    position: absolute;
    bottom: 13px;
    left: 0;
    width: 100%;
    padding: 0 20px; }
  .reviews__view {
    float: left; }
  .reviews__full {
    margin-bottom: 10px; }
  .reviews__item.active .reviews__inner {
    padding-bottom: 60px; } }

/* Bonus main */
@media (min-width: 992px) {
  .bonus {
    padding-top: 50px; }
    .bonus__text {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 35px; }
    .bonus__item {
      margin-bottom: 70px; } }

/* Bonus detail */
@media (min-width: 992px) {
  .bonus-detail {
    padding-top: 50px;
    padding-bottom: 60px; }
    .bonus-detail h1 {
      margin-bottom: 55px; }
    .bonus-detail__image {
      margin-bottom: 57px; }
    .bonus-detail__preview {
      margin-bottom: 0; }
    .bonus-detail__desc h2 {
      margin: 40px 0;
      line-height: 60px; }
    .bonus-detail__tbl h2 {
      margin: 35px 0 45px; }
    .bonus-detail__table table tbody tr td {
      padding: 20px 20px 20px 32px; }
  .bonus-attention {
    padding: 35px 0 71px; }
    .bonus-attention h4 {
      margin-bottom: 41px; }
    .bonus-attention ol li {
      padding-left: 45px;
      margin-bottom: 24px; }
      .bonus-attention ol li:before {
        font-size: 28px;
        top: -5px; } }

@media (max-width: 991px) {
  .bonus-detail__table {
    overflow-x: auto; } }

/* Address */
@media (min-width: 992px) {
  .address {
    padding-top: 15px; }
  .address-nav {
    margin-bottom: 50px; }
    .address-nav__link {
      font-size: 20px;
      padding-bottom: 21px; }
  .address-content__thead {
    display: block; }
  .address-content__row {
    margin-bottom: 0; }
  .address-map__area {
    height: 500px; } }

/* Found */
@media (min-width: 992px) {
  .found {
    padding-top: 47px; }
    .found__inner {
      padding-top: 16px; }
    .found__label {
      top: 25px;
      margin: 0; }
    .found__caption {
      position: relative;
      top: 3px; } }

/* Popups */
@media (min-width: 992px) {
  .popup--login .form__button {
    padding-top: 4px; } }

/* Partners page */
@media (min-width: 1454px) {
  .partners ol {
    padding-left: 32px; }
  .become-partner__form {
    padding: 0 60px; }
  .header__text svg {
    margin-right: 14px; }
  .header__panel-item {
    margin-left: 70px; } }

/* About page */
@media (min-width: 1454px) {
  .about__label {
    font-size: 30px;
    line-height: 34px; }
  .about-promo__sub {
    margin-bottom: 75px; }
  .about-promo__slider--sub {
    box-shadow: 0 0 344px 12px rgba(65, 133, 244, 0.36); }
  .about-promo__arrow {
    width: 50px; }
    .about-promo__arrow--prev {
      left: -50px; }
    .about-promo__arrow--next {
      right: -50px; }
  .advantages__list {
    padding: 45px 0 50px; }
  .advantages__item {
    margin-bottom: 65px; } }

/* Reviews page */
@media (min-width: 1454px) {
  .reviews__add {
    height: 290px;
    padding-left: 40px;
    padding-right: 40px; }
  .reviews__item {
    min-height: 290px; }
    .reviews__item.active .reviews__inner {
      padding-bottom: 65px; }
  .reviews__inner {
    min-height: 290px;
    padding: 35px 30px 20px; }
  .reviews__name {
    font-size: 22px;
    line-height: 29px; }
  .reviews__entry {
    max-height: 90px; }
  .reviews__action {
    bottom: 30px;
    padding: 0 30px; }
  .reviews__view {
    float: right; }
  .reviews__full {
    float: left;
    margin-bottom: 0; }
  .reviews__response {
    padding: 35px 30px 20px; } }

/* Bonus detail */
@media (min-width: 1454px) {
  .bonus-detail__table table tbody tr td:first-child {
    font-size: 18px; } }

/* Modals */
@media (min-width: 1454px) {
  .modal-content {
    box-shadow: 0 0 344px 12px rgba(65, 133, 244, 0.37); }
  .modal-dialog {
    width: 944px; }
  .popup {
    padding-bottom: 60px; }
    .popup__title {
      padding: 65px 0 55px;
      font-size: 40px; }
    .popup__phone {
      margin-bottom: 75px; }
    .popup--consult .popup__title {
      padding-top: 65px; }
  .popup-nav {
    padding: 70px 0 64px; }
    .popup-nav__link {
      font-size: 40px;
      padding-top: 1px;
      padding-bottom: 1px; }
    .popup-nav__item.active .popup__link {
      padding-bottom: 2px; } }

/* Vacancy page */
@media (min-width: 1454px) {
  .vacancy-menu__link {
    padding-left: 40px;
    padding-right: 40px; }
  .vacancy-menu__title {
    font-size: 28px; }
  .vacancy-menu.active .vacancy-menu__info {
    padding-left: 125px; }
  .become-job__form {
    padding: 0 60px; } }

/* Address */
@media (min-width: 1454px) {
  .address-content__thead {
    padding-right: 70px; }
  .address-content__name {
    font-size: 22px; }
  .address-content__item .address-content__link {
    font-size: 18px;
    padding: 16px 70px 17px 75px; }
  .address-content__item.active .address-content__link {
    padding-top: 15px;
    padding-right: 69px; }
  .address-content__desc {
    padding: 18px 70px 26px 75px; } }

.service {
  padding: 30px 0 40px; }
  @media (min-width: 992px) {
    .service .contacts {
      padding: 40px 0 70px; } }
  .service h2.title {
    margin-top: 21px;
    font-size: 60px;
    line-height: 82px; }

.order__line {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 21px;
  margin-top: 42px; }

.order__line-step {
  top: -76px;
  position: absolute;
  opacity: 0.5;
  color: #515b6d;
  font-size: 14px;
  line-height: 30px; }

.order__line-item {
  width: 25%;
  text-align: center;
  position: relative; }
  .order__line-item--last:after {
    display: none !important; }
  .order__line-item.complete:after {
    background-color: #4185f4; }
  .order__line-item.complete .order__line-circle {
    background-color: #4185f4;
    border-color: #4185f4;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/_images/svg/service-ok.png"); }
  .order__line-item.active .order__line-text {
    color: #4185f4;
    font-size: 20px;
    opacity: 1; }
    @media (max-width: 767px) {
      .order__line-item.active .order__line-text {
        font-size: 10px; } }
  .order__line-item.active .order__line-circle {
    box-shadow: 0 2px 12px #d0d7e2;
    border-color: #fff;
    position: relative;
    z-index: 2; }
    .order__line-item.active .order__line-circle:before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #4185f4;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .order__line-item:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #d0d7e2;
    height: 2px;
    width: 100%;
    left: 50%;
    top: 50%;
    margin-top: -2px;
    z-index: 1; }
  .order__line-item--short {
    width: 50%; }

.order__line-text {
  display: block;
  text-align: center;
  color: #515b6d;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  position: absolute !important;
  top: -133%;
  left: 50%;
  transform: translate(-50%, 0%); }
  @media (max-width: 767px) {
    .order__line-text {
      font-size: 10px;
      white-space: normal;
      line-height: 1; } }

.order__line-circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #d0d7e2;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  z-index: 2; }

.order__services-title {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 36px; }

.order__services {
  border: 1px solid #c0c9d9;
  background-color: #f0f5f9;
  padding: 22px 22px;
  margin-bottom: 20px; }

.order__find {
  position: relative; }
  .order__find input[type='text'] {
    border-radius: 3px;
    border: 1px solid #d0d7e2;
    background-color: #ffffff;
    width: 100%;
    height: 58px;
    line-height: 58px;
    padding: 0 25px;
    font-size: 16px; }
  .order__find button[type='submit'] {
    width: 19px;
    height: 20px;
    border: 0;
    background: transparent url("/_images/svg/search.svg") no-repeat center;
    position: absolute;
    z-index: 2;
    top: 19px;
    right: 18px; }

.order__additition {
  position: relative; }
  .order__additition input[type='text'] {
    border-radius: 3px;
    border: 1px solid #d0d7e2;
    background-color: #ffffff;
    width: 100%;
    height: 45px;
    line-height: 58px;
    padding: 0 25px;
    font-size: 16px; }
  .order__additition span {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 0 23px;
    height: 45px;
    border-radius: 5px;
    border: 2px solid #4185f4;
    line-height: 42px;
    text-align: center;
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    background-color: #f0f5f9;
    transition: all .2s; }
    .order__additition span:hover {
      color: #fff;
      background-color: #4185f4; }

.order__services-list {
  overflow: auto;
  margin-top: 27px;
  border: 1px solid #d0d7e2;
  background-color: #ffffff;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 20px;
  max-height: 710px;
  position: relative; }
  .order__services-list .mCSB_inside > .mCSB_container {
    margin-right: 10px; }
  .order__services-list .mCSB_scrollTools {
    width: 10px; }
  .order__services-list .mCSB_draggerContainer {
    width: 10px;
    background-color: rgba(65, 133, 244, 0.5) !important; }
  .order__services-list .mCSB_dragger_bar {
    width: 6px !important;
    left: 0px;
    background-color: #4185f4 !important; }
  .order__services-list .mCSB_draggerRail {
    display: none !important; }

.order__category {
  width: 36%; }
  @media (max-width: 767px) {
    .order__category {
      width: 100%; } }

.order__service-item {
  width: 100%;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  color: #515b6d;
  font-size: 16px;
  line-height: 53px;
  padding: 0 26px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid #d0d7e2; }
  .order__service-item:hover {
    transition: color .2s;
    color: #4185f4; }
  .order__service-item:last-child {
    border-bottom: 0; }
  .order__service-item.active {
    transition: all .2s;
    color: #ffffff;
    background-color: #4185f4;
    border-color: #4185f4; }
    .order__service-item.active:after {
      content: '';
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      width: 7px;
      height: 12px;
      background-image: url("/_images/svg/arrow-service.svg"); }

.order__service-question {
  cursor: pointer;
  position: relative;
  z-index: 3;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  background-image: url("/_images/svg/round-question.svg");
  background-size: contain;
  background-repeat: no-repeat; }
  .order__service-question:hover {
    background-image: url("/_images/svg/round-question-hover.svg"); }

.order__items {
  background: #fff;
  width: 64%;
  box-shadow: inset 0px 0px 0 1px #4185f4; }
  @media (max-width: 767px) {
    .order__items {
      z-index: 2;
      width: 100%;
      position: absolute;
      top: 0;
      transform: translate(100%, 0%);
      transition: transform .3s;
      height: 0; }
      .order__items.active {
        height: auto;
        transform: translate(0%, 0%); } }

.order__services-btn-wrap {
  text-align: right; }
  .order__services-btn-wrap.-center {
    text-align: center;
    margin-top: 20px; }

.order__services-btn {
  padding: 0 23px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid #4185f4;
  text-align: center;
  line-height: 42px;
  text-align: center;
  color: #4185f4;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  background-color: #f0f5f9; }
  .order__services-btn:hover {
    transition: all .2s;
    color: #fff;
    background-color: #4185f4; }

.order__selected {
  padding: 30px 0;
  background-repeat: no-repeat;
  background-position: 90% bottom;
  background-color: #f0f5f9;
  border-top: 1px solid rgba(192, 201, 217, 0.5); }
  .order__selected.-step-1 {
    border-top: 0;
    background-image: url("/_images/svg/1.svg"); }
  .order__selected.-step-2 {
    background-image: url("/_images/svg/2.svg"); }
  .order__selected.-step-3 {
    background-image: url("/_images/svg/3.svg"); }

.order__selected-title {
  color: #000;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 23px; }

.order__selected-list--car {
  display: flex;
  flex-wrap: nowrap; }
  @media (max-width: 767px) {
    .order__selected-list--car {
      flex-direction: column; } }
  .order__selected-list--car .order__selected-item {
    color: #515b6d;
    font-size: 14px;
    margin-right: 14px; }
    .order__selected-list--car .order__selected-item .order__selected-edit {
      right: 14px; }
    .order__selected-list--car .order__selected-item.brand {
      width: 20%; }
      @media (max-width: 767px) {
        .order__selected-list--car .order__selected-item.brand {
          width: 100%; } }
    .order__selected-list--car .order__selected-item.model {
      width: 30%; }
      @media (max-width: 767px) {
        .order__selected-list--car .order__selected-item.model {
          width: 100%; } }
    .order__selected-list--car .order__selected-item.ser {
      width: 25%; }
      @media (max-width: 767px) {
        .order__selected-list--car .order__selected-item.ser {
          width: 100%; } }
    .order__selected-list--car .order__selected-item.wtf {
      width: 25%;
      margin-right: 0; }
      @media (max-width: 767px) {
        .order__selected-list--car .order__selected-item.wtf {
          width: 100%; } }

.order__selected-list--place .order__selected-item {
  color: #515b6d;
  font-size: 14px; }
  .order__selected-list--place .order__selected-item .time {
    color: #4185f4; }
  .order__selected-list--place .order__selected-item .order__selected-edit {
    right: 14px; }

.order__selected-item {
  cursor: pointer;
  padding: 0 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 44px;
  border-radius: 5px;
  background-color: #ffffff;
  line-height: 46px;
  color: #4185f4;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
  position: relative;
  transition: all .3s;
  outline: none;
  user-select: none; }
  .order__selected-item:hover {
    box-shadow: 0 0 17px 5px rgba(65, 133, 244, 0.15); }
  .order__selected-item--big {
    height: 44px;
    line-height: 46px; }
    @media (max-width: 767px) {
      .order__selected-item--big {
        padding-right: 75px;
        font-size: 12px; } }

.order__selected-close {
  transform: rotate(0deg);
  top: 15px;
  right: 16px;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("/_images/svg/cross.svg"); }

.order__selected-edit {
  top: 13px;
  right: 51px;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  background-image: url("/_images/svg/pencil-black.svg"); }
  .order__selected-edit:hover {
    background-image: url("/_images/svg/pencil.svg"); }

.order__selected-more {
  display: block;
  cursor: pointer;
  color: #4185f4;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.71px; }
  .order__selected-more:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #4185f4;
    background-image: url("/_images/svg/plus.svg"); }
  .order__selected-more.-right {
    float: right; }

.order__tab {
  list-style: none;
  margin-bottom: 17px;
  padding-left: 27px; }

.order__tab-item {
  float: left;
  opacity: 0.7;
  font-size: 14px;
  line-height: 22px;
  margin-right: 26px; }
  .order__tab-item a {
    color: #515b6d;
    font-weight: 300;
    border-bottom: 1px dashed; }
  .order__tab-item:last-child {
    right: 0; }
  .order__tab-item.active a {
    color: #000000;
    border-bottom: none; }

.order__tab-content .tab-pane {
  display: block;
  border: 1px solid #d0d7e2;
  background-color: #ffffff;
  padding: 18px 12px; }
  .order__tab-content .tab-pane.-four-col {
    column-count: 4; }
  .order__tab-content .tab-pane.-two-col {
    column-count: 2; }

.order__shop-wrap {
  display: block;
  width: 100%; }

.order__shop {
  width: 100%;
  display: flex; }

.order__shop-item {
  margin-bottom: 30px;
  cursor: pointer;
  width: 33%;
  text-align: center; }
  .order__shop-item:hover img {
    border: 2px solid #4185f4; }
  .order__shop-item.active .order__shop-img:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 6px;
    transform: translateX(25px);
    left: 50%;
    background-repeat: no-repeat;
    background-color: #4185f4;
    border-radius: 50%;
    background-position: center;
    background-image: url("/_images/svg/service-ok.png"); }
    @media (max-width: 767px) {
      .order__shop-item.active .order__shop-img:before {
        left: 30% !important; } }
  .order__shop-item.active .order__shop-img img {
    border: 2px solid #4185f4;
    box-shadow: inset 0 0 0px 2px #4185f4; }

.order__shop-img {
  position: relative; }
  .order__shop-img img {
    display: inline-block;
    border-radius: 50%;
    width: 119px;
    height: 119px;
    border: 2px solid transparent;
    box-shadow: inset 0 0 0px 2px #4185f4; }
    @media (max-width: 767px) {
      .order__shop-img img {
        width: 90%;
        max-width: 100%;
        height: auto; } }

.order__shop-type {
  color: #515b6d;
  font-size: 12px;
  line-height: 18px;
  margin-top: 21px;
  font-weight: 300; }

.order__shop-address {
  color: #000000;
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px; }

.order__calendar {
  padding: 30px 0px;
  border-top: 1px solid #d0d7e2; }
  @media (max-width: 767px) {
    .order__calendar {
      display: flex;
      flex-direction: column; } }

.order__calendar-wrap {
  float: left;
  width: 61%;
  border-right: 1px solid #d0d7e2;
  padding-right: 37px; }
  @media (max-width: 767px) {
    .order__calendar-wrap {
      width: 100%;
      padding: 0;
      border: 0; } }

.order__calendar-scheduler {
  float: left;
  width: 39%;
  margin-top: 92px;
  padding-left: 17px; }
  @media (max-width: 767px) {
    .order__calendar-scheduler {
      width: 100%;
      padding: 0;
      border: 0;
      margin-top: 20px;
      /*display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;*/ } }

.order__success {
  color: #515b6d;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 58px;
  font-weight: 300; }

.order_success-title {
  margin-top: 19px;
  font-size: 60px;
  line-height: 82px; }

.order__print {
  font-weight: 300;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 49px;
  color: #4185f4;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.71px; }
  .order__print:before {
    margin-right: 15px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background-color: #2ed672;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/_images/svg/printer.svg"); }

.order__form-subtitle {
  text-align: center;
  color: #515b6d;
  font-size: 16px;
  line-height: 22px; }

.order__form-alarm {
  top: 10px;
  color: #515b6d;
  font-size: 12px;
  line-height: 18px;
  padding-left: 38px;
  position: relative; }
  @media (max-width: 767px) {
    .order__form-alarm {
      top: 0; } }
  .order__form-alarm:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    content: '';
    vertical-align: middle;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("/_images/svg/red-alarm.svg"); }

.order__from-repair {
  margin-top: 16px;
  display: flex; }
  .order__from-repair input {
    display: none; }
    .order__from-repair input:checked + label {
      color: #4185f4;
      box-shadow: 0 0 0 2px #4688f1;
      z-index: 2; }
  .order__from-repair label {
    width: 50%;
    height: 42px;
    border-radius: 100px;
    color: #515b6d;
    background-color: #ffffff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    box-shadow: 0 0 0 2px #d0d7e2;
    position: relative; }
    .order__from-repair label.self {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0; }
    .order__from-repair label.repair {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: 0; }

.order__preloader {
  text-align: center;
  padding: 80px 20px; }

.tab-content__column {
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 40px; }
  @media (max-width: 767px) {
    .tab-content__column {
      width: 50%; } }

.tab-content__brand {
  color: #515b6d;
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  cursor: pointer;
  font-weight: 300;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0 13px; }
  .tab-content__brand:hover, .tab-content__brand.active {
    transition: .2s ease-out;
    border: 1px solid #4185f4;
    color: #4185f4; }

.tab-content--fix-height {
  max-height: 550px;
  overflow: auto; }
  .tab-content--fix-height .mCSB_inside > .mCSB_container {
    margin-right: 10px; }
  .tab-content--fix-height .mCSB_scrollTools {
    width: 10px; }
  .tab-content--fix-height .mCSB_draggerContainer {
    width: 10px;
    background-color: rgba(65, 133, 244, 0.5) !important; }
  .tab-content--fix-height .mCSB_dragger_bar {
    width: 6px !important;
    left: 0px;
    background-color: #4185f4 !important; }
  .tab-content--fix-height .mCSB_draggerRail {
    display: none !important; }

.tab-content__checkbox {
  position: relative;
  cursor: pointer;
  line-height: 36px;
  padding-left: 30px; }
  .tab-content__checkbox:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border-radius: 50%;
    border: 1px solid #ccc; }
  .tab-content__checkbox:after {
    display: none;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    left: 5px;
    top: 50%;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: #555; }
  .tab-content__checkbox.active:after {
    display: block; }

.question {
  text-align: center;
  margin-top: 74px;
  margin-bottom: 108px; }
  .question__btn {
    cursor: pointer;
    text-align: center;
    position: relative;
    width: 736px;
    display: inline-block;
    height: 133px;
    box-shadow: 0 8px 54px 5px rgba(65, 133, 244, 0.33);
    border-radius: 100px;
    border: 1px solid #f0f5f9;
    background-color: #ffffff;
    color: #000000;
    font-size: 34px;
    line-height: 131px; }
    @media (max-width: 991px) {
      .question__btn {
        width: 100%;
        height: auto;
        font-size: 28px;
        line-height: 100px; } }
    @media (max-width: 767px) {
      .question__btn {
        width: 100%;
        height: auto;
        font-size: 20px;
        line-height: 80px; } }
    .question__btn:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 121px;
      margin-right: 20px;
      position: relative;
      top: 8px;
      height: 66px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("/_images/svg/question-car.svg"); }
      @media (max-width: 395px) {
        .question__btn:before {
          display: none; } }
      @media (max-width: 767px) {
        .question__btn:before {
          width: 63px;
          height: 36px; } }
  .question__phone {
    bottom: -37px;
    right: 143px;
    display: block;
    position: absolute;
    width: 308px;
    height: 54px;
    border-radius: 86px;
    background-color: #4185f4;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    line-height: 54px; }
    .question__phone:hover {
      color: #ffffff; }
    @media (max-width: 767px) {
      .question__phone {
        font-size: 16px;
        line-height: 34px;
        height: 34px;
        bottom: -26px;
        left: 0;
        width: 100%; } }

.tooltip.in {
  opacity: 1 !important; }

.tooltip.top {
  background-color: #ffffff; }
  .tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff; }
  .tooltip.top .tooltip-inner {
    box-shadow: 0 2px 33px rgba(65, 133, 244, 0.36);
    background-color: #ffffff;
    color: #515b6d;
    font-size: 12px;
    line-height: 16px;
    padding: 18px; }

.calendar__header {
  text-align: center;
  height: 19px;
  font-size: 16px;
  color: #515b6d;
  margin: 7px 0 12px 0;
  font-weight: 300; }
  .calendar__header > span {
    color: #4185f4;
    font-weight: 300; }

.calendar__arrow {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 12px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer; }
  .calendar__arrow--prev {
    margin-right: 42px;
    background-image: url("/_images/svg/calendar-prev.svg"); }
    @media (max-width: 480px) {
      .calendar__arrow--prev {
        margin-right: 22px; } }
  .calendar__arrow--next {
    margin-left: 42px;
    background-image: url("/_images/svg/calendar-next.svg"); }
    @media (max-width: 480px) {
      .calendar__arrow--next {
        margin-left: 22px; } }

.calendar__week {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.calendar__name {
  color: #4185f4;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  text-align: center;
  width: 55px;
  height: 55px;
  line-height: 55px;
  margin: 9px 0px;
  margin-bottom: -11px !important; }

.calendar__day {
  cursor: pointer;
  margin: 9px 0px;
  text-align: center;
  width: 55px;
  height: 55px;
  border-radius: 5px;
  line-height: 55px;
  border: 1px solid #c0c9d9;
  background-color: #ffffff;
  font-size: 14px;
  color: #000; }
  @media (max-width: 767px) {
    .calendar__day {
      width: 26px;
      height: 26px;
      line-height: 26px; } }
  .calendar__day:last-child {
    margin-right: 0; }
  .calendar__day:hover {
    transition: all .2s;
    color: #4185f4;
    border: 1px solid #4185f4; }
  .calendar__day.broned {
    pointer-events: none;
    border: 0;
    background-color: #f0f5f9;
    color: rgba(81, 91, 109, 0.5); }
  .calendar__day.past {
    background-color: #f0f5f9;
    opacity: .6; }
  .calendar__day.active {
    background-color: #4185f4;
    color: #fff;
    border-color: #4185f4; }

.calendar__time {
  /*float: left;*/
  color: #000000;
  font-size: 14px;
  /*width: 55px;*/
  height: 55px;
  border-radius: 5px;
  border: 1px solid #c0c9d9;
  background-color: #ffffff;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
  margin: 9px 15px 18px; }
  @media (max-width: 767px) {
    .calendar__time {
      height: 32px;
      line-height: 32px;
      margin: 9px; } }
  .calendar__time:hover {
    transition: all .2s;
    color: #4185f4;
    border: 1px solid #4185f4; }
  .calendar__time.broned {
    background-color: #f0f5f9;
    opacity: 0.5;
    color: #515b6d;
    pointer-events: none;
    border: 0; }
  .calendar__time.active {
    color: #fff;
    background-color: #4185f4;
    border-color: #4185f4; }
  .calendar__time-text {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 9px 15px 18px; }

.p-menu {
  border-bottom: 1px solid #ebeaf1;
  margin: 9px 0 62px; }
  .p-menu--manager {
    padding: 0 375px; }
    @media (max-width: 1454px) {
      .p-menu--manager {
        padding: 0 205px; } }
    @media (max-width: 991px) {
      .p-menu--manager {
        padding: 0 105px; } }
  .p-menu__wrap {
    display: flex;
    justify-content: space-around; }
  .p-menu__item {
    color: rgba(81, 91, 109, 0.7);
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 12px;
    position: relative; }
    @media (max-width: 991px) {
      .p-menu__item {
        text-align: center;
        font-size: 14px;
        line-height: 17px; } }
    .p-menu__item:hover {
      color: #5887F0; }
      .p-menu__item:hover:after {
        left: 0;
        width: 100%;
        background-color: #5887F0; }
    .p-menu__item:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -13px;
      width: 0px;
      height: 3px;
      background-color: #5887F0;
      transition: all .3s; }
    .p-menu__item.active {
      color: #515b6d;
      position: relative;
      /*      pointer-events: none;*/ }
      .p-menu__item.active:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        left: 0;
        bottom: -13px;
        background-color: #515b6d; }

.no-car {
  color: #515b6d;
  margin-bottom: 100px; }
  .no-car__title {
    font-size: 40px;
    line-height: 38px;
    font-weight: 300;
    margin-bottom: 28px; }
  .no-car__text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 41px; }

.p-car {
  margin-bottom: 80px; }
  .p-car .order__selected-more {
    margin-top: 30px; }
  .p-car--selected {
    background-color: #f0f5f9;
    padding: 30px 0;
    margin-bottom: 54px; }
  .p-car--single .order__selected-more {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
    @media (max-width: 1454px) {
      .p-car--single .order__selected-more {
        text-align: center;
        width: 100%;
        left: 0;
        transform: none; } }
  .p-car--single .p-car__wrap {
    justify-content: center; }
  .p-car--single .p-car__item {
    margin-right: 0; }
    @media (max-width: 1454px) {
      .p-car--single .p-car__item {
        width: 70%; } }
  @media (max-width: 991px) {
    .p-car--single .car__item {
      flex-direction: row !important; } }
  @media (max-width: 480px) {
    .p-car--single .car__item {
      flex-direction: column !important; } }
  .p-car--list .car__btn {
    transition: opacity 0.3s;
    opacity: 0; }
  .p-car--list .p-car__item:hover .car__btn {
    opacity: 1; }
  .p-car--list .car__item.active .car__btn--close {
    left: 17px; }
  .p-car--list .car__item.active .car__btn--select {
    display: none; }
  .p-car__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .p-car__wrap {
        flex-direction: column; } }
  .p-car__item {
    background: #fff;
    width: 49%;
    position: relative;
    margin-bottom: 24px; }
    @media (max-width: 991px) {
      .p-car__item {
        width: 100% !important;
        margin-right: 0;
        margin-bottom: 24px; } }
    .p-car__item:nth-child(2n) {
      margin-right: 0; }
    .p-car__item--selected {
      margin: 0;
      width: 100%; }
  .p-car__link-detail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .p-car__select {
    color: #515b6d;
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    top: -13px;
    left: 16px;
    background: white;
    padding-right: 18px;
    font-weight: 300; }
    .p-car__select .order__line-circle {
      margin-right: 9px;
      display: inline-block;
      vertical-align: middle;
      background-color: #4185f4;
      border-color: #4185f4;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("/_images/svg/service-ok.png"); }

.car__item {
  cursor: pointer;
  border: 1px solid #d0d7e2;
  display: flex;
  height: 100%;
  padding: 46px 28px 24px 28px; }
  @media (max-width: 480px) {
    .car__item {
      flex-direction: column; } }
  .car__item.active {
    border: 1px solid #4185f4; }
  .car__item--selected {
    cursor: default;
    padding: 13px;
    border: 1px solid #4185f4; }
    @media (max-width: 767px) {
      .car__item--selected {
        flex-direction: column; } }

.car__img-wrap {
  width: 40%;
  height: 100%;
  text-align: center;
  padding-top: 52px; }
  @media (max-width: 480px) {
    .car__img-wrap {
      width: 100%; } }
  .car__img-wrap:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle; }

.car__img {
  max-width: 100%;
  width: 97%;
  vertical-align: middle; }

.car__description {
  padding-left: 68px;
  width: 60%; }
  @media (max-width: 1454px) {
    .car__description {
      margin-top: 3px; } }
  @media (max-width: 480px) {
    .car__description {
      width: 100%;
      padding-left: 0 !important;
      text-align: center; } }

.car__name {
  color: #4185f4;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 14px;
  font-weight: 300; }
  @media (max-width: 991px) {
    .car__name {
      font-size: 20px;
      line-height: 26px; } }

.car__param {
  color: #515b6d;
  font-size: 18px;
  line-height: 35px;
  font-weight: 300; }
  @media (max-width: 991px) {
    .car__param {
      font-size: 14px;
      line-height: 26px; } }

.car__info {
  width: 50%;
  display: flex;
  border-right: 1px solid rgba(65, 133, 244, 0.5);
  align-items: center; }
  @media (max-width: 767px) {
    .car__info {
      width: 100%;
      border: 0; } }
  @media (max-width: 480px) {
    .car__info {
      flex-direction: column; } }
  .car__info .car__description {
    padding-left: 49px; }

.car__actions {
  width: 50%; }
  @media (max-width: 767px) {
    .car__actions {
      width: 100%;
      padding-left: 0; } }

.car__btn {
  width: 30px;
  height: 30px;
  background-color: #4185f4;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat; }
  .car__btn:hover {
    transition: .2s all;
    background-color: #fd6555; }
  .car__btn--edit {
    top: 20px;
    left: 22px;
    background-image: url("/_images/svg/pencil-white.png"); }
  .car__btn--close {
    top: 20px;
    left: 67px;
    background-image: url("/_images/svg/cross-white.svg"); }
  .car__btn--select {
    background-color: transparent;
    top: 20px;
    left: 22px;
    background-image: url("/_images/svg/select_auto.svg"); }
    .car__btn--select:hover {
      background-color: #e7fbe7; }
  .car__btn .absolute-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

.actions__title {
  text-align: center;
  color: #000;
  font-size: 40px;
  line-height: 79px;
  font-weight: 300; }

.actions__buttons {
  display: flex;
  margin-bottom: 29px; }
  @media (max-width: 480px) {
    .actions__buttons {
      flex-direction: column; } }

.actions__btn {
  position: relative;
  text-align: center;
  margin: 0 15px;
  height: 120px;
  width: 30%;
  border-radius: 5px;
  border: 2px solid #4185f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: all .2s; }
  @media (max-width: 480px) {
    .actions__btn {
      width: 100%;
      margin: 5px 0 !important; } }
  .actions__btn:hover {
    box-shadow: 0 11px 23px rgba(65, 133, 244, 0.43); }
  .actions__btn.active {
    box-shadow: none;
    cursor: default; }
  .actions__btn:hover, .actions__btn.active {
    background-color: #4185f4;
    color: #fff; }
    .actions__btn:hover .actions__btn-text, .actions__btn.active .actions__btn-text {
      color: #fff; }
    .actions__btn:hover .actions__btn-icon, .actions__btn.active .actions__btn-icon {
      background-color: #fff !important; }
    .actions__btn:hover .actions__btn-icon--history, .actions__btn.active .actions__btn-icon--history {
      background-image: url("/_images/svg/history-hover.svg"); }
    .actions__btn:hover .actions__btn-icon--note, .actions__btn.active .actions__btn-icon--note {
      background-image: url("/_images/svg/note-hover.svg"); }
    .actions__btn:hover .actions__btn-icon--orders, .actions__btn.active .actions__btn-icon--orders {
      background-image: url("/_images/svg/repair-hover.svg"); }

.actions__btn-counter {
  position: absolute;
  top: -8px;
  right: -13px;
  width: 30px;
  height: 30px;
  background-color: #515b6d;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px; }
  .actions__btn-counter:hover {
    transition: all .2s;
    background-color: #ff6451; }

.actions__btn-text {
  line-height: 18px;
  text-align: center;
  color: #4185f4;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.71px; }
  @media (max-width: 991px) {
    .actions__btn-text {
      line-height: 12px;
      font-size: 9px;
      letter-spacing: normal; } }

.actions__btn-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #4185f4;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat; }
  .actions__btn-icon--history {
    background-image: url("/_images/svg/history.svg"); }
  .actions__btn-icon--note {
    background-image: url("/_images/svg/note.svg"); }
  .actions__btn-icon--orders {
    background-image: url("/_images/svg/repair.svg"); }

.b-useful {
  display: flex;
  margin-bottom: 78px; }
  .b-useful ul {
    width: 25%;
    list-style: none;
    margin: 0;
    padding: 0; }
    .b-useful ul li a {
      font-weight: 300;
      color: #4185f4;
      font-size: 18px;
      line-height: 42px; }

.manager__form {
  position: relative; }
  .manager__form input {
    width: 100%;
    height: 58px;
    border-radius: 3px;
    border: 1px solid #d0d7e2;
    background-color: #ffffff;
    line-height: 58px;
    padding: 0 20px; }
    .manager__form input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #979DA7; }
    .manager__form input::-moz-placeholder {
      /* Firefox 19+ */
      color: #979DA7; }
    .manager__form input:-ms-input-placeholder {
      /* IE 10+ */
      color: #979DA7; }
    .manager__form input:-moz-placeholder {
      /* Firefox 18- */
      color: #979DA7; }
  .manager__form:after {
    content: '';
    width: 19px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
    position: absolute;
    border: 0;
    top: 19px;
    right: 20px;
    cursor: pointer;
    z-index: 2;
    background-color: #fff;
    background-image: url("/_images/svg/search.svg"); }

.manager__client {
  margin-top: 80px; }

.manager__actions-btns {
  justify-content: space-between;
  margin-bottom: 47px; }
  .manager__actions-btns .actions__btn {
    cursor: pointer;
    margin: 0;
    height: 150px; }

.manager__forms {
  background-color: #f0f5f9;
  padding-bottom: 89px;
  padding-top: 48px;
  margin-bottom: -40px; }

.manager__orders {
  border: 1px solid #d0d7e2;
  background-color: #ffffff;
  padding: 24px 32px; }

.m-table__head {
  display: flex;
  color: #515b6d;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px; }

.m-table__body .m-table__place {
  padding: 0px; }

.m-table__row {
  position: relative;
  display: flex;
  color: #515b6d;
  font-size: 14px;
  line-height: 41px;
  height: 41px;
  border-radius: 3px;
  border: 1px solid #d0d7e2;
  margin-bottom: 10px;
  padding: 0 15px; }
  @media (max-width: 767px) {
    .m-table__row {
      height: auto;
      padding: 6px 15px;
      line-height: 21px; } }
  .m-table__row:hover {
    transition: all .2s;
    border: 1px solid #4185f4;
    color: #4185f4; }
    .m-table__row:hover .m-table__printer {
      display: block; }

.m-table__time {
  width: 30%;
  user-select: none; }

.m-table__place {
  width: 70%;
  user-select: none; }

.m-table__no-order {
  text-align: center; }

.m-table__printer {
  cursor: pointer;
  display: none;
  position: absolute;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  right: 10px;
  top: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2ed672;
  background-image: url("/_images/svg/printer.svg"); }

.client__name {
  color: #4185f4;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 15px; }

.client__phone {
  color: #000000;
  font-size: 24px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 49px; }

.personal__wrap {
  background-color: #f0f5f9;
  padding: 56px 0; }

@media (max-width: 991px) {
  .personal__photo-wrap {
    margin-bottom: 24px; } }

.personal__photo .no-photo {
  border: 2px solid #4185f4;
  background-color: #ffffff;
  display: inline-block;
  width: 142px;
  height: 142px;
  border-radius: 50%;
  background: url("/_images/svg/no-photo.svg") no-repeat center; }

.personal__soc-title {
  color: #515b6d;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px; }

.personal__soc-item {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  margin-right: 16px;
  background: no-repeat center; }
  .personal__soc-item:last-child {
    margin-right: 0; }
  .personal__soc-item.vkontakte {
    background-image: url("/_images/svg/vk.svg"); }
    .personal__soc-item.vkontakte.active {
      background-image: url("/_images/svg/vk-icon.svg"); }
  .personal__soc-item.facebook {
    background-image: url("/_images/svg/fb.svg"); }
    .personal__soc-item.facebook.active {
      background-image: url("/_images/svg/fb-icon.svg"); }
  .personal__soc-item.odnoklassniki {
    background-image: url("/_images/svg/ok.svg"); }
    .personal__soc-item.odnoklassniki.active {
      background-image: url("/_images/svg/ok-icon.svg"); }
  .personal__soc-item.googleoauth {
    background-image: url("/_images/svg/gplus.png"); }
    .personal__soc-item.googleoauth.active {
      background-image: url("/_images/svg/gl-icon.svg"); }
  .personal__soc-item.mail {
    background-image: url("/_images/svg/mail.svg"); }
  .personal__soc-item .close {
    bottom: -10px;
    right: -11px;
    transform: rotate(45deg);
    opacity: 1 !important;
    cursor: pointer;
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fd6557;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/_images/svg/close-white.png"); }

.personal__left {
  padding-top: 57px;
  text-align: left !important; }

.personal .add_photo {
  border: 2px solid #4185f4;
  line-height: 41px; }

.personal .change-pas {
  line-height: 41px;
  margin-top: 6px;
  width: 100%;
  border: 2px solid #4185f4; }
  @media (max-width: 1454px) {
    .personal .change-pas {
      margin-top: 0; } }
  .personal .change-pas[disabled] {
    color: #ccc;
    border: 1px solid; }
    .personal .change-pas[disabled]:hover {
      color: #ccc;
      background: initial; }

.history__car {
  margin-bottom: 51px;
  text-align: center; }
  .history__car .jq-selectbox {
    width: 442px; }
    @media (max-width: 767px) {
      .history__car .jq-selectbox {
        width: 320px;
        margin-left: 0;
        margin-top: 10px; } }
  .history__car .jq-selectbox__select {
    border-color: transparent; }
  .history__car .jq-selectbox__trigger {
    border-color: transparent; }
  .history__car .jq-selectbox__select-text {
    color: #4185f4;
    font-size: 22px;
    top: 0;
    position: relative; }
    .history__car .jq-selectbox__select-text:after {
      content: '';
      display: block;
      border-bottom: 1px dashed #4185f4;
      position: absolute;
      bottom: 12px;
      left: 3%;
      width: 95%; }

.history__time {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 98px;
  background-color: #f0f5f9; }
  @media (max-width: 767px) {
    .history__time {
      flex-direction: column;
      height: auto;
      padding: 10px 0; } }

.history__form {
  position: relative;
  font-size: 22px;
  font-weight: 300; }
  @media (max-width: 991px) {
    .history__form {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .history__form {
      font-size: 13px; } }
  .history__form input {
    width: 116px;
    margin: 0 20px;
    padding: 0 19px;
    font-size: 14px;
    line-height: 58px;
    height: 58px;
    border-radius: 3px;
    border: 1px solid #d0d7e2;
    background-color: #ffffff; }
    @media (max-width: 991px) {
      .history__form input {
        width: 80px;
        margin: 0 6px;
        padding: 0 10px;
        font-size: 14px;
        line-height: 42px;
        height: 42px; } }

.history__add-btn {
  margin-left: 97px; }
  @media (max-width: 991px) {
    .history__add-btn {
      margin-left: 30px; } }
  @media (max-width: 767px) {
    .history__add-btn {
      margin-left: 0;
      margin-top: 10px; } }

.history__table {
  font-weight: 300;
  margin-bottom: 31px;
  margin-top: 51px;
  width: 100%; }
  .history__table thead {
    background-color: #4185f4; }
    .history__table thead tr th {
      vertical-align: middle;
      padding: 24px;
      font-size: 18px;
      color: #ffffff;
      padding-left: 25px; }
      @media (max-width: 991px) {
        .history__table thead tr th {
          padding: 14px;
          font-size: 13px; } }
  .history__table tr.total-row td {
    border: 0; }
  .history__table tr td {
    color: #515b6d;
    font-size: 14px;
    line-height: 22px;
    padding: 23px 22px 14px 22px;
    border: 1px solid #ebeaf1; }
    .history__table tr td.date {
      padding: 23px 15px 14px; }
    .history__table tr td.price {
      width: 21%; }
    .history__table tr td.master {
      width: 16%; }
    @media (max-width: 991px) {
      .history__table tr td {
        font-size: 12px;
        line-height: 17px;
        padding: 9px 23px 4px 21px; } }
    .history__table tr td.buttons {
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      padding: 23px 14px 14px; }
      .history__table tr td.buttons--full {
        white-space: normal;
        width: 19%; }
        .history__table tr td.buttons--full button, .history__table tr td.buttons--full .button {
          display: block;
          width: 100%;
          margin: 10px 0; }
    .history__table tr td .comment {
      background-color: #f0f5f9;
      color: #515b6d;
      font-size: 14px;
      line-height: 22px;
      padding: 17px;
      position: relative;
      margin-bottom: 12px; }
      .history__table tr td .comment:after {
        top: 100%;
        right: 15%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(240, 245, 249, 0);
        border-top-color: #f0f5f9;
        border-width: 13px;
        margin-left: -13px; }
    .history__table tr td .job {
      margin-bottom: 16px; }
      .history__table tr td .job:last-child {
        margin-bottom: 11px; }
      .history__table tr td .job__more {
        display: inline-block;
        color: #4185f4;
        font-size: 12px;
        line-height: 16px;
        border-bottom: 1px dashed #4185f4; }
    .history__table tr td .invoice_job {
      width: 22%; }
    .history__table tr td .invoice_comment {
      width: 31%; }
  .history__table .inner {
    width: 100%; }
    .history__table .inner tr.total td {
      padding-top: 19px; }
    .history__table .inner tr td {
      border: 0;
      padding: 0; }
      .history__table .inner tr td:first-child {
        font-weight: 400; }
      .history__table .inner tr td:last-child {
        text-align: right;
        white-space: nowrap; }

.history__table-btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-block;
  border: 0;
  border-radius: 50%;
  margin: 0 3px;
  background-position: center;
  background-repeat: no-repeat; }
  .history__table-btn.red {
    background-color: #ff6451;
    background-image: url("/_images/svg/close-white.png");
    transform: rotate(45deg); }
  .history__table-btn.blue {
    background-color: #4185f4;
    background-image: url("/_images/svg/pencil-white.svg"); }
  .history__table-btn.green {
    background-color: #2ed672;
    background-image: url("/_images/svg/printer.svg"); }

.with-btn li.sel {
  position: relative; }
  .with-btn li.sel:after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    right: 11px;
    width: 30px;
    height: 30px;
    background-color: #4185f4;
    border-radius: 50%;
    background-image: url("/_images/svg/service-ok.png");
    background-position: center;
    background-repeat: no-repeat; }

.with-btn li.btn-car {
  font-weight: 300;
  text-align: right;
  color: #4185f4;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.71px; }
  .with-btn li.btn-car:before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #4185f4;
    border-radius: 50%;
    background-image: url("/_images/svg/plus.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin: 10px 4px 10px 0px;
    vertical-align: middle; }

.select-map {
  border-left: 1px solid #d0d7e2;
  color: #4185f4;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  cursor: pointer;
  background: #fff;
  position: absolute;
  z-index: 999;
  right: 57px;
  top: 1px;
  height: 56px;
  width: 138px;
  padding-top: 15px;
  text-align: center; }
  .select-map__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .select-map span {
    vertical-align: middle;
    display: inline-flex; }
  .select-map .marker {
    margin-right: 11px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    background-image: url("/_images/svg/place.svg");
    width: 16px;
    height: 23px; }

.address-map-select {
  padding: 50px 0; }
  .address-map-select__title {
    margin-bottom: 30px;
    text-align: center;
    color: #000000;
    font-size: 40px; }
  .address-map-select__wrap {
    width: 90%;
    height: 550px;
    position: relative;
    margin: 0 auto;
    border: 1px solid #4185f4; }
    @media (max-width: 1454px) {
      .address-map-select__wrap {
        height: 350px; } }
  .address-map-select__area {
    width: 100%;
    height: 100%; }

.datepicker .glyphicon.glyphicon-chevron-left {
  width: 6px;
  height: 9px;
  background-image: url(/_images/svg/calendar-arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(180deg); }
  .datepicker .glyphicon.glyphicon-chevron-left:before {
    display: none; }

.datepicker .glyphicon.glyphicon-chevron-right {
  width: 6px;
  height: 9px;
  background-image: url(/_images/svg/calendar-arrow.svg);
  background-size: cover;
  background-repeat: no-repeat; }
  .datepicker .glyphicon.glyphicon-chevron-right:before {
    display: none; }

.form__input.active .form__label--selected {
  display: block; }

.form__input.active .form__label--unselected {
  display: none; }

.car-styler .jq-selectbox__dropdown {
  border: none;
  border-top: 1px solid #4185f4; }
  .car-styler .jq-selectbox__dropdown li:hover {
    background-color: #f5f9fe; }

.car-styler.opened .jq-selectbox__select {
  box-shadow: 0 11px 23px rgba(65, 133, 244, 0.43); }

.car-styler.opened .jq-selectbox__select-text:after {
  display: none; }

.breadcrumb > li + li:before {
  content: "";
  padding: 0 15px;
  color: #ccc;
  width: 3px;
  height: 6px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAAx8TU7AAAAfklEQVQYV2MMjspxX7tsyk4GJMAYGhrK/IdN4uf6pZNZYOKMIIanZy47hzDDx/VLJnOA+GBBEAgNzeL5w8b8fP3SybxwQZBEYHTu4/+MDGVwwYDo3IeszEy2qxdNfAQWDIzOvcL071/42uVTr4LNDIjKSWZiZNy5bunkJzDzAcoOJnsr0MDkAAAAAElFTkSuQmCC"); }

.breadcrumb {
  margin: 0;
  padding: 0;
  display: inline-block;
  background: transparent; }
  .breadcrumb-wrap {
    border-bottom: 1px solid #f3f3f3; }
    @media (max-width: 767px) {
      .breadcrumb-wrap {
        display: none; } }
  .breadcrumb li {
    font-size: 12px;
    line-height: 58px;
    color: #515b6d; }
    .breadcrumb li a {
      cursor: pointer;
      opacity: 0.5;
      color: #515b6d; }
      .breadcrumb li a:hover {
        opacity: 1;
        transition: all .2s; }
  .breadcrumb__back {
    position: relative;
    cursor: pointer;
    margin-right: 22px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    color: #4185f4;
    font-size: 12px;
    letter-spacing: 1.71px;
    line-height: 58px;
    padding-right: 19px; }
    .breadcrumb__back:after {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #515b6d;
      height: 54%;
      top: 12px;
      right: -1px; }
    .breadcrumb__back:before {
      content: '';
      position: relative;
      top: -2px;
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 8px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("/_images/svg/back-arrow.svg"); }

.avatar__file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  opacity: 0;
  cursor: pointer; }

.service-main .button {
  padding: 0 45px; }

.service-catalog {
  background: #f0f5f9;
  padding: 50px 0; }
  .service-catalog__item {
    display: block;
    text-align: center;
    color: #515b6d;
    font-size: 22px;
    box-shadow: 0 10px 18px rgba(65, 133, 244, 0.15);
    margin: 0 10px 50px;
    transition: all 0.5s; }
    .service-catalog__item-discr {
      background: #fff;
      padding: 30px 15px; }
    .service-catalog__item-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
      @media (max-width: 768px) {
        .service-catalog__item-title {
          white-space: initial;
          text-overflow: initial;
          overflow: initial; } }
    .service-catalog__item-text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 300; }
    .service-catalog__item-photo-wrap {
      height: 250px;
      overflow: hidden;
      border-bottom: 1px solid #f0f5f9; }
    .service-catalog__item-photo {
      height: 100%;
      transform: scale(1.2);
      background: #fff no-repeat center;
      background-size: cover;
      transition: all 0.5s; }
      @media (max-width: 991px) {
        .service-catalog__item-photo {
          transform: none; } }
      .service-catalog__item-photo.-no {
        background-image: url("/_images/svg/service-no-photo.svg");
        background-size: 20%; }
    .service-catalog__item:hover .service-catalog__item-photo {
      transform: none;
      /*      transform: scale(1.05);
              box-shadow: 0 0 50px rgba(65, 133, 244, 0.15);*/ }

.best-service {
  padding: 50px 0; }
  .best-service h2 {
    margin-bottom: 70px; }
  .best-service__photo {
    width: 100%;
    position: relative; }
    .best-service__photo-small {
      position: absolute;
      max-height: 72%;
      bottom: -2%;
      left: 58%; }
  .best-service__item {
    position: relative;
    margin-bottom: 60px;
    padding-left: 120px; }
    .best-service__item-title {
      color: #000000;
      font-size: 22px;
      line-height: 36px;
      font-weight: 300; }
      @media (max-width: 479px) {
        .best-service__item-title {
          font-size: 18px; } }
    .best-service__item-text {
      color: #515b6d;
      font-size: 16px;
      line-height: 24px; }
      @media (max-width: 479px) {
        .best-service__item-text {
          font-size: 14px; } }
    .best-service__item.-icon:after {
      content: '';
      position: absolute;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: #f0f5f9 no-repeat center;
      background-size: 40%;
      top: 0;
      left: 20px; }
      @media (max-width: 479px) {
        .best-service__item.-icon:after {
          display: none; } }
    .best-service__item.-icon.-order:after {
      background-image: url("/_images/service/svg/order.svg");
      background-size: 50%; }
    .best-service__item.-icon.-hist:after {
      background-image: url("/_images/service/svg/history.svg"); }
    .best-service__item.-icon.-guaranty:after {
      background-image: url("/_images/service/svg/medal.svg"); }
    .best-service__item.-icon.-watch:after {
      background-image: url("/_images/service/svg/eye.svg");
      background-size: 45%; }
    .best-service__item--btn {
      margin-top: -20px;
      margin-bottom: 0; }
    @media (max-width: 767px) {
      .best-service__item {
        margin: 30px auto; } }
    @media (max-width: 479px) {
      .best-service__item {
        margin: 20px auto;
        padding-left: 0;
        text-align: center; } }

.service-map__container {
  height: 500px; }

.slider-service {
  position: relative;
  height: 700px;
  background-color: #444; }
  @media (max-width: 991px) {
    .slider-service {
      margin-top: 59px;
      height: 50vh; } }
  @media (max-width: 767px) {
    .slider-service {
      margin-top: 85px;
      max-height: 203px; } }
  .slider-service__slider {
    height: 100%;
    z-index: 100; }
    .slider-service__slider .slick-list,
    .slider-service__slider .slick-track {
      height: 100%; }
    .slider-service__slider .slick-dots {
      display: block;
      text-align: center;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%; }
      .slider-service__slider .slick-dots li {
        display: inline-block;
        margin: 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #d8d8d8; }
        .slider-service__slider .slick-dots li button {
          opacity: 0; }
        .slider-service__slider .slick-dots li.slick-active {
          background-color: #515b6d; }
  .slider-service__item {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover; }
    .slider-service__item:before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
    .slider-service__item .container {
      position: relative;
      z-index: 100;
      height: 100%; }
    .slider-service__item.slick-active .slider-service__content {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.7s linear; }
  .slider-service__content {
    position: absolute;
    left: 8px;
    bottom: 142px;
    transform: translateY(200%);
    opacity: 0; }
    @media (max-width: 991px) {
      .slider-service__content {
        bottom: 75px; } }
    @media (max-width: 767px) {
      .slider-service__content {
        bottom: 55px;
        text-align: center;
        width: 100%; } }
  .slider-service__title {
    margin-bottom: 22px;
    color: #fff;
    font-size: 72px;
    line-height: 82px; }
    @media (max-width: 991px) {
      .slider-service__title {
        margin-bottom: 15px;
        font-size: 36px;
        line-height: 44px; } }
    @media (max-width: 767px) {
      .slider-service__title {
        font-size: 22px;
        line-height: 26px; } }
  .slider-service__text {
    font-weight: 100;
    margin-bottom: 62px;
    color: #fff;
    opacity: 0.8;
    font-size: 18px;
    line-height: 30px; }
    @media (max-width: 991px) {
      .slider-service__text {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 22px; } }
    @media (max-width: 767px) {
      .slider-service__text {
        font-size: 12px;
        line-height: 18px; } }
  .slider-service__link {
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    transition: all .3s;
    padding: 15px 55px;
    border: 2px solid #fff;
    border-radius: 5px; }
    @media (max-width: 991px) {
      .slider-service__link {
        font-size: 10px;
        letter-spacing: 1.43px; } }
    @media (max-width: 767px) {
      .slider-service__link {
        /*      display: none;*/ } }
    .slider-service__link:hover {
      color: #4185f4;
      background-color: #fff;
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.5); }

.service-detail-text {
  padding: 40px 10px;
  background: #f0f5f9;
  color: #515b6d;
  font-size: 16px;
  line-height: 24px; }
  .service-detail-text__title {
    color: #000000;
    font-size: 50px;
    line-height: 82px; }
  .service-detail-text__sub-title {
    text-align: left;
    color: #000000;
    font-size: 30px;
    line-height: 82px; }
  .service-detail-text__list {
    padding-left: 25px; }
    .service-detail-text__list li {
      position: relative;
      line-height: 28px; }
      .service-detail-text__list li:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        margin-top: -3px;
        border-radius: 50%;
        background-color: #4185f4;
        left: -20px;
        top: 50%; }

.service-price {
  padding: 40px 10px; }
  .service-price table {
    width: 100%;
    color: #515b6d;
    font-size: 16px;
    text-align: center;
    border-collapse: collapse; }
    .service-price table tr {
      transition: height 0.5s; }
    .service-price table td {
      padding: 20px 0; }
    .service-price table tbody td {
      border: 1px solid #ebeaf1; }
  .service-price__name {
    text-align: left;
    padding-left: 30px !important; }
  .service-price__value {
    color: #4185f4;
    font-size: 14px;
    line-height: 22px; }
  .service-price__additition-row {
    display: none; }
  .service-price__show-more {
    margin-top: 25px;
    padding-left: 40px;
    position: relative;
    cursor: pointer;
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px; }
    .service-price__show-more:before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      border-radius: 50%;
      top: 50%;
      left: 0;
      background: #4185f4 no-repeat center;
      background-image: url("/_images/svg/arrow-down-white.svg"); }
    .service-price__show-more.opened:before {
      transform: rotate(180deg);
      /*background-image: url('/_images/svg/arrow-up-white.svg');*/ }
    .service-price__show-more.-right {
      float: right; }

.service-sert {
  padding: 40px 10px; }
  .service-sert__slider {
    padding: 15px;
    margin: 0 50px;
    box-shadow: 0 0 91px 3px rgba(65, 133, 244, 0.36);
    border-radius: 3px;
    background-color: #ffffff; }
    .service-sert__slider .slick-arrow {
      outline: none;
      position: absolute;
      width: 30px;
      height: 24px;
      margin-top: -12px;
      top: 50%;
      background: transparent no-repeat center;
      border: none;
      font-size: 0; }
      .service-sert__slider .slick-arrow.slick-prev {
        left: -50px;
        background-image: url("/_images/svg/arrow-left-blue.svg"); }
        .service-sert__slider .slick-arrow.slick-prev:hover {
          background-image: url("/_images/svg/arrow-left-blue-hover.svg"); }
      .service-sert__slider .slick-arrow.slick-next {
        right: -50px;
        background-image: url("/_images/svg/arrow-right-blue.svg"); }
        .service-sert__slider .slick-arrow.slick-next:hover {
          background-image: url("/_images/svg/arrow-right-blue-hover.svg"); }
    .service-sert__slider-item {
      display: block;
      margin: 0 5px; }
      .service-sert__slider-item img {
        width: 100%; }

.service-banner {
  position: relative;
  height: 430px;
  padding: 80px 60px;
  background: #ddd no-repeat center;
  background-size: cover;
  border: 1px solid #ccc;
  margin: 70px auto; }
  @media (max-width: 991px) {
    .service-banner {
      /*margin-top: 59px;*/
      height: 350px; } }
  @media (max-width: 767px) {
    .service-banner {
      /*margin-top: 85px;*/
      padding: 40px 30px;
      max-height: 203px; } }
  .service-banner__title {
    margin-bottom: 22px;
    color: #fff;
    font-size: 50px;
    line-height: 60px; }
    @media (max-width: 991px) {
      .service-banner__title {
        margin-bottom: 15px;
        font-size: 36px;
        line-height: 44px; } }
    @media (max-width: 767px) {
      .service-banner__title {
        font-size: 22px;
        line-height: 26px; } }
  .service-banner__text {
    font-weight: 100;
    margin-bottom: 62px;
    color: #fff;
    font-size: 24px; }
    @media (max-width: 991px) {
      .service-banner__text {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 22px; } }
    @media (max-width: 767px) {
      .service-banner__text {
        font-size: 12px;
        line-height: 18px; } }
  .service-banner__link {
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    transition: all .3s;
    padding: 15px 55px;
    border: 2px solid #fff;
    border-radius: 5px; }
    @media (max-width: 991px) {
      .service-banner__link {
        font-size: 10px;
        letter-spacing: 1.43px; } }
    @media (max-width: 767px) {
      .service-banner__link {
        /*      display: none;*/ } }
    .service-banner__link:hover {
      color: #4185f4;
      background-color: #fff;
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.5); }

.order-pdf {
  width: 992px; }
  .order-pdf__header {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    background-color: #5881f4;
    padding: 10px 20px; }
    .order-pdf__header-logo {
      display: inline-block;
      padding: 15px;
      border-right: 1px solid; }
    .order-pdf__header-img {
      display: block;
      width: 90%;
      margin-bottom: 10px; }
    .order-pdf__header-contacts {
      position: relative;
      float: right;
      line-height: 82px;
      vertical-align: top;
      text-align: right; }
      .order-pdf__header-contacts span {
        display: inline-block;
        margin: 0 10px; }
    .order-pdf__header-address {
      position: relative;
      position: relative;
      padding-left: 40px; }
      .order-pdf__header-address:before {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        margin-top: -15px;
        left: 0;
        top: 50%;
        background: no-repeat center;
        background-size: contain;
        background-image: url("/_images/svg/marker.svg") !important; }
    .order-pdf__header-phone {
      position: relative;
      padding-left: 30px; }
      .order-pdf__header-phone:before {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        margin-top: -10px;
        left: 0;
        top: 50%;
        background: no-repeat center;
        background-size: contain;
        background-image: url("/_images/svg/phone.svg"); }
  .order-pdf__content {
    font-weight: 300;
    padding: 20px 40px; }
    .order-pdf__content-title {
      text-align: center;
      font-size: 40px;
      margin: 0 auto 15px;
      line-height: 50px; }
    .order-pdf__content-main-img {
      margin: 10px auto;
      text-align: center; }
      .order-pdf__content-main-img img {
        width: 70%; }
    .order-pdf__content-text {
      text-align: center;
      font-size: 20px;
      margin: 0 auto 15px;
      line-height: 30px; }
    .order-pdf__content-table {
      line-height: 44px; }
      .order-pdf__content-table-head {
        height: 44px; }
        .order-pdf__content-table-head-col {
          box-sizing: border-box;
          float: left; }
          .order-pdf__content-table-head-col:nth-of-type(1) {
            width: 60%; }
          .order-pdf__content-table-head-col:nth-of-type(2) {
            width: 20%; }
          .order-pdf__content-table-head-col:nth-of-type(3) {
            width: 20%; }
      .order-pdf__content-table-row {
        height: 44px;
        border: 1px solid #ccc;
        border-radius: 5px;
        border-bottom: none; }
        .order-pdf__content-table-row:last-of-type {
          border-bottom: 1px solid #ccc; }
      .order-pdf__content-table-col {
        padding: 0 10px;
        box-sizing: border-box;
        height: 100%;
        float: left; }
        .order-pdf__content-table-col:nth-of-type(1) {
          width: 60%; }
        .order-pdf__content-table-col:nth-of-type(2) {
          width: 20%;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc; }
        .order-pdf__content-table-col:nth-of-type(3) {
          width: 20%; }
    .order-pdf__content-parts {
      padding: 15px 0;
      color: #555; }
      .order-pdf__content-parts-checkbox {
        position: relative;
        padding-left: 40px;
        margin-left: 20px; }
        .order-pdf__content-parts-checkbox:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 26px;
          height: 26px;
          margin-top: -13px;
          border: 1px solid #ccc;
          border-radius: 5px; }
    .order-pdf__content-confirm {
      text-align: justify;
      font-size: 12px;
      margin-bottom: 15px; }
  .order-pdf__form-item {
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    height: 64px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box; }
    .order-pdf__form-item.-left {
      width: 600px;
      margin-right: 15px; }
    .order-pdf__form-item.-right {
      width: 288px; }
    .order-pdf__form-item.-half {
      width: 447px; }
      .order-pdf__form-item.-half:nth-of-type(1) {
        margin-right: 14px; }
  .order-pdf__form-label {
    position: absolute;
    font-weight: 300;
    top: 10px;
    left: 10px;
    color: #bbb; }
  .order-pdf__form-text {
    position: relative;
    top: 22px; }
  .order-pdf__form-date {
    position: absolute;
    font-weight: 300;
    bottom: 10px;
    right: 10px;
    color: #545b6d; }
  .order-pdf__footer {
    color: #fff;
    font-family: Roboto;
    font-size: 12px;
    background-color: #545b6d;
    padding: 10px 20px;
    line-height: 16px;
    text-align: center; }

.paragraph {
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-size: 16px;
  color: #515B6D; }

.b-text {
  font-weight: bold; }

.txt-16 {
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
  font-size: 16px;
  color: #000000; }
  @media (max-width: 570px) {
    .txt-16 {
      line-height: 20px;
      font-size: 14px; } }

.txt-18 {
  font-size: 18px;
  color: #515B6D; }

.txt-14 {
  font-size: 14px; }

.txt-24 {
  font-style: normal;
  font-weight: normal;
  line-height: 34px;
  font-size: 24px; }

.crossed_out {
  position: relative;
  color: rgba(81, 91, 109, 0.5); }
  @media (max-width: 570px) {
    .crossed_out {
      font-size: 12px; } }
  .crossed_out:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-top: 1px solid rgba(81, 91, 109, 0.5); }

.placemark {
  width: 40px;
  height: 60px;
  background: url("/_images/svg/red_map.svg") no-repeat;
  background-size: contain;
  background-position: center; }
  .placemark.active {
    width: 27px;
    height: 27px;
    background: url("/_images/svg/blue_map.svg") no-repeat; }

@keyframes show-big-placemark {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes show-small-placemark {
  0% {
    transform: scale(1);
    background-image: url("/_images/svg/blue_map.svg");
    width: 27px;
    height: 27px; }
  100% {
    transform: scale(0);
    background-image: url("/_images/svg/blue_map.svg"); } }

.pay_info {
  margin: 30px 0 80px; }
  .pay_info__section {
    margin-top: 60px; }
    .pay_info__section h4 {
      margin-bottom: 22px; }
    .pay_info__section.-nal h3 {
      margin-top: 25px; }
    .pay_info__section.-cart svg {
      margin: -40px -20px -20px; }
    .pay_info__section.-cart h3 {
      margin-bottom: 45px; }
    .pay_info__section.-present h3 {
      margin: 23px 0 35px; }
  .pay_info__wrap {
    margin-top: 40px; }
    .pay_info__wrap h4 {
      margin-bottom: 16px; }

.delivery {
  margin: 53px 0 60px; }
  .delivery__courier {
    margin-top: 40px; }
  .delivery__list {
    margin-top: 40px; }
    .delivery__list li {
      position: relative;
      padding-left: 46px;
      margin-bottom: 20px; }
      .delivery__list li:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 26px;
        background-color: #4185F4;
        left: 0;
        top: 11px; }
      .delivery__list li:nth-child(2) {
        max-width: 620px; }
      .delivery__list li:last-child {
        max-width: 850px; }
  .delivery__pickup {
    margin-bottom: 40px; }
  .delivery__paragraph {
    max-width: 790px; }
  @media (max-width: 768px) {
    .delivery br {
      display: none; } }

.result {
  margin-bottom: 180px; }
  .result__button {
    margin-top: 50px; }
    .result__button .button:hover {
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.43); }
    .result__button a {
      margin: 0 8px; }
    .result__button .button {
      border-width: 2px; }
  .result__paragraph {
    margin-top: 30px; }
  @media (max-width: 768px) {
    .result {
      margin-bottom: 40px; }
      .result__paragraph {
        margin-top: 0; }
      .result__button {
        margin-top: 20px; }
        .result__button a {
          margin: 8px 0; } }
  @media (max-width: 480px) {
    .result .container {
      padding: 0; }
    .result .button-blue {
      padding: 0 15px; } }

.cart {
  margin-bottom: 19px; }
  .cart__goods {
    margin-top: 40px; }
  .cart__caption_free {
    color: #515B6D; }
    .cart__caption_free b {
      color: #000000;
      font-weight: 400; }
  .cart__img {
    max-width: 88px;
    width: 100%; }
    .cart__img img {
      width: 100%; }
  .cart__link {
    display: block; }
    .cart__link:hover {
      cursor: pointer; }
  .cart__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 18px 0;
    flex-wrap: wrap; }
    .cart__wrap-mob {
      display: none; }
    .cart__wrap:first-child:after {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      height: 1px;
      background-color: rgba(208, 215, 226, 0.5);
      top: 0; }
    .cart__wrap:before {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      height: 1px;
      background-color: rgba(208, 215, 226, 0.5);
      bottom: 0; }
  .cart__additionally {
    margin-top: 80px; }
  .cart__delete {
    background-color: #FF6451;
    border-radius: 50%;
    position: relative;
    height: 30px;
    width: 30px;
    border: none;
    background-image: url("/_images/svg/delete.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    color: #ffffff; }
    .cart__delete:hover {
      cursor: pointer; }
  .cart__wrap_fix {
    position: relative; }
  .cart__window {
    position: sticky;
    max-width: 409px;
    width: 100%;
    float: right;
    top: 115px; }
    @media (max-width: 991px) {
      .cart__window {
        position: relative;
        top: 0; } }
  .cart__bar {
    margin-top: 7px;
    height: 2px;
    width: 100%;
    background-color: rgba(208, 215, 226, 0.5); }
    .cart__bar div {
      background-color: #4185F4;
      height: 2px; }
  .cart__count {
    height: 42px; }
    .cart__count .number_of_goods {
      margin-bottom: 10px; }
    .cart__count .txt-14 {
      color: #515B6D; }
  .cart__flex_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 344px; }
  .cart__price {
    margin-left: 25px; }
  .cart__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px; }
  .cart__description {
    width: 462px;
    padding-right: 15px; }
    .cart__description .p-16 {
      margin-bottom: 10px; }
  .cart__in-stock {
    font-size: 14px;
    padding-left: 15px;
    position: relative; }
    .cart__in-stock:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      left: 0;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%); }
    .cart__in-stock--red {
      color: #FF6451; }
      .cart__in-stock--red:before {
        background: #FF6451; }
    .cart__in-stock--green {
      color: #2ED672; }
      .cart__in-stock--green:before {
        background: #2ED672; }
  @media (max-width: 1455px) {
    .cart__wrap {
      display: none; }
      .cart__wrap-mob {
        display: flex; }
    .cart__window {
      float: initial; }
    .cart__price {
      margin: 0 0 10px 0; }
    .cart__description {
      width: 100%;
      padding: 0 0 14px 0; }
      .cart__description p:first-child {
        padding-bottom: 8px; }
    .cart__flex_wrap {
      justify-content: left; }
    .cart__count {
      margin-right: 23px; }
    .cart__wrap {
      padding: 20px 0 40px;
      justify-content: left;
      flex-wrap: nowrap;
      align-items: end; }
    .cart__img {
      width: 100%;
      max-width: 90px;
      margin-right: 20px; }
      .cart__img img {
        width: inherit; } }
  @media (max-width: 1091px) {
    .cart__window {
      margin-top: 28px; }
    .cart__items {
      margin-top: 30px; } }

.product-card {
  box-sizing: border-box;
  border: 1px solid rgba(208, 215, 226, 0.5);
  position: relative; }
  @media (max-width: 768px) {
    .product-card {
      padding: 10px 10px 35px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .product-card {
      padding: 10px 10px 40px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .product-card {
      padding: 12px 14px 40px; } }
  @media (min-width: 1455px) {
    .product-card {
      padding: 12px 14px 25px; } }
  .product-card__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    user-select: none;
    pointer-events: none; }
    @media (max-width: 768px) {
      .product-card__header {
        margin: -10px -10px 5px -10px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__header {
        margin: -10px -10px 5px -10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__header {
        margin: -15px -14px 5px; } }
    @media (min-width: 1455px) {
      .product-card__header {
        margin: -15px -14px 5px; } }
    @media (max-width: 768px) {
      .product-card__header {
        padding: 10px 10px 10px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__header {
        padding: 10px 10px 14px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__header {
        padding: 12px 15px 14px; } }
    @media (min-width: 1455px) {
      .product-card__header {
        padding: 12px 15px 0px; } }
    @media (max-width: 768px) {
      .product-card__header {
        height: 100%; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__header {
        height: 100%; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__header {
        height: 100%; } }
    @media (min-width: 1455px) {
      .product-card__header {
        height: unset; } }
    @media (max-width: 768px) {
      .product-card__header {
        flex-direction: column-reverse; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__header {
        flex-direction: column-reverse; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__header {
        flex-direction: column-reverse; } }
    @media (min-width: 1455px) {
      .product-card__header {
        flex-direction: row; } }
  .product-card__status {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    height: 16px;
    position: relative;
    text-transform: lowercase; }
    @media (max-width: 768px) {
      .product-card__status {
        font-size: 12px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__status {
        font-size: 12px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__status {
        font-size: 13px; } }
    @media (min-width: 1455px) {
      .product-card__status {
        font-size: 13px; } }
    @media (max-width: 768px) {
      .product-card__status {
        padding-left: 15px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__status {
        padding-left: 15px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__status {
        padding-left: 15px; } }
    @media (min-width: 1455px) {
      .product-card__status {
        padding-left: 20px; } }
    @media (max-width: 768px) {
      .product-card__status {
        padding-bottom: 16px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__status {
        padding-bottom: 16px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__status {
        padding-bottom: 16px; } }
    @media (min-width: 1455px) {
      .product-card__status {
        padding-bottom: 0; } }
    .product-card__status::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 7px; }
      @media (max-width: 768px) {
        .product-card__status::before {
          left: 2px; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .product-card__status::before {
          left: 2px; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .product-card__status::before {
          left: 3px; } }
      @media (min-width: 1455px) {
        .product-card__status::before {
          left: 6px; } }
    .product-card__status.in-stock {
      color: #2ED672; }
      .product-card__status.in-stock::before {
        background-image: url("/_images/svg/item-status-in_stock.svg"); }
    .product-card__status.few {
      color: #FF6451; }
      .product-card__status.few::before {
        background-image: url("/_images/svg/item-status-few.svg"); }
    .product-card__status.custom {
      color: #515B6D; }
      .product-card__status.custom::before {
        background-image: url("/_images/svg/item-custom.svg"); }
  .product-card__proposal {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    flex-direction: column; }
    @media (max-width: 768px) {
      .product-card__proposal {
        right: 0; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__proposal {
        right: -2px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__proposal {
        right: -2px; } }
    @media (min-width: 1455px) {
      .product-card__proposal {
        right: -2px; } }
    @media (max-width: 768px) {
      .product-card__proposal {
        justify-content: center; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__proposal {
        justify-content: center; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__proposal {
        justify-content: flex-start; } }
    @media (min-width: 1455px) {
      .product-card__proposal {
        justify-content: flex-start; } }
    @media (max-width: 768px) {
      .product-card__proposal {
        height: 160px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__proposal {
        height: 200px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__proposal {
        height: auto; } }
    @media (min-width: 1455px) {
      .product-card__proposal {
        height: auto; } }
    @media (max-width: 768px) {
      .product-card__proposal {
        align-items: center; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__proposal {
        align-items: center; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__proposal {
        align-items: flex-end; } }
    @media (min-width: 1455px) {
      .product-card__proposal {
        align-items: flex-end; } }
    @media (max-width: 768px) {
      .product-card__proposal > div:not(:only-of-type).product-card__warranty {
        margin: 15px 0px 10px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__proposal > div:not(:only-of-type).product-card__warranty {
        margin: 15px 0px 10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__proposal > div:not(:only-of-type).product-card__warranty {
        margin: 5px 0px; } }
    @media (min-width: 1455px) {
      .product-card__proposal > div:not(:only-of-type).product-card__warranty {
        margin: 15px 0px 10px; } }
    @media (max-width: 768px) {
      .product-card__proposal > div:only-of-type.product-card__warranty {
        margin-top: 10px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__proposal > div:only-of-type.product-card__warranty {
        margin-top: 10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__proposal > div:only-of-type.product-card__warranty {
        margin-top: 10px; } }
    @media (min-width: 1455px) {
      .product-card__proposal > div:only-of-type.product-card__warranty {
        margin-top: 20px; } }
  .product-card__discount {
    width: 50px;
    height: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    background-image: url("/_images/svg/item-discount.svg");
    background-size: 100%;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .product-card__discount {
        display: none !important; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__discount {
        display: none !important; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__discount {
        display: block; } }
    @media (min-width: 1455px) {
      .product-card__discount {
        display: block; } }
    .product-card__discount::after {
      content: '%'; }
    .product-card__discount::before {
      content: '-'; }
  .product-card__warranty {
    font-style: normal;
    font-weight: normal;
    color: #fff;
    max-width: 171px;
    height: 24px;
    line-height: 24px;
    background-size: 100%;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .product-card__warranty {
        font-size: 12px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__warranty {
        font-size: 12px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__warranty {
        font-size: 13px; } }
    @media (min-width: 1455px) {
      .product-card__warranty {
        font-size: 13px; } }
    @media (max-width: 768px) {
      .product-card__warranty {
        text-align: center; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__warranty {
        text-align: center; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__warranty {
        text-align: right; } }
    @media (min-width: 1455px) {
      .product-card__warranty {
        text-align: right; } }
    @media (max-width: 768px) {
      .product-card__warranty {
        width: 94%; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__warranty {
        width: 94%; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__warranty {
        width: 94%; } }
    @media (min-width: 1455px) {
      .product-card__warranty {
        width: 94%; } }
    @media (max-width: 767px) {
      .product-card__warranty {
        margin: 0 -5px 0 -5px !important; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .product-card__warranty {
        margin: 62% auto !important; } }
    @media (max-width: 768px) {
      .product-card__warranty {
        padding-right: 0; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__warranty {
        padding-right: 0; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__warranty {
        padding-right: 11px; } }
    @media (min-width: 1455px) {
      .product-card__warranty {
        padding-right: 11px; } }
    @media (max-width: 768px) {
      .product-card__warranty {
        background-image: url("/_images/svg/item-warranty-2.svg"); } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__warranty {
        background-image: url("/_images/svg/item-warranty-2.svg"); } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__warranty {
        background-image: url("/_images/svg/item-warranty.svg"); } }
    @media (min-width: 1455px) {
      .product-card__warranty {
        background-image: url("/_images/svg/item-warranty.svg"); } }
  .product-card__body {
    padding: 0px 4px;
    width: 100%; }
    @media (max-width: 768px) {
      .product-card__body {
        margin-top: 3px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__body {
        margin-top: 10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__body {
        margin-top: 25px; } }
    @media (min-width: 1455px) {
      .product-card__body {
        margin-top: 30px; } }
    @media (max-width: 768px) {
      .product-card__body form .form__button {
        padding-top: 3px !important; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__body form .form__button {
        padding-top: 3px !important; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__body form .form__button {
        padding-top: 3px !important; } }
    @media (min-width: 1455px) {
      .product-card__body form .form__button {
        padding-top: 23px; } }
    @media (max-width: 768px) {
      .product-card__body form .form__button {
        display: none; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__body form .form__button {
        display: none; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__body form .form__button {
        display: block; } }
    @media (min-width: 1455px) {
      .product-card__body form .form__button {
        display: block; } }
  .product-card__img {
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    @media (max-width: 768px) {
      .product-card__img {
        height: 120px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__img {
        height: 160px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__img {
        height: 180px; } }
    @media (min-width: 1455px) {
      .product-card__img {
        height: 210px; } }
    @media (max-width: 768px) {
      .product-card__img {
        width: 90%; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__img {
        width: 85%; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__img {
        width: 90%; } }
    @media (min-width: 1455px) {
      .product-card__img {
        width: 75%; } }
  .product-card__name {
    font-weight: 300;
    overflow: hidden;
    display: flex;
    color: #000;
    cursor: pointer; }
    @media (max-width: 768px) {
      .product-card__name {
        font-size: 14px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__name {
        font-size: 15px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__name {
        font-size: 16px; } }
    @media (min-width: 1455px) {
      .product-card__name {
        font-size: 18px; } }
    @media (max-width: 768px) {
      .product-card__name {
        line-height: 18px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__name {
        line-height: 20px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__name {
        line-height: 22px; } }
    @media (min-width: 1455px) {
      .product-card__name {
        line-height: 25px; } }
    @media (max-width: 768px) {
      .product-card__name {
        height: 32px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__name {
        height: 55px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__name {
        height: 60px; } }
    @media (min-width: 1455px) {
      .product-card__name {
        height: 68px; } }
    @media (max-width: 768px) {
      .product-card__name {
        align-items: baseline; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__name {
        align-items: baseline; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__name {
        align-items: baseline; } }
    @media (min-width: 1455px) {
      .product-card__name {
        align-items: center; } }
    @media (max-width: 768px) {
      .product-card__name {
        margin-top: 12px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__name {
        margin-top: 20px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__name {
        margin-top: 20px; } }
    @media (min-width: 1455px) {
      .product-card__name {
        margin-top: 20px; } }
    .product-card__name:hover {
      color: #969696; }
    .product-card__name.overflowed {
      position: relative; }
      .product-card__name.overflowed::after {
        content: '...';
        position: absolute;
        bottom: -3px;
        right: 3px; }
  .product-card__props {
    width: 100%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      .product-card__props {
        margin-top: 0px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__props {
        margin-top: 20px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__props {
        margin-top: 20px; } }
    @media (min-width: 1455px) {
      .product-card__props {
        margin-top: 13px; } }
    @media (max-width: 767px) {
      .product-card__props {
        position: absolute;
        top: 5px;
        right: 2px;
        width: 100%;
        display: flex;
        justify-content: flex-end; } }
  .product-card__prop {
    display: flex;
    width: 100%;
    font-weight: 300;
    line-height: 25px;
    font-size: 14px;
    color: #515B6D; }
    @media (max-width: 768px) {
      .product-card__prop {
        justify-content: flex-end; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__prop {
        justify-content: space-between; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__prop {
        justify-content: space-between; } }
    @media (min-width: 1455px) {
      .product-card__prop {
        justify-content: space-between; } }
    @media (max-width: 767px) {
      .product-card__prop-name {
        display: none; } }
    @media (max-width: 767px) {
      .product-card__prop-value {
        font-size: 0px !important; } }
    .product-card__prop-value[data-icon] {
      position: relative; }
      .product-card__prop-value[data-icon]::before {
        position: absolute;
        top: 4px;
        left: -22px;
        content: '';
        height: 16px;
        width: 16px;
        display: block;
        padding-right: 10px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center; }
    .product-card__prop-value[data-icon="winter"]::before {
      background-image: url("/_images/svg/item-season-winter.svg"); }
    .product-card__prop-value[data-icon="summer"]::before {
      background-image: url("/_images/svg/item-season-summer.svg"); }
    .product-card__prop-value[data-icon="all"]::before {
      background-image: url("/_images/svg/item-season-all.svg"); }
  .product-card__footer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .product-card__footer > .counter {
        display: none; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .product-card__footer > .counter {
        display: none; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .product-card__footer > .counter {
        display: none; } }
    @media (min-width: 1455px) {
      .product-card__footer > .counter {
        display: flex; } }
  .product-card__price {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative; }
    .product-card__price .value {
      color: #515B6D; }
      @media (max-width: 768px) {
        .product-card__price .value {
          font-size: 20px; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .product-card__price .value {
          font-size: 22px; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .product-card__price .value {
          font-size: 24px; } }
      @media (min-width: 1455px) {
        .product-card__price .value {
          font-size: 26px; } }
    .product-card__price .old {
      font-size: 14px;
      color: #515B6D;
      opacity: 0.5;
      text-decoration: line-through;
      position: absolute;
      top: 0px;
      transform: translateY(-50%); }
      @media (max-width: 768px) {
        .product-card__price .old {
          padding-bottom: 20px; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .product-card__price .old {
          padding-bottom: 20px; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .product-card__price .old {
          padding-bottom: 20px; } }
      @media (min-width: 1455px) {
        .product-card__price .old {
          padding-bottom: 8px; } }
  .product-card__btn {
    margin-top: 9px;
    color: #fff;
    user-select: none; }
    .product-card__btn.-pc_buy {
      text-indent: 27px;
      background-image: url("/_images/svg/shopping-cart.svg");
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: 23px 15px; }
    .product-card__btn.-pc_order {
      padding-left: 47px;
      padding-right: 47px; }
  .product-card.readonly {
    padding: 15px 15px !important; }
    .product-card.readonly .product-card__warranty {
      display: none; }
    .product-card.readonly .product-card__status {
      display: none; }
    .product-card.readonly .product-card__body {
      margin-top: 5px; }
    .product-card.readonly .product-card__name {
      font-size: 14px;
      line-height: 18px;
      height: 68px;
      align-items: baseline;
      margin-top: 12px; }
    .product-card.readonly .product-card__img {
      height: 140px; }
    .product-card.readonly .product-card__price .value {
      font-size: 18px; }
    .product-card.readonly .product-card__price .old {
      font-size: 14px;
      padding-bottom: 22px; }
    .product-card.readonly .product-card__footer .counter {
      display: none; }
    .product-card.readonly .form__button {
      display: none; }
    @media (max-width: 768px) {
      .product-card.readonly {
        padding: 0px !important;
        margin-bottom: 20px;
        border: none; }
      .product-card.readonly .product-card__header {
        margin: 0px !important; }
      .product-card.readonly .product-card__body {
        margin: 0px !important; }
      .product-card.readonly .product-card__img {
        height: 118px;
        width: 118px;
        border: 1px solid rgba(208, 215, 226, 0.5);
        margin: 0px !important; }
      .product-card.readonly .product-card__name {
        margin-top: 16px;
        font-size: 14px;
        line-height: 18px;
        height: 38px; }
      .product-card.readonly .product-card__price {
        display: none; }
      .product-card.readonly:not(:first-of-type) {
        margin-left: 16px; }
      .product-card.readonly:last-of-type::after {
        content: '';
        width: 16px;
        height: 1px;
        display: block;
        position: absolute;
        right: -16px; }
      .product-card.readonly .product-card__discount {
        display: block;
        position: absolute;
        top: 0;
        right: -1px; } }

@media (max-width: 768px) {
  .catalog__products {
    margin-top: 30px; } }

@media (max-width: 992px) and (min-width: 768px) {
  .catalog__products {
    margin-top: 35px; } }

@media (max-width: 1455px) and (min-width: 992px) {
  .catalog__products {
    margin-top: 40px; } }

@media (min-width: 1455px) {
  .catalog__products {
    margin-top: 40px; } }

.catalog__products-section {
  width: 100%; }
  @media (max-width: 768px) {
    .catalog__products-section {
      margin-bottom: 15px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__products-section {
      margin-bottom: 20px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__products-section {
      margin-bottom: 25px; } }
  @media (min-width: 1455px) {
    .catalog__products-section {
      margin-bottom: 30px; } }
  .catalog__products-section-title {
    font-weight: 300;
    line-height: 30px;
    color: #000; }
    @media (max-width: 768px) {
      .catalog__products-section-title {
        font-size: 20px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__products-section-title {
        font-size: 24px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__products-section-title {
        font-size: 27px; } }
    @media (min-width: 1455px) {
      .catalog__products-section-title {
        font-size: 30px; } }
    @media (max-width: 768px) {
      .catalog__products-section-title {
        margin-bottom: 15px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__products-section-title {
        margin-bottom: 20px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__products-section-title {
        margin-bottom: 25px; } }
    @media (min-width: 1455px) {
      .catalog__products-section-title {
        margin-bottom: 30px; } }

.catalog__products-sections {
  width: 100%; }
  @media (max-width: 768px) {
    .catalog__products-sections {
      padding-bottom: 15px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog__products-sections {
      padding-bottom: 20px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog__products-sections {
      padding-bottom: 20px; } }
  @media (min-width: 1455px) {
    .catalog__products-sections {
      padding-bottom: 20px; } }

.catalog__section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 10px;
  position: relative; }
  .catalog__section.waiting::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 99; }
  .catalog__section-footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 768px) {
      .catalog__section-footer > div:first-of-type {
        margin-top: 20px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__section-footer > div:first-of-type {
        margin-top: 40px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__section-footer > div:first-of-type {
        margin-top: 40px; } }
    @media (min-width: 1455px) {
      .catalog__section-footer > div:first-of-type {
        margin-top: 40px; } }
    @media (max-width: 768px) {
      .catalog__section-footer > div:last-of-type {
        padding-bottom: 20px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog__section-footer > div:last-of-type {
        padding-bottom: 0px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog__section-footer > div:last-of-type {
        padding-bottom: 0px; } }
    @media (min-width: 1455px) {
      .catalog__section-footer > div:last-of-type {
        padding-bottom: 0px; } }
    .catalog__section-footer-pagination {
      margin-top: 40px;
      position: relative; }
      @media (max-width: 768px) {
        .catalog__section-footer-pagination {
          display: none; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog__section-footer-pagination {
          display: flex; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog__section-footer-pagination {
          display: flex; } }
      @media (min-width: 1455px) {
        .catalog__section-footer-pagination {
          display: flex; } }
      .catalog__section-footer-pagination .left, .catalog__section-footer-pagination .right {
        position: absolute;
        content: '';
        display: block;
        height: 6px;
        width: 12px;
        background-size: 100%;
        background-image: url("/_images/svg/arrow-down-black.svg");
        background-repeat: no-repeat;
        background-position: center;
        top: 12px;
        cursor: pointer; }
      .catalog__section-footer-pagination .left {
        transform: rotate(-90deg);
        left: -38px; }
      .catalog__section-footer-pagination .right {
        transform: rotate(90deg);
        right: -38px; }
      .catalog__section-footer-pagination-page {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        margin-left: 7px;
        margin-right: 7px;
        user-select: none; }
        .catalog__section-footer-pagination-page.active {
          background: #4185F4;
          color: #fff; }
        .catalog__section-footer-pagination-page:hover:not(.empty) {
          background: #F0F5F9;
          color: #000; }
          .catalog__section-footer-pagination-page:hover:not(.empty):not(.active) {
            cursor: pointer; }
  @media (max-width: 767px) {
    .catalog__section[data-inrow-xs='1'] > .product-card {
      margin-left: 0px; } }
  @media (max-width: 767px) and (max-width: 768px) {
    .catalog__section[data-inrow-xs='1'] > .product-card {
      width: 100%; } }

@media (max-width: 767px) and (max-width: 768px) {
  .catalog__section[data-inrow-xs='2'] > .product-card {
    width: 50%; } }
  @media (max-width: 767px) {
    .catalog__section[data-inrow-xs='2'] > .product-card:nth-of-type(n+3) {
      border-top: none; }
    .catalog__section[data-inrow-xs='2'] > .product-card:nth-of-type(2n) {
      border-left: none; } }

@media (max-width: 767px) and (max-width: 768px) {
  .catalog__section[data-inrow-xs='3'] > .product-card {
    width: calc(33.33% - 10.66px); } }
  @media (max-width: 767px) {
    .catalog__section[data-inrow-xs='3'] > .product-card:not(:nth-of-type(3n-2)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-xs='3'] > .product-card:nth-of-type(n + 4) {
      margin-top: 16px; } }
  @media (min-width: 768px) and (max-width: 992px) {
    .catalog__section[data-inrow-sm='1'] > .product-card {
      margin-left: 0px; } }
  @media (min-width: 768px) and (max-width: 992px) and (max-width: 992px) and (min-width: 768px) {
    .catalog__section[data-inrow-sm='1'] > .product-card {
      width: 100%; } }

@media (min-width: 768px) and (max-width: 992px) and (max-width: 992px) and (min-width: 768px) {
  .catalog__section[data-inrow-sm='2'] > .product-card {
    width: calc(50% - 8px); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .catalog__section[data-inrow-sm='2'] > .product-card:not(:nth-of-type(2n-1)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-sm='2'] > .product-card:nth-of-type(n + 3) {
      margin-top: 16px; } }

@media (min-width: 768px) and (max-width: 992px) and (max-width: 992px) and (min-width: 768px) {
  .catalog__section[data-inrow-sm='3'] > .product-card {
    width: calc(33.33% - 10.66px); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .catalog__section[data-inrow-sm='3'] > .product-card:not(:nth-of-type(3n-2)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-sm='3'] > .product-card:nth-of-type(n + 4) {
      margin-top: 16px; } }

@media (min-width: 768px) and (max-width: 992px) and (max-width: 992px) and (min-width: 768px) {
  .catalog__section[data-inrow-sm='4'] > .product-card {
    width: calc(25% - 12px); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .catalog__section[data-inrow-sm='4'] > .product-card:not(:nth-of-type(4n-3)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-sm='4'] > .product-card:nth-of-type(n + 5) {
      margin-top: 16px; } }

@media (min-width: 768px) and (max-width: 992px) and (max-width: 992px) and (min-width: 768px) {
  .catalog__section[data-inrow-sm='5'] > .product-card {
    width: calc(20% - 12.8px); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .catalog__section[data-inrow-sm='5'] > .product-card:not(:nth-of-type(5n-4)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-sm='5'] > .product-card:nth-of-type(n + 6) {
      margin-top: 16px; } }
  @media (min-width: 992px) and (max-width: 1455px) {
    .catalog__section[data-inrow-md='1'] > .product-card {
      margin-left: 0px; } }
  @media (min-width: 992px) and (max-width: 1455px) and (max-width: 1455px) and (min-width: 992px) {
    .catalog__section[data-inrow-md='1'] > .product-card {
      width: 100%; } }

@media (min-width: 992px) and (max-width: 1455px) and (max-width: 1455px) and (min-width: 992px) {
  .catalog__section[data-inrow-md='2'] > .product-card {
    width: calc(50% - 8px); } }
  @media (min-width: 992px) and (max-width: 1455px) {
    .catalog__section[data-inrow-md='2'] > .product-card:not(:nth-of-type(2n-1)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-md='2'] > .product-card:nth-of-type(n + 3) {
      margin-top: 16px; } }

@media (min-width: 992px) and (max-width: 1455px) and (max-width: 1455px) and (min-width: 992px) {
  .catalog__section[data-inrow-md='3'] > .product-card {
    width: calc(33.33% - 10.66px); } }
  @media (min-width: 992px) and (max-width: 1455px) {
    .catalog__section[data-inrow-md='3'] > .product-card:not(:nth-of-type(3n-2)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-md='3'] > .product-card:nth-of-type(n + 4) {
      margin-top: 16px; } }

@media (min-width: 992px) and (max-width: 1455px) and (max-width: 1455px) and (min-width: 992px) {
  .catalog__section[data-inrow-md='4'] > .product-card {
    width: calc(25% - 12px); } }
  @media (min-width: 992px) and (max-width: 1455px) {
    .catalog__section[data-inrow-md='4'] > .product-card:not(:nth-of-type(4n-3)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-md='4'] > .product-card:nth-of-type(n + 5) {
      margin-top: 16px; } }

@media (min-width: 992px) and (max-width: 1455px) and (max-width: 1455px) and (min-width: 992px) {
  .catalog__section[data-inrow-md='5'] > .product-card {
    width: calc(20% - 12.8px); } }
  @media (min-width: 992px) and (max-width: 1455px) {
    .catalog__section[data-inrow-md='5'] > .product-card:not(:nth-of-type(5n-4)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-md='5'] > .product-card:nth-of-type(n + 6) {
      margin-top: 16px; } }

@media (min-width: 992px) and (max-width: 1455px) and (max-width: 1455px) and (min-width: 992px) {
  .catalog__section[data-inrow-md='6'] > .product-card {
    width: calc(16.66% - 13.33px); } }
  @media (min-width: 992px) and (max-width: 1455px) {
    .catalog__section[data-inrow-md='6'] > .product-card:not(:nth-of-type(6n-5)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-md='6'] > .product-card:nth-of-type(n + 7) {
      margin-top: 16px; } }

@media (min-width: 992px) and (max-width: 1455px) and (max-width: 1455px) and (min-width: 992px) {
  .catalog__section[data-inrow-md='7'] > .product-card {
    width: calc(16.66% - 13.33px); } }
  @media (min-width: 992px) and (max-width: 1455px) {
    .catalog__section[data-inrow-md='7'] > .product-card:not(:nth-of-type(7n-6)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-md='7'] > .product-card:nth-of-type(n + 8) {
      margin-top: 16px; } }
  @media (min-width: 1455px) {
    .catalog__section[data-inrow-lg='1'] > .product-card {
      margin-left: 0px; } }
  @media (min-width: 1455px) and (min-width: 1455px) {
    .catalog__section[data-inrow-lg='1'] > .product-card {
      width: 100%; } }

@media (min-width: 1455px) and (min-width: 1455px) {
  .catalog__section[data-inrow-lg='2'] > .product-card {
    width: calc(50% - 8px); } }
  @media (min-width: 1455px) {
    .catalog__section[data-inrow-lg='2'] > .product-card:not(:nth-of-type(2n-1)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-lg='2'] > .product-card:nth-of-type(n + 3) {
      margin-top: 16px; } }

@media (min-width: 1455px) and (min-width: 1455px) {
  .catalog__section[data-inrow-lg='3'] > .product-card {
    width: calc(33.33% - 10.66px); } }
  @media (min-width: 1455px) {
    .catalog__section[data-inrow-lg='3'] > .product-card:not(:nth-of-type(3n-2)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-lg='3'] > .product-card:nth-of-type(n + 4) {
      margin-top: 16px; } }

@media (min-width: 1455px) and (min-width: 1455px) {
  .catalog__section[data-inrow-lg='4'] > .product-card {
    width: calc(25% - 12px); } }
  @media (min-width: 1455px) {
    .catalog__section[data-inrow-lg='4'] > .product-card:not(:nth-of-type(4n-3)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-lg='4'] > .product-card:nth-of-type(n + 5) {
      margin-top: 16px; } }

@media (min-width: 1455px) and (min-width: 1455px) {
  .catalog__section[data-inrow-lg='5'] > .product-card {
    width: calc(20% - 12.8px); } }
  @media (min-width: 1455px) {
    .catalog__section[data-inrow-lg='5'] > .product-card:not(:nth-of-type(5n-4)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-lg='5'] > .product-card:nth-of-type(n + 6) {
      margin-top: 16px; } }

@media (min-width: 1455px) and (min-width: 1455px) {
  .catalog__section[data-inrow-lg='6'] > .product-card {
    width: calc(16.66% - 13.33px); } }
  @media (min-width: 1455px) {
    .catalog__section[data-inrow-lg='6'] > .product-card:not(:nth-of-type(6n-5)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-lg='6'] > .product-card:nth-of-type(n + 7) {
      margin-top: 16px; } }

@media (min-width: 1455px) and (min-width: 1455px) {
  .catalog__section[data-inrow-lg='7'] > .product-card {
    width: calc(14.28% - 12.86px); } }
  @media (min-width: 1455px) {
    .catalog__section[data-inrow-lg='7'] > .product-card:not(:nth-of-type(7n-6)) {
      margin-left: 16px; }
    .catalog__section[data-inrow-lg='7'] > .product-card:nth-of-type(n + 8) {
      margin-top: 16px; } }
  .catalog__section.readonly {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding-left: 16px;
    overflow-x: auto;
    max-width: calc(100% + 32px);
    padding-bottom: 10px;
    position: relative;
    flex-wrap: unset; }

.catalog-filter {
  border: 1px solid rgba(208, 215, 226, 0.5);
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .catalog-filter {
      height: 100%;
      overflow-y: auto;
      border: none;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      display: flex;
      z-index: 1000;
      background: #fff; }
      .catalog-filter__mobile {
        display: flex !important; } }
  .catalog-filter__mobile {
    display: none;
    height: 70px;
    padding: 20px;
    align-items: center;
    justify-content: space-between; }
  .catalog-filter__title {
    font-weight: 300;
    line-height: 32px;
    font-size: 24px;
    color: #000000; }
  .catalog-filter__close {
    width: 16px;
    height: 16px;
    transition: all .2s ease-in;
    background-image: url("/_images/svg/catalog-filter-close.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center; }
  @media (max-width: 768px) {
    .catalog-filter {
      width: 90%;
      position: fixed;
      right: -100%;
      top: 0;
      transition: right .4s ease-in; }
      .catalog-filter .smartfilter {
        display: none; }
      .catalog-filter.active {
        top: 0;
        border-top: 0;
        right: 0; }
        .catalog-filter.active::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          transition: background .9s ease-in;
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          top: 0;
          left: 0;
          z-index: -2; }
        .catalog-filter.active::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: white;
          position: fixed;
          z-index: -1; }
        .catalog-filter.active .smartfilter {
          display: initial; } }
  .catalog-filter__input[type=number]::-webkit-inner-spin-button, .catalog-filter__input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; }
  .catalog-filter__input {
    background: #FFFFFF;
    border: 1px solid #D0D7E2;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    font-size: 16px;
    color: #000;
    padding: 0px 16px; }
    @media (max-width: 768px) {
      .catalog-filter__input[type="number"] {
        width: 48%; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-filter__input[type="number"] {
        width: 48%; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-filter__input[type="number"] {
        width: 45%; } }
    @media (min-width: 1455px) {
      .catalog-filter__input[type="number"] {
        width: 34%; } }
    .catalog-filter__input[type="text"] {
      width: 100%; }
    .catalog-filter__input-group {
      display: flex;
      justify-content: space-between; }
  .catalog-filter__property {
    position: relative; }
    @media (max-width: 768px) {
      .catalog-filter__property.active .catalog-filter__property-title {
        padding-left: 57px;
        position: fixed;
        top: 0;
        right: 0;
        /*width: 284px;*/
        width: 90%;
        height: 50px;
        z-index: 1005; }
        .catalog-filter__property.active .catalog-filter__property-title::before {
          transform: rotate(270deg);
          left: 24px; }
        .catalog-filter__property.active .catalog-filter__property-title ~ .catalog-filter__property-body {
          position: fixed;
          /*width: 284px;*/
          width: 90%;
          background: white;
          z-index: 1008;
          overflow-y: auto;
          top: 50px;
          right: 0;
          height: calc(100% - 50px);
          display: flex;
          flex-direction: column;
          padding-bottom: 0;
          justify-content: flex-start;
          align-items: normal;
          transition: right .2s ease-in; } }
    @media (min-width: 768px) {
      .catalog-filter__property.active .catalog-filter__property-title.list {
        padding-left: 57px; }
        .catalog-filter__property.active .catalog-filter__property-title.list::before {
          transform: scaleY(1);
          left: 24px; }
        .catalog-filter__property.active .catalog-filter__property-title.list ~ .catalog-filter__property-body {
          display: block; }
      .catalog-filter__property.active .catalog-filter__property-title:not(.list) ~ .catalog-filter__property-body {
        display: block; } }
    @media (max-width: 768px) {
      .catalog-filter__property:not(.active) .catalog-filter__property-title {
        height: auto;
        padding-bottom: 25px;
        padding-right: 57px;
        background: white; }
        .catalog-filter__property:not(.active) .catalog-filter__property-title::before {
          transform: rotate(90deg);
          right: 24px; }
        .catalog-filter__property:not(.active) .catalog-filter__property-title ~ .catalog-filter__property-body {
          display: none; } }
    @media (min-width: 768px) {
      .catalog-filter__property:not(.active) .catalog-filter__property-title.list {
        padding-left: 57px; }
        .catalog-filter__property:not(.active) .catalog-filter__property-title.list::before {
          transform: scaleY(-1);
          left: 24px; }
        .catalog-filter__property:not(.active) .catalog-filter__property-title.list ~ .catalog-filter__property-body {
          display: none; }
      .catalog-filter__property:not(.active) .catalog-filter__property-title:not(.list) ~ .catalog-filter__property-body {
        display: block; } }
    .catalog-filter__property-body {
      padding: 10px 20px 20px; }
    @media (min-width: 768px) {
      .catalog-filter__property:not(:first-of-type) {
        border-top: 1px solid rgba(208, 215, 226, 0.5); } }
    .catalog-filter__property-title {
      background: #fff;
      user-select: none;
      cursor: pointer;
      padding: 10px 20px;
      line-height: 18px;
      display: flex;
      align-items: center;
      height: 50px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: rgba(81, 91, 109, 0.6);
      transition: color .2s ease-in, background .2s ease-in;
      position: relative; }
      .catalog-filter__property-title::before {
        position: absolute;
        content: '';
        width: 12px;
        height: 6px;
        top: calc(50% - 3px);
        transition: transform .2s ease-in;
        background-image: url("/_images/svg/arrow-down-black.svg");
        background-size: 100%;
        background-position: center; }
      @media (max-width: 768px) {
        .catalog-filter__property-title {
          color: #515b6d; } }
      @media (min-width: 768px) {
        .catalog-filter__property-title.range {
          line-height: 40px;
          padding: 10px 20px 0px;
          height: 40px; }
          .catalog-filter__property-title.range::before {
            content: unset; }
        .catalog-filter__property-title.list:hover {
          background: rgba(208, 215, 226, 0.2);
          color: #515B6D; } }
    .catalog-filter__property-value {
      max-width: 90%;
      padding: 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      bottom: 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: rgba(81, 91, 109, 0.6);
      display: none; }
      @media (max-width: 768px) {
        .catalog-filter__property-value {
          display: initial; } }
    .catalog-filter__property-list {
      margin-right: -19px;
      padding-top: 7px;
      overflow-y: auto;
      margin-top: -10px;
      padding-bottom: 1px; }
      @media (min-width: 768px) {
        .catalog-filter__property-list {
          max-height: 140px; } }
      .catalog-filter__property-list::-webkit-scrollbar-thumb {
        background: #515B6D;
        border-radius: 7px;
        width: 5px; }
      .catalog-filter__property-list::-webkit-scrollbar {
        background: transparent;
        border: none;
        width: 7px; }
      .catalog-filter__property-list::-webkit-scrollbar-button {
        display: none; }
      .catalog-filter__property-list > div:first-of-type {
        margin-top: 0px !important; }
      .catalog-filter__property-list-item {
        color: #000000;
        margin: 10px 0px 0px 1px;
        padding-right: 20px; }
        .catalog-filter__property-list-item input[type=checkbox] {
          display: none; }
        .catalog-filter__property-list-item > label {
          position: relative;
          padding-left: 30px;
          cursor: pointer;
          font-weight: 300;
          line-height: 19px;
          height: 18px;
          font-size: 14px;
          user-select: none; }
        .catalog-filter__property-list-item > label::before {
          height: 18px;
          width: 18px;
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          background-image: url("/_images/svg/checkbox.svg");
          background-size: 100%;
          background-repeat: no-repeat; }
        .catalog-filter__property-list-item > label:hover::before {
          background-image: url("/_images/svg/checkbox-hover.svg"); }
        .catalog-filter__property-list-item > input[type=checkbox]:checked ~ label::before {
          background-image: url("/_images/svg/checkbox-checked.svg"); }
        .catalog-filter__property-list-item > input[type=checkbox]:disabled ~ label::before {
          background-image: url("/_images/svg/checkbox-disabled.svg") !important; }
        .catalog-filter__property-list-item.disabled > label::before {
          background-image: url("/_images/svg/checkbox-disabled.svg"); }
      .catalog-filter__property-list-search {
        padding-bottom: 25px; }
      .catalog-filter__property-list-noitems {
        position: relative;
        padding-left: 10px;
        cursor: pointer;
        font-weight: 300;
        line-height: 19px;
        height: 18px;
        font-size: 14px;
        user-select: none; }
    .catalog-filter__property-clear {
      line-height: 22px;
      font-size: 12px;
      text-align: right;
      display: inline-block;
      line-height: 30px;
      color: rgba(81, 91, 109, 0.6);
      display: none; }
      @media (min-width: 768px) {
        .catalog-filter__property-clear {
          display: none; } }
    .catalog-filter__property-apply {
      padding: 10px 0px;
      width: 100%;
      margin-top: auto;
      background: white; }
      @media (min-width: 768px) {
        .catalog-filter__property-apply {
          display: none; } }
      .catalog-filter__property-apply > button {
        width: 100% !important;
        padding: 10px 20px;
        margin: 0px !important; }
    @media (max-width: 768px) {
      .catalog-filter__property .bx-filter-container-modef {
        display: none; } }
  .catalog-filter__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-top: 1px solid rgba(208, 215, 226, 0.5); }
    @media (min-width: 768px) {
      .catalog-filter__footer {
        width: 100%;
        position: static; } }
    @media (max-width: 768px) {
      .catalog-filter__footer {
        width: 284px;
        margin-top: auto;
        height: 60px;
        background: white;
        z-index: 1006;
        border: none; } }
    .catalog-filter__footer-reset {
      height: 45px;
      width: 256px;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      text-align: center;
      letter-spacing: 1.71429px;
      text-transform: uppercase;
      color: #515B6D;
      border: 2px solid #ededed;
      box-sizing: border-box;
      border-radius: 5px;
      background: #fff;
      transition: all .2s ease-in; }
      .catalog-filter__footer-reset:hover {
        border-color: #c7c7c7; }
      @media (max-width: 768px) {
        .catalog-filter__footer-reset {
          width: 107px !important;
          margin-right: 20px; } }
    @media (min-width: 768px) {
      .catalog-filter__footer-show {
        display: none; } }
    @media (max-width: 768px) {
      .catalog-filter__footer-show {
        display: block;
        height: 45px;
        width: 107px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1.71429px;
        text-transform: uppercase;
        color: #515b6d;
        border: navajowhite;
        box-sizing: border-box;
        border-radius: 5px;
        background: #fff;
        color: white;
        transition: all .2s ease-in;
        background: #4185F4; } }

.noUi-base {
  width: 100%;
  height: 4px;
  background: rgba(208, 215, 226, 0.5);
  border-radius: 100px;
  position: relative;
  margin-top: 25px;
  margin-bottom: 5px; }

.noUi-connects {
  width: 100%;
  height: 100%; }

.noUi-origin {
  position: absolute;
  width: 100%;
  left: 8px;
  top: -6px; }

.noUi-connect {
  background: #4185F4;
  height: 100%;
  position: absolute; }

.noUi-handle {
  width: 16px;
  height: 16px;
  background: #4185F4;
  border-radius: 100px;
  position: absolute;
  right: 0;
  cursor: pointer; }

.catalog-banner {
  height: auto; }
  @media (max-width: 768px) {
    .catalog-banner {
      width: calc(100% + 32px); } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog-banner {
      width: 100%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog-banner {
      width: 100%; } }
  @media (min-width: 1455px) {
    .catalog-banner {
      width: 100%; } }
  @media (max-width: 768px) {
    .catalog-banner {
      margin-left: -16px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog-banner {
      margin-left: 0px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog-banner {
      margin-left: 0px; } }
  @media (min-width: 1455px) {
    .catalog-banner {
      margin-left: 0px; } }
  .catalog-banner img {
    width: 100%;
    height: auto; }
    @media (min-width: 768px) {
      .catalog-banner img.mobile {
        display: none; }
      .catalog-banner img.desktop {
        display: block; } }
    @media (max-width: 767px) {
      .catalog-banner img.mobile {
        display: block; }
      .catalog-banner img.desktop {
        display: none; } }

.catalog-sorting {
  user-select: none; }
  @media (max-width: 768px) {
    .catalog-sorting {
      padding: 10px 0px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog-sorting {
      padding: 20px 0px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog-sorting {
      padding: 25px 0px; } }
  @media (min-width: 1455px) {
    .catalog-sorting {
      padding: 30px 0px; } }
  @media (min-width: 768px) {
    .catalog-sorting__mobile {
      display: none !important; }
    .catalog-sorting__desktop {
      display: flex !important; } }
  @media (max-width: 768px) {
    .catalog-sorting__desktop {
      display: none !important; }
    .catalog-sorting {
      width: calc(100% + 32px);
      margin: 10px -16px;
      padding: 10px 16px;
      height: 65px;
      background: #F0F5F9; }
    .catalog-sorting__mobile {
      display: flex !important; } }
  .catalog-sorting__filter-anchor {
    width: calc(50% - 8px);
    height: 100%; }
    .catalog-sorting__filter-anchor::after {
      content: attr(data-filter-props);
      width: 22px;
      height: 22px;
      background: #FF6451;
      border-radius: 22px;
      margin-left: 6px;
      color: white;
      line-height: 22px;
      font-size: 12px; }
  .catalog-sorting__select {
    width: calc(50% - 8px);
    margin-right: 16px;
    height: 100%; }
    .catalog-sorting__select .jq-selectbox {
      height: 100%; }
    .catalog-sorting__select .jq-selectbox__select {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 40px;
      border: 1px solid #d0d7e2 !important; }
    .catalog-sorting__select .jq-selectbox__trigger {
      width: 36px;
      border: none; }
    .catalog-sorting__select .jq-selectbox__select-text {
      line-height: unset;
      text-overflow: unset;
      top: 0px;
      font-size: 14px;
      color: #000;
      position: relative; }
      .catalog-sorting__select .jq-selectbox__select-text::after {
        content: '';
        width: 12px;
        height: 30px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: inline-block;
        background: linear-gradient(transparent 0%, rgba(255, 255, 255, 0.7) 90%, #fff 100%); }
    .catalog-sorting__select li {
      border: none !important;
      line-height: 30px;
      font-size: 14px; }
    .catalog-sorting__select .jq-selectbox__trigger-arrow {
      width: 10px;
      height: 16px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(/_images/svg/sorting-select-arrow.svg);
      background-position: center; }
    .catalog-sorting__select .jq-selectbox__dropdown {
      border: none;
      bottom: auto;
      top: 45px;
      box-shadow: 0 11px 23px rgba(0, 0, 0, 0.14) !important; }
    .catalog-sorting__select .jq-selectbox.opened .jq-selectbox__select {
      border: 1px solid #E8EBF0; }
  .catalog-sorting__desktop, .catalog-sorting__mobile {
    width: 100%;
    height: 100%; }
  .catalog-sorting__title {
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    color: #000000; }
  .catalog-sorting__type {
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    margin-left: 25px;
    position: relative;
    color: #336DF1; }
    .catalog-sorting__type:not(.active) {
      cursor: pointer; }
      .catalog-sorting__type:not(.active):hover {
        color: #0a246a; }
    .catalog-sorting__type.active {
      color: #000;
      padding-right: 22px; }
      .catalog-sorting__type.active::after {
        padding: 10px 14px 14px 0px;
        position: absolute;
        content: '';
        top: 6.5px;
        margin-left: 8px;
        right: 0;
        z-index: 100;
        background-image: url("/_images/svg/catalog-sort-icon.svg");
        background-size: 95%;
        background-repeat: no-repeat; }

.catalog-result {
  margin-top: 25px; }
  .catalog-result__title {
    text-align: left; }
  .catalog-result__item {
    margin-bottom: 30px; }
    .catalog-result__item-title {
      margin-bottom: 10px; }
    .catalog-result__item-text {
      line-height: 24px; }
      .catalog-result__item-text--link {
        text-decoration: underline; }
      .catalog-result__item-text--disable {
        color: #aaa; }

.breadcrumbs {
  height: 60px;
  padding: 15px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .breadcrumbs-wrapper {
    width: 100%;
    box-shadow: 0px 3px 6px rgba(208, 215, 226, 0.12); }
    @media (max-width: 768px) {
      .breadcrumbs-wrapper {
        margin-top: 0px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .breadcrumbs-wrapper {
        margin-top: 60px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .breadcrumbs-wrapper {
        margin-top: 130px; } }
    @media (min-width: 1455px) {
      .breadcrumbs-wrapper {
        margin-top: 130px; } }
    @media (max-width: 768px) {
      .breadcrumbs-wrapper {
        margin-bottom: 0px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .breadcrumbs-wrapper {
        margin-bottom: -110px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .breadcrumbs-wrapper {
        margin-bottom: -160px; } }
    @media (min-width: 1455px) {
      .breadcrumbs-wrapper {
        margin-bottom: -130px; } }
    @media (max-width: 768px) {
      .breadcrumbs-wrapper {
        display: none; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .breadcrumbs-wrapper {
        display: block; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .breadcrumbs-wrapper {
        display: block; } }
    @media (min-width: 1455px) {
      .breadcrumbs-wrapper {
        display: block; } }
  .breadcrumbs__back {
    height: 100%;
    width: 100px;
    position: relative;
    margin-right: 40px;
    background-image: url("/_images/svg/breadcrumbs-back.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer; }
    .breadcrumbs__back::after {
      content: '';
      display: block;
      height: 30px;
      width: 1px;
      background: #515B6D;
      position: absolute;
      right: -20px;
      cursor: default; }
  .breadcrumbs__path-part {
    height: 100%;
    font-weight: 300;
    line-height: 22px;
    font-size: 12px;
    color: #515B6D;
    mix-blend-mode: normal;
    opacity: 0.5;
    margin-right: 46px;
    cursor: pointer;
    position: relative;
    align-items: center;
    display: flex; }
    .breadcrumbs__path-part::after {
      content: '';
      display: block;
      height: 30px;
      width: 5px;
      background-image: url("/_images/svg/breadcrumbs-arrow-right.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: -26px;
      opacity: 1 !important;
      top: -4%;
      cursor: default; }
    .breadcrumbs__path-part:last-of-type {
      opacity: 1;
      cursor: default; }
      .breadcrumbs__path-part:last-of-type::after {
        content: unset; }

.catalog-detail {
  display: flex;
  justify-content: space-between;
  margin-top: -18px; }
  @media (max-width: 768px) {
    .catalog-detail {
      flex-direction: column; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog-detail {
      flex-direction: row; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog-detail {
      flex-direction: row; } }
  @media (min-width: 1455px) {
    .catalog-detail {
      flex-direction: row; } }
  @media (max-width: 768px) {
    .catalog-detail__mobile {
      display: block; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog-detail__mobile {
      display: none; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog-detail__mobile {
      display: none; } }
  @media (min-width: 1455px) {
    .catalog-detail__mobile {
      display: none; } }
  @media (max-width: 768px) {
    .catalog-detail__desktop {
      display: none; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog-detail__desktop {
      display: block; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog-detail__desktop {
      display: block; } }
  @media (min-width: 1455px) {
    .catalog-detail__desktop {
      display: block; } }
  .catalog-detail__name {
    font-weight: 300;
    color: #000; }
    @media (max-width: 768px) {
      .catalog-detail__name {
        font-size: 22px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__name {
        font-size: 26px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__name {
        font-size: 34px; } }
    @media (min-width: 1455px) {
      .catalog-detail__name {
        font-size: 40px; } }
    @media (max-width: 768px) {
      .catalog-detail__name {
        line-height: 32px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__name {
        line-height: 38px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__name {
        line-height: 44px; } }
    @media (min-width: 1455px) {
      .catalog-detail__name {
        line-height: 51px; } }
    @media (max-width: 768px) {
      .catalog-detail__name {
        margin: 0px 0px 12px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__name {
        margin: 0px 0px 14px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__name {
        margin: 0px 0px 18px; } }
    @media (min-width: 1455px) {
      .catalog-detail__name {
        margin: 0px 0px 20px; } }
  .catalog-detail__code {
    font-size: 14px;
    color: #515B6D;
    font-weight: 300; }
    @media (max-width: 768px) {
      .catalog-detail__code {
        height: 30px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__code {
        height: 35px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__code {
        height: 40px; } }
    @media (min-width: 1455px) {
      .catalog-detail__code {
        height: 50px; } }
    @media (max-width: 768px) {
      .catalog-detail__code {
        line-height: 30px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__code {
        line-height: 35px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__code {
        line-height: 40px; } }
    @media (min-width: 1455px) {
      .catalog-detail__code {
        line-height: 50px; } }
    .catalog-detail__code::before {
      content: 'Код: '; }
  .catalog-detail__photos {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .catalog-detail__photos {
        width: calc(50% - 8px); } }
    @media (max-width: 768px) {
      .catalog-detail__photos {
        width: 100%; } }
  .catalog-detail__main-photo {
    width: 100%;
    height: auto;
    max-height: 495px;
    padding: 5% 10%;
    border: 1px solid rgba(208, 215, 226, 0.5);
    position: relative; }
    .catalog-detail__main-photo-adds {
      position: absolute;
      top: 0;
      right: -1px;
      display: flex;
      justify-content: flex-end; }
    .catalog-detail__main-photo img {
      width: 100%;
      padding: 0px 10%;
      height: auto; }
  .catalog-detail__discount {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    color: #fff;
    margin-left: 11px;
    background-image: url("/_images/svg/item-discount.svg");
    background-size: 100%;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .catalog-detail__discount {
        width: 50px;
        height: 24px;
        line-height: 24px; } }
    @media (min-width: 768px) {
      .catalog-detail__discount {
        width: 75px;
        height: 36px;
        line-height: 36px; } }
    .catalog-detail__discount::after {
      content: '%'; }
    .catalog-detail__discount::before {
      content: '- '; }
  .catalog-detail__action {
    background: #4185F4;
    height: 36px;
    width: 36px;
    padding: 2px;
    background-image: url("/_images/svg/product-action.svg");
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .catalog-detail__action {
        display: none; } }
  .catalog-detail__gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: inherit; }
    @media (max-width: 768px) {
      .catalog-detail__gallery {
        margin-top: 8px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__gallery {
        margin-top: 10px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__gallery {
        margin-top: 13px; } }
    @media (min-width: 1455px) {
      .catalog-detail__gallery {
        margin-top: 16px; } }
    .catalog-detail__gallery-img {
      border: 1px solid rgba(208, 215, 226, 0.5);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer; }
      @media (max-width: 768px) {
        .catalog-detail__gallery-img {
          width: calc(25% - 6px); } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog-detail__gallery-img {
          width: calc(25% - 7.5px); } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog-detail__gallery-img {
          width: calc(20% - 10px); } }
      @media (min-width: 1455px) {
        .catalog-detail__gallery-img {
          width: calc(16.666% - 6.67px); } }
      @media (max-width: 768px) {
        .catalog-detail__gallery-img:not(:first-of-type) {
          margin-left: 8px; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog-detail__gallery-img:not(:first-of-type) {
          margin-left: 10px; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog-detail__gallery-img:not(:first-of-type) {
          margin-left: 13px; } }
      @media (min-width: 1455px) {
        .catalog-detail__gallery-img:not(:first-of-type) {
          margin-left: 16px; } }
      .catalog-detail__gallery-img.active {
        border: 1px solid #4185F4; }
      .catalog-detail__gallery-img img {
        width: 100%;
        height: auto;
        margin: auto; }
  .catalog-detail__properties {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .catalog-detail__properties {
        width: calc(50% - 8px); } }
    @media (max-width: 768px) {
      .catalog-detail__properties {
        width: 100%; } }
    .catalog-detail__properties-line {
      display: flex;
      width: 100%;
      justify-content: space-between; }
      @media (max-width: 768px) {
        .catalog-detail__properties-line {
          flex-direction: column; } }
    .catalog-detail__properties-top {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(208, 215, 226, 0.5); }
      @media (max-width: 768px) {
        .catalog-detail__properties-top {
          margin-top: 20px; } }
  .catalog-detail__vendor {
    font-size: 14px;
    font-weight: 300;
    color: #0B66C3; }
    @media (max-width: 768px) {
      .catalog-detail__vendor {
        margin-bottom: 12px; } }
    .catalog-detail__vendor::before {
      font-weight: 300;
      font-size: 14px;
      color: #515B6D;
      content: 'Производитель: '; }
  .catalog-detail__status {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    height: 16px;
    position: relative;
    text-transform: lowercase; }
    @media (max-width: 768px) {
      .catalog-detail__status {
        font-size: 14px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__status {
        font-size: 12px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__status {
        font-size: 13px; } }
    @media (min-width: 1455px) {
      .catalog-detail__status {
        font-size: 13px; } }
    @media (max-width: 768px) {
      .catalog-detail__status {
        padding-left: 15px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__status {
        padding-left: 15px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__status {
        padding-left: 15px; } }
    @media (min-width: 1455px) {
      .catalog-detail__status {
        padding-left: 20px; } }
    @media (max-width: 768px) {
      .catalog-detail__status {
        padding-bottom: 16px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__status {
        padding-bottom: 16px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__status {
        padding-bottom: 16px; } }
    @media (min-width: 1455px) {
      .catalog-detail__status {
        padding-bottom: 0; } }
    .catalog-detail__status::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 7px; }
      @media (max-width: 768px) {
        .catalog-detail__status::before {
          left: 2px; } }
      @media (max-width: 992px) and (min-width: 768px) {
        .catalog-detail__status::before {
          left: 2px; } }
      @media (max-width: 1455px) and (min-width: 992px) {
        .catalog-detail__status::before {
          left: 3px; } }
      @media (min-width: 1455px) {
        .catalog-detail__status::before {
          left: 6px; } }
    .catalog-detail__status.in-stock {
      color: #2ED672; }
      .catalog-detail__status.in-stock::before {
        background-image: url("/_images/svg/item-status-in_stock.svg"); }
    .catalog-detail__status.few {
      color: #FF6451; }
      .catalog-detail__status.few::before {
        background-image: url("/_images/svg/item-status-few.svg"); }
    .catalog-detail__status.custom {
      color: #515B6D; }
      .catalog-detail__status.custom::before {
        background-image: url("/_images/svg/item-custom.svg"); }
  .catalog-detail__price {
    position: relative;
    color: #515B6D; }
    @media (min-width: 1455px) {
      .catalog-detail__price {
        margin: 25px 0px; } }
    @media (max-width: 768px) {
      .catalog-detail__price {
        transform: translateY(-20%); } }
    @media (max-width: 768px) {
      .catalog-detail__price {
        font-size: 24px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__price {
        font-size: 24px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__price {
        font-size: 24px; } }
    @media (min-width: 1455px) {
      .catalog-detail__price {
        font-size: 26px; } }
    .catalog-detail__price::before {
      display: block;
      content: 'Цена:';
      font-size: 14px;
      color: #515B6D;
      opacity: .7;
      padding-bottom: 12px; }
    .catalog-detail__price::after {
      content: ' руб.'; }
  .catalog-detail__form {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1455px) {
      .catalog-detail__form {
        margin-top: 25px; } }
    .catalog-detail__form-buttons {
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 1455px) {
        .catalog-detail__form-buttons {
          justify-content: space-between;
          width: 100%;
          padding-top: 16px; }
          .catalog-detail__form-buttons > * {
            margin: 0px !important; } }
      @media (min-width: 1455px) {
        .catalog-detail__form-buttons {
          align-items: flex-end; }
          .catalog-detail__form-buttons > input:nth-of-type(1) {
            margin-left: 20px; }
          .catalog-detail__form-buttons > input:nth-of-type(2) {
            margin-left: 15px; } }
    .catalog-detail__form-pricewrapper {
      display: flex; }
      @media (min-width: 1455px) {
        .catalog-detail__form-pricewrapper {
          flex-direction: column; } }
      @media (max-width: 1455px) {
        .catalog-detail__form-pricewrapper {
          flex-direction: row;
          width: 100%;
          align-items: flex-end;
          justify-content: space-between; } }
  @media (max-width: 992px) {
    .catalog-detail__buy {
      background-position: 31px 15px !important;
      font-size: 0;
      padding: 0px 40px;
      width: 0; } }
  @media (max-width: 768px) {
    .catalog-detail__buy-for-click {
      width: 187px; } }
  .catalog-detail__more {
    font-weight: 300;
    line-height: 21px;
    font-size: 14px;
    color: #515B6D; }
    @media (max-width: 768px) {
      .catalog-detail__more {
        margin-top: 20px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .catalog-detail__more {
        margin-top: 30px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .catalog-detail__more {
        margin-top: 30px; } }
    @media (min-width: 1455px) {
      .catalog-detail__more {
        margin-top: 30px; } }
  @media (max-width: 768px) {
    .catalog-detail__characteristics {
      width: 100%; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .catalog-detail__characteristics {
      width: 100%; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .catalog-detail__characteristics {
      width: 100%; } }
  @media (min-width: 1455px) {
    .catalog-detail__characteristics {
      width: 50%; } }
  .catalog-detail__characteristics-item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EBEAF1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px; }
    .catalog-detail__characteristics-item .-cd_c-value {
      font-weight: normal; }
    .catalog-detail__characteristics-item:last-of-type {
      border-bottom: none; }
  @media (min-width: 768px) {
    .catalog-detail__footer {
      margin-top: 50px; } }
  @media (max-width: 768px) {
    .catalog-detail__footer {
      margin-top: 15px; } }

.tabs__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px; }
  @media (max-width: 768px) {
    .tabs__header {
      height: 46px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .tabs__header {
      height: 48px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .tabs__header {
      height: 50px; } }
  @media (min-width: 1455px) {
    .tabs__header {
      height: 54px; } }
  @media (max-width: 768px) {
    .tabs__header {
      margin-bottom: -4px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .tabs__header {
      margin-bottom: -2px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .tabs__header {
      margin-bottom: -2px; } }
  @media (min-width: 1455px) {
    .tabs__header {
      margin-bottom: 0px; } }
  @media (max-width: 776px) {
    .tabs__header {
      padding: 0px 10px;
      overflow: auto; }
      .tabs__header::-webkit-scrollbar {
        width: 0;
        height: 0; } }

.tabs__header-wrapper {
  border-bottom: 1px solid #EBEAF1; }
  @media (max-width: 776px) {
    .tabs__header-wrapper {
      margin-left: -15px;
      margin-right: -15px; } }

.tabs__tab {
  display: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto; }
  @media (max-width: 767px) {
    .tabs__tab {
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px); } }
  .tabs__tab.visible {
    display: block; }
  .tabs__tab > .active {
    display: block; }
    .tabs__tab > .active.fadeToRight {
      animation: fadeInToRight .3s ease-in; }
    .tabs__tab > .active.fadeToLeft {
      animation: fadeInToRight .3s ease-in; }
  .tabs__tab > div:not(.active) {
    display: none; }
    .tabs__tab > div:not(.active).fadeToRight {
      animation: fadeOutToRight .3s ease-in; }
    .tabs__tab > div:not(.active).fadeToLeft {
      animation: fadeOutToRight .3s ease-in; }

.tabs__tab-title {
  user-select: none;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
  opacity: .7;
  position: relative;
  color: #515B6D; }
  @media (max-width: 776px) {
    .tabs__tab-title:not(:first-of-type) {
      margin-left: 18px; }
    .tabs__tab-title:not(:last-of-type) {
      margin-right: 18px; } }
  @media (max-width: 768px) {
    .tabs__tab-title {
      font-size: 14px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .tabs__tab-title {
      font-size: 16px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .tabs__tab-title {
      font-size: 17px; } }
  @media (min-width: 1455px) {
    .tabs__tab-title {
      font-size: 20px; } }
  .tabs__tab-title::after {
    width: 0%;
    content: '';
    transition: all .3s ease-in;
    height: 3px;
    background-color: #515B6D;
    transform: none;
    position: absolute;
    right: 0; }
    @media (max-width: 768px) {
      .tabs__tab-title::after {
        bottom: -10px; } }
    @media (max-width: 992px) and (min-width: 768px) {
      .tabs__tab-title::after {
        bottom: -14px; } }
    @media (max-width: 1455px) and (min-width: 992px) {
      .tabs__tab-title::after {
        bottom: -14px; } }
    @media (min-width: 1455px) {
      .tabs__tab-title::after {
        bottom: -18px; } }
  .tabs__tab-title.active {
    opacity: 1;
    transform: none !important; }
    .tabs__tab-title.active::after {
      left: 0;
      width: 100%; }

@media (min-width: 767px) {
  .tabs__content {
    margin: 60px 0px; } }

@media (max-width: 767px) {
  .tabs__content {
    margin: 40px 0px; } }

.tabs.style-info .tabs__header {
  padding: 0px !important;
  justify-content: flex-start !important; }

.tabs.style-info .tabs__tab-title {
  letter-spacing: 3px; }
  @media (max-width: 768px) {
    .tabs.style-info .tabs__tab-title {
      font-size: 12px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .tabs.style-info .tabs__tab-title {
      font-size: 13px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .tabs.style-info .tabs__tab-title {
      font-size: 14px; } }
  @media (min-width: 1455px) {
    .tabs.style-info .tabs__tab-title {
      font-size: 14px; } }
  .tabs.style-info .tabs__tab-title:not(:first-of-type) {
    margin-left: 30px; }
  .tabs.style-info .tabs__tab-title::after {
    bottom: -10px !important; }

.tabs.style-info .tabs__header {
  height: 40px !important;
  margin-left: -5px;
  margin-right: -5px; }
  @media (max-width: 768px) {
    .tabs.style-info .tabs__header {
      margin-bottom: -1px; } }

.tabs.style-info .tabs__header-wrapper {
  margin: 0px !important; }

.tabs.style-info .tabs__content {
  border-bottom: 1px solid rgba(208, 215, 226, 0.5);
  margin: 0 !important; }

.tabs.style-info .tabs__tab {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  overflow-y: auto; }
  .tabs.style-info .tabs__tab:not(.--no-margin-top) {
    margin-top: 20px !important; }
  .tabs.style-info .tabs__tab:not(.--no-margin-bottom) {
    margin-bottom: 20px !important; }
  .tabs.style-info .tabs__tab > div {
    display: block;
    animation: none !important; }
  @media (max-width: 768px) {
    .tabs.style-info .tabs__tab:not(.--unlimited-height) {
      max-height: 170px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .tabs.style-info .tabs__tab:not(.--unlimited-height) {
      max-height: 180px; } }
  @media (max-width: 1455px) and (min-width: 992px) {
    .tabs.style-info .tabs__tab:not(.--unlimited-height) {
      max-height: 195px; } }
  @media (min-width: 1455px) {
    .tabs.style-info .tabs__tab:not(.--unlimited-height) {
      max-height: 210px; } }
  .tabs.style-info .tabs__tab::-webkit-scrollbar-thumb {
    background: #515B6D;
    border-radius: 7px;
    width: 5px; }
  .tabs.style-info .tabs__tab::-webkit-scrollbar {
    background: transparent;
    border: none;
    width: 7px; }
  .tabs.style-info .tabs__tab::-webkit-scrollbar-button {
    display: none; }

.number_of_goods {
  display: flex; }
  .number_of_goods button {
    background-color: rgba(65, 133, 244, 0.2);
    width: 38px;
    height: 44px;
    border: none;
    position: relative; }
    .number_of_goods button:after {
      width: 12px; }
    .number_of_goods button:before {
      width: 12px; }
    .number_of_goods button:hover {
      cursor: pointer;
      background-color: rgba(65, 133, 244, 0.3); }
  .number_of_goods__minus {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
    .number_of_goods__minus:after {
      content: '';
      position: absolute;
      height: 2px;
      background: #515b6d;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .number_of_goods__plus {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
    .number_of_goods__plus:after {
      content: '';
      position: absolute;
      height: 2px;
      background: #515b6d;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .number_of_goods__plus:before {
      content: '';
      position: absolute;
      height: 12px;
      width: 2px !important;
      background: #515b6d;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .number_of_goods__count {
    text-align: center;
    border: 1px solid rgba(81, 91, 109, 0.2);
    width: 40px;
    height: 44px;
    font-size: 14px;
    color: #515B6D;
    background: #ffffff; }

.checkout_box {
  margin-top: 30px;
  background-color: #F0F5F9; }
  .checkout_box .caption {
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 22px; }
    .checkout_box .caption-link {
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      letter-spacing: 1.71429px;
      text-transform: uppercase;
      color: #4185F4; }
  .checkout_box__goods {
    display: flex;
    margin-top: 25px; }
  .checkout_box__img {
    max-width: 74px;
    height: 74px;
    width: 20%; }
    .checkout_box__img img {
      width: 100%;
      height: 100%; }
  .checkout_box__info_good {
    width: 80%;
    margin-left: 16px;
    line-height: 20px; }
  .checkout_box__wrap {
    padding: 30px; }
    .checkout_box__wrap--first {
      padding-bottom: 12px; }
    .checkout_box__wrap--two {
      padding-bottom: 20px; }
    .checkout_box__wrap--three {
      padding-top: 19px;
      padding-bottom: 40px; }
    .checkout_box__wrap--four {
      margin: 50px 30px 0;
      padding: 0;
      position: relative; }
      .checkout_box__wrap--four:after {
        content: '';
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        border: 1px solid rgba(208, 215, 226, 0.5); }
  .checkout_box b {
    font-weight: 400; }
    .checkout_box b.red {
      color: red; }
  .checkout_box hr {
    border: 1px solid rgba(208, 215, 226, 0.5);
    margin: 0; }
  .checkout_box__caption {
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    color: #000000;
    font-size: 16px; }
  .checkout_box__txt14 {
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    color: #000000;
    margin: 4px 0 20px 0; }
    .checkout_box__txt14:last-child {
      margin-bottom: 0; }
  .checkout_box__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; }
    .checkout_box__total:last-child {
      margin-bottom: 0; }
  .checkout_box__collect {
    background-color: rgba(65, 133, 244, 0.1);
    padding: 14px 30px;
    display: flex;
    justify-content: space-between; }
  .checkout_box__checkbox_button .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0; }
  .checkout_box__checkbox_button .checkbox + label {
    position: relative;
    padding: 0 0 0 32px;
    cursor: pointer; }
  .checkout_box__checkbox_button .checkbox + label:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    border-radius: 3px;
    height: 18px;
    background: #ffffff;
    border: 1px solid #4185f4;
    transition: .2s; }
  .checkout_box__checkbox_button .checkbox + label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    transition: .2s;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat; }
  .checkout_box__checkbox_button .checkbox:checked + label:after {
    background-image: url("/_images/svg/check.svg"); }
  .checkout_box__checkbox_button .checkbox:checked + label:before {
    background: #4185f4; }
  .checkout_box__summ {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
  .checkout_box__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px; }
  .checkout_box__button p {
    margin-top: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    color: #515B6D; }
  .checkout_box .hint_price_info {
    width: 20px;
    height: 20px;
    background-image: url("/_images/svg/exclamation.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative; }
    .checkout_box .hint_price_info:hover {
      cursor: pointer; }
  .checkout_box__tooltip {
    position: absolute;
    background-color: #ffffff;
    width: 274px;
    border-radius: 3px;
    padding: 14px 5px 12px 20px;
    left: -290px;
    top: -25px;
    z-index: 1000;
    font-weight: 300;
    line-height: 16px;
    font-size: 12px;
    color: #515B6D;
    filter: drop-shadow(0px 0px 7px rgba(65, 133, 244, 0.3));
    display: none; }
    .checkout_box__tooltip.active {
      display: block; }
    .checkout_box__tooltip:after {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      background-color: #ffffff;
      top: 50%;
      right: -5px;
      transform: translateY(-50%) rotate(45deg); }

@media (max-width: 767px) {
  .checkout_box__wrap--four {
    margin: 33px 16px 0; } }

@media (max-width: 570px) {
  .checkout_box .button-blue {
    padding: 0 15px; }
  .checkout_box__collect {
    padding: 14px 16px; }
  .checkout_box__wrap {
    padding: 16px; } }

.checkout {
  margin-bottom: 60px; }
  .checkout .ymaps-2-1-72-balloon {
    transform: translate(-30%, -6%); }
    .checkout .ymaps-2-1-72-balloon__tail {
      left: 45% !important; }
    .checkout .ymaps-2-1-72-balloon:after {
      position: absolute;
      content: url("/_images/svg/blue_map.svg");
      left: 45%;
      bottom: -20%; }
  .checkout__caption_info {
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    color: #515B6D;
    margin-bottom: 4px; }
  .checkout__information {
    margin-top: 35px; }
  .checkout__wrapper {
    display: flex;
    margin: -10px;
    flex-wrap: wrap; }
  .checkout__process {
    width: 262px;
    height: 120px;
    border-radius: 5px;
    border: 2px solid #4185F4;
    padding: 22px 30px 25px;
    transition: all .2s linear;
    background: #ffffff;
    color: #4185F4;
    margin: 10px; }
    .checkout__process--pay {
      width: 224px;
      height: 100px; }
    .checkout__process .caption {
      font-style: normal;
      font-weight: normal;
      line-height: 17px;
      font-size: 12px;
      letter-spacing: 1.71429px;
      text-transform: uppercase;
      color: #4185F4;
      padding-bottom: 10px;
      display: inline-block;
      text-align: left;
      width: 100%; }
    .checkout__process .description {
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      font-size: 14px;
      color: rgba(65, 133, 244, 0.8);
      text-align: left;
      display: inline-block;
      width: 100%; }
    .checkout__process.active {
      background: #4185F4;
      box-shadow: 0px 11px 23px rgba(65, 133, 244, 0.434754); }
      .checkout__process.active .caption,
      .checkout__process.active .description {
        color: #ffffff; }
    .checkout__process:hover {
      background: #4185F4;
      box-shadow: 0px 11px 23px rgba(65, 133, 244, 0.434754); }
      .checkout__process:hover .caption,
      .checkout__process:hover .description {
        color: #ffffff; }
  .checkout__poin {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px; }
    .checkout__poin h4 {
      margin: 0; }
  .checkout__switch {
    display: flex;
    justify-content: center; }
    .checkout__switch button {
      width: 136px;
      text-align: center;
      padding: 6px 0 7px;
      border: 2px solid #4185F4;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 16px;
      background: #ffffff;
      color: #4185F4;
      transition: all .2s linear; }
      .checkout__switch button.active {
        background: #4185F4;
        color: #FFFFFF; }
      .checkout__switch button:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px; }
      .checkout__switch button:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px; }
      .checkout__switch button:hover {
        background: #4185F4;
        color: #ffffff; }
  .checkout__section {
    margin-top: 40px; }
    .checkout__section h4 {
      margin-bottom: 10px; }
  .checkout__form {
    margin-top: 26px;
    max-width: 584px; }
    .checkout__form .form__input {
      margin-bottom: 20px;
      position: relative; }
  .checkout__map {
    height: 410px; }
    .checkout__map-finish {
      height: 279px; }
  .checkout__service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid rgba(208, 215, 226, 0.5); }
    .checkout__service-finish {
      border-bottom: none; }
    .checkout__service:nth-child(n+2) {
      border-top: none; }
  .checkout__wrap {
    max-height: 461px; }
    .checkout__wrap .mCS-dark_custom .mCSB_container {
      margin-right: 5px !important; }
    .checkout__wrap .mCS-dark_custom.mCSB_scrollTools_onDrag .mCSB_dragger_bar {
      background-color: #515B6D !important; }
    .checkout__wrap .mCS-dark_custom .mCSB_dragger_bar {
      background-color: #515B6D !important;
      margin: 20px 6px !important; }
    .checkout__wrap .mCS-dark_custom .mCSB_dragger:hover .mCSB_dragger_bar {
      background-color: #515B6D !important; }
    .checkout__wrap .mCS-dark_custom .mCSB_dragger:active .mCSB_dragger_bar {
      background-color: #515B6D !important; }
    .checkout__wrap .mCS-dark_custom .mCSB_draggerRail {
      background-color: transparent !important; }
  .checkout__warning .caption {
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #FF6451;
    position: relative;
    padding-left: 48px;
    margin-bottom: 30px; }
    .checkout__warning .caption:after {
      position: absolute;
      content: '';
      width: 29.21px;
      height: 29.21px;
      background-image: url("/_images/svg/exclamation.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .checkout__warning ul {
    margin-bottom: 38px; }
  .checkout__warning li {
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    color: #515B6D;
    position: relative;
    padding-left: 46px; }
    .checkout__warning li:nth-child(n+2) {
      margin-top: 16px; }
    .checkout__warning li:after {
      position: absolute;
      content: '';
      top: 11px;
      left: 0;
      width: 26px;
      border-top: 2px solid #FF6451; }
  .checkout__warning a {
    font-weight: normal;
    color: #4185f4; }
  .checkout hr {
    border-top: 1px solid rgba(208, 215, 226, 0.5); }
  .checkout__payment {
    margin-top: 20px; }
    .checkout__payment h4 {
      margin-bottom: 30px; }

@media (max-width: 1455px) {
  .checkout__warning br {
    display: none; } }

@media (max-width: 767px) {
  .checkout__caption_info {
    font-size: 12px; }
  .checkout__poin {
    display: block; }
  .checkout__switch {
    margin-top: 16px; }
  .checkout__map {
    height: 199px; }
  .checkout__section {
    margin-top: 24px; }
  .checkout__form {
    margin-top: 20px; }
  .checkout__window {
    margin-top: 10px; }
  .checkout__service {
    flex-wrap: wrap;
    border-left: none;
    border-right: none; }
  .checkout__wrap {
    max-height: 100%; } }

.service_info {
  background: #ffffff;
  display: flex;
  align-items: center; }
  .service_info--map {
    padding: 10px;
    display: block; }
  .service_info p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-size: 14px;
    color: #4185F4; }
  .service_info__button {
    padding: 0 15px;
    width: 200px;
    border-width: 2px; }
    .service_info__button--map {
      margin-top: 11.74px; }
  .service_info__caption {
    font-style: normal;
    font-weight: normal;
    line-height: 26px;
    font-size: 18px;
    color: #40485A;
    margin: 10px 0 11.26px; }
  .service_info__tel {
    font-weight: 300;
    line-height: 25px;
    font-size: 14px;
    color: #40485A; }
  .service_info__wrap {
    margin-left: 20px; }

@media (max-width: 767px) {
  .service_info img {
    display: none; }
  .service_info__wrap {
    margin: 0; }
  .service_info__button {
    margin-top: 10px;
    border-width: 2px; } }
