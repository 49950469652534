.paragraph {
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-size: 16px;
  color: #515B6D;
}
.b-text {
  font-weight: bold;
}
.txt-16 {
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
  font-size: 16px;
  color: #000000;
  @media (max-width: 570px) {
    line-height: 20px;
    font-size: 14px;
  }
}
.txt-18 {
  font-size: 18px;
  color: #515B6D;
}
.txt-14 {
  font-size: 14px;
}
.txt-24 {
  font-style: normal;
  font-weight: normal;
  line-height: 34px;
  font-size: 24px;
}
.crossed_out {
  position: relative;
  color: rgba(81, 91, 109, 0.5);
  @media (max-width: 570px) {
    font-size: 12px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-top: 1px solid rgba(81, 91, 109, 0.5);
  }
}
.placemark {
  width: 40px;
  height: 60px;
  background: url('/_images/svg/red_map.svg') no-repeat;
  background-size: contain;
  background-position: center;
  &.active {
    width: 27px;
    height: 27px;
    background: url('/_images/svg/blue_map.svg') no-repeat;
  }
}
@keyframes show-big-placemark {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes show-small-placemark {
  0% {
    transform: scale(1);
    background-image: url('/_images/svg/blue_map.svg');
    width: 27px;
    height: 27px;
  }
  100% {
    transform: scale(0);
    background-image: url('/_images/svg/blue_map.svg');
  }
}