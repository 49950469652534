.catalog-sorting {
  @include ap(padding, 10px 0px, 20px 0px, 25px 0px, 30px 0px);
  @media (min-width: $sm) {
    &__mobile {
      display: none !important;
    }
    &__desktop {
      display: flex !important;
    }
  }
  @media (max-width: $sm) {
    &__desktop {
      display: none !important;
    }
    & {
      width: calc(100% + 32px);
      margin: 10px -16px;
      padding: 10px 16px;
      height: 65px;
      background: #F0F5F9;
    }
    &__mobile {
      display: flex !important;
    }
  }
  &__filter-anchor {
    width: calc(50% - 8px);
    height: 100%;
    &::after {
      content: attr(data-filter-props);
      width: 22px;
      height: 22px;
      background: #FF6451;
      border-radius: 22px;
      margin-left: 6px;
      color: white;
      line-height: 22px;
      font-size: 12px;
    }
  }
  &__select {
    width: calc(50% - 8px);
    margin-right: 16px;
    height: 100%;
    .jq-selectbox{
      height: 100%;
    }
    .jq-selectbox__select {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 40px;
      border: 1px solid #d0d7e2 !important;
    }
    .jq-selectbox__trigger {
      width: 36px;
      border: none;
    }
    .jq-selectbox__select-text {
      line-height: unset;
      text-overflow: unset;
      top: 0px;
      font-size: 14px;
      color: #000;
      position: relative;
      &::after {
        content: '';
        width: 12px;
        height: 30px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: inline-block;
        background: linear-gradient(transparent 0%,hsla(0,0%,100%,.7) 90%,#fff 100%);
      }
    }
    li {
      border: none !important;
      line-height: 30px;
      font-size: 14px;
    }
    .jq-selectbox__trigger-arrow {
      width: 10px;
      height: 16px;
      background: {
        size: 100%;
        repeat: no-repeat;
        image: url(/_images/svg/sorting-select-arrow.svg);
        position: center;
      }
    }
    .jq-selectbox__dropdown {
      border: none;
      bottom: auto;
      top: 45px;
      box-shadow: 0 11px 23px rgba(0, 0, 0, 0.14) !important;
    }
    .jq-selectbox.opened .jq-selectbox__select {
      border: 1px solid #E8EBF0;
    }
  }
  user-select: none;
  &__desktop, &__mobile {
    width: 100%;
    height: 100%;
  }
  &__title {
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    color: #000000;
  }
  &__type {
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    margin-left: 25px;
    position: relative;
    color: #336DF1;
    &:not(.active) {
      cursor: pointer;
      &:hover {
        color: #0a246a;
      }
    }
    &.active {
      color: #000;
      padding-right: 22px;
      &::after {
        padding: 10px 14px 14px 0px;
        position: absolute;
        content: '';
        top: 6.5px;
        margin-left: 8px;
        right: 0;
        z-index: 100;
        background: {
          image: url('/_images/svg/catalog-sort-icon.svg');
          size: 95%;
          repeat: no-repeat;
        }
      }
    }

  }

}