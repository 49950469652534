/* Partners page */

@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 82px;
  }
  h3 {
    font-size: 40px;
  }
  .content {
    padding-top: 131px;
  }
  .partners {
    padding: 0px 0 55px;
    &__text {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 71px;
    }
    ol {
      li {
        padding-left: 45px;
        margin-bottom: 23px;
        &:before {
          font-size: 28px;
          top: -2px;
        }
      }
    }
  }

  .become-partner {
    padding: 54px 0 42px;
    h3 {
      margin-bottom: 23px;
    }
  }
  .form {
    &__input {
      margin-bottom: 26px;
    }
    &__button {
      padding-top: 23px;
    }
  }
  .phones {
    padding: 60px 0 0;
    h3 {
      text-align: left;
    }
    &__list {
      padding-top: 17px;
    }
    &__item {
      margin-bottom: 47px;
    }
    &__title {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

/* About page */

@media (min-width: 992px) {
  .about {
    padding-top: 15px;
    h1 {
      margin-bottom: 35px;
    }
    h2 {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    &__text {
      margin-bottom: 60px;
    }
    &__list {
      margin-bottom: 57px;
    }
    &__item {
      margin-bottom: 0;
    }
  }
  .about-promo {
    &__sub {
      margin-top: -50px;
      margin-bottom: 50px;
    }
  }
  .advantages {
    h3 {
      text-align: left;
    }
    &__item {
      margin-bottom: 45px;
    }
    &__icon {
      justify-content: flex-start;
      margin-bottom: 25px;
    }
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
    &__desc {
      margin-bottom: 20px;
      padding-right: 20px;
    }
  }
  .condition {
    padding: 66px 0 60px;
    h3 {
      line-height: 50px;
      margin-bottom: 20px;
    }
    &__text {
      margin-bottom: 35px;
    }
  }
}

/* Contatcs page */

@media (min-width: 992px) {
  .contacts {
    padding: 0px 0 70px;
  }
}

/* Vacancy page */

@media (min-width: 992px) {
  .vacancy {
    padding-top: 0px;
  }
  .vacancy-menu {
    padding: 35px 0;
    &__item {
      margin-bottom: 0;
    }
    &__link {
      padding: 37px 20px 33px;
    }
    &__icon {
      float: none;
      margin-bottom: 35px;
    }
    &__title {
      margin-bottom: 10px;
    }
    &__info {
      float: left;
      padding: 0;
    }
    &__more {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      padding: 0;
    }
    &.active {
      .vacancy-menu__link {
        padding-top: 12px;
        padding-bottom: 11px;
      }
      .vacancy-menu__icon {
        float: left;
        margin: 0;
      }
      .vacancy-menu__info {
        float: none;
        padding: 15px 0 0 105px;
      }
      .vacancy-menu__more {
        display: none;
      }
    }
  }
  .vacancy-content {
    padding: 36px 0 47px;
    &__count {
      margin-bottom: 40px;
    }
    &__desc {
      padding: 30px 75px 54px;
    }
  }
  .become-job {
    padding: 54px 0 39px;
    &__text {
      margin-bottom: 44px;
    }
  }
}

/* Reviews page */

@media (min-width: 992px) {
  .reviews {
    &__add {
      height: 320px;
    }
    &__item {
      min-height: 320px;
    }
    &__inner {
      min-height: 320px;
    }
    &__action {
      position: absolute;
      bottom: 13px;
      left: 0;
      width: 100%;
      padding: 0 20px;
    }
    &__view {
      float: left;
    }
    &__full {
      margin-bottom: 10px;
    }
    &__item {
      &.active {
        .reviews__inner {
          padding-bottom: 60px;
        }
      }
    }
  }
}

/* Bonus main */

@media (min-width: 992px) {
  .bonus {
    padding-top: 50px;
    &__text {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 35px;
    }
    &__item {
      margin-bottom: 70px;
    }
  }
}

/* Bonus detail */

@media (min-width: 992px) {
  .bonus-detail {
    padding-top: 50px;
    padding-bottom: 60px;
    h1 {
      margin-bottom: 55px;
    }
    &__image {
      margin-bottom: 57px;
    }
    &__preview {
      margin-bottom: 0;
    }
    &__desc {
      h2 {
        margin: 40px 0;
        line-height: 60px;
      }
    }
    &__tbl {
      h2 {
        margin: 35px 0 45px;
      }
    }
    &__table {
      table {
        tbody {
          tr {
            td {
              padding: 20px 20px 20px 32px;
            }
          }
        }
      }
    }
  }
  .bonus-attention {
    padding: 35px 0 71px;
    h4 {
      margin-bottom: 41px;
    }
    ol {
      li {
        padding-left: 45px;
        margin-bottom: 24px;
        &:before {
          font-size: 28px;
          top: -5px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .bonus-detail {
    &__table {
      overflow-x: auto;
    }
  }
}

/* Address */

@media (min-width: 992px) {
  .address {
    padding-top: 15px;
  }
  .address-nav {
    margin-bottom: 50px;
    &__link {
      font-size: 20px;
      padding-bottom: 21px;
    }
  }
  .address-content {
    &__thead {
      display: block;
    }
    &__row {
      margin-bottom: 0;
    }
  }
  .address-map {
    &__area {
      height: 500px;
    }
  }
}

/* Found */

@media (min-width: 992px) {
  .found {
    padding-top: 47px;
    &__inner {
      padding-top: 16px;
    }
    &__label {
      top: 25px;
      margin: 0;
    }
    &__caption {
      position: relative;
      top: 3px;
    }
  }
}

/* Popups */

@media (min-width: 992px) {
  .popup {
    &--login {
      .form__button {
        padding-top: 4px;
      }
    }
  }
}



