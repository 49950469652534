/* Forms */

@media (min-width: 768px) {
  .form {
    &__file {
      padding: 0 190px 0 0;
    }
    &__hint {
      position: absolute;
      top: 0;
      right: 0;
      width: 160px;
      padding: 0;
    }
  }
}

/* Partners page */

@media (min-width: 768px) {
  .phones {
    &__list {
      .row {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

/* About page */

@media (min-width: 768px) {
  .advantages {
    &__list {
      .row {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &__icon {
      height: 100px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}

/* Reviews page */

@media (min-width: 768px) {
  .reviews {
    &__add {
      height: 310px;
    }
    &__item {
      height: 290px;
      &.active {
        z-index: 5;
        .reviews__inner {
          box-shadow: 0 0 113px rgba(65,133,244,.36);
        }
      }
    }
    &__inner {
      min-height: 290px;
    }
    &__img {
      float: left;
      margin: 0;
    }
    &__info {
      padding: 0 0 0 94px;
    }
    &__full {
      float: none;
      margin-bottom: 15px;
    }
    &__view {
      float: none;
    }
  }
}

/* Modals */

@media (min-width: 768px) {
  .modal-dialog {
    width: 720px;
  }
  .modal-close {
    width: 48px;
    height: 48px;
    i {
      margin-top: 14px;
    }
  }
  .popup {
    &__container {
      margin-bottom: 48px;
    }
  }
  .popup-nav {
    padding: 40px 0;
    &__link {
      font-size: 24px;
    }
  }
}

/* Vacancy page */

@media (min-width: 768px) {
  .vacancy-content {
    &__item {
      .vacancy-content__link {
        font-size: 22px;
        padding: 18px 0 18px 35px;
        i {
          display: inline-block;
        }
      }
      &.active {
        .vacancy-content__link {
          i {
            background-image: url('/_images/svg/arrow-up-white.svg');
          }
        }
      }
    }
    &__btn {
      display: inline-block;
      padding: 0 35px;
      border-width: 2px;
    }
  }
}

/* 404 */

@media (min-width: 768px) {
  .found {
    padding-bottom: 100px;
    &__top {
      padding-top: 48px;
    }
    &__inner {
      &:before {
        width: 305px;
        height: 96px;
        left: 65px;
      }
    }
    &__label {
      font-size: 36px;
      line-height: 68px;
      height: 70px;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__caption {
      padding-left: 0;
      float: right;
    }
    &__desc {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

/* Address */

@media (min-width: 768px) {
  .address-nav {
    &__item {
      margin-bottom: 0;
    }
  }
  .address-content {
    &__item {
      .address-content__link {
        padding: 17px 40px 17px 75px;
        i {
          display: block;
        }
      }
      &.active {
        .address-content__link {
          padding: 16px 39px 17px 74px;
        }
      }
    }
    &__desc {
      padding: 30px 40px;
    }
  }
}



