/* Partners page */

@media (min-width: 1454px) {
  .partners {
    ol {
      padding-left: 32px;
    }
  }
  .become-partner {
    &__form {
      padding: 0 60px;
    }
  }


  .header {
    &__text {
      svg {
        margin-right: 14px;
      }
    }
    &__panel-item {
      margin-left:70px;
    }
  }




}

/* About page */

@media (min-width: 1454px) {
  .about {
    &__label {
      font-size: 30px;
      line-height: 34px;
    }
  }
  .about-promo {
    &__sub {
      margin-bottom: 75px;
    }
    &__slider {
      &--sub {
        box-shadow: 0 0 344px 12px rgba(65, 133, 244, .36);
      }
    }
    &__arrow {
      width: 50px;
      &--prev {
        left: -50px;
      }
      &--next {
        right: -50px;
      }
    }
  }
  .advantages {
    &__list {
      padding: 45px 0 50px;
    }
    &__item {
      margin-bottom: 65px;
    }
  }
}

/* Reviews page */

@media (min-width: 1454px) {
  .reviews {
    &__add {
      height: 290px;
      padding-left: 40px;
      padding-right: 40px;
    }
    &__item {
      min-height: 290px;
      &.active {
        .reviews__inner {
          padding-bottom: 65px;
        }
      }
    }
    &__inner {
      min-height: 290px;
      padding: 35px 30px 20px;
    }
    &__name {
      font-size: 22px;
      line-height: 29px;
    }
    &__entry {
      max-height: 90px;
    }
    &__action {
      bottom: 30px;
      padding: 0 30px;
    }
    &__view {
      float: right;
    }
    &__full {
      float: left;
      margin-bottom: 0;
    }
    &__response {
      padding: 35px 30px 20px;
    }
  }
}

/* Bonus detail */

@media (min-width: 1454px) {
  .bonus-detail {
    &__table {
      table {
        tbody {
          tr {
            td {
              &:first-child {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

/* Modals */

@media (min-width: 1454px) {
  .modal-content {
    box-shadow: 0 0 344px 12px rgba(65, 133, 244, .37);
  }
  .modal-dialog {
    width: 944px;
  }
  .popup {
    padding-bottom: 60px;
    &__title {
      padding: 65px 0 55px;
      font-size: 40px;
    }
    &__phone {
      margin-bottom: 75px;
    }
    &--consult {
      .popup__title {
        padding-top: 65px;
      }
    }
  }
  .popup-nav {
    padding: 70px 0 64px;
    &__link {
      font-size: 40px;
      padding-top: 1px;
      padding-bottom: 1px;
    }
    &__item {
      &.active {
        .popup__link {
          padding-bottom: 2px;
        }
      }
    }
  }
}

/* Vacancy page */

@media (min-width: 1454px) {
  .vacancy-menu {
    &__link {
      padding-left: 40px;
      padding-right: 40px;
    }
    &__title {
      font-size: 28px;
    }
    &.active {
      .vacancy-menu__info {
        padding-left: 125px;
      }
    }
  }
  .become-job {
    &__form {
      padding: 0 60px;
    }
  }
}

/* Address */

@media (min-width: 1454px) {
  .address-content {
    &__thead {
      padding-right: 70px;
    }
    &__name {
      font-size: 22px;
    }
    &__item {
      .address-content__link {
        font-size: 18px;
        padding: 16px 70px 17px 75px;
      }
      &.active {
        .address-content__link {
          padding-top: 15px;
          padding-right: 69px;
        }
      }
    }
    &__desc {
      padding: 18px 70px 26px 75px;
    }
  }
}

