.number_of_goods {
  display: flex;
  button {
    background-color: rgba(65, 133, 244, 0.2);
    width: 38px;
    height: 44px;
    border: none;
    position: relative;
    &:after {
      width: 12px;
    }
    &:before {
      width: 12px;
    }
    &:hover {
      cursor: pointer;
      background-color: rgba(65,133,244,.3);
    }
  }
  &__minus {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      background: #515b6d;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &__plus {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      background: #515b6d;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    &:before {
      content: '';
      position: absolute;
      height: 12px;
      width: 2px !important;
      background: #515b6d;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

  }
  &__count {
    text-align: center;
    border: 1px solid rgba(81, 91, 109, 0.2);
    width: 40px;
    height: 44px;
    font-size: 14px;
    color: #515B6D;
    background: #ffffff;
  }
}