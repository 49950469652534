.breadcrumbs {
  height: 60px;
  padding: 15px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-wrapper {
    width: 100%;
    //Remove it
    @include ap(margin-top, 0px, 60px, 130px, 130px);
    @include ap(margin-bottom, 0px, -110px, -160px, -130px);
    @include ap(display, none, block, block, block);
    box-shadow: 0px 3px 6px rgba(208, 215, 226, 0.12);
  }
  &__back {
    height: 100%;
    width: 100px;
    position: relative;
    margin-right: 40px;
    background: {
      image: url('/_images/svg/breadcrumbs-back.svg');
      size: 100%;
      repeat: no-repeat;
      position: center;
    }
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      height: 30px;
      width: 1px;
      background: #515B6D;
      position: absolute;
      right: -20px;
      cursor: default;
    }
  }
  &__path-part {
    height: 100%;
    font-weight: 300;
    line-height: 22px;
    font-size: 12px;
    color: #515B6D;
    mix-blend-mode: normal;
    opacity: 0.5;
    margin-right: 46px;
    cursor: pointer;
    position: relative;
    align-items: center;
    display: flex;
    &::after {
      content: '';
      display: block;
      height: 30px;
      width: 5px;
      background: {
        image: url('/_images/svg/breadcrumbs-arrow-right.svg');
        size: contain;
        repeat: no-repeat;
        position: center;
      };
      position: absolute;
      right: -26px;
      opacity: 1 !important;
      top: -4%;
      cursor: default;
    }
    &:last-of-type {
      opacity: 1;
      cursor: default;
      &::after {
        content: unset;
      }
    }
  }
}