.catalog-filter {
  @media (max-width: $sm) {
    height: 100%;
    overflow-y: auto;
    border: none;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    z-index: 1000;
    background: #fff;
    &__mobile {
      display: flex !important;
    }
  }
  &__mobile {
    display: none;
    height: 70px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-weight: 300;
    line-height: 32px;
    font-size: 24px;
    color: #000000;
  }
  &__close {
    width: 16px;
    height: 16px;
    transition: all .2s ease-in;
    background: {
      image: url('/_images/svg/catalog-filter-close.svg');
      size: 100%;
      repeat: no-repeat;
      position: center;
    }
  }

  @media (max-width: $sm) {
    width: 90%;
    position: fixed;
    right: -100%;
    top: 0;
    transition: right .4s ease-in;

    .smartfilter {
      display: none;
    }

    &.active {
      top: 0;
      border-top: 0;
      right: 0;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        transition: background .9s ease-in;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: -2;
      }
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: white;
        position: fixed;
        z-index: -1;
      }
      .smartfilter {
        display: initial;
      }
    }
  }

  border: 1px solid rgba(208, 215, 226, 0.5);
  box-sizing: border-box;
  &__input[type=number] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  &__input {
    background: #FFFFFF;
    border: 1px solid #D0D7E2;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    font-size: 16px;
    color: #000;
    padding: 0px 16px;
    &[type="number"] {
      @include ap(width, 48%, 48%, 45%, 34%);
    }
    &[type="text"] {
      width: 100%;
    }
    &-group {
      display: flex;
      justify-content: space-between;
    }
  }
  &__property {
    position: relative;
    &.active { //selected
      @media (max-width: $sm) { //mobile
        .catalog-filter__property-title {
          padding-left: 57px;
          position: fixed;
          top: 0;
          right: 0;
          /*width: 284px;*/
          width: 90%;
          height: 50px;
          z-index: 1005;

          &::before {
            transform: rotate(270deg);
            left: 24px;
          }
          & ~ .catalog-filter__property-body {
            position: fixed;
            /*width: 284px;*/
            width: 90%;
            background: white;
            z-index: 1008;
            overflow-y: auto;
            top: 50px;
            right: 0;
            height: calc(100% - 50px);
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            justify-content: flex-start;
            align-items: normal;
            transition: right .2s ease-in;
          }
        }
      }

      @media (min-width: $sm) { //desktop
        .catalog-filter__property-title.list {
          padding-left: 57px;

          &::before {
            transform: scaleY(1);
            left: 24px;
          }
          & ~ .catalog-filter__property-body {
            display: block;
          }
        }
        .catalog-filter__property-title:not(.list) {

          & ~ .catalog-filter__property-body {
            display: block;
          }
        }
      }

    }
    &:not(.active) { //not selected
      @media (max-width: $sm) { //mobile
        .catalog-filter__property-title {
          height: auto;
          padding-bottom: 25px;
          padding-right: 57px;
          background: white;

          &::before {
            transform: rotate(90deg);
            right: 24px;
          }

          & ~ .catalog-filter__property-body {
            display: none;
          }
        }

      }
      @media (min-width: $sm) { //desktop
        .catalog-filter__property-title.list {
          padding-left: 57px;

          &::before {
            transform: scaleY(-1);
            left: 24px;
          }

          & ~ .catalog-filter__property-body {
            display: none;
          }
        }
        .catalog-filter__property-title:not(.list) {

          & ~ .catalog-filter__property-body {
            display: block;
          }
        }

      }
    }

    &-body {
      padding: 10px 20px 20px;
    }
    &:not(:first-of-type) {
      @media (min-width: $sm) {
        border-top: 1px solid rgba(208, 215, 226, 0.5);
      }
    }
    &-title {
      background: #fff;
      user-select: none;
      cursor: pointer;
      padding: 10px 20px;
      line-height: 18px;
      display: flex;
      align-items: center;
      height: 50px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: rgba(81, 91, 109, 0.6);
      transition: color .2s ease-in, background .2s ease-in;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        width: 12px;
        height: 6px;
        top: calc(50% - 3px);
        transition: transform .2s ease-in;
        background: {
          image: url('/_images/svg/arrow-down-black.svg');
          size: 100%;
          position: center;
        }
      }

      @media (max-width: $sm) {
        color: #515b6d;
      }
      @media (min-width: $sm) {
        &.range {
          line-height: 40px;
          padding: 10px 20px 0px;
          height: 40px;
          &::before {
            content: unset;
          }
        }
        &.list {
          &:hover {
            background: rgba(208, 215, 226, 0.2);
            color: #515B6D;
          }
        }
      }
    }
    &-value {
      max-width: 90%;
      padding: 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      bottom: 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: rgba(81, 91, 109, 0.6);
      display: none;
      @media (max-width: $sm) {
        display: initial;
      }
    }
    &-list {
      margin-right: -19px;
      padding-top: 7px;
      @media (min-width: $sm) {
        max-height: 140px;
      }
      overflow-y: auto;
      margin-top: -10px;
      padding-bottom: 1px; //To hide scrollbar in not overflowed list
      &::-webkit-scrollbar-thumb {
        background: #515B6D;
        border-radius: 7px;
        width: 5px;
      }
      &::-webkit-scrollbar {
        background: transparent;
        border: none;
        width: 7px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      & > div:first-of-type {
        margin-top: 0px !important;
      }
      &-item {
        color: #000000;
        margin: 10px 0px 0px 1px;
        padding-right: 20px;
        input[type=checkbox] {
          display: none;
        }
        & > label {
          position: relative;
          padding-left: 30px;
          cursor: pointer;
          font-weight: 300;
          line-height: 19px;
          height: 18px;
          font-size: 14px;
          user-select: none;
        }
        & > label::before {
          height: 18px;
          width: 18px;
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          background: {
            image: url('/_images/svg/checkbox.svg');
            size: 100%;
            repeat: no-repeat;
          }
        }
        & > label:hover::before {
          background-image: url('/_images/svg/checkbox-hover.svg');
        }
        & > input[type=checkbox]:checked ~ label::before {
          background-image: url('/_images/svg/checkbox-checked.svg');
        }
        & > input[type=checkbox]:disabled ~ label::before {
          background-image: url('/_images/svg/checkbox-disabled.svg') !important;
        }
        &.disabled > label::before {
          background-image: url('/_images/svg/checkbox-disabled.svg');
        }
      }
      &-search {
        padding-bottom: 25px;
      }
      &-noitems {
        position: relative;
        padding-left: 10px;
        cursor: pointer;
        font-weight: 300;
        line-height: 19px;
        height: 18px;
        font-size: 14px;
        user-select: none;
      }
    }
    &-clear {
      line-height: 22px;
      font-size: 12px;
      text-align: right;
      display: inline-block;
      line-height: 30px;
      color: rgba(81, 91, 109, 0.6);
      display: none;
      @media (min-width: $sm) {
        display: none;
      }
    }
    &-apply {
      @media (min-width: $sm) {
        display: none;
      }
      padding: 10px 0px;
      width: 100%;
      margin-top: auto;
      background: white;
      & > button {
        width: 100% !important;
        padding: 10px 20px;
        margin: 0px !important;
      }

    }
    @media (max-width: $sm) {
      .bx-filter-container-modef {
        display: none;
      }
    }
  }
  &__footer {
    @media (min-width: $sm) {
      width: 100%;
      position: static;
    }
    @media (max-width: $sm) {
      width: 284px;
      margin-top: auto;
      height: 60px;
      background: white;
      z-index: 1006;
      border: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-top: 1px solid rgba(208, 215, 226, 0.5);

    &-reset {
      height: 45px;
      width: 256px;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      text-align: center;
      letter-spacing: 1.71429px;
      text-transform: uppercase;
      color: #515B6D;
      border: 2px solid #ededed;
      box-sizing: border-box;
      border-radius: 5px;
      background: #fff;
      transition: all .2s ease-in;
      &:hover {
        border-color: #c7c7c7;
      }
      @media (max-width: $sm) {
        width: 107px !important;
        margin-right: 20px;
      }
    }
    &-show {
      @media (min-width: $sm) {
        display: none;
      }
      @media (max-width: $sm) {
        display: block;
        height: 45px;
        width: 107px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1.71429px;
        text-transform: uppercase;
        color: #515b6d;
        border: navajowhite;
        box-sizing: border-box;
        border-radius: 5px;
        background: #fff;
        color: white;
        transition: all .2s ease-in;
        background: #4185F4;
      }
    }

  }
}

.noUi {
  &-base {
    width: 100%;
    height: 4px;
    background: rgba(208, 215, 226, 0.5);
    border-radius: 100px;
    position: relative;
    margin-top: 25px;
    margin-bottom: 5px;
  }
  &-connects {
    width: 100%;
    height: 100%;
  }
  &-origin {
    position: absolute;
    width: 100%;
    left: 8px;
    top: -6px;
  }
  &-connect {
    background: #4185F4;
    height: 100%;
    position: absolute;
  }
  &-handle {
    width: 16px;
    height: 16px;
    background: #4185F4;
    border-radius: 100px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}