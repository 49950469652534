
.service {
  padding: 30px 0 40px;
  @media (min-width: 992px) {
    .contacts {
      padding: 40px 0 70px;
    }
  }

  h2.title {
    margin-top: 21px;
    font-size: 60px;
    line-height: 82px;
  }
}

.order {
  &__line {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 21px;
    margin-top: 42px;
  }
  &__line-step {
    top: -76px;
    position: absolute;
    opacity: 0.5;
    color: rgb(81, 91, 109);
    font-size: 14px;
    line-height: 30px;
  }
  &__line-item {
    width: 25%;
    text-align: center;
    position: relative;
    &--last {
      &:after {
        display: none !important;
      }
    }
    &.complete {
      &:after {
        background-color: #4185f4;
      }
      .order__line-circle {
        background-color: #4185f4;
        border-color: #4185f4;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/_images/svg/service-ok.png');
      }
    }
    &.active {
      .order__line-text {
        color: #4185f4;
        font-size: 20px;
        opacity: 1;
        //top: -150%;
        @media (max-width: $sm-down) {
          font-size: 10px;
        }

      }
      .order__line-circle {
        box-shadow: 0 2px 12px #d0d7e2;
        border-color: #fff;
        position: relative;
        z-index: 2;
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          background-color: #4185f4;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: #d0d7e2;
      height: 2px;
      width: 100%;
      left: 50%;
      top: 50%;
      margin-top: -2px;
      z-index: 1;
    }
    &--short {
      width: 50%;
    }
  }
  &__line-text {
    display: block;
    text-align: center;
    //opacity: 0.5;
    color: rgb(81, 91, 109);
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    position: absolute !important;
    top: -133%;
    left: 50%;
    transform: translate(-50%, 0%);
    @media (max-width: $sm-down) {
      font-size: 10px;
      white-space: normal;
      line-height: 1;
    }
  }
  &__line-circle {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid #d0d7e2;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    z-index: 2;
  }
  &__services-title {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 36px;
  }
  &__services {
    border: 1px solid #c0c9d9;
    background-color: #f0f5f9;
    padding: 22px 22px;
    margin-bottom: 20px;
  }
  &__find {
    position: relative;
    input[type='text'] {
      border-radius: 3px;
      border: 1px solid #d0d7e2;
      background-color: #ffffff;
      width: 100%;
      height: 58px;
      line-height: 58px;
      padding: 0 25px;
      font-size: 16px;
    }
    button[type='submit'] {
      width: 19px;
      height: 20px;
      border: 0;
      background: transparent url('/_images/svg/search.svg') no-repeat center;
      position: absolute;
      z-index: 2;
      top: 19px;
      right: 18px;
    }
  }
  &__additition {
    position: relative;
    input[type='text'] {
      border-radius: 3px;
      border: 1px solid #d0d7e2;
      background-color: #ffffff;
      width: 100%;
      height: 45px;
      line-height: 58px;
      padding: 0 25px;
      font-size: 16px;
    }
    span {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      padding: 0 23px;
      height: 45px;
      border-radius: 5px;
      border: 2px solid #4185f4;
      line-height: 42px;
      text-align: center;
      color: #4185f4;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.71px;
      background-color: #f0f5f9;
      transition: all .2s;
      &:hover {
        color: #fff;
        background-color: #4185f4;
      }
    }
  }

  &__services-list {

    overflow: auto;
    margin-top: 27px;
    border: 1px solid #d0d7e2;
    background-color: #ffffff;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 20px;
    max-height: 710px;
    position: relative;
    @media (max-width: $sm-down) {
      //overflow: hidden;
    }
    .mCSB_inside > .mCSB_container {
      margin-right: 10px;
    }
    .mCSB_scrollTools {
      width: 10px;
    }
    .mCSB_draggerContainer {
      width: 10px;
      background-color: rgba(65, 133, 244, .5) !important;
    }
    .mCSB_dragger_bar {
      width: 6px !important;
      left: 0px;
      background-color: rgb(65, 133, 244) !important;
    }
    .mCSB_draggerRail {
      display: none !important;
    }
  }
  &__category {
    width: 36%;
    @media (max-width: $sm-down) {
      width: 100%;
    }
  }
  &__service-item {
    width: 100%;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    color: #515b6d;
    font-size: 16px;
    line-height: 53px;
    padding: 0 26px;
    cursor: pointer;
    white-space: nowrap;

    border-bottom: 1px solid #d0d7e2;
    &:hover {
      transition: color .2s;
      color: #4185f4;
    }
    &:last-child {
      border-bottom: 0;
    }
    &.active {
      transition: all .2s;
      color: #ffffff;
      background-color: #4185f4;
      border-color: #4185f4;
      &:after {
        content: '';
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
        width: 7px;
        height: 12px;
        background-image: url('/_images/svg/arrow-service.svg');
      }
    }
  }
  &__service-question {
    cursor: pointer;
    position: relative;
    z-index: 3;
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    background-image: url('/_images/svg/round-question.svg');
    background-size: contain;
    background-repeat: no-repeat;
    &:hover {
      background-image: url('/_images/svg/round-question-hover.svg');
    }
  }
  &__items {

    background: #fff;
    width: 64%;
    box-shadow: inset 0px 0px 0 1px #4185f4;
    @media (max-width: $sm-down) {
      z-index: 2;
      width: 100%;
      position: absolute;
      top: 0;
      transform: translate(100%, 0%);
      transition: transform .3s;
      height: 0;
      &.active {
        height: auto;
        transform: translate(0%, 0%);
      }
    }
  }
  &__services-btn-wrap {
    text-align: right;
    &.-center {
      text-align: center;
      margin-top: 20px;
    }
  }
  &__services-btn {
    padding: 0 23px;
    height: 45px;
    border-radius: 5px;
    border: 2px solid #4185f4;
    text-align: center;
    line-height: 42px;
    text-align: center;
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    background-color: #f0f5f9;
    &:hover {
      transition: all .2s;
      color: #fff;
      background-color: #4185f4;
    }
  }
  &__selected {
    padding: 30px 0;
    background-repeat: no-repeat;
    background-position: 90% bottom;
    background-color: #f0f5f9;
    border-top: 1px solid rgba(192, 201, 217, .5);
    &.-step-1 {
      border-top: 0;
      background-image: url('/_images/svg/1.svg');
    }
    &.-step-2 {
      background-image: url('/_images/svg/2.svg');
    }
    &.-step-3 {
      background-image: url('/_images/svg/3.svg');
    }
  }
  &__selected-title {
    color: #000;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 23px;
  }
  &__selected-list {
    &--car {
      display: flex;
      flex-wrap: nowrap;
      @media (max-width: $sm-down) {
        flex-direction: column;
      }
      .order__selected-item {
        color: #515b6d;
        font-size: 14px;
        margin-right: 14px;
        .order__selected-edit {
          right: 14px;
        }
        &.brand {
          width: 20%;
          @media (max-width: $sm-down) {
            width: 100%;
          }
        }
        &.model {
          width: 30%;
          @media (max-width: $sm-down) {
            width: 100%;
          }
        }
        &.ser {
          @media (max-width: $sm-down) {
            width: 100%;
          }
          width: 25%;
        }
        &.wtf {
          @media (max-width: $sm-down) {
            width: 100%;
          }
          width: 25%;
          margin-right: 0;
        }
      }
    }
    &--place {
      .order__selected-item {
        color: #515b6d;
        font-size: 14px;
        .time {
          color: #4185f4;
        }
        .order__selected-edit {
          right: 14px;
        }
      }
    }
  }
  &__selected-item {
    cursor: pointer;
    padding: 0 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 44px;
    border-radius: 5px;
    background-color: #ffffff;
    line-height: 46px;
    color: #4185f4;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    position: relative;
    transition: all .3s;
    outline: none;
    user-select: none;

    &:hover {
      box-shadow: 0 0 17px 5px rgba(65, 133, 244, 0.15);
    }
    &--big {
      height: 44px;
      line-height: 46px;
      @media (max-width: $sm-down) {
        padding-right: 75px;
        font-size: 12px;
      }
    }

  }
  &__selected-close {
    //transform: rotate(45deg);
    transform: rotate(0deg);
    top: 15px;
    right: 16px;
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url('/_images/svg/cross.svg');
  }
  &__selected-edit {
    top: 13px;
    right: 51px;
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    background-image: url('/_images/svg/pencil-black.svg');
    &:hover {
      background-image: url('/_images/svg/pencil.svg');
    }
  }
  &__selected-more {
    display: block;
    cursor: pointer;
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 12px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #4185f4;
      background-image: url('/_images/svg/plus.svg');
    }
    &.-right {
      float: right;
    }
  }
  &__tab {
    list-style: none;
    margin-bottom: 17px;
    padding-left: 27px;
  }
  &__tab-item {
    float: left;
    opacity: 0.7;
    font-size: 14px;
    line-height: 22px;
    margin-right: 26px;
    a {
      color: #515b6d;
      font-weight: 300;
      border-bottom: 1px dashed;
    }
    &:last-child {
      right: 0;
    }
    &.active {
      a {
        color: #000000;
        border-bottom: none;
      }
    }
  }
  &__tab-content {
    .tab-pane {
      display: block;
      border: 1px solid #d0d7e2;
      background-color: #ffffff;
      padding: 18px 12px;
      &.-four-col {
        column-count: 4;
      }
      &.-two-col {
        column-count: 2;
      }
    }
  }
  &__shop-wrap {
    display: block;
    width: 100%;

  }
  &__shop {
    width: 100%;
    display: flex;
  }
  &__shop-item {
    margin-bottom: 30px;
    cursor: pointer;
    width: 33%;
    text-align: center;
    &:hover {
      img {
        border: 2px solid #4185f4;
      }
    }
    &.active {
      .order__shop-img {
        &:before {
          content: '';
          position: absolute;
          width: 30px;
          height: 30px;
          bottom: 6px;
          transform: translateX(25px);
          left: 50%;
          background-repeat: no-repeat;
          background-color: #4185f4;
          border-radius: 50%;
          background-position: center;
          background-image: url('/_images/svg/service-ok.png');
          @media (max-width: $sm-down) {
            left: 30% !important;
          }
        }
        img {
          border: 2px solid #4185f4;
          box-shadow: inset 0 0 0px 2px #4185f4;

        }
      }
    }
  }

  &__shop-img {
    position: relative;
    img {
      display: inline-block;
      border-radius: 50%;
      width: 119px;
      height: 119px;
      border: 2px solid transparent;
      box-shadow: inset 0 0 0px 2px #4185f4;
      @media (max-width: $sm-down) {
        width: 90%;
        max-width: 100%;
        height: auto;
      }
    }
  }
  &__shop-type {
    color: #515b6d;
    font-size: 12px;
    line-height: 18px;
    margin-top: 21px;
    font-weight: 300;
  }
  &__shop-address {
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    margin-top: 6px;
  }
  &__calendar {
    //padding: 0 37px;
    padding: 30px 0px;
    border-top: 1px solid #d0d7e2;

    @media (max-width: $sm-down) {
      display: flex;
      flex-direction: column;
    }
  }

  &__calendar-wrap {
    float: left;
    width: 61%;
    border-right: 1px solid #d0d7e2;
    padding-right: 37px;
    @media (max-width: $sm-down) {
      width: 100%;
      padding: 0;
      border: 0;
    }
  }
  &__calendar-scheduler {
    float: left;
    width: 39%;
    margin-top: 92px;
    padding-left: 17px;
    @media (max-width: $sm-down) {
      width: 100%;
      padding: 0;
      border: 0;
      margin-top: 20px;

      /*display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;*/
    }
  }
  &__success {
    color: #515b6d;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 58px;
    font-weight: 300;
  }
  &_success-title {
    margin-top: 19px;
    font-size: 60px;
    line-height: 82px;
  }
  &__print {
    font-weight: 300;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 49px;
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    &:before {
      margin-right: 15px;
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      background-color: #2ed672;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/_images/svg/printer.svg');
    }
  }
  &__form-subtitle {
    text-align: center;
    color: #515b6d;
    font-size: 16px;
    line-height: 22px;
  }
  &__form-alarm {
    top: 10px;
    color: #515b6d;
    font-size: 12px;
    line-height: 18px;
    padding-left: 38px;
    position: relative;
    @media (max-width: $sm-down) {
      top: 0;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url('/_images/svg/red-alarm.svg');
    }
  }
  &__from-repair {
    margin-top: 16px;
    display: flex;
    input {
      display: none;
      &:checked + label {
        color: #4185f4;
        box-shadow: 0 0 0 2px #4688f1;
        z-index: 2;
      }
    }
    label {
      width: 50%;
      height: 42px;
      border-radius: 100px;
      color: #515b6d;
      background-color: #ffffff;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      box-shadow: 0 0 0 2px #d0d7e2;
      position: relative;
      &.self {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: 0;
      }
      &.repair {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 0;
      }
    }
  }
  &__preloader {
    text-align: center;
    padding: 80px 20px;
  }
}

.tab-content {
  &__column {
    width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 40px;
    @media (max-width: $sm-down) {
      width: 50%;
    }
  }
  &__brand {
    color: #515b6d;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    cursor: pointer;
    font-weight: 300;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0 13px;
    &:hover, &.active {
      transition: .2s ease-out;
      border: 1px solid rgb(65, 133, 244);
      color: rgb(65, 133, 244);
    }
  }
  &--fix-height {
    max-height: 550px;
    overflow: auto;
    .mCSB_inside > .mCSB_container {
      margin-right: 10px;
    }
    .mCSB_scrollTools {
      width: 10px;
    }
    .mCSB_draggerContainer {
      width: 10px;
      background-color: rgba(65, 133, 244, .5) !important;
    }
    .mCSB_dragger_bar {
      width: 6px !important;
      left: 0px;
      background-color: rgb(65, 133, 244) !important;
    }
    .mCSB_draggerRail {
      display: none !important;
    }
  }
  &__checkbox {
    position: relative;
    cursor: pointer;
    line-height: 36px;
    padding-left: 30px;
    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      left: 0;
      top: 50%;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
    &:after {
      display: none;
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      margin-top: -5px;
      left: 5px;
      top: 50%;
      border-radius: 50%;
      border: 1px solid #ccc;
      background: #555;
    }
    &.active {
      &:after {
        display: block;
      }
    }
  }
}

.question {
  text-align: center;
  margin-top: 74px;
  margin-bottom: 108px;

  &__btn {
    cursor: pointer;
    text-align: center;
    position: relative;
    width: 736px;
    display: inline-block;
    height: 133px;
    box-shadow: 0 8px 54px 5px rgba(65, 133, 244, 0.33);
    border-radius: 100px;
    border: 1px solid #f0f5f9;
    background-color: #ffffff;
    color: #000000;
    font-size: 34px;
    line-height: 131px;
    @media (max-width: $md-down) {
      width: 100%;
      height: auto;
      font-size: 28px;
      line-height: 100px;
    }
    @media (max-width: $sm-down) {
      width: 100%;
      height: auto;
      font-size: 20px;
      line-height: 80px;
    }
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 121px;
      margin-right: 20px;
      position: relative;
      top: 8px;
      height: 66px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('/_images/svg/question-car.svg');
      @media (max-width: 395px) {
        display: none;
      }
      @media (max-width: $sm-down) {
        width: 63px;
        height: 36px;
      }

    }
  }
  &__phone {
    bottom: -37px;
    right: 143px;
    display: block;
    position: absolute;
    width: 308px;
    height: 54px;
    border-radius: 86px;
    background-color: #4185f4;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    line-height: 54px;
    &:hover {
      color: #ffffff;
    }
    @media (max-width: $sm-down) {
      font-size: 16px;
      line-height: 34px;
      height: 34px;
      bottom: -26px;
      left: 0;
      width: 100%;
    }
  }
}

.tooltip {
  &.in {
    opacity: 1 !important;
  }
  &.top {
    background-color: #ffffff;
    .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff
    }
    .tooltip-inner {
      box-shadow: 0 2px 33px rgba(65, 133, 244, 0.36);
      background-color: #ffffff;
      color: #515b6d;
      font-size: 12px;
      line-height: 16px;
      padding: 18px;
    }
  }
}

.calendar {
  &__header {
    text-align: center;
    height: 19px;
    font-size: 16px;
    color: #515b6d;
    margin: 7px 0 12px 0;
    font-weight: 300;
    & > span {
      color: #4185f4;
      font-weight: 300;
    }

  }
  &__arrow {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 7px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    &--prev {

      margin-right: 42px;
      background-image: url('/_images/svg/calendar-prev.svg');
      @media (max-width: 480px) {
        margin-right: 22px;
      }
    }
    &--next {
      margin-left: 42px;
      background-image: url('/_images/svg/calendar-next.svg');
      @media (max-width: 480px) {
        margin-left: 22px;
      }
    }
  }
  &__month {

  }
  &__week {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  &__name {
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    margin: 9px 0px;
    //margin-right: 16px;
    margin-bottom: -11px !important;
  }

  &__day {
    cursor: pointer;
    margin: 9px 0px;
    //margin-right: 16px;
    text-align: center;
    width: 55px;
    height: 55px;
    border-radius: 5px;
    line-height: 55px;
    border: 1px solid #c0c9d9;
    background-color: #ffffff;
    font-size: 14px;
    color: #000;
    @media (max-width: $sm-down) {
      width: 26px;
      height: 26px;
      line-height: 26px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      transition: all .2s;
      color: #4185f4;
      border: 1px solid #4185f4;
    }
    &.broned {
      pointer-events: none;
      border: 0;
      background-color: #f0f5f9;
      color: rgba(81, 91, 109, .5);
    }
    &.past {
      background-color: #f0f5f9;
      opacity: .6;
    }
    &.active {
      background-color: #4185f4;
      color: #fff;
      border-color: #4185f4;
    }
  }
  &__time {
    /*float: left;*/
    color: #000000;
    font-size: 14px;
    /*width: 55px;*/
    height: 55px;
    border-radius: 5px;
    border: 1px solid #c0c9d9;
    background-color: #ffffff;
    text-align: center;
    line-height: 55px;
    cursor: pointer;
    margin: 9px 15px 18px;
    @media (max-width: $sm-down) {
      height: 32px;
      line-height:32px;
      margin: 9px;
    }
    &:hover {
      transition: all .2s;
      color: #4185f4;
      border: 1px solid #4185f4;
    }
    &.broned {
      background-color: #f0f5f9;
      opacity: 0.5;
      color: #515b6d;
      pointer-events: none;
      border: 0;
    }
    &.active {
      color: #fff;
      background-color: #4185f4;
      border-color: #4185f4;
    }
    &-text{
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      margin: 9px 15px 18px;
    }
  }
}

.p-menu {
  border-bottom: 1px solid #ebeaf1;
  margin: 9px 0 62px;

  &--manager {
    padding: 0 375px;
    @media (max-width: $lg-down) {
      padding: 0 205px;
    }
    @media (max-width: $md-down) {
      padding: 0 105px;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-around;
  }
  &__item {
    color: rgba(81, 91, 109, .7);
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 12px;
    position: relative;
    @media (max-width: $md-down) {
      text-align: center;
      font-size: 14px;
      line-height: 17px;
    }
    &:hover {
      color: #5887F0;
      &:after {
        left: 0;
        width: 100%;
        background-color: #5887F0;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -13px;
      width: 0px;
      height: 3px;
      background-color: #5887F0;
      transition: all .3s;
    }

    &.active {
      color: #515b6d;
      position: relative;
      /*      pointer-events: none;*/
      &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        left: 0;
        bottom: -13px;
        background-color: #515b6d;
      }
    }
  }
}

.no-car {
  color: #515b6d;
  margin-bottom: 100px;
  &__title {
    font-size: 40px;
    line-height: 38px;
    font-weight: 300;
    margin-bottom: 28px;
  }
  &__text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 41px
  }
}

.p-car {
  margin-bottom: 80px;
  .order__selected-more {
    margin-top: 30px;
  }
  &--selected {
    background-color: #f0f5f9;
    padding: 30px 0;
    margin-bottom: 54px;

  }
  &--single {
    .order__selected-more {
      width: 50%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      @media (max-width: $lg-down) {
        text-align: center;
        width: 100%;
        left: 0;
        transform: none;
      }
    }
    .p-car__wrap {
      justify-content: center;
    }
    .p-car__item {
      margin-right: 0;
      @media (max-width: $lg-down) {
        width: 70%;
      }
    }
    .car__item {
      @media (max-width: $md-down) {
        flex-direction: row !important;
      }
      @media (max-width: 480px) {
        flex-direction: column !important;
      }
    }
  }
  &--list {
    .car__btn {
      transition: opacity 0.3s;
      opacity: 0;
    }
    .p-car__item:hover {
      .car__btn {
        opacity: 1;
      }
    }
    .car__item.active {
      .car__btn--close {
        left: 17px;
      }
      .car__btn--select {
        display: none;
      }
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: $md-down) {
      flex-direction: column;
    }
  }

  &__item {
    background: #fff;
    width: 49%;
    position: relative;
    margin-bottom: 24px;
    @media (max-width: $md-down) {
      width: 100% !important;
      margin-right: 0;
      margin-bottom: 24px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &--selected {
      margin: 0;
      width: 100%;
    }

  }
  &__link-detail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__select {
    color: #515b6d;
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    top: -13px;
    left: 16px;
    background: white;
    padding-right: 18px;
    font-weight: 300;
    .order__line-circle {
      margin-right: 9px;
      display: inline-block;
      vertical-align: middle;
      background-color: #4185f4;
      border-color: #4185f4;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('/_images/svg/service-ok.png');
    }

  }
}

.car {
  &__item {
    cursor: pointer;
    border: 1px solid #d0d7e2;
    display: flex;
    height: 100%;
    padding: 46px 28px 24px 28px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
    &.active {
      border: 1px solid #4185f4;
    }
    &--selected {
      cursor: default;
      padding: 13px;
      border: 1px solid #4185f4;
      @media (max-width: $sm-down) {
        flex-direction: column;
      }
    }
  }
  &__img-wrap {
    width: 40%;
    height: 100%;
    text-align: center;
    padding-top: 52px;
    @media (max-width: 480px) {
      width: 100%;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 100%;
      vertical-align: middle;
    }
  }
  &__img {
    max-width: 100%;
    width: 97%;
    vertical-align: middle;
  }
  &__description {
    padding-left: 68px;
    width: 60%;
    //margin-top: 25px;
    @media (max-width: $lg-down) {
      margin-top: 3px;
    }
    @media (max-width: 480px) {
      width: 100%;
      padding-left: 0 !important;
      text-align: center;
    }
  }
  &__name {
    color: #4185f4;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 14px;
    font-weight: 300;
    @media (max-width: $md-down) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  &__param {
    color: #515b6d;
    font-size: 18px;
    line-height: 35px;
    font-weight: 300;
    @media (max-width: $md-down) {
      font-size: 14px;
      line-height: 26px;

    }
  }
  &__info {
    width: 50%;
    display: flex;
    border-right: 1px solid rgba(65, 133, 244, .5);
    align-items: center;
    @media (max-width: $sm-down) {
      width: 100%;
      border: 0;
    }
    @media (max-width: 480px) {
      flex-direction: column;
    }
    .car__description {
      padding-left: 49px;
    }
  }
  &__actions {
    width: 50%;
    @media (max-width: $sm-down) {
      width: 100%;
      padding-left: 0;
    }
  }
  &__btn {
    width: 30px;
    height: 30px;
    background-color: #4185f4;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      transition: .2s all;
      background-color: #fd6555;
    }
    &--edit {
      top: 20px;
      left: 22px;
      background-image: url('/_images/svg/pencil-white.png');
    }
    &--close {
      top: 20px;
      left: 67px;
      background-image: url('/_images/svg/cross-white.svg');
    }
    &--select {
      background-color: transparent;
      top: 20px;
      left: 22px;
      background-image: url('/_images/svg/select_auto.svg');
      &:hover {
        background-color: #e7fbe7;
      }
    }
    .absolute-link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.actions {
  &__title {
    text-align: center;
    color: #000;
    font-size: 40px;
    line-height: 79px;
    font-weight: 300;
  }
  &__buttons {
    display: flex;
    margin-bottom: 29px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
  &__btn {
    position: relative;
    text-align: center;
    margin: 0 15px;
    height: 120px;
    width: 30%;
    border-radius: 5px;
    border: 2px solid #4185f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transition: all .2s;
    @media (max-width: 480px) {
      width: 100%;
      margin: 5px 0 !important;
    }

    &:hover {
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.43);
    }
    &.active {
      box-shadow: none;
      cursor: default;
    }
    &:hover, &.active {
      background-color: #4185f4;
      color: #fff;
      .actions__btn-text {
        color: #fff;
      }
      .actions__btn-icon {
        background-color: #fff !important;
      }

      .actions__btn-icon--history {
        background-image: url('/_images/svg/history-hover.svg');
      }
      .actions__btn-icon--note {
        background-image: url('/_images/svg/note-hover.svg');
      }
      .actions__btn-icon--orders {
        background-image: url('/_images/svg/repair-hover.svg');
      }
    }
  }
  &__btn-counter {
    position: absolute;
    top: -8px;
    right: -13px;
    width: 30px;
    height: 30px;
    background-color: #515b6d;
    border-radius: 50%;
    color: #ffffff;
    font-size: 12px;
    line-height: 30px;
    &:hover {
      transition: all .2s;
      background-color: #ff6451;
    }
  }
  &__btn-text {
    line-height: 18px;
    text-align: center;
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    @media (max-width: $md-down) {
      line-height: 12px;
      font-size: 9px;
      letter-spacing: normal;
    }
  }
  &__btn-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #4185f4;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    &--history {
      background-image: url('/_images/svg/history.svg');
    }
    &--note {
      background-image: url('/_images/svg/note.svg');
    }
    &--orders {
      background-image: url('/_images/svg/repair.svg');
    }
  }

}

.b-useful {
  display: flex;
  margin-bottom: 78px;
  ul {
    width: 25%;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      a {
        font-weight: 300;
        color: #4185f4;
        font-size: 18px;
        line-height: 42px;
      }
    }
  }
}

.manager {
  &__form {
    position: relative;
    input {
      width: 100%;
      height: 58px;
      border-radius: 3px;
      border: 1px solid #d0d7e2;
      background-color: #ffffff;
      line-height: 58px;
      padding: 0 20px;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #979DA7;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #979DA7;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #979DA7;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #979DA7;
      }

    }
    &:after {
      content: '';
      width: 19px;
      height: 20px;
      display: block;
      background-repeat: no-repeat;
      position: absolute;
      border: 0;
      top: 19px;
      right: 20px;
      cursor: pointer;
      z-index: 2;
      background-color: #fff;
      background-image: url('/_images/svg/search.svg');
    }
  }
  &__client {
    margin-top: 80px;
  }
  &__actions {

  }
  &__actions-btns {
    justify-content: space-between;
    margin-bottom: 47px;
    .actions__btn {
      cursor: pointer;
      margin: 0;
      height: 150px;
    }
  }
  &__forms {
    background-color: #f0f5f9;
    padding-bottom: 89px;
    padding-top: 48px;
    margin-bottom: -40px;
  }

  &__orders {
    border: 1px solid #d0d7e2;
    background-color: #ffffff;
    padding: 24px 32px;
  }
}

.m-table {
  &__head {
    display: flex;
    color: #515b6d;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  &__body {
    .m-table__place {
      padding: 0px;
    }
  }
  &__row {
    position: relative;
    display: flex;
    color: #515b6d;
    font-size: 14px;
    line-height: 41px;
    height: 41px;
    border-radius: 3px;
    border: 1px solid #d0d7e2;
    margin-bottom: 10px;
    padding: 0 15px;
    @media (max-width: $sm-down) {
      height: auto;
      padding: 6px 15px;
      line-height: 21px;
    }
    &:hover {
      transition: all .2s;
      border: 1px solid #4185f4;
      color: #4185f4;
      .m-table__printer {
        display: block;
      }
    }
  }
  &__time {
    width: 30%;
    user-select: none;
  }
  &__place {
    width: 70%;
    user-select: none;
  }
  &__no-order {
    text-align: center;
  }
  &__printer {
    cursor: pointer;
    display: none;
    position: absolute;
    width: 30px;
    border-radius: 50%;
    height: 30px;
    right: 10px;
    top: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #2ed672;
    background-image: url('/_images/svg/printer.svg');
  }
}

.client {
  &__name {
    color: #4185f4;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 15px;
  }
  &__phone {
    color: #000000;
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 49px;

  }
}

.personal {
  &__wrap {
    background-color: #f0f5f9;
    padding: 56px 0;
  }
  &__photo-wrap {
    @media (max-width: $md-down) {
      margin-bottom: 24px;
    }
  }
  &__photo {
    .no-photo {
      border: 2px solid #4185f4;
      background-color: #ffffff;
      display: inline-block;
      width: 142px;
      height: 142px;
      border-radius: 50%;
      background: url('/_images/svg/no-photo.svg') no-repeat center;
    }
  }
  &__soc-title {
    color: #515b6d;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  &__soc-wrap {

  }
  &__soc-item {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    margin-right: 16px;
    background: no-repeat center;
    &:last-child {
      margin-right: 0;
    }
    &.vkontakte {
      background-image: url('/_images/svg/vk.svg');
      &.active {
        background-image: url('/_images/svg/vk-icon.svg');
      }
    }
    &.facebook {
      background-image: url('/_images/svg/fb.svg');
      &.active {
        background-image: url('/_images/svg/fb-icon.svg');
      }
    }
    &.odnoklassniki {
      background-image: url('/_images/svg/ok.svg');
      &.active {
        background-image: url('/_images/svg/ok-icon.svg');
      }
    }
    &.googleoauth {
      background-image: url('/_images/svg/gplus.png');
      &.active {
        background-image: url('/_images/svg/gl-icon.svg');
      }
    }
    &.mail {
      background-image: url('/_images/svg/mail.svg');
      &.active {

      }
    }

    .close {
      bottom: -10px;
      right: -11px;
      transform: rotate(45deg);
      opacity: 1 !important;
      cursor: pointer;
      position: absolute;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #fd6557;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('/_images/svg/close-white.png');
    }
  }
  &__left {
    padding-top: 57px;
    text-align: left !important;
  }
  .add_photo {
    border: 2px solid #4185f4;
    line-height: 41px;
  }
  .change-pas {
    line-height: 41px;
    margin-top: 6px;
    width: 100%;
    border: 2px solid #4185f4;
    @media (max-width: $lg-down) {
      margin-top: 0;
    }
    &[disabled] {

      color: #ccc;
      border: 1px solid;
      &:hover {
        color: #ccc;
        background: initial;
      }
    }
  }

}

.history {
  &__car {
    margin-bottom: 51px;
    text-align: center;
    .jq-selectbox {
      width: 442px;
      @media (max-width: $sm-down) {
        width: 320px;
        margin-left: 0;
        margin-top: 10px;
      }
    }
    .jq-selectbox__select {
      border-color: transparent;
    }
    .jq-selectbox__trigger {
      border-color: transparent;
    }
    .jq-selectbox__select-text {
      color: #4185f4;
      font-size: 22px;
      top: 0;
      //line-height: 24px;
      position: relative;
      &:after {
        content: '';
        display: block;
        border-bottom: 1px dashed rgb(65, 133, 244);
        position: absolute;
        bottom: 12px;
        left: 3%;
        width: 95%;
      }
    }
  }
  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 98px;
    background-color: #f0f5f9;
    @media (max-width: $sm-down) {
      flex-direction: column;
      height: auto;
      padding: 10px 0;
    }
  }
  &__form {
    position: relative;
    font-size: 22px;
    font-weight: 300;
    @media (max-width: $md-down) {
      font-size: 16px;
    }
    @media (max-width: $sm-down) {
      font-size: 13px;
    }

    input {
      width: 116px;
      margin: 0 20px;
      padding: 0 19px;
      font-size: 14px;
      line-height: 58px;
      height: 58px;
      border-radius: 3px;
      border: 1px solid #d0d7e2;
      background-color: #ffffff;
      @media (max-width: $md-down) {
        width: 80px;
        margin: 0 6px;
        padding: 0 10px;
        font-size: 14px;
        line-height: 42px;
        height: 42px;
      }

    }
  }
  &__add-btn {
    margin-left: 97px;
    @media (max-width: $md-down) {
      margin-left: 30px;
    }
    @media (max-width: $sm-down) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  &__table {
    font-weight: 300;
    margin-bottom: 31px;
    margin-top: 51px;
    width: 100%;

    thead {
      background-color: #4185f4;
      tr {
        th {
          vertical-align: middle;
          padding: 24px;
          font-size: 18px;
          color: #ffffff;
          padding-left: 25px;
          @media (max-width: $md-down) {
            padding: 14px;
            font-size: 13px;
          }
        }
      }
    }
    tr {
      &.total-row {
        td {
          border: 0;
        }
      }
      td {
        color: #515b6d;
        font-size: 14px;
        line-height: 22px;
        padding: 23px 22px 14px 22px;
        border: 1px solid #ebeaf1;
        &.date {
          padding: 23px 15px 14px;
        }
        &.price {
          width: 21%;
        }
        &.master {
          width: 16%;
        }
        @media (max-width: $md-down) {
          font-size: 12px;
          line-height: 17px;
          padding: 9px 23px 4px 21px;
        }
        &.buttons {
          white-space: nowrap;
          text-align: center;
          vertical-align: middle;
          padding: 23px 14px 14px;
          &--full {
            white-space: normal;
            width: 19%;
            button, .button {
              display: block;
              width: 100%;
              margin: 10px 0;
            }
          }
        }
        .comment {
          background-color: #f0f5f9;
          color: #515b6d;
          font-size: 14px;
          line-height: 22px;
          padding: 17px;
          position: relative;
          margin-bottom: 12px;
          &:after {
            top: 100%;
            right: 15%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(240, 245, 249, 0);
            border-top-color: #f0f5f9;
            border-width: 13px;
            margin-left: -13px;
          }
        }
        .job {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 11px;
          }
          &__more {
            display: inline-block;
            color: rgb(65, 133, 244);
            font-size: 12px;
            line-height: 16px;
            border-bottom: 1px dashed rgb(65, 133, 244);
          }
        }
        .invoice_job {
          width: 22%;
        }
        .invoice_comment {
          width: 31%;
        }
      }
    }
    .inner {
      width: 100%;
      tr {
        &.total {
          td {
            padding-top: 19px;
          }
        }
        td {
          border: 0;
          padding: 0;
          &:first-child {
            font-weight: 400;
          }
          &:last-child {
            text-align: right;
            white-space: nowrap;
          }
        }
      }
    }
  }
  &__table-btn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 0;
    border-radius: 50%;
    margin: 0 3px;
    background-position: center;
    background-repeat: no-repeat;
    &.red {
      background-color: #ff6451;
      background-image: url('/_images/svg/close-white.png');
      transform: rotate(45deg);
    }
    &.blue {
      background-color: #4185f4;
      background-image: url('/_images/svg/pencil-white.svg');
    }
    &.green {
      background-color: #2ed672;
      background-image: url('/_images/svg/printer.svg');
    }
  }
}

.with-btn {
  li.sel {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 10px;
      right: 11px;
      width: 30px;
      height: 30px;
      background-color: #4185f4;
      border-radius: 50%;
      background-image: url('/_images/svg/service-ok.png');
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  li.btn-car {
    font-weight: 300;
    text-align: right;
    color: rgb(65, 133, 244);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    &:before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: #4185f4;
      border-radius: 50%;
      background-image: url('/_images/svg/plus.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin: 10px 4px 10px 0px;
      vertical-align: middle;
    }
  }
}

.select-map {
  border-left: 1px solid rgb(208, 215, 226);
  color: #4185f4;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  cursor: pointer;
  background: #fff;
  position: absolute;
  z-index: 999;
  right: 57px;
  top: 1px;
  height: 56px;
  width: 138px;
  padding-top: 15px;
  text-align: center;
  &__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  span {
    vertical-align: middle;
    display: inline-flex;
  }
  .marker {
    margin-right: 11px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    background-image: url('/_images/svg/place.svg');
    width: 16px;
    height: 23px;
  }
}

.address-map-select {
  padding: 50px 0;
  &__title {
    margin-bottom: 30px;
    text-align: center;
    color: #000000;
    font-size: 40px;
  }
  &__wrap {
    width: 90%;
    height: 550px;
    position: relative;
    margin: 0 auto;
    border: 1px solid #4185f4;
    @media (max-width: $lg-down) {
      height: 350px;
    }
  }
  &__area {
    width: 100%;
    height: 100%;
  }
}

.datepicker {
  .glyphicon.glyphicon-chevron-left {
    width: 6px;
    height: 9px;
    background-image: url(/_images/svg/calendar-arrow.svg);
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    &:before {
      display: none;
    }
  }
  .glyphicon.glyphicon-chevron-right {
    width: 6px;
    height: 9px;
    background-image: url(/_images/svg/calendar-arrow.svg);
    background-size: cover;
    background-repeat: no-repeat;
    &:before {
      display: none;
    }
  }
}

.form__input {
  &.active {
    .form__label--selected {
      display: block;
    }
    .form__label--unselected {
      display: none;
    }
  }
}

.car-styler {
  .jq-selectbox__dropdown {
    border: none;
    border-top: 1px solid #4185f4;
    li {
      &:hover {
        background-color: #f5f9fe;
      }

    }
  }
  &.opened {
    .jq-selectbox__select {
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.43);
    }
    .jq-selectbox__select-text:after {
      display: none;
    }
  }
}

.breadcrumb > li + li {
  &:before {
    content: "";
    padding: 0 15px;
    color: #ccc;
    width: 3px;
    height: 6px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAAx8TU7AAAAfklEQVQYV2MMjspxX7tsyk4GJMAYGhrK/IdN4uf6pZNZYOKMIIanZy47hzDDx/VLJnOA+GBBEAgNzeL5w8b8fP3SybxwQZBEYHTu4/+MDGVwwYDo3IeszEy2qxdNfAQWDIzOvcL071/42uVTr4LNDIjKSWZiZNy5bunkJzDzAcoOJnsr0MDkAAAAAElFTkSuQmCC');
  }
}

.breadcrumb {
  &-wrap {
    border-bottom: 1px solid #f3f3f3;
    @media (max-width: $sm-down) {
      display: none;
    }
  }
  margin: 0;
  padding: 0;
  display: inline-block;
  background: transparent;
  li {
    font-size: 12px;
    line-height: 58px;
    color: rgb(81, 91, 109);
    a {
      cursor: pointer;
      opacity: 0.5;
      color: rgb(81, 91, 109);
      &:hover {
        opacity: 1;
        transition: all .2s;
      }
    }
  }
  &__back {
    position: relative;
    cursor: pointer;
    margin-right: 22px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    color: rgb(65, 133, 244);
    font-size: 12px;
    letter-spacing: 1.71px;
    line-height: 58px;
    padding-right: 19px;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #515b6d;
      height: 54%;
      top: 12px;
      right: -1px;
    }
    &:before {
      content: '';
      position: relative;
      top: -2px;
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 8px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/_images/svg/back-arrow.svg')
    }
  }
}

.jq-selectbox__dropdown {
  //top: 57px !important;
}

.avatar {
  &__file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    opacity: 0;
    cursor: pointer;
  }
}

.service-main {
  .button {
    padding: 0 45px;
  }
}

.service-catalog {
  background: #f0f5f9;
  padding: 50px 0;
  &__item {
    display: block;
    text-align: center;
    color: #515b6d;
    font-size: 22px;
    box-shadow: 0 10px 18px rgba(65, 133, 244, 0.15);
    margin: 0 10px 50px;
    transition: all 0.5s;
    &-discr {
      background: #fff;
      padding: 30px 15px;
    }
    &-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @media (max-width: 768px) {
        white-space: initial;
        text-overflow: initial;
        overflow: initial;
      }
    }
    &-text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 300;
    }
    &-photo-wrap {
      height: 250px;
      overflow: hidden;
      border-bottom: 1px solid #f0f5f9;
    }
    &-photo {
      height: 100%;
      transform: scale(1.2);
      background: #fff no-repeat center;
      background-size: cover;
      transition: all 0.5s;
      @media (max-width: $md-down) {
        transform: none;
      }
      &.-no {
        background-image: url("/_images/svg/service-no-photo.svg");
        background-size: 20%;
      }
    }
    &:hover {
      .service-catalog__item-photo {
        transform: none;
        /*      transform: scale(1.05);
              box-shadow: 0 0 50px rgba(65, 133, 244, 0.15);*/
      }

    }
  }
}

.best-service {
  padding: 50px 0;
  h2 {
    margin-bottom: 70px;
  }
  &__photo {
    width: 100%;
    position: relative;
    &-small {
      position: absolute;
      max-height: 72%;
      bottom: -2%;
      left: 58%;
    }
  }
  &__item {
    position: relative;
    margin-bottom: 60px;
    padding-left: 120px;
    &-title {
      color: #000000;
      font-size: 22px;
      line-height: 36px;
      font-weight: 300;
      @media (max-width: $xs-down) {
        font-size: 18px;
      }
    }
    &-text {
      color: #515b6d;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: $xs-down) {
        font-size: 14px;
      }
    }
    &.-icon {
      &:after {
        content: '';
        position: absolute;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #f0f5f9 no-repeat center;
        background-size: 40%;
        top: 0;
        left: 20px;
        @media (max-width: $xs-down) {
          display: none;
        }
      }
      &.-order {
        &:after {
          background-image: url("/_images/service/svg/order.svg");
          background-size: 50%;
        }
      }
      &.-hist {
        &:after {
          background-image: url("/_images/service/svg/history.svg");
        }
      }
      &.-guaranty {
        &:after {
          background-image: url("/_images/service/svg/medal.svg");
        }
      }
      &.-watch {
        &:after {
          background-image: url("/_images/service/svg/eye.svg");
          background-size: 45%;
        }
      }
    }
    &--btn {
      margin-top: -20px;
      margin-bottom: 0;
    }
    @media (max-width: $sm-down) {
      margin: 30px auto;
    }
    @media (max-width: $xs-down) {
      margin: 20px auto;
      padding-left: 0;
      text-align: center;
    }
  }
}

.service-map {
  &__container {
    height: 500px;
  }
}

.slider-service {
  position: relative;
  height: 700px;
  background-color: #444;
  @media (max-width: $md-down) {
    margin-top: 59px;
    height: 50vh;
  }
  @media (max-width: $sm-down) {
    margin-top: 85px;
    max-height: 203px;
  }
  &__slider {
    height: 100%;
    z-index: 100;
    .slick-list,
    .slick-track {
      height: 100%;
    }
    .slick-dots {
      display: block;
      text-align: center;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      li {
        display: inline-block;
        margin: 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #d8d8d8;
        button {
          opacity: 0;
        }
        &.slick-active {
          background-color: #515b6d;
        }
      }
    }

  }
  &__item {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .3);
    }
    .container {
      position: relative;
      z-index: 100;
      height: 100%;
    }
    &.slick-active {
      .slider-service__content {
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.7s linear;
      }
    }
  }
  &__content {
    position: absolute;
    left: 8px;
    bottom: 142px;
    transform: translateY(200%);
    opacity: 0;
    @media (max-width: $md-down) {
      bottom: 75px;
    }
    @media (max-width: $sm-down) {
      bottom: 55px;
      text-align: center;
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 22px;
    color: #fff;
    font-size: 72px;
    line-height: 82px;
    @media (max-width: $md-down) {
      margin-bottom: 15px;
      font-size: 36px;
      line-height: 44px;
    }
    @media (max-width: $sm-down) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  &__text {
    font-weight: 100;
    margin-bottom: 62px;
    color: #fff;
    opacity: 0.8;
    font-size: 18px;
    line-height: 30px;
    @media (max-width: $md-down) {
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 22px;
    }
    @media (max-width: $sm-down) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  &__link {
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    transition: all .3s;
    padding: 15px 55px;
    border: 2px solid #fff;
    border-radius: 5px;
    @media (max-width: $md-down) {
      font-size: 10px;
      letter-spacing: 1.43px;
    }
    @media (max-width: $sm-down) {
      /*      display: none;*/
    }
    &:hover {
      color: #4185f4;
      background-color: #fff;
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.5);
    }

  }

}

.service-detail-text {
  padding: 40px 10px;
  background: #f0f5f9;
  color: #515b6d;
  font-size: 16px;
  line-height: 24px;
  &__title {

    color: #000000;
    font-size: 50px;
    line-height: 82px;
  }
  &__sub-title {
    text-align: left;
    color: #000000;
    font-size: 30px;
    line-height: 82px;
  }
  &__list {
    padding-left: 25px;
    li {
      position: relative;
      line-height: 28px;
      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        margin-top: -3px;
        border-radius: 50%;
        background-color: #4185f4;
        left: -20px;
        top: 50%;

      }
    }
  }
}

.service-price {
  padding: 40px 10px;
  table {
    width: 100%;
    color: #515b6d;
    font-size: 16px;
    text-align: center;
    border-collapse: collapse;
    tr {
      transition: height 0.5s;
    }
    td {
      padding: 20px 0;
    }
    tbody {
      td {
        border: 1px solid #ebeaf1;
      }
    }
  }
  &__name {
    text-align: left;
    padding-left: 30px !important;
  }
  &__value {
    color: #4185f4;
    font-size: 14px;
    line-height: 22px;
  }
  &__additition-row {
    display: none;
  }
  &__show-more {
    margin-top: 25px;
    padding-left: 40px;
    position: relative;
    cursor: pointer;
    color: #4185f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    &:before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      border-radius: 50%;
      top: 50%;
      left: 0;
      background: #4185f4 no-repeat center;
      background-image: url('/_images/svg/arrow-down-white.svg');
    }
    &.opened {
      &:before {
        transform: rotate(180deg);
        /*background-image: url('/_images/svg/arrow-up-white.svg');*/
      }
    }
    &.-right {
      float: right;
    }
  }
}

.service-sert {
  padding: 40px 10px;
  &__slider {
    padding: 15px;
    margin: 0 50px;
    box-shadow: 0 0 91px 3px rgba(65, 133, 244, 0.36);
    border-radius: 3px;
    background-color: #ffffff;
    .slick-arrow {
      outline: none;
      position: absolute;
      width: 30px;
      height: 24px;
      margin-top: -12px;
      top: 50%;
      background: transparent no-repeat center;
      border: none;
      font-size: 0;
      &.slick-prev {
        left: -50px;
        background-image: url("/_images/svg/arrow-left-blue.svg");
        &:hover {
          background-image: url("/_images/svg/arrow-left-blue-hover.svg");
        }
      }
      &.slick-next {
        right: -50px;
        background-image: url("/_images/svg/arrow-right-blue.svg");
        &:hover {
          background-image: url("/_images/svg/arrow-right-blue-hover.svg");
        }
      }

    }
    &-item {
      display: block;
      margin: 0 5px;
      img {
        width: 100%;
      }
    }
  }
}

.service-banner {
  position: relative;
  height: 430px;
  padding: 80px 60px;
  background: #ddd no-repeat center;
  background-size: cover;
  border: 1px solid #ccc;
  margin: 70px auto;
  @media (max-width: $md-down) {
    /*margin-top: 59px;*/
    height: 350px;
  }
  @media (max-width: $sm-down) {
    /*margin-top: 85px;*/
    padding: 40px 30px;
    max-height: 203px;
  }

  &__title {
    margin-bottom: 22px;
    color: #fff;
    font-size: 50px;
    line-height: 60px;
    @media (max-width: $md-down) {
      margin-bottom: 15px;
      font-size: 36px;
      line-height: 44px;
    }
    @media (max-width: $sm-down) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  &__text {
    font-weight: 100;
    margin-bottom: 62px;
    color: #fff;
    font-size: 24px;
    @media (max-width: $md-down) {
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 22px;
    }
    @media (max-width: $sm-down) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  &__link {
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    transition: all .3s;
    padding: 15px 55px;
    border: 2px solid #fff;
    border-radius: 5px;
    @media (max-width: $md-down) {
      font-size: 10px;
      letter-spacing: 1.43px;
    }
    @media (max-width: $sm-down) {
      /*      display: none;*/
    }
    &:hover {
      color: #4185f4;
      background-color: #fff;
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.5);
    }

  }

}

.order-pdf {
  width: 992px;

  &__header {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    background-color: #5881f4;
    padding: 10px 20px;
    &-logo {
      display: inline-block;
      padding: 15px;
      border-right: 1px solid;
    }
    &-img {
      display: block;
      width: 90%;
      margin-bottom: 10px;
    }
    &-contacts {
      position: relative;
      float: right;
      line-height: 82px;
      vertical-align: top;
      text-align: right;
      span {
        display: inline-block;
        margin: 0 10px;
      }
    }
    &-address {
      position: relative;
      position: relative;
      padding-left: 40px;
      &:before {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        margin-top: -15px;
        left: 0;
        top: 50%;
        background: no-repeat center;
        background-size: contain;
        background-image: url("/_images/svg/marker.svg") !important;
      }
    }
    &-phone {
      position: relative;
      padding-left: 30px;
      &:before {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        margin-top: -10px;
        left: 0;
        top: 50%;
        background: no-repeat center;
        background-size: contain;
        background-image: url("/_images/svg/phone.svg");
      }
    }
  }

  &__content {
    font-weight: 300;
    padding: 20px 40px;
    &-title {
      text-align: center;
      font-size: 40px;
      margin: 0 auto 15px;
      line-height: 50px;
    }
    &-main-img {
      margin: 10px auto;
      text-align: center;
      img {
        width: 70%;
      }

    }
    &-text {
      text-align: center;
      font-size: 20px;
      margin: 0 auto 15px;
      line-height: 30px;
    }
    &-table {
      &-head {
        height: 44px;
        &-col {
          box-sizing: border-box;
          float: left;
          &:nth-of-type(1) {
            width: 60%;
          }
          &:nth-of-type(2) {
            width: 20%;
          }
          &:nth-of-type(3) {
            width: 20%;
          }
        }
      }
      line-height: 44px;
      &-row {
        height: 44px;
        border: 1px solid #ccc;
        border-radius: 5px;
        border-bottom: none;
        &:last-of-type {
          border-bottom: 1px solid #ccc;
        }
      }
      &-col {
        padding: 0 10px;
        box-sizing: border-box;
        height: 100%;
        float: left;
        &:nth-of-type(1) {
          width: 60%;
        }
        &:nth-of-type(2) {
          width: 20%;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
        &:nth-of-type(3) {
          width: 20%;
        }
      }
    }
    &-parts {
      padding: 15px 0;
      color: #555;
      &-checkbox {
        position: relative;
        padding-left: 40px;
        margin-left: 20px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 26px;
          height: 26px;
          margin-top: -13px;
          border: 1px solid #ccc;
          border-radius: 5px;

        }
      }
    }

    &-confirm {
      text-align: justify;
      font-size: 12px;
      margin-bottom: 15px;
    }
  }

  &__form {
    &-item {
      overflow: hidden;
      position: relative;
      display: inline-block;
      vertical-align: top;
      padding: 10px;
      height: 64px;
      margin-bottom: 15px;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-sizing: border-box;
      &.-left {
        width: 600px;
        margin-right: 15px;
      }
      &.-right {
        width: 288px;
      }
      &.-half {
        width: 447px;
        &:nth-of-type(1) {
          margin-right: 14px;
        }
      }
    }
    &-label {
      position: absolute;
      font-weight: 300;
      top: 10px;
      left: 10px;
      color: #bbb;
    }
    &-text {
      position: relative;
      top: 22px;
    }
    &-date {
      position: absolute;
      font-weight: 300;
      bottom: 10px;
      right: 10px;
      color: #545b6d;
    }
  }

  &__footer {
    color: #fff;
    font-family: Roboto;
    font-size: 12px;
    background-color: #545b6d;
    padding: 10px 20px;
    line-height: 16px;
    text-align: center;
  }

}

