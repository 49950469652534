html, body {
  backface-visibility: hidden;
}

a :active, a:focus, a:hover {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
  color: #000;
  margin-bottom: 18px;
  margin-top: 47px;
  text-align: center;
  @media (max-width: $sm-down) {
    font-size: 30px;
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

h2 {
  margin-bottom: 30px;
  color: #000;
  font-weight: 300;
  font-size: 50px;
  text-align: center;
  @media (max-width: $md-down) {
    font-size: 30px;
  }
}

h3 {
  margin-bottom: 30px;
  color: #000;
  font-weight: 300;
  font-size: 25px;
  text-align: center;
}

h4 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
  color: #000;
  margin-bottom: 25px;
  text-align: center;
  @media (max-width: $md-down) {
    font-size: 20px;
    line-height: 26px;
  }
}

@keyframes animation-opacity-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation-opacity-in {
  animation: animation-opacity-in 2s forwards;
}

.container {
  @media (max-width: $sm-down) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

* {
  outline: none !important;
}

.error {
  border: 1px solid #eb0c0c !important;
  background: url("/_images/svg/warn.svg") no-repeat 95% center;
}

/*Так как в bootstrap 3 нет .visible-<pref>-flex*/
@media (max-width: 767px) {
  .visible-xs-flex {
    display:flex !important
  }
}
@media (min-width: 768px) and (max-width:991px) {
  .visible-sm-flex {
    display:flex !important
  }
}
@media (min-width: 992px) and (max-width:1453px) {
  .visible-md-flex {
    display:flex !important
  }
}
@media (min-width: 1454px) {
  .visible-lg-flex {
    display:flex !important
  }
}
