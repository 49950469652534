.tooltip {
  background: #fff;
  padding: 15px 20px 12px;
  box-shadow: 0px 2px 33px rgba(65, 133, 244, 0.355044);
  position: absolute;
  font-weight: 300;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  color: #515B6D;
  opacity: 1;
  display: flex;
  align-items: baseline;
  justify-content: center;
  &::before {
    content: '';
    display: block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #fff;
  }

  &-target {
    cursor: pointer;
  }
}
