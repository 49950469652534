.result {
  margin-bottom: 180px;
  &__button {
    margin-top: 50px;
    .button:hover {
      box-shadow: 0 11px 23px rgba(65,133,244,.43);
    }
    a {
      margin: 0 8px;
    }
    .button {
      border-width: 2px;
    }
  }
  &__paragraph {
    margin-top: 30px;
  }
  @media (max-width: $sm) {
    margin-bottom: 40px;
    &__paragraph {
      margin-top: 0;
    }
    &__button {
      margin-top: 20px;
      a {
        margin: 8px 0;
      }
    }
  }
  @media (max-width: $xs) {
    .container {
      padding: 0;
    }
    .button-blue {
      padding: 0 15px;
    }
  }
}