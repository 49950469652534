.product-card {
  @include ap(padding, 10px 10px 35px, 10px 10px 40px, 12px 14px 40px, 12px 14px 25px);
  box-sizing: border-box;
  border: 1px solid rgba(208, 215, 226, 0.5);
  position: relative;

  &__header {
    display: flex;
    @include ap(margin, -10px -10px 5px -10px, -10px -10px 5px -10px, -15px -14px 5px, -15px -14px 5px);
    @include ap(padding, 10px 10px 10px, 10px 10px 14px, 12px 15px 14px, 12px 15px 0px);
    width: 100%;
    @include ap(height, 100%, 100%, 100%, unset);
    @include ap(flex-direction, column-reverse, column-reverse, column-reverse, row);
    justify-content: space-between;
    position: absolute;
    user-select: none;
    pointer-events: none;
  }
  &__status {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    height: 16px;
    @include ap(font-size, 12px, 12px, 13px, 13px);
    @include ap(padding-left, 15px, 15px, 15px, 20px);
    @include ap(padding-bottom, 16px, 16px, 16px, 0);
    position: relative;
    text-transform: lowercase;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      background: {
        size: 100%;
        repeat: no-repeat;
      };
      @include ap(left, 2px, 2px, 3px, 6px);
      top: 7px;
    }
    &.in-stock {
      color: #2ED672;
      &::before {
        background-image: url('/_images/svg/item-status-in_stock.svg');
      }

    }
    &.few {
      color: #FF6451;
      &::before {
        background-image: url('/_images/svg/item-status-few.svg');
      }
    }
    &.custom {
      color: #515B6D;
      &::before {
        background-image: url('/_images/svg/item-custom.svg');
      }
    }

  }
  &__proposal {
    position: absolute;
    @include ap(right, 0, -2px, -2px, -2px);
    top: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    @include ap(justify-content, center, center, flex-start, flex-start);
    @include ap(height, 160px, 200px, auto, auto);
    @include ap(align-items, center, center, flex-end, flex-end);
    & > div:not(:only-of-type).product-card__warranty {
      @include ap(margin, 15px 0px 10px, 15px 0px 10px, 5px 0px, 15px 0px 10px);
    }
    & > div:only-of-type.product-card__warranty {
      @include ap(margin-top, 10px, 10px, 10px, 20px);
    }
  }
  &__discount {
    @include ap(display, none !important, none !important, block, block);
    width: 50px;
    height: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #fff;

    background: {
      image: url('/_images/svg/item-discount.svg');
      size: 100%;
      repeat: no-repeat;
    }
    &::after {
      content: '%';
    }
    &::before {
      content: '-';
    }
  }
  &__warranty {
    font-style: normal;
    font-weight: normal;
    @include ap(font-size, 12px, 12px, 13px, 13px);
    @include ap(text-align, center, center, right, right);
    color: #fff;
    @include ap(width, 94%, 94%, 94%, 94%);
    max-width: 171px;
    @media (max-width: $sm-down) {
      margin: 0 -5px 0 -5px !important;
    }
    @media (min-width: $sm) and (max-width: $md-down) {
      margin: 62% auto !important;
    }
    height: 24px;
    line-height: 24px;
    @include ap(padding-right, 0, 0, 11px, 11px);
    @include ap(background-image, url('/_images/svg/item-warranty-2.svg'), url('/_images/svg/item-warranty-2.svg'), url('/_images/svg/item-warranty.svg'), url('/_images/svg/item-warranty.svg'));
    background: {
      size: 100%;
      repeat: no-repeat;
    }

  }
  &__body {
    @include ap(margin-top, 3px, 10px, 25px, 30px);
    padding: 0px 4px;
    width: 100%;
    form .form__button {
      @include ap(padding-top, 3px !important, 3px !important, 3px !important, 23px);
      @include ap(display, none, none, block, block);
    }
  }
  &__img {
    @include ap(height, 120px, 160px, 180px, 210px);
    @include ap(width, 90%, 85%, 90%, 75%);
    margin: auto;
    background: {
      size: contain;
      repeat: no-repeat;
      position: center;
    }
  }
  &__name {
    @include ap(font-size, 14px, 15px, 16px, 18px);
    @include ap(line-height, 18px, 20px, 22px, 25px);
    font-weight: 300;
    @include ap(height, 32px, 55px, 60px, 68px);
    overflow: hidden;
    display: flex;
    @include ap(align-items, baseline, baseline, baseline, center);
    color: #000;
    @include ap(margin-top, 12px, 20px, 20px, 20px);
    cursor: pointer;
    &:hover {
      color: #969696;
    }
    &.overflowed {
      position: relative;
      &::after {
        content: '...';
        position: absolute;
        bottom: -3px;
        right: 3px;
      }
    }
  }
  &__props {
    @include ap(margin-top, 0px, 20px, 20px, 13px);
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: $sm-down) {
      position: absolute;
      top: 5px;
      right: 2px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__prop {
    display: flex;
    @include ap(justify-content, flex-end, space-between, space-between, space-between);
    width: 100%;
    font-weight: 300;
    line-height: 25px;
    font-size: 14px;
    color: #515B6D;
    &-name {
      @media (max-width: $sm-down) {
        display: none;
      }
    }
    &-value {
      @media (max-width: $sm-down) {
        font-size: 0px !important;
      }

      &[data-icon] {
        position: relative;
        &::before {
          position: absolute;
          top: 4px;
          left: -22px;
          content: '';
          height: 16px;
          width: 16px;
          display: block;
          padding-right: 10px;
          background: {
            size: 100%;
            repeat: no-repeat;
            position: center;
          }
        }
      }

      &[data-icon="winter"]::before {
        background-image: url('/_images/svg/item-season-winter.svg');
      }
      &[data-icon="summer"]::before {
        background-image: url('/_images/svg/item-season-summer.svg');
      }
      &[data-icon="all"]::before {
        background-image: url('/_images/svg/item-season-all.svg');
      }
    }
  }
  &__footer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    & > .counter {
      @include ap(display, none, none, none, flex);
    }
  }
  &__price {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .value {
      @include ap(font-size, 20px, 22px, 24px, 26px);
      color: #515B6D;
    }
    .old {
      font-size: 14px;
      color: #515B6D;
      opacity: 0.5;
      text-decoration: line-through;
      @include ap(padding-bottom, 20px, 20px, 20px, 8px);
      position: absolute;
      top: 0px;
      transform: translateY(-50%);
    }
  }
  &__btn {
    margin-top: 9px;
    color: #fff;

    &.-pc_buy {
      text-indent: 27px;
      background: {
        image: url('/_images/svg/shopping-cart.svg');
        size: 16px;
        repeat: no-repeat;
        position: 23px 15px;
      }
    }
    &.-pc_order {
      padding-left: 47px;
      padding-right: 47px;
    }

    user-select: none;
  }

  &.readonly {
    padding: 15px 15px !important;
    .product-card__warranty {
      display: none;
    }
    .product-card__status {
      display: none;
    }
    .product-card__body {
      margin-top: 5px;
    }
    .product-card__name {
      font-size: 14px;
      line-height: 18px;
      height: 68px;
      align-items: baseline;
      margin-top: 12px;
    }
    .product-card__img {
      height: 140px;
    }
    .product-card__price {
      .value {
        font-size: 18px;
      }
      .old {
        font-size: 14px;
        padding-bottom: 22px;
      }
    }
    .product-card__footer {
      .counter {
        display: none;
      }
    }
    .form__button {
      display: none;
    }

    @media (max-width: $sm) {
      & {
        padding: 0px!important;
        margin-bottom: 20px;
        border: none;
      }
      .product-card__header {
        margin: 0px !important;
      }
      .product-card__body {
        margin: 0px !important;
      }
      .product-card__img {
        height: 118px;
        width: 118px;
        border: 1px solid rgba(208,215,226,.5);
        margin: 0px !important;
      }
      .product-card__name {
        margin-top: 16px;
        font-size: 14px;
        line-height: 18px;
        height: 38px;
      }
      .product-card__price {
        display: none;
      }
      &:not(:first-of-type) {
        margin-left: 16px;
      }
      &:last-of-type::after {
        content: '';
        width: 16px;
        height: 1px;
        display: block;
        position: absolute;
        right: -16px;
      }
      .product-card__discount {
        display: block;
        position: absolute;
        top: 0;
        right: -1px;
      }
    }
  }
}