.promo {
  position: relative;
  height: 100vh;
  background-color: #444;
  @media (max-width: $md-down) {
    margin-top: 59px;
    height: 50vh;
  }
  @media (max-width: $sm-down) {
    margin-top: 85px;
    max-height: 203px;
  }
  &__slider {
    height: 100%;
    z-index: 100;
    .slick-list,
    .slick-track {
      height: 100%;
    }
  }
  &__item {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    /*    @media (min-width: $sm) {
          background-image: none !important;
        }*/
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      // http://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.46+0,0+100
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.46+0,0+100 */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.46) 0%, rgba(125, 185, 232, 0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.46) 0%, rgba(125, 185, 232, 0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.46) 0%, rgba(125, 185, 232, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#75000000', endColorstr='#007db9e8', GradientType=0); /* IE6-9 */
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .3);
    }
    .container {
      position: relative;
      z-index: 100;
      height: 100%;
    }
    &.slick-active {
      .promo__content {
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.7s linear;
      }
    }
  }
  &__content {
    position: absolute;
    left: 8px;
    bottom: 142px;
    transform: translateY(200%);
    opacity: 0;
    @media (max-width: $md-down) {
      bottom: 75px;
    }
    @media (max-width: $sm-down) {
      bottom: 55px;
    }
  }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    display: block;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: $sm-down) {
      display: none;
    }
  }
  &__title {
    margin-bottom: 62px;
    color: #fff;
    font-size: 72px;
    line-height: 82px;
    @media (max-width: $md-down) {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 44px;
    }
    @media (max-width: $sm-down) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  &__link {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all .3s;
    @media (max-width: $md-down) {
      font-size: 10px;
      letter-spacing: 1.43px;
    }
    @media (max-width: $sm-down) {
      display: none;
    }
    &:hover {
      color: #4185f4;
      svg {
        use {
          stroke: #4185f4;
        }
      }
    }
    svg {
      margin-left: 15px;
      transform: translateY(1px);
      transition: all .3s;
      @media (max-width: $md-down) {
        width: 5px;
        height: 9px;
      }
    }
  }
  &__pagination {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .container {
      position: relative;
      height: 100%;
      @media (max-width: $md-down) {
        position: static;
      }
    }
  }
  &__pagination-wrap {
    position: absolute;
    z-index: 100;
    width: 40px;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    text-align: center;
    transition: all .5s;
    @media (max-width: $md-down) {
      width: 100px;
      top: auto;
      right: 35px;
      bottom: 20px;
    }
    @media (max-width: $sm-down) {
      width: auto;
      left: 8px;
      right: 8px;
    }
  }
  &__buttons {
    .slick-arrow {
      position: absolute;
      left: 0;
      width: 40px;
      height: 40px;
      border: none;
      background: none;
      font-size: 0;
      outline: none;
      &:hover {
        &:before {
          opacity: 0.69;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;
        box-shadow: 0 1px 22px #4185f4;
        background-color: #000;
        border-radius: 50%;
        opacity: 0;
        transition: all .3s;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;
        background: transparent url('/_images/svg/promo-arrow.svg') center no-repeat;
      }
      &.slick-prev {
        top: -33px;
        transform: rotate(-90deg);
        @media (max-width: $md-down) {
          float: left;
          position: static;
          transform: rotate(-180deg);
        }
      }
      &.slick-next {
        bottom: -33px;
        transform: rotate(90deg);
        @media (max-width: $md-down) {
          float: right;
          position: static;
          transform: rotate(0);
        }
      }
    }
  }
  &__dots {
    @media (max-width: $md-down) {
      display: none;
    }
    .slick-dots {
      li {
        display: block;
        &.slick-active {
          button {
            opacity: 1;
          }
        }
        &:first-child {
          button {
            margin-top: 20px;
          }
        }
        &:last-child {
          button {
            margin-bottom: 20px;
          }
        }
        button {
          position: relative;
          margin: 8px 0;
          width: 40px;
          height: 40px;
          border: none;
          background: none;
          opacity: 0.5;
          color: #fff;
          font-size: 20px;
          outline: none;
          transition: all .3s;
          &:hover {
            opacity: 1;
            &:before {
              opacity: 0.69;
            }
          }
          &:before {
            content: '';
            position: absolute;
            z-index: -1;
            width: 40px;
            height: 40px;
            top: 50%;
            left: 50%;
            margin-left: -20px;
            margin-top: -20px;
            box-shadow: 0 1px 22px #4185f4;
            background-color: #000;
            border-radius: 50%;
            opacity: 0;
            transition: all .3s;
          }
        }
      }
    }
  }
  &__scroll {
    position: absolute;
    z-index: 100;
    bottom: 10px;
    left: 50%;
    margin-left: -20px;
    opacity: 0;
    animation-delay: 1s;
    @media (max-width: $md-down) {
      display: none;
    }
  }
}

.special {
  padding: 0 0 51px;
  &--main {
    padding-top: 46px;
  }
  &__nav {
    margin-bottom: 45px;
    border-bottom: 1px solid #ebeaf1;
  }
  &__nav-list {
    text-align: center;
  }
  &__nav-item {
    display: inline-block;
    position: relative;
    margin: 0 20px;
    &.active {
      .special__nav-link {
        &:after {
          width: 100%;
        }
      }
    }
  }
  &__nav-link {
    display: block;
    padding-bottom: 14px;
    color: #515b6d;
    font-size: 20px;
    transition: all .3s;
    @media (max-width: $lg-down) {
      font-size: 16px;
    }
    &:hover {
      color: #6d97f2;
      &:after {
        left: 0;
        width: 100%;
        background-color: #1f61ed;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      width: 0px;
      height: 3px;
      background-color: #515b6d;
      transition: all .3s;
    }
  }
  &__tab-item {
    display: none;
    &.active {
      display: block;
    }
  }
  &__item {
    position: relative;
    float: left;
    margin-bottom: 33px;
    padding-top: 69px;
    padding-right: 38px;
    padding-bottom: 54px;
    width: 50%;
    border-right: 1px solid #ebeaf1;
    @media (max-width: $md-down) {
      margin-bottom: 21px;
      padding: 0 0 28px 0;
    }
    @media (max-width: $sm-down) {
      margin-bottom: 0;
      padding: 0 0 28px 0 !important;
      width: 100%;
      border-right: none !important;
    }
    &.special__item--even {
      padding-left: 38px;
      padding-right: 0;
      border-right: 0;
    }
  }
  &__image {
    text-align: center;
    margin-bottom: 30px;
    @media (min-width: $md) {
      float: right;
      width: 200px;
      margin-bottom: 0;
    }
    @media (min-width: $lg) {
      width: 280px;
    }
    img {
      max-width: 100%
    }
  }
  &__title {
    margin-bottom: 20px;
    color: #515b6d;
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
    @media (min-width: $md) {
      margin-right: 230px;
    }
    @media (min-width: $lg) {
      margin-right: 330px;
    }
    @media (min-width: $sm) and (max-width: $md-down) {
      height: 64px;
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: $sm-down) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__text {
    margin-bottom: 28px;
    color: #515b6d;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    @media (min-width: $md) {
      margin-right: 230px;
    }
    @media (min-width: $lg) {
      margin-right: 330px;
    }
    @media (min-width: $sm) and (max-width: $md-down) {
      height: 88px;
    }
  }
  &__link {
    color: #4185f4;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    &:hover {
      color: #1254ea;
      .icon {
        background-image: url('/_images/svg/arrow-right-hover.svg');
      }
    }
    .icon {
      margin-left: 15px;
      transform: translateY(2px);
    }
  }
}

.splitter {
  clear: both;
  width: 100%;
  height: 1px;
  background: #ebeaf1;
  margin-bottom: 33px;
  @media (max-width: $md-down) {
    margin-bottom: 21px;
  }
  @media (max-width: $sm-down) {
    margin-bottom: 32px;
  }
}

.clients {
  padding: 46px 0 51px;
  background-color: #f0f5f9;
  &__text {
    margin-bottom: 50px;
    color: #515b6d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    @media (max-width: $md-down) {
      font-size: 14px;
      line-height: 22px;
    }
    @media (max-width: $xs-down) {
      text-align: left;
    }
  }
  &__container {
    margin: 0 -8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    @media (max-width: $md-down) {
      flex-wrap: wrap;
    }
    @media (max-width: $xs-down) {
      margin: 0 -10px 20px;
    }
  }
  &__item {
    padding: 0 8px;
    @media (max-width: $md-down) {
      flex-basis: 50%;
    }
    @media (max-width: $xs-down) {
      padding: 0 7px;
    }
    &:nth-of-type(odd) {
      .clients__item-wrap {
        @media (max-width: $md-down) {
          float: right;
        }
      }
    }
  }
  &__item-wrap {
    margin-bottom: 10px;
    height: 224px;
    width: 224px;
    flex-shrink: 0;
    @media (max-width: $xs-down) {
      margin-bottom: 13px;
      height: 138px;
      width: 138px;
    }
  }
  &__link {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 32px;
    height: 100%;
    border: 1px solid rgba(65, 133, 244, 0.36);
    background-color: #fff;
    transition: all .3s;
    &:hover {
      border-color: transparent;
      box-shadow: 0 0 150px 12px rgba(65, 133, 244, 0.36);
      border-radius: 3px;
      transform: scale(1.1);
      z-index: 200;
    }
    @media (max-width: $xs-down) {
      padding-bottom: 16px;
    }
  }
  &__image {
    position: absolute;
    left: 50%;
    top: 41%;
    transform: translate(-50%, -50%);
    @media (max-width: $xs-down) {
      top: 32%;
      transform: translate(-50%, -50%) scale(.8);
    }
  }
  &__title {
    color: #4185f4;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    backface-visibility: hidden;
    @media (max-width: $xs-down) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.loyalty {
  padding: 46px 0 51px;
  &__text {
    margin-bottom: 50px;
    color: #515b6d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    @media (max-width: $md-down) {
      font-size: 14px;
      line-height: 22px;
    }
    @media (max-width: $sm-down) {
      text-align: left;
    }
  }
  &__nav {
    margin-bottom: 45px;
    border-bottom: 1px solid #ebeaf1;
    @media (max-width: $sm-down) {
      border: none;
    }
  }
  &__nav-list {
    text-align: center;
  }
  &__nav-item {
    display: inline-block;
    position: relative;
    margin: 0 20px;
    @media (max-width: $sm-down) {
      display: block;
      padding: 0;
      margin: 0 0 15px;
    }
    &.active {
      .loyalty__nav-link {
        &:after {
          width: 100%;
        }
      }
      @media (max-width: $sm-down) {
        .loyalty__nav-link {
          background-color: #2ed672;
          border-color: transparent;
          color: #fff;
        }
      }
    }
  }
  &__nav-link {
    display: block;
    padding-bottom: 14px;
    color: #515b6d;
    font-size: 20px;
    transition: all .3s;
    @media (max-width: $lg-down) {
      font-size: 16px;
    }
    @media (max-width: $sm-down) {
      display: inline-block;
      border-radius: 5px;
      border: 1px solid rgba(81, 91, 109, 0.3);
      padding: 0 15px;
      height: 32px;
      line-height: 30px;
    }
    &:hover {
      color: #6d97f2;
      &:after {
        left: 0;
        width: 100%;
        background-color: #1f61ed;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      width: 0px;
      height: 3px;
      background-color: #515b6d;
      transition: all .3s;
      @media (max-width: $sm-down) {
        display: none;
      }
    }
  }
  &__tab-item {
    display: none;
    &.active {
      display: block;
    }
  }
  &__item {
    position: relative;
    @media (max-width: $sm-down) {
      margin-left: -24px;
      margin-right: -24px;
    }

  }
  &__image {
    width: 100%;
    max-width: 100%;
  }
  &__button {
    display: inline-block;
    position: absolute;
    left: 73px;
    bottom: 47px;
    padding: 0 24px;
    height: 41px;
    border-radius: 3px;
    border: 2px solid #fff;
    line-height: 39px;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    transition: all .3s;
    @media (max-width: $sm-down) {
      left: 30px;
      bottom: 39px;
      height: 36px;
      line-height: 34px;
      font-size: 13px;
    }
    &:hover {
      box-shadow: 0 11px 23px rgba(65, 133, 244, 0.5);
      background-color: #fff;
      color: #4185f4;
    }
  }
  &--inner {
    padding: 0;
  }
}

/* Partners page */

.button-blue {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  outline: none;
  border: 0;
  cursor: pointer;
  color: #fff;
  height: 45px;
  font-size: 12px;
  line-height: 45px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  background-color: #4185F4;
  border-radius: 5px;
  padding: 0 30px;
  transition: .3s;
  position: relative;
  z-index: 2;
  &.disable {
    background: rgba(65, 133, 244, 0.2);
    &:hover {
      background: rgba(65, 133, 244, 0.2);
      box-shadow: none;
    }
  }
  &:hover {
    color: #fff;
    background-color: #3B52EA;
    box-shadow: 0 11px 23px rgba(65, 133, 244, .43);
  }
  &:focus {
    outline: none;
    color: #fff;
  }
}

.partners {
  padding: 0px 0 10px;
  &__text {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 30px;
    color: #515B6D;
  }
  h2 {
    margin-bottom: 46px;
  }
  ol {
    padding: 0;
    margin-bottom: 30px;
    counter-reset: li;
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 7px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #515B6D;
      &:before {
        content: counter(li) ".";
        counter-increment: li;
        position: absolute;
        top: 0;
        left: 0;
        color: #4185F4;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}

.become-partner {
  padding: 30px 0;
  background-color: #F0F5F9;
  &__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
    color: #515B6D;
    margin-bottom: 40px;
    a {
      color: #4185F4;
      border-bottom: 1px dashed rgba(65, 133, 244, .5);
      transition: .3s;
      &:hover {
        border-color: rgba(18, 84, 234, .5);
        color: #1254ea;
      }
    }
  }
  &__form {
  }
}

.form {
  &__group {
  }
  &__input {
    position: relative;
    margin-bottom: 15px;
  }
  &__label {
    z-index: 101;
    font-size: 16px;
    font-weight: 400;
    color: rgba(81, 91, 109, .6);
    position: absolute;
    left: 17px;
    top: 21px;
    pointer-events: none;
    transition: .3s;
    &--top {
      z-index: 101;
    }
    &--selected {
      z-index: 101;
      display: none;
    }
  }
  &__txt, &__text {
    display: block;
    width: 100%;
    height: 58px;
    background-color: #fff;
    border: 1px solid #D0D7E2;
    border-radius: 3px;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 17px;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    &:focus {
      outline: none;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }
  &__text {
    height: 116px;
    padding: 17px;
    padding-top: 25px;
    resize: none;
  }
  &__button {
    text-align: center;
    padding-top: 11px;
  }
  &__btn {
    min-width: 220px;
    position: relative;
    &--full {
      width: 100%;
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__note {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    color: #515B6D;
    text-align: center;
    padding-top: 22px;
    a {
      color: #4185F4;
      transition: .3s;
      &:hover {
        color: #1254EA;
      }
    }
  }
  &__input {
    &.active {
      .form__label {
        font-size: 12px;
        top: 8px;
      }
    }
    &--file {
      .form__txt {
        padding-right: 35px;
      }
    }
  }
  &__file {
    .input-file {
      position: absolute;
      top: 15px;
      right: 13px;
      z-index: 2;
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        top: -10px;
        left: -18px;
        width: 50px;
        height: 50px;
        background: radial-gradient(#ffffff 40%, transparent);
      }
      &__input {
        display: none;
      }
      &__btn {
        display: block;
        width: 30px;
        height: 30px;
        background-color: #2ED672;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        transition: .3s;
        box-shadow: 0 2px 25px #fff;
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          background: transparent url(/_images/svg/plus-white.svg) no-repeat 0 0;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -6px;
        }
        &:hover {
          background-color: #0DBF55;
          box-shadow: 0 2px 21px rgba(46, 214, 114, .51);
        }
        &.disabled {
          display: none;
        }
        &--delete {
          display: none;
          z-index: 2;
          background-color: #FF6451;
          &.active {
            display: block;
          }
          &:before {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  &__file-inner {
    position: relative;
    &.active {
      .form__label {
        font-size: 12px;
        top: 11px;
      }
    }
  }
  &__hint {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    color: rgba(81, 91, 109, .65);
    padding-top: 15px;
  }
  &__error {
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
    color: #f00;
    padding-top: 15px;
    &.-warn {
      color: #ca9d0b;
    }
  }
}

.form-forgot {
  float: right;
  padding-top: 13px;
  margin-bottom: 18px;
  &__link {
    display: inline-block;
    vertical-align: top;
    color: #4185F4;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 1px dashed rgba(65, 133, 244, .5);
    padding-bottom: 2px;
    transition: .3s;
    &:hover {
      color: #1254EA;
      border-color: rgba(18, 84, 234, .5);
    }
  }
}

.view-password {
  position: absolute;
  top: 21px;
  right: 17px;
  width: 20px;
  height: 15px;
  background: url("/_images/svg/eye_closed.svg") no-repeat center;
  &--open {
    background-image: url("/_images/svg/eye.svg");
    top: 19px;
  }
}

.phones {
  padding-top: 30px;
  &__list {
  }
  &__item {
    margin-bottom: 30px;
    font-weight: 300;
  }
  &__title {
    font-size: 16px;
    line-height: 22px;
    color: #4185F4;
    margin-bottom: 17px;
  }
  &__number {
    font-size: 14px;
    line-height: 20px;
    color: #515B6D;
    margin-bottom: 1px;
  }
  &__additional {
    color: rgba(81, 91, 109, .5);
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #515B6D;
  }
}

/* About Page */

.about {
  padding-top: 0;
  &__icon {
    text-align: center;
    margin-bottom: 33px;
  }
  &__text {
    color: #515B6D;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 30px;
    text-align: center;
  }
  &__list {
    margin-bottom: 20px;
  }
  &__item {
    text-align: center;
    margin-bottom: 30px;
  }
  &__digit {
    color: #FF6451;
    font-size: 70px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 23px;
  }
  &__label {
    font-size: 24px;
    font-weight: 300;
    color: #515B6D;
  }
}

.about-promo {
  position: relative;
  &__main {
  }
  &__sub {
    margin-top: -25px;
    margin-bottom: 30px;
    padding: 0 50px;
  }
  &__slider {
    outline: none;
    &--main {
      .about-promo__item {
        background: #fff no-repeat center;
        background-size: cover;
        height: 580px;
      }
    }
    &--sub {
      max-width: 1220px;
      margin: 0 auto;
      padding: 16px 9px;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0 0 172px 6px rgba(65, 133, 244, .36);
      .about-promo__item {
        position: relative;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: #4185f4;
          transition: opacity 0.3s;
          opacity: 0;
        }
        &:hover:before {

          opacity: 0.3;
        }
        &.slick-current.slick-active {
          img {
            border: 2px solid #4185F4;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  &__item {
    outline: none;
    border: 0;
    max-height: 580px;
    overflow: hidden;

    img {
      vertical-align: middle;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
    }
  }
  &__slider {
    &--main {
      img {
        width: 100%;
      }
    }
    &--sub {
      .about-promo__item {
        margin: 0 8px;
      }
    }
  }
  &__arrow {
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    .icon {
      position: absolute;
      bottom: 50px;
    }
    &--prev {
      left: -40px;
      .icon {
        left: 0;
      }
    }
    &--next {
      right: -40px;
      .icon {
        right: 0;
      }
    }
  }
}

.advantages {
  &__list {
  }
  &__item {
    margin-bottom: 30px;
  }
  &__icon {
    text-align: center;
    margin-bottom: 20px;
  }
  &__title {
    color: #000;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
    margin-bottom: 10px;
    word-wrap: break-word;
  }
  &__desc {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #515B6D;
    margin-bottom: 15px;
  }
  &__link {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    color: #4185F4;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    .icon {
      vertical-align: top;
      margin-left: 18px;
    }
    &:hover {
      color: #1254ea;
      .icon {
        background-image: url(/_images/svg/arrow-right-hover.svg);
      }
    }
  }
}

.condition {
  background-color: #F0F5F9;
  padding: 30px 0;
  &__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #515B6D;
    margin-bottom: 30px;
    text-align: center;
  }
  &__button {
    text-align: center;
  }
  &__btn {
    min-width: 256px;
  }
}

/* Contacts page */

.contacts {
  padding: 0px 0 40px;
  &__icon {
    text-align: center;
    position: relative;
    padding-bottom: 2px;
    margin-top: 6px;
    margin-bottom: 30px;
    &:after {
      content: "";
      width: 110px;
      height: 2px;
      background: #4185f4;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -55px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    color: #515B6D;
    margin-bottom: 25px;
    text-align: center;
  }
  &__phone {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    a {
      color: #4185F4;
      span {
        color: #515B6D;
      }
    }
  }
}

.clients {
  &--contacts {
    padding: 17px 0 7px;
    .clients__container {
      margin-bottom: 0;
    }
    .clients__link {
      border-color: #EBEAF1;
    }
  }
}

/* Reviews page */

.reviews {
  padding: 0px 0 50px;
  &__add {
    height: 290px;
    background: #F0F5F9;
    text-align: center;
    padding: 40px 20px 37px;
    border-radius: 5px;
    margin-bottom: 16px;
  }
  &__icon {
    margin-bottom: 19px;
  }
  &__desc {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color: #515B6D;
    margin-bottom: 30px;
  }
  &__btn {
    min-width: 224px;
  }
  &__list {
  }
  &__item {
    position: relative;
    margin-bottom: 16px;
  }
  &__inner {
    border: 2px solid #F0F5F9;
    border-radius: 5px;
    padding: 20px 20px 15px;
    background: #fff;
    position: relative;
    transition: .3s;
  }
  &__user {
    overflow: hidden;
    margin-bottom: 19px;
  }
  &__img {
    width: 78px;
    margin: 0 auto 20px;
    img {
      display: block;
      border-radius: 50%;
      border: 1px solid #4185f4;
      max-width: 100%;
      height: auto;
    }
  }
  &__name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    color: #4185F4;
    margin-bottom: 9px;
  }
  &__date {
    font-size: 12px;
    font-weight: 300;
    color: #515B6D;
  }
  &__user {
    &--admin {
      .reviews__name {
        color: #FF6451;
      }
    }
  }
  &__text {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: #515B6D;
    position: relative;
  }
  &__entry {
    max-height: 132px;
    overflow: hidden;
  }
  &__detail {
    display: none;
  }
  &__action {
    overflow: hidden;
    padding-top: 15px;
  }
  &__full {
    display: block;
    float: left;
    font-size: 14px;
    font-weight: 300;
    color: #2ED672;
    text-decoration: none;
    @media (max-width: $xs-down) {
      float: none;
      margin-bottom: 15px;
    }
    &:hover {
      color: #14AC77;
    }
    &:focus, &:active {
      color: #2ED672;
    }
    .icon {
      vertical-align: top;
      margin: 0 10px 0 0;
    }
  }
  &__view {
    display: inline-block;
    float: right;
    font-size: 14px;
    font-weight: 300;
    color: #4185F4;
    text-decoration: none;
    border-bottom: 1px dashed rgba(65, 133, 244, .5);
    transition: .3s;
    @media (max-width: $xs-down) {
      float: none;
    }
    &:hover {
      color: #1254ea;
      border-color: rgba(18, 84, 234, .5);
    }
    &:focus, &:active {
      color: #4185F4;
    }
  }
  &__more {
    padding: 28px 0 0;
    text-align: center;
  }
  &__show {
    min-width: 256px;
    border: 2px solid #4185f4;
  }
  &__response {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 5;
    margin-top: 16px;
    background: #fff;
    border: 2px solid #FF6451;
    border-radius: 5px;
    padding: 20px 20px 15px;
    &:before {
      content: "";
      border-bottom: 11px solid #FF6451;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      position: absolute;
      top: -11px;
      left: 50%;
      margin-left: -9px;
    }
    &:after {
      content: "";
      border-bottom: 11px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      top: -9px;
      left: 50%;
      margin-left: -8px;
    }
  }
  &__item {
    &.active {
      .reviews__full {
        .icon {
          background-image: url(/_images/svg/minus-green.svg);
        }
      }
    }
  }
}

/* Modals */

.modal-content {
  border: 0;
  border-radius: 0;
  background-color: #F0F5F9;
  box-shadow: 0 0 172px 6px rgba(65, 133, 244, .37);
}

.modal-close {
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  i {
    margin-top: 9px;
  }
}

.jq-selectbox {
  width: 100%;
  z-index: 100;
  &.error {
    .jq-selectbox__select {
      border-color: #eb0c0c;
    }
  }
  &__select {
    height: 58px;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #D0D7E2;
    color: #000;
    font-size: 16px;
    line-height: 54px;
    padding-right: 56px;
    padding-left: 17px;
    &.disable {
      opacity: 0.4;
    }
  }
  &__trigger {
    width: 56px;
    height: 100%;
    border-left: 1px solid #D0D7E2;
    position: absolute;
    top: 0;
    right: 0;
  }
  &__trigger-arrow {
  }
  &__search {
    input {
      border-radius: 0;
    }
  }
}

.popup {
  max-width: 614px;
  margin: 0 auto;
  padding: 0 15px 30px;
  &__container {
    max-width: 614px;
    margin: 0 auto 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__title {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #000;
    padding: 25px 0;
  }
  &__subtitle {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    color: #000;
    padding: 5px 0;
    margin-bottom: 10px;
  }
  &__desc {
    text-align: center;
    color: #515B6D;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  &__phone {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    color: #515B6D;
    margin-bottom: 30px;
    span {
      color: #4185F4;
    }
  }
  &--consult {
    .popup__title {
      padding: 25px 0 15px;
    }
  }
  &--login {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    max-width: 100%;
  }
}

.popup-nav {
  padding: 30px 0;
  &__list {
    margin: 0;
    list-style: none;
    text-align: center;
  }
  &__item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 22px 3px;
    border-right: 1px solid #515B6D;
    margin-right: -5px;
    &:last-child {
      border-right: 0;
    }
  }
  &__link {
    display: block;
    color: rgba(0, 0, 0, .5);
    border-bottom: 1px dashed rgba(0, 0, 0, .5);
    font-size: 20px;
    font-weight: 300;
    transition: color .3s, border-color .3s;
    &:hover {
      color: #1254EA;
      border-color: #1254EA;
    }
  }
  &__item {
    &.active {
      .popup-nav__link {
        color: #000;
        border: 0;
        padding-bottom: 1px;
      }
    }
  }
}

.popup-content {
  &__tab {
    display: none;
    &.active {
      display: block;
    }
  }
}

.popup-social {
  text-align: center;
  background-color: rgba(255, 255, 255, .5);
  border-top: 1px solid #EBEAF1;
  border-bottom: 1px solid #EBEAF1;
  padding: 33px 0 18px;
  &__title {
    color: #515B6D;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 26px;
  }
  &__list {
    padding: 0 20px;
    margin: 0;
    list-style: none;
  }
  &__item {
    display: inline-block;
    vertical-align: top;
    margin: 0 7px 7px;
  }
  &__link {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    &--facebook {
      background: url("/_images/svg/fb-icon.svg") no-repeat center;
      &:hover {
        background-image: url("/_images/svg/fb-hover.svg");
      }
    }
    &--mailruopenid {
      background: url("/_images/svg/ml-icon.svg") no-repeat center;
    }
    &--vkontakte {
      background: url("/_images/svg/vk-icon.svg") no-repeat center;
      &:hover {
        background-image: url("/_images/svg/vk-hover.svg");
      }
    }
    &--googleoauth {
      background: url("/_images/svg/gl-icon.svg") no-repeat center;
      &:hover {
        background-image: url("/_images/svg/gl-hover.svg");
      }
    }
    &--odnoklassniki {
      background: url("/_images/svg/ok-icon.svg") no-repeat center;
      &:hover {
        background-image: url("/_images/svg/ok-hover.svg");
      }
    }
  }
}

.reviews-form {
  &__author {
    text-align: center;
    margin-bottom: 27px;
  }
  &__img {
    margin-bottom: 15px;
  }
  &__name {
    color: #4185F4;
    font-size: 22px;
    line-height: 29px;
    font-weight: 300;
  }
}

/* Vacancy */

.vacancy {
  padding-top: 20px;
  h1 {
    margin-bottom: 0;
  }
  &__text {
    text-align: center;
    color: #515B6D;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    margin: 0px 0 22px;
  }
}

.vacancy-menu {
  background-color: #F0F5F9;
  padding: 30px 0 15px;
  &.active {
    .vacancy-menu__icon {
      float: left;
      margin: 0;
    }
    .vacancy-menu__info {
      float: none;
      padding: 15px 0 0 105px;
    }
    .vacancy-menu__more {
      display: none;
    }
  }
  &__item {
    margin-bottom: 15px;
  }
  &__link {
    display: block;
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(65, 133, 244, .3);
    border-radius: 4px;
    text-decoration: none;
    transition: all .3s;
    &--empty {
      cursor: default;
      background-color: transparent;
    }
    &:focus {
      outline: none;
    }
  }
  &__inner {
    position: relative;
  }
  &__icon {
    float: left;
    width: 85px;
    height: 85px;
    position: relative;
    border-radius: 50%;
    border: 1px solid #4185F4;
    margin-bottom: 15px;
    transition: all .3s;
  }
  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transition: opacity .3s;
    &--hover {
      opacity: 0;
    }
  }
  &__info {
    float: none;
    padding-left: 105px;
  }
  &__title {
    color: #000;
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 5px;
  }
  &__count {
    color: #515B6D;
    font-size: 16px;
    font-weight: 300;
  }
  &__more {
    color: #4185F4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    margin-top: 15px;
    padding-left: 105px;
    i {
      vertical-align: top;
      margin-left: 10px;
    }
  }
  &__link {
    &.active {
      background-color: #4185F4;
      border-color: #4185F4;
      color: #fff;
      .vacancy-menu__icon {
        border-color: #fff;
      }
      .vacancy-menu__img--default {
        opacity: 0;
      }
      .vacancy-menu__img--hover {
        opacity: 1;
      }
      .vacancy-menu__title, .vacancy-menu__count {
        color: inherit;
      }
    }
  }
}

.vacancy-content {
  padding: 30px 0 40px;
  display: none;
  opacity: 0;
  transition: opacity .15s linear;
  &.active {
    display: block;
    opacity: 1;
  }
  &__pane {
    display: none;
    opacity: 0;
    transition: opacity .15s linear;
    &.active {
      display: block;
      opacity: 1;
    }
    h2 {
      margin-bottom: 15px;
    }
  }
  &__count {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;
  }
  &__item {
    margin-bottom: -1px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #EBEAF1;
  }
  &__link {
    display: block;
    font-weight: 300;
    font-size: 16px;
    padding: 20px 0 20px 25px;
    i {
      display: none;
      vertical-align: top;
      margin: 7px 25px 0 0;
    }
  }
  &__item {
    .vacancy-content__link {
      color: #4185F4;
      transition: ease .3s;
    }
    &.active {
      border-color: #4185F4;
      margin-bottom: 0;
      box-shadow: inset 0 0 1px 1px #4185f4;
      .vacancy-content__link {
        color: #fff;
        background: #4185F4;
      }
    }
  }
  &__desc {
    padding: 30px 25px;
    color: #515B6D;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
  }
  &__caption {
    padding: 20px 0 18px;
    color: #000103;
    font-size: 16px;
  }
  ul {
    margin-bottom: 7px;
    li {
      padding: 0 0 0 20px;
      margin-bottom: 6px;
      position: relative;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #4185f4;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }
  &__btn {
    padding: 0 10px;
    display: block;
    text-align: center;
    margin-top: 34px;
  }
}

.become-job {
  padding: 30px 0;
  background-color: #F0F5F9;
  &__text {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 30px;
  }
}

/* Bonus main */

.bonus {
  padding-top: 30px;
  &--main {
    h1 {
      margin-bottom: 17px;
    }
  }
  &__text {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;
    max-width: 950px;
    margin: 0 auto 30px;
  }
  &__list {
  }
  &__item {
    margin-bottom: 40px;
  }
}

/* Bonus detail */

.bonus-detail {
  padding-top: 30px;
  padding-bottom: 30px;
  &__image {
    margin-bottom: 30px;
  }
  &__desc {
    color: #515B6D;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    h2 {
      line-height: 34px;
      margin: 30px 0;
    }
    ul {
      margin-bottom: 30px;
      li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 25px;
        &:before {
          content: "";
          width: 26px;
          height: 2px;
          background: #4185f4;
          position: absolute;
          top: 10px;
          left: 0;
        }
      }
    }
  }
  &__preview {
    margin-bottom: 30px;
  }
  &__table {
    table {
      width: 100%;
      min-width: 600px;
      thead {
        tr {
          td {
            text-align: center;
            font-weight: 400;
            padding: 14px 4px;
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: center;
            border: 1px solid #EBEAF1;
            padding: 15px 16px;
            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.bonus-attention {
  padding: 35px 0 25px;
  background-color: #F0F5F9;
  &__icon {
    margin-bottom: 5px;
    text-align: center;
    i {
      vertical-align: top;
    }
  }
  h4 {
    color: #FF6451;
  }
  ol {
    color: #515B6D;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    counter-reset: li;
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #515B6D;
      a {
        font-weight: 400;
        color: #4185F4;
        span {
          color: #515B6D;
        }
      }
      &:before {
        content: counter(li) ".";
        counter-increment: li;
        position: absolute;
        top: 0;
        left: 0;
        color: #FF6451;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}

/* 404 */

.found {
  padding: 20px 0 50px;
  &__top {
    border-bottom: 2px solid #F0F5F9;
    margin-bottom: 30px;
  }
  &__inner {
    position: relative;
    text-align: center;
    overflow: hidden;
    &:before {
      content: "";
      width: 150px;
      height: 47px;
      background: url(../_images/svg/car-b-bg.svg) no-repeat 0 0 / contain;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  &__label {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    height: 50px;
    text-align: center;
    color: #fff;
    font-size: 26px;
    line-height: 48px;
    background-color: #FF6451;
    margin-bottom: 30px;
  }
  &__caption {
    padding-left: 110px;
    svg {
      max-width: 100%;
      height: auto;
    }
  }
  &__desc {
    text-align: center;
    color: #515B6D;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    a {
      color: #4185F4;
      &:hover {
        color: #1254ea;
      }
    }
  }
}

/* Address */

.address {
  padding: 0 0 40px;
}

.address-nav {
  margin-bottom: 30px;
  border-bottom: 1px solid #ebeaf1;
  &__list {
    text-align: center;
  }
  &__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0 20px 20px;
  }
  &__link {
    display: block;
    color: #515b6d;
    font-size: 16px;
    padding-bottom: 14px;
    transition: all .3s;
    opacity: .7;
    &:hover {
      color: #6d97f2;
      opacity: 1;
    }
    &:after {
      content: "";
      width: 0px;
      height: 3px;
      background-color: #515b6d;
      position: absolute;
      right: 0;
      bottom: -1px;
      transition: all .3s;
    }
  }
  &__item {
    &.active {
      .address-nav__link {
        opacity: 1;
        &:after {
          width: 100%;
        }
      }
    }
  }
}

.address-content {
  margin-bottom: 40px;
  &__tab {
    display: none;
    &.active {
      display: block;
    }
  }
  &__thead {
    display: none;
    color: #515B6D;
    font-size: 16px;
    padding: 14px 40px 14px 75px;
  }
  &__list {
  }
  &__item {
    margin-bottom: -1px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #ebeaf1;
    .address-content__link {
      display: block;
      color: #4185F4;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      padding: 20px;
      position: relative;
      transition: color ease .3s, background ease .3s;
      &:focus {
        outline: none;
      }
      i {
        display: none;
        position: absolute;
        left: 37px;
        top: 25px;
      }
    }
  }
  &__row {
    margin-bottom: 5px;
    white-space: nowrap;
  }
  &__location {
    border-bottom: 1px dashed rgba(65, 133, 244, .5);
  }
  &__item {
    &.active {
      border-width: 2px;
      border-color: #4185f4;
      margin-bottom: 0;
      .address-content__link {
        color: #fff;
        background: #4185F4;
        padding: 19px 19px 20px;
        i {
          background-image: url(/_images/svg/arrow-up-white.svg);
        }
      }
      .address-content__location {
        border-color: #fff;
      }
    }
  }
  &__desc {
    padding: 20px;
    background-color: rgba(65, 133, 244, .05);
    color: #515B6D;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
  &__group {
    border-bottom: 1px solid rgba(65, 133, 244, .3);
    padding: 9px 0;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  &__title {
  }
  &__phone {
    span {
      color: rgba(81, 91, 109, .5);
    }
  }
}

.address-map {
  &__area {
    width: 100%;
    height: 340px;
  }
}

.address-balloon {
  padding: 18px;
  font-weight: 300;
  color: #40485a;
  &__title {
    margin-bottom: 16px;
    color: #4185f4;
    font-size: 22px;
  }
  &__item {
    margin-bottom: 10px;
    font-size: 12px;
    span {
      color: #515b6d;
      font-size: 14px;
      font-weight: 400;
    }
    a {
      color: #336df1;
      border-bottom: 1px dashed #a0c2f9;
    }
  }
}

@mixin normal-font() {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

@mixin inline-btn() {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 1.71429px;
  text-transform: uppercase;
  color: #4185F4;
  user-select: none;
  cursor: pointer;
}

@keyframes fadeInToRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  70% {
    opacity: 0.2;
  }
  99.999% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: none;
  }
}

@keyframes fadeInToLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  70% {
    opacity: 0.2;
  }
  99.999% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: none;
  }
}

@keyframes fadeOutToRight {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  99.999% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    display: none;
  }
}

@keyframes fadeOutToLeft {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  99.999% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    display: none;
  }
}

.cabinet {
  &__history-list {
    width: 100%;
  }
  &__history-item {
    width: 100%;
    border: 1px solid #EBEAF1;
    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }
  &__history-item-header {
    border-bottom: 1px solid #EBEAF1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (min-width: $sm-down) {
      padding: 9px 20px;
    }
    @media (max-width: $sm-down) {
      padding: 12px 20px;
    }
  }
  &__history-item-info {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    @media (max-width: $sm-down) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__history-item-number {
    @include normal-font();
    line-height: 23px;
    font-size: 16px;
    margin-right: 30px;
    user-select: none;
    color: #000;
    &:hover {
      color: #4185F4;
      cursor: pointer;
    }
  }
  &__history-item-date {
    @include normal-font();
    line-height: 22px;
    font-size: 14px;
    @media (max-width: $sm-down) {
      margin-top: 5px;
    }
  }
  &__history-item-state {
    @include normal-font();
    line-height: 22px;
    font-size: 14px;
    text-align: right;
    &.normal {
      color: #4185F4;
    }
    &.success {
      color: #2ED672;
    }
    &.fail {
      color: #FF6451;
    }
    @media (max-width: $sm-down) {
      align-self: baseline;
    }
  }
  &__history-item-content {
    display: flex;
    justify-content: space-between;
    @media (min-width: $sm-down) {
      padding: 20px;
    }
    @media (max-width: $sm-down) {
      flex-direction: column;
      padding: 20px 15px;
    }
  }
  &__history-item-gallery {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    a {
      width: fit-content;
      height: fit-content;
      margin-right: 10px;

      img {
        float: left;
      }
    }
  }
  &__history-item-gallery-img {
    max-height: 80px;
    max-width: 80px;
    padding: 1px;
    transition: border .2s ease-in;
    &:not(.disabled):hover {
      padding: 0px;
      border: 1px solid rgba(81, 91, 109, 0.8);
      cursor: pointer;
    }
    &.disabled {
      cursor: default;
      opacity: .5;
    }
  }
  &__history-item-gallery.overflowed::after {
    content: attr(data-content);
    display: block;
    width: 80px;
    height: 80px;
    border: 1px solid rgba(208, 215, 226, 0.5);
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    color: #515B6D;
    user-select: none;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    line-height: 80px;
  }
  &__history-item-right {
    height: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (max-width: $sm-down) {
      margin-top: 25px;
    }
  }
  &__history-item-price {
    text-align: right;
    @include normal-font();
    line-height: 22px;
    font-size: 22px;
    text-align: right;
    color: #515B6D;

    @media (max-width: $sm-down) {
      text-align: left;
      margin-bottom: 5px;

    }
  }
  &__history-item-buttons {
    display: flex;
    @media (min-width: $sm-down) {
      margin-left: 100px;
      margin-top: 25px;
    }
    @media (max-width: $sm-down) {
      flex-direction: column;
      margin-top: 20px;
    }
    button:not(:only-of-type):first-of-type {
      @media (max-width: $sm-down) {
        margin-bottom: 15px;
      }
      @media (min-width: $sm-down) {
        margin-right: 20px;
        width: 182px;
      }
    }
  }
  &__order-detail {
    border: 1px solid rgba(208, 215, 226, 0.5);
    padding: 30px;

    @media (max-width: $sm-down) {
      padding: 16px;
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
  &__back {
    @include normal-font();
    @include inline-btn();
    position: relative;
    padding-left: 50px;
    &::before {
      content: '';
      display: block;
      height: 14px;
      width: 32px;
      position: absolute;
      left: 0px;
      background-image: url('/_images/tyres/arrow-back.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__order-head {
    width: 100%;
    padding: 33px 0px;
    @media (max-width: $sm-down) {
      padding: 20px 0px;
    }
  }
  &__order-num {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    font-size: 26px;
    color: #000000;
    @media (max-width: $sm-down) {
      font-size: 20px;
      padding: 10px 0px 6px 0px;
    }
  }
  &__order-date {
    @include normal-font();
    line-height: 22px;
    font-size: 16px;
    color: #515B6D;
    margin-top: 30px;
    @media (max-width: $sm-down) {
      font-size: 14px;
      margin-top: 20px;
    }
  }
  &__order-status {
    @include normal-font();
    line-height: 22px;
    font-size: 16px;
    margin-top: 15px;
    color: #515B6D;
    span.normal {
      color: #4185F4;
    }
    span.success {
      color: #2ED672;
    }
    span.fail {
      color: #FF6451;
    }
    @media (max-width: $sm-down) {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  &__order-info {
    @include normal-font();
    border-color: rgba(208, 215, 226, 0.5);
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding-top: 27px;
    padding-bottom: 30px;
    @media (max-width: $sm-down) {
      padding-top: 0px;
      padding-bottom: 20px;
      border: 1px solid rgba(208, 215, 226, .5);
    }
  }
  &__order-cost {
    @media (max-width: $sm-down) {
      display: flex;
      flex-direction: row !important;
      & > .cabinet__info-value {
        padding: 0px;
        white-space: nowrap;
        padding-right: 16px !important;
        span {
          line-height: 38px;
        }
      }
      & > .cabinet__info-title {
        font-size: 16px !important;
        background: #fff;
        white-space: nowrap
      }
    }
  }
  &__info-block {
    display: flex;
    @media (max-width: $sm-down) {
      flex-direction: column;
    }
  }
  &__info-title {
    @include normal-font();
    line-height: 22px;
    font-size: 14px !important;
    color: #202020;
    &.bold {
      font-size: 17px;
      color: #000;
      font-weight: 500;
    }
    @media (min-width: $sm-down) {
      width: 170px;
      padding-right: 10px;
    }
    @media (max-width: $sm-down) {
      background: #f0f5f9;
      width: 100%;
      line-height: 38px;
      text-indent: 15px;
    }
  }
  &__info-value {
    display: flex;
    padding-bottom: 25px;
    &.list {
      flex-direction: column;
    }
    &:not(.list) span:not(:first-of-type) {
      @media (min-width: $sm-down) {
        margin-left: 30px;
      }
    }
    @media (max-width: $sm-down) {
      flex-direction: column;
      padding: 15px 15px 20px 15px;
    }
    span {
      line-height: 24px;
      font-weight: 300;
      font-size: 14px;
    }
    &.bold span {
      font-size: 17px;
      color: #000;
      font-weight: 500;
    }

  }
  &__show-map {
    @include inline-btn();
    @media (max-width: $sm-down) {
      font-size: 12px !important;
    }
  }
  &__order-buttons {

    @media (min-width: $sm-down) {
      margin-left: 170px;
      display: flex;
      & > *:not(:first-of-type) {
        margin-left: 20px;
      }
    }
    @media (max-width: 467px) {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      & > *:not(:first-of-type) {
        margin-top: 15px;
      }
    }
    @media (min-width: 467px) and (max-width: $sm-down) {
      margin-top: 25px;
      padding-right: 15px;
      padding-left: 15px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      & > button:not(:first-of-type) {
        margin-left: 20px;
      }

    }
  }
  &__order-products {
    margin-top: 25px;
    width: 100%;
  }
  &__order-product {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .left, .right {
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: $sm-down) {
      &:not(:first-of-type) {
        margin-top: 50px !important;
      }
      flex-direction: column;
      .left {
        justify-content: flex-start;
      }
      .right {
        padding-top: 25px;
      }
    }
  }
  &__order-products-title {
    @include normal-font();
    font-weight: 300;
    font-size: 22px;
    color: #000;
    margin-left: 2px;
    @media (max-width: $sm-down) {
      font-size: 18px;
    }
  }
  &__order-product-img {
    text-align: left;
    margin-right: 10px;
    img {
      max-height: 104px;
      max-width: 104px;
      user-select: none;
      background: #fff;
    }
  }
  &__order-product-name {
    @include normal-font();
    font-weight: 300;
    line-height: 20px;
    color: #000;
    text-align: left;
    font-size: 15px;
  }
  &__order-product-count {
    @include normal-font();
    font-weight: 300;
    text-align: center;
    color: #515B6D;
    white-space: nowrap;
    min-width: fit-content;
    font-size: 15px;
    @media (min-width: $lg-down) {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    @media (min-width: $md-down)  and (max-width: $lg-down) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
    @media (min-width: $sm-down) and (max-width: $md-down) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    @media (max-width: $sm-down) {
      width: 90px;
      text-align: center;
    }
  }
  &__order-product-cost {
    @include normal-font();
    text-align: right;
    font-size: 15px;
    color: #515B6D;
    white-space: nowrap;
    min-width: fit-content;
    @media (max-width: $sm-down) {
      width: calc(100% - 90px);
      padding-left: 20px;
      text-align: left;
    }
  }
  &__order-product-state {
    display: flex;
    flex-direction: column;
    @include ap(padding-left, 10px, 14px, 16px, 20px);
    @include ap(padding-top, 8px, 10px, 12px, 14px);
  }
  &__order-product-status {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    margin-top: 10px;
    height: 16px;
    @include ap(font-size, 12px, 12px, 13px, 13px);
    padding-left: 14px;
    position: relative;
    text-transform: lowercase;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      background: {
        size: 100%;
        repeat: no-repeat;
      };
      left: 0px;
      top: 7px;
    }
    &.in-stock {
      color: #2ED672;
      &::before {
        background-image: url('/_images/svg/item-status-in_stock.svg');
      }

    }
    &.few {
      color: #FF6451;
      &::before {
        background-image: url('/_images/svg/item-status-few.svg');
      }
    }
    &.custom {
      color: #515B6D;
      &::before {
        background-image: url('/_images/svg/item-custom.svg');
      }
    }

  }
  &__order-product-count {
    @include ap(padding-right, 10px, 15px, 20px, 20px);
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__order-product-value {
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    color: #515B6D;
    margin-top: 10px;
  }

}

.catalog {
  &__fullwidth-wrapper {
    background: #F0F5F9;
    width: 100%;
    &.dmtr {
      @include ap(background-image, none, linear-gradient(rgba(65, 133, 244, 0.05), rgba(65, 133, 244, 0.05)), linear-gradient(rgba(65, 133, 244, 0.05), rgba(65, 133, 244, 0.05)), linear-gradient(rgba(65, 133, 244, 0.05), rgba(65, 133, 244, 0.05)));
      background-size: 1px 100%;
      background-position: 50% 0%;
      background-repeat: no-repeat;
    }
  }
  &__search-wrapper {
    margin-top: 18px;
    .container {
    @media (max-width: $sm) {
      padding: 0px !important;
    }
  }

  }
  &__btn {
    min-width: 200px;
  }
  &__top-search {
    @include ap(padding-top, 0px, 30px, 30px, 30px);
    @include ap(padding-bottom, 5px, 35px, 35px, 35px);
    display: flex;
    width: 100%;
  }
  &__top-search-half {
    width: 50%;
    display: flex;
    @include ap(flex-direction, column, column, column, row);
    &.active {
      &.left {
        & > .catalog__search-panel {
          @include ap(width, 200%, 100%, 100%, 68%);
        }
        & > .catalog__top-search-half-left {
          @include ap(width, 100%, 100%, 100%, 32%);
        }
      }
      &.right > .catalog__search-panel {
        @include ap(width, 200%, 100%, 100%, 100%);
        @include ap(transform, translateX(-50%), none, none, none);
      }
    }
    &:not(.active) {
      height: fit-content;
      & > .catalog__search-panel {
        @include ap(display, none, flex, flex, flex);
      }
      & > .catalog__top-search-half-left {
        @include ap(background, #fff, unset, unset, unset);
        & > .catalog__top-search-img {
          @include ap(opacity, .5, 1, 1, 1);
        }
        & > .catalog__top-search-title {
          & > span {
            @include ap(border-bottom, 1px dashed rgba(0,0,0,.5), none, none, none);
            @include ap(opacity, .5, 1, 1, 1)
          }
        }
      }
    }
  }
  &__top-search-title {
    font-weight: 300;
    line-height: 30px;
    @include ap(font-size, 20px, 24px, 26px, 30px);
    @include ap(width, 100%, 100%, 100%, unset);
    color: #000000;
    @include ap(align-items, center, center, center, none);
    @include ap(display, flex, flex, flex, block);
    @include ap(padding-left, 0, 0px, 50px, 0);
    @include ap(padding-bottom, 10px, 0, 0, 0);
    @include ap(justify-content, center, left, left, none);


  }
  &__top-search-half-left {
    &.right {
      @include ap(padding-left, 0, 15px, 20px, 30px);
      @include ap(margin-right, 0, 0, 0, 12px);
      & ~ .catalog__search-panel {
        @include ap(padding-left, 0, 20px, 25px, 0);
      }
    }
    @include ap(width, 100%, 100%, 100%, 33.33%);
    @include ap(display, flex, flex, flex, block);
    @include ap(flex-direction, column-reverse, row-reverse, row-reverse, none);
    @include ap(justify-content, center, flex-end, flex-end, none);
  }
  &__top-search-img {
    @include ap(justify-content, center, flex-start, flex-start, flex-start);
    @include ap(width, 100%, auto, auto, auto);
    @include ap(height, 95px, 120px, 120px, 100%);
    @include ap(padding, 25px 30px 10px, 10px 30px, 0px 20px, 25px 30px 5px 5px);
    display: flex;
    align-items: center;
    img {
      @include ap(width, auto, auto, auto, 100%);
      @include ap(height, 100%, 90px, 100px, unset);
    }
  }
  &__search-param {
    @include ap(width, calc(50% - 8px), 45%, 45%, 140px);
    @include ap(min-width, none, none, none, 136px);
    height: 88px;
    padding-top: 50px;
    position: relative;
    @include ap(margin-right, 16px, 5%, 5%, 14px);
    &:nth-of-type(2n) {
      @include ap(margin-right, 0, 15px, 15px, 15px);
    }

  }
  &__search-param-name {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 0px;
    position: absolute;
    bottom: 38px;
  }
  &__search-panel {
    @include ap(width, 100%, 100%, 100%, 66.66%);
    display: flex;
    flex-direction: column;
    @include ap(padding-top, 10px, 15px, 20px, 20px);
    &-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: start;
      @include ap(justify-content, space-between, none, none, none);
      @include ap(padding, 0px 16px, 0, 0, 0);
    }
    justify-content: flex-start;
  }
  &__search-select {
    height: 38px;
    &.opened .jq-selectbox__trigger-arrow {
      transform: translateY(-50%) rotate(180deg) !important;
    }
    .jq-selectbox__trigger-arrow {
      background-image: url('/_images/svg/catalog-select-arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      width: 9px;
      height: 4px;
      transition: transform .05s ease-in !important;
    }
    .jq-selectbox__trigger {
      width: 39px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }
    .jq-selectbox__select-text {
      line-height: 30px;
      font-size: 14px;
      width: 100% !important;
    }
    .jq-selectbox__select {
      padding-left: 13px;
      padding-right: 30px;
      border: none;
      line-height: 34px;
      height: 38px;
    }
    .jq-selectbox__dropdown {
      box-shadow: 0 5px 18px rgba(65, 133, 244, 0.2);
      border: none;
      bottom: unset !important;
    }
    .jq-selectbox__dropdown ul li {
      border-bottom: none;
      padding: 2px 13px;
      line-height: 34px;
      font-size: 14px;
    }
  }
  &__search-btn {
    padding-top: 0px !important;
    @include ap(margin, 30px auto, 30px 0px 0px, 30px 0px 0px, 30px 0px 0px);
    width: fit-content;
  }
  .row {
    display: flex;
  }
  [class*="col-"] {
    float: none;
  }

  &__search-by-car {
    padding-top: 40px;
    h3 {
      @include ap(margin-bottom, 10px, 20x, 30px, 30px);
    }
  }
  &__search-type-switcher {
    margin: 10px auto 25px;
    display: flex;
    align-items: center;
    @include ap(font-size, 15px, 16px, 16px, 16px);
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    max-width: 420px;
    @include ap(height, 35px, 38px, 42px, 42px);

    &-half {
      width: 50%;
      height: 100%;

      text-align: center;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color .2s ease-in;
      border: 2px solid #4185f4;
      position: relative;
      box-sizing: padding-box;
      &:nth-of-type(1) {
        border-radius: 100px 0px 0px 100px;
      }
      &:nth-of-type(2) {
        border-radius: 0px 100px 100px 0px;
      }
      user-select: none;
      &.selected {
        transition: color .2s ease-in, background .2s ease-in;
        background: #4185f4;
        color: #fff;
        &::before {
          width: 100%;
        }
      }
      &:not(.selected) {
        background: #fff;
        color: #4185f4;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__your-car {
    padding-top: 28px;
    padding-bottom: 35px;
    &-form {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .catalog__search-btn {
        margin-bottom: 0px !important;
      }
    }
    &-title {
      text-align: left;
      margin-bottom: 0px !important;
      /*@include ap(display, none, block, block, block);*/
    }
    &-detail {
      width: 100%;
      @include ap(margin-top, 0px, 20px, 20px, 20px);
      display: flex;
      @include ap(flex-direction, column, row, row, row);
      &-param {
        background: #fff;
        position: relative;
        user-select: none;
        &-select {
          @include ap(display, block, none, none, none);
          &.validation-error {
            .jq-selectbox__select {
              border: 1px solid #FF6451;
              .jq-selectbox__trigger .jq-selectbox__trigger-arrow {
                background-image: url('/_images/svg/select-arrow-error.svg');
              }
              .jq-selectbox__select-text::after {
                color: #ff6451;
              }
            }
          }
        }
        .jq-selectbox__select-text {
          top: 0;
          height: 100%;
          line-height: 78px;
          &::after {
            display: block;
            width: 100%;
            color: rgba(81, 91, 109, 0.6);
            transition: top .15s ease-in, line-height .15s ease-in;
          }
          &:empty::after {
            top: 0;
            line-height: 58px;
            content: attr(data-placeholder);
            height: 100%;
          }
          &:not(:empty) {
            margin-left: -6px;
            transition: margin-left .15s ease-in;
          }
          &:not(:empty)::after {
            content: attr(data-legend);
            display: block;
            position: absolute;
            height: auto;
            top: 10px;
            line-height: 10px;
            left: 0;
            font-size: 12px;
          }

        }

        & > input {
          /*@include ap(display, none, block, block, block);*/
        }
        &:nth-of-type(2) {
          @include ap(width, 100%, calc(50% - 16px), calc(50% - 16px), calc(50% - 16px));
        }
        &:not(:nth-of-type(2)) {
          @include ap(width, 100%, calc(25% - 7px), calc(25% - 7px), calc(25% - 7px));
        }
        &:not(:first-of-type) {
          @include ap(margin, 16px 0px 0px, 0px 0px 0px 16px, 0px 0px 0px 16px, 0px 0px 0px 16px);
        }
        &::after {

            content: '';
            display: block;
            background: {
              image: url('/_images/svg/param-edit.svg');
              repeat: no-repeat;
              size: contain;
            }
            position: absolute;
            right: 16px;
            width: 14px;
            height: 14px;
            top: 11px;
            cursor: pointer;

        }
        & > input {
          height: 35px;
          width: 100%;
          font-size: 14px;
          line-height: 35px;
          text-indent: 19px;
          font-weight: 300;
          padding-right: 34px;
          user-select: none;
          border: none;

          &:hover {
            cursor: pointer;
            background: #f8f8f8;
          }
        }
      }
    }

    &-select-param {
      margin-top: 20px;
      width: 100%;
      background: #F0F5F9;
      border: 1px solid #C0C9D9;
      padding: 0px 24px 26px 24px;

      &.active {
        display: block;
      }

      &:not(.active) {
        display: none;
      }

      &-header {
        padding: 20px 25px;
        display: flex;
        user-select: none;

        &-variant {
          font-size: 14px;
          line-height: 22px;

          &:not(:first-of-type) {
            margin-left: 20px;
          }

          &:not(.active) {
            opacity: .7;
            color: #515B6D;
            border-bottom: 1px dashed rgba(81, 92, 120, .5);

            &:hover {
              opacity: .85;
              border-bottom: 1px dashed rgba(81, 92, 120, .7);
              cursor: pointer;
            }
          }

          &.active {
            color: #000;
            opacity: 1;
            border: none;
          }
        }
      }

      &-list {
        max-height: 674px;
        border: 1px solid #D0D7E2;
        overflow-y: auto;

        &::-webkit-scrollbar-button {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          background: #4185F4;
          border-radius: 53px;
          width: 7px;
        }

        &::-webkit-scrollbar {
          background: rgba(41, 85, 244, .2);
          mix-blend-mode: normal;
          width: 9px;
        }

        ul {
          background: #FFFFFF;
          box-sizing: border-box;
          width: 100%;
          padding: 7px 24px 25px 10px;
          list-style-type: none;
          column-gap: 6%;

          &.col-2 {
            columns: 2;
          }

          &.col-3 {
            columns: 3;
          }

          &.col-4 {
            columns: 4;
          }

          li {
            font-weight: 300;
            line-height: 40px;
            font-size: 14px;
            color: #515B6D;
            margin-right: 10px;
            padding-left: 13px;
            user-select: none;
            position: relative;

            &:hover {
              &::before {
                content: '';
                width: calc(100% - 2px);
                height: calc(100% - 4px);
                top: 2px;
                left: 1px;
                position: absolute;
                border: 1px solid #4185F4;
                border-radius: 3px;
              }

              cursor: pointer;
              color: #4185F4;
            }
          }
        }
      }
    }
  }

}

.order-auth {
  background-color: #f0f5f9;
  margin-bottom: 60px;
  &__text {
    @include ap(font-size, 14px, 15px, 16px, 18px);
    @include ap(margin-top, 0px, 10px, 15px, 25px);
    @include ap(margin-bottom, 25px, 30px, 35px, 40px);
    line-height: 26px;
    font-weight: 300;
    color: #515B6D;
    text-align: center;
  }
  .popup-social__item {
    @include xs(margin, 0px 4px 7px);
  }
  .popup-social {
    margin-bottom: 60px;
  }
}

.popup {
  &.--order {
    @include ap(padding, 0px 15px 30px !important, 0px 25px 30px !important, 0px 30px 30px !important, 0px 0px 30px !important);
  }
  max-width: 790px;
  &.-nb {
    padding-bottom: 0px !important;
  }
  &__subtitle-sm {
    @include ap(font-size, 14px, 15px, 16px, 18px);
    @include ap(margin-top, 0px, 10px, 15px, 25px);
    @include ap(margin-bottom, 15px, 16px, 20px, 25px);
    line-height: 26px;
    font-weight: 300;
    color: #515B6D;
    text-align: center;
  }
  &__title {
    padding-bottom: 10px !important;
    &.-bm  {
      @include ap(padding-top, 25px, 35px, 50px, 65px);
      @include ap(margin-bottom, 5px, 10px, 15px, 25px);
    }
  }
  &__product-list {
    @include ap(padding-bottom, 20px, 20px, 25px, 25px);

  }
  &__splitter {
    width: 100%;
    height: 1px;
    background: rgba(208,215,226,.5);
    display: block;
    position: absolute;
    left: 0;
  }
  &__form {
    @include ap(margin-top, 28px, 32px, 36px, 40px);
    .form__btn {
      @include xs(width, 100%);
      @include xs(max-width, 300px);
    }
  }
  .cabinet__order-product-cost {
    font-size: 18px;
    @include ap(padding-top, 15px, 15px, 14px, 14px);
  }
  &__footer {
    display: flex;
    padding: 30px 0px;
    align-items: center;
    justify-content: space-between;
    @include xs(flex-direction, column);
    &-cart {
      height: 100%;
      display: flex;
      align-items: flex-start;
      font-weight: 300;
      line-height: 24px;
      font-size: 16px;
      @include xs(align-items, center);
      @media (max-width: $lg) {
        flex-direction: column;
      }
      &-count {
        color: #4185F4;
        cursor: pointer;
        padding-right: 6px;
      }
    }
    &-buttons {
      display: flex;
      @include xs(flex-direction, column);
      @include xs(margin-top, 20px);
      @include xs(width, 100%);
      @include xs(align-items, center);
      .flat-btn {
        @include xs(width, 100%);
        @include xs(max-width, 320px);
        &:first-of-type {
          @include xs(margin-bottom, 15px);
          @media (min-width: $sm) {
            margin-right: 20px;
          }

        }
      }
    }
  }

}

.modal {
  padding-right: 0px !important;
}


@import "responsive/768";
@import "responsive/992";
@import "responsive/1454";


