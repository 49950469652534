.catalog__products {
  @include ap(margin-top, 30px, 35px, 40px, 40px);
  &-section {
    width: 100%;
    @include ap(margin-bottom, 15px, 20px, 25px, 30px);
    &-title {
      @include ap(font-size, 20px, 24px, 27px, 30px);
      @include ap(margin-bottom, 15px, 20px, 25px, 30px);
      font-weight: 300;
      line-height: 30px;
      color: #000;
    }
  }
  &-sections {
    width: 100%;
    @include ap(padding-bottom, 15px, 20px, 20px, 20px);
  }
}

.catalog__section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
  &.waiting {
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, .7);
      z-index: 99;
    }
  }
  &-footer {
    display: flex;
    & > div:first-of-type {
      @include ap(margin-top, 20px, 40px, 40px, 40px);
    }
    & > div:last-of-type {
      @include ap(padding-bottom, 20px, 0px, 0px, 0px);
    }
    width: 100%;
    flex-direction: column;
    align-items: center;
    &-pagination {
      margin-top: 40px;
      position: relative;
      @include ap(display, none, flex, flex, flex);
      & .left, & .right {
        position: absolute;
        content: '';
        display: block;
        height: 6px;
        width: 12px;
        background: {
          size: 100%;
          image: url('/_images/svg/arrow-down-black.svg');
          repeat: no-repeat;
          position: center;
        }
        top: 12px;
        cursor: pointer;
      }
      & .left {
        transform: rotate(-90deg);
        left: -38px;
      }
      & .right {
        transform: rotate(90deg);
        right: -38px;
      }

      &-page {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        margin-left: 7px;
        margin-right: 7px;
        user-select: none;
        &.active {
          background: #4185F4;
          color: #fff;
        }
        &:hover:not(.empty) {
          background: #F0F5F9;
          color: #000;
          &:not(.active) {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (max-width: $sm-down) {
    &[data-inrow-xs='1'] > .product-card {
      @include xs(width, 100%);
      margin-left: 0px;
    }
    &[data-inrow-xs='2'] > .product-card {
      @include xs(width, 50%);
      &:nth-of-type(n+3) {
        border-top: none;
      }
      &:nth-of-type(2n) {
        border-left: none;
      }
    }
    &[data-inrow-xs='3'] > .product-card {
      @include xs(width, calc(33.33% - 10.66px));
      &:not(:nth-of-type(3n-2)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 4) {
        margin-top: 16px;
      }
    }
  }

  @media (min-width: $sm) and (max-width: $md) {
    &[data-inrow-sm='1'] > .product-card {
      @include sm(width, 100%);
      margin-left: 0px;
    }
    &[data-inrow-sm='2'] > .product-card {
      @include sm(width, calc(50% - 8px));
      &:not(:nth-of-type(2n-1)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 3) {
        margin-top: 16px;
      }
    }
    &[data-inrow-sm='3'] > .product-card {
      @include sm(width,  calc(33.33% - 10.66px));
      &:not(:nth-of-type(3n-2)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 4) {
        margin-top: 16px;
      }
    }
    &[data-inrow-sm='4'] > .product-card {
      @include sm(width, calc(25% - 12px));
      &:not(:nth-of-type(4n-3)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 5) {
        margin-top: 16px;
      }
    }
    &[data-inrow-sm='5'] > .product-card {
      @include sm(width, calc(20% - 12.8px));
      &:not(:nth-of-type(5n-4)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 6) {
        margin-top: 16px;
      }
    }
  }

  @media (min-width: $md) and (max-width: $lg) {
    &[data-inrow-md='1'] > .product-card {
      @include md(width, 100%);
      margin-left: 0px;
    }
    &[data-inrow-md='2'] > .product-card {
      @include md(width, calc(50% - 8px));
      &:not(:nth-of-type(2n-1)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 3) {
        margin-top: 16px;
      }
    }
    &[data-inrow-md='3'] > .product-card {
      @include md(width, calc(33.33% - 10.66px));
      &:not(:nth-of-type(3n-2)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 4) {
        margin-top: 16px;
      }
    }
    &[data-inrow-md='4'] > .product-card {
      @include md(width, calc(25% - 12px));
      &:not(:nth-of-type(4n-3)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 5) {
        margin-top: 16px;
      }
    }
    &[data-inrow-md='5'] > .product-card {
      @include md(width, calc(20% - 12.8px));
      &:not(:nth-of-type(5n-4)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 6) {
        margin-top: 16px;
      }
    }
    &[data-inrow-md='6'] > .product-card {
      @include md(width, calc(16.66% - 13.33px));
      &:not(:nth-of-type(6n-5)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 7) {
        margin-top: 16px;
      }
    }
    &[data-inrow-md='7'] > .product-card {
      @include md(width, calc(16.66% - 13.33px));
      &:not(:nth-of-type(7n-6)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 8) {
        margin-top: 16px;
      }
    }
  }

  @media (min-width: $lg) {
    &[data-inrow-lg='1'] > .product-card {
      @include lg(width, 100%);
      margin-left: 0px;
    }
    &[data-inrow-lg='2'] > .product-card {
      @include lg(width, calc(50% - 8px));
      &:not(:nth-of-type(2n-1)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 3) {
        margin-top: 16px;
      }
    }
    &[data-inrow-lg='3'] > .product-card {
      @include lg(width, calc(33.33% - 10.66px));
      &:not(:nth-of-type(3n-2)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 4) {
        margin-top: 16px;
      }
    }
    &[data-inrow-lg='4'] > .product-card {
      @include lg(width, calc(25% - 12px));
      &:not(:nth-of-type(4n-3)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 5) {
        margin-top: 16px;
      }
    }
    &[data-inrow-lg='5'] > .product-card {
      @include lg(width, calc(20% - 12.8px));
      &:not(:nth-of-type(5n-4)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 6) {
        margin-top: 16px;
      }
    }
    &[data-inrow-lg='6'] > .product-card {
      @include lg(width, calc(16.66% - 13.33px));
      &:not(:nth-of-type(6n-5)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 7) {
        margin-top: 16px;
      }
    }
    &[data-inrow-lg='7'] > .product-card {
      @include lg(width, calc(14.28% - 12.86px));
      &:not(:nth-of-type(7n-6)) {
        margin-left: 16px;
      }
      &:nth-of-type(n + 8) {
        margin-top: 16px;
      }
    }
  }

  &.readonly {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding-left: 16px;
    overflow-x: auto;
    max-width: calc(100% + 32px);
    padding-bottom: 10px;
    position: relative;
    flex-wrap: unset;
  }

}