.pay_info {
  margin: 30px 0 80px;
  &__section {
    margin-top: 60px;
    h4 {
      margin-bottom: 22px;
    }
    &.-nal {
      h3 {
        margin-top: 25px;
      }
    }
    &.-cart {
      svg {
        margin: -40px -20px -20px;
      }
      h3 {
        margin-bottom: 45px;
      }
    }
    &.-present {
      h3 {
        margin: 23px 0 35px;
      }
    }
  }
  &__wrap {
    margin-top: 40px;
    h4 {
      margin-bottom: 16px;
    }
  }
}