.catalog-detail {
  display: flex;
  justify-content: space-between;
  margin-top: -18px;
  @include ap(flex-direction, column, row, row, row);
  &__mobile {
    @include ap(display, block, none, none, none);
  }
  &__desktop {
    @include ap(display, none, block, block, block);
  }
  &__name {
    font-weight: 300;
    @include ap(font-size, 22px, 26px, 34px, 40px);
    @include ap(line-height, 32px, 38px, 44px, 51px);
    @include ap(margin, 0px 0px 12px, 0px 0px 14px, 0px 0px 18px, 0px 0px 20px);
    color: #000;
  }
  &__code {
    @include ap(height, 30px, 35px, 40px, 50px);
    @include ap(line-height, 30px, 35px, 40px, 50px);
    font-size: 14px;
    color: #515B6D;
    font-weight: 300;
    &::before {
      content: 'Код: ';
    }
  }
  &__photos {
    @media (min-width: $sm) {
      width: calc(50% - 8px);
    }
    @media (max-width: $sm) {
      width: 100%;
    }

    display: flex;
    flex-direction: column;
  }
  &__main-photo {
    width: 100%;
    height: auto;
    max-height: 495px;
    padding: 5% 10%;
    border: 1px solid rgba(208, 215, 226, 0.5);
    position: relative;
    &-adds {
      position: absolute;
      top: 0;
      right: -1px;
      display: flex;
      justify-content: flex-end;
    }
    img {
      width: 100%;
      padding: 0px 10%;
      height: auto;
    }
  }
  &__discount {
    @media (max-width: $sm) {
      width: 50px;
      height: 24px;
      line-height: 24px;
    }
    @media (min-width: $sm) {
      width: 75px;
      height: 36px;
      line-height: 36px;
    }

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    color: #fff;
    margin-left: 11px;

    background: {
      image: url('/_images/svg/item-discount.svg');
      size: 100%;
      repeat: no-repeat;
    }
    &::after {
      content: '%';
    }
    &::before {
      content: '- ';
    }
  }
  &__action {
    background: #4185F4;
    height: 36px;
    width: 36px;
    padding: 2px;
    background: {
      image: url('/_images/svg/product-action.svg');
      size: 40%;
      position: center;
      repeat: no-repeat;
    }
    @include xs(display, none);
  }
  &__gallery {
    @include ap(margin-top, 8px, 10px, 13px, 16px);
    display: flex;
    flex-wrap: wrap;
    align-items: inherit;
    &-img {
      border: 1px solid rgba(208, 215, 226, 0.5);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include ap(width, calc(25% - 6px), calc(25% - 7.5px), calc(20% - 10px), calc(16.666% - 6.67px));
      cursor: pointer;
      &:not(:first-of-type) {
        @include ap(margin-left, 8px, 10px, 13px, 16px);
      }
      &.active {
        border: 1px solid #4185F4;
      }
      img {
        width: 100%;
        height: auto;
        margin: auto;
      }
    }
  }
  &__properties {
    @media (min-width: $sm) {
      width: calc(50% - 8px);
    }
    @media (max-width: $sm) {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    &-line {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @include xs(flex-direction, column);
    }
    &-top {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(208, 215, 226, 0.5);
      @include xs(margin-top, 20px);
    }
  }
  &__vendor {
    font-size: 14px;
    font-weight: 300;
    color: #0B66C3;
    @include xs(margin-bottom, 12px);
    &::before {
      font-weight: 300;
      font-size: 14px;
      color: #515B6D;
      content: 'Производитель: ';
    }
  }
  &__status {
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    height: 16px;
    @include ap(font-size, 14px, 12px, 13px, 13px);
    @include ap(padding-left, 15px, 15px, 15px, 20px);
    @include ap(padding-bottom, 16px, 16px, 16px, 0);
    position: relative;
    text-transform: lowercase;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      background: {
        size: 100%;
        repeat: no-repeat;
      };
      @include ap(left, 2px, 2px, 3px, 6px);
      top: 7px;
    }
    &.in-stock {
      color: #2ED672;
      &::before {
        background-image: url('/_images/svg/item-status-in_stock.svg');
      }

    }
    &.few {
      color: #FF6451;
      &::before {
        background-image: url('/_images/svg/item-status-few.svg');
      }
    }
    &.custom {
      color: #515B6D;
      &::before {
        background-image: url('/_images/svg/item-custom.svg');
      }
    }
  }
  &__price {
    position: relative;
    @include lg(margin, 25px 0px);
    @include xs(transform, translateY(-20%));
    @include ap(font-size, 24px, 24px, 24px, 26px);
    color: #515B6D;
    &::before {
      display: block;
      content: 'Цена:';
      font-size: 14px;
      color: #515B6D;
      opacity: .7;
      padding-bottom: 12px;
    }
    &::after {
      content: ' руб.'
    }
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: $lg) {
      margin-top: 25px;
    }
    &-buttons {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: $lg) {
        justify-content: space-between;
        width: 100%;
        padding-top: 16px;
        & > * {
          margin: 0px !important;
        }
      }
      @media (min-width: $lg) {
        align-items: flex-end;
        & > input:nth-of-type(1) {
          margin-left: 20px;
        }
        & > input:nth-of-type(2) {
          margin-left: 15px;
        }

      }
    }
    &-pricewrapper {
      display: flex;
      @include lg(flex-direction, column);
      @media (max-width: $lg) {
        flex-direction: row;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
  &__buy {
    @media (max-width: $md) {
      background-position: 31px 15px !important;
      font-size: 0;
      padding: 0px 40px;
      width: 0;
    }
  }
  &__buy-for-click {
    @include xs(width, 187px);
  }

  &__more {
    @include ap(margin-top, 20px, 30px, 30px, 30px);
    font-weight: 300;
    line-height: 21px;
    font-size: 14px;
    color: #515B6D;
  }
  &__characteristics {
    @include ap(width, 100%, 100%, 100%, 50%);
    &-item {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #EBEAF1;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 10px;

      & .-cd_c-value {
        font-weight: normal;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  &__footer {
    @media (min-width: $sm) {
      margin-top: 50px;
    }
    @media (max-width: $sm) {
      margin-top: 15px;
    }

  }

}