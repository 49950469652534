.headroom {
  &--pinned {
    top: 0;
  }
  &--unpinned {
    top: -100% !important;
  }
}

html.overflow-hidden {
  overflow: hidden !important;
  body {
    overflow: hidden !important;
  }
}

.header {
  position: fixed;
  z-index: 202;
  left: 0;
  right: 0;
  top: 0;
  color: #fff;
  transition: all .5s;
  @media (max-width: $md-down) {
    background-color: #fff;
    svg {
      path, use {
        fill: #4185f4 !important;
        stroke: #4185f4 !important;
      }
    }
  }
  &--white, &.is-pinned, &.is-black {
    background-color: #fff;
    box-shadow: 0 0 23px 2px rgba(81, 91, 109, 0.08);
    @media (max-width: $md-down) {
      box-shadow: 0px 0px 15px rgba(81, 91, 109, 0.2);
      position: static;
    }
    color: #515b6d;
    svg {
      path, use {
        fill: #4185f4;
        stroke: #4185f4;
      }
    }
    a {
      &:hover {
        color: #1254ea;
      }
    }
    .header-menu {
      &--sub {
        background-color: #4185f4;
        &:after {
          border-bottom-color: #4185f4;
        }
        .header-menu {
          &__link {
            color: #fff;
          }
        }
      }
      &__link {
        &:hover {
          color: #1254ea;
        }
      }
    }
    .header__splitter {
      background-color: #a7acb6;
    }
  }
  a {
    &:hover {
      color: #4185f4;
    }
  }
  svg {
    path, use {
      fill: #fff;
      stroke: #fff;
    }
  }
  &__top {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid rgba(192, 201, 217, 0.3);
    @media (max-width: $md-down) {
      //height: 59px;
      //line-height: 59px;
      .container {
        padding: 0;
        width: auto;
      }
    }
    @media (max-width: $sm-down) {
      height: fit-content !important;

    }
  }
  &__container {
    display: flex;
    flex-flow: row wrap;
  }
  &__buttons {
    @media (max-width: $sm-down) {
      height: 50px;
    }
    @media (max-width: 400px) {
      flex-grow: 1;
      & > .custom-dropdown-menu-wrapper {
        width: 100% !important;
      }
    }
  }
  &__button {
    float: left;
    padding: 0 25px;
    border-right: 1px solid rgba(192, 201, 217, 0.3);
    color: inherit;
    font-size: 14px;
    text-align: center;
    line-height: 17px;
    height: 50px;
    display: flex;
    align-items: center;
    @media (max-width: $md-down) {
      padding: 0 18px 0 18px;
    }
    @media (max-width: $sm-down) {
      padding: 0 15px 0 15px;
      border-right: 0px;
    }
    &:first-child {
      border-left: 1px solid rgba(192, 201, 217, 0.3);
    }
    &--active {
      background-color: #4185f4;
      border-color: transparent !important;
      color: #fff !important;
    }
    svg {
      margin-left: 44px;
      path, use {
        fill: #fff !important;
        stroke: inherit !important;
      }
    }
  }

  &__sections {
    width: 100%;
    order: 3;
    display: none;
  }
  &__sections-item {
    display: block;
    padding: 20px 0 20px 17px;
    background-color: #5a99ff;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    &:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, .5);
    }
  }
  &__info {
    display: flex;
    justify-content: space-around;
    flex: 1;
    @media (max-width: $lg-down) {
      display: none;
    }
  }
  &__text {
    font-size: 13px;
    /* убрать после добавления авторизации */
    margin-left: 10px;
    svg {
      margin-right: 4px;
      transform: translateY(3px);
    }
    a {
      color: inherit;
    }
  }
  &__panel {
    margin-left: auto;
    /*@media (max-width: $md-down) and (min-width: $sm) {
      flex: 1;
      margin-left: 0;
    }*/
    @media (max-width: $md-down) {
      height: 60px;
      margin-left: 0;
      width: 100%;
      order: 4;
      background-color: #fff;
      border-bottom: 1px solid rgba(235, 234, 241, 0.3);
    }
  }
  &__panel-item {
    float: right;
    color: inherit;
    font-size: 13px;
    margin-left: 10px;
    @media (max-width: $md-down) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 60px;
      margin-left: 0 !important;
      border-left: 1px solid #ebeef3;
      font-size: 0;
    }
    &:nth-of-type(1) {
      /*      margin-left: 55px;*/
      svg {
        #tp8ya, #tp8yb {
          stroke: none;
        }
      }
    }
    svg {
      margin-right: 15px;
      transform: translateY(3px);
      @media (max-width: $md-down) {
        margin-right: 0;
        transform: translateY(0);
      }
    }
    a {
      color: inherit;
    }
  }
  &__splitter {
    display: inline-block;
    margin: 0 12px;
    width: 1px;
    height: 22px;
    background-color: #fff;
    opacity: 0.5;
    transform: translateY(5px);
    @media (max-width: $md-down) {
      display: none;
    }
  }
  &__avatar {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 34px;
    height: 34px;
    border: 1px solid #4185f4;
    border-radius: 50%;
    background: url(/_images/svg/no-photo.svg) no-repeat center;
    background-size: 60%;
  }
  &__user {
    vertical-align: middle;
    display: inline-block;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: $lg-down) {
      max-width: 60px;
    }
  }
  &__menu-button {
    padding: 0 32px 0 18px;
    border-left: 1px solid #ebeef3;
    color: #4185f4;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    @media (max-width: $md-down) {
      margin-left: auto;
    }
    @media (max-width: $sm-down) {
      padding: 0 17px;
      border-bottom: 1px solid #ebeef3;
      height: 50px;
      line-height: 53px;
      //border-left: 0;
    }
    &:hover {
      color: #4185f4;
    }
    svg {
      margin-left: 15px;
      transform: translateY(3px);
    }
  }
  &__bottom {
    padding: 22px 0;
  }
  &__logo {
    transform: translateY(7px);
    @media (max-width: $md-down) {
      float: left;
      margin-left: 26px;
    }
    @media (max-width: $sm-down) {
      margin-left: 15px;
      transform: translateY(9px);
    }
  }
  &__menu-container {
    display: flex;
    overflow: hidden;
    visibility: hidden;
    &.is-initialized {
      overflow: visible;
      visibility: visible;
    }
  }
}

.header-menu {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  min-width: 100%;
  white-space: nowrap;
  &--sub {
    position: absolute;
    display: block;
    padding: 19px;
    min-width: auto;
    top: 100%;
    right: 0;
    transform: translateY(25px);
    background-color: #fff;
    text-align: right;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    &:after {
      bottom: 100%;
      right: 10px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 7px;
      margin-left: -7px;
    }
    .header-menu {
      &__item {
        margin-bottom: 15px;
        padding: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__link {
        color: #000;
        white-space: nowrap;
      }
    }
  }
  &__item {
    padding: 10px 0;
    flex-shrink: 0;
    &:hover {
      & > .header-menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }
    }
  }
  &__link {
    color: inherit;
    font-size: 16px;
  }
}

.menu {
  position: fixed;
  z-index: 1003;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(17, 34, 64, 0.8);
  display: none;
  opacity: 0;
  &.is-open {
    .menu {
      &__container {
        transform: translateY(0);
      }
    }
  }
  &__container {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: all .4s linear;
    transform: translateY(-100%);
  }
  &__header {
    padding: 15px 20px;
    text-align: right;
    background-color: #fff;
    border-bottom: 1px solid #eceff4;
  }
  &__close {
    color: #4185f4;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    cursor: pointer;
    svg {
      margin-left: 15px;
      transform: translateY(3px);
    }
  }
  &__body {
    position: absolute;
    top: 51px;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__list {

  }
  &__item {
    padding: 0 9px;
    background-color: #f0f5f9;
    border-bottom: 1px solid #e0e5eb;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__link {
    display: block;
    padding: 20px 0;
    color: #515b6d;
    font-size: 15px;
    span {
      padding-left: 12px;
    }
  }
}

/* Content work area */

.content {
  padding-top: 85px;
  @media (max-width: $sm-down) {
    padding-top: 0 !important;
  }
}

.footer {
  &__top {
    padding: 70px 0 90px;
    background-color: #383e50;
    @media (max-width: $sm-down) {
      padding: 0;
      .container {
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

    }
  }
  &__middle {
    padding: 48px 0;
    background-color: #292e3b;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    @media (max-width: $sm-down) {
      padding: 32px 0 43px;
    }
  }
  &__bottom {
    background-color: #292e3b;
  }
  &__logo {
    transform: translateY(5px);
    @media (max-width: $sm-down) {
      text-align: center;
    }
  }
  &__service {
    padding: 28px 0 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    @media (max-width: $md-down) {
      padding-bottom: 0;
      border: none;
    }
  }
  &__service-container {
    display: flex;
    overflow: hidden;
    visibility: hidden;
    &.is-initialized {
      overflow: visible;
      visibility: visible;
    }
  }
  &__group-title {
    margin-bottom: 41px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    @media (max-width: $md-down) {
      margin-bottom: 34px;
    }
    @media (max-width: $sm-down) {
      display: none;
    }
  }
  &__copy {
    padding: 23px 0;
  }
  &__text {
    float: left;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    @media (max-width: $sm-down) {
      margin-bottom: 24px;
      float: none;
      text-align: center;
    }
  }
  &__made {
    float: right;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    a {
      color: #0f81ff;
      transition: color .3s;
      &:hover {
        color: #fff;
      }
    }
    @media (max-width: $sm-down) {
      float: none;
      text-align: center;
    }
  }
}

.footer-menu {
  &.is-open {
    .footer-menu {
      &__header {
        background-color: #808899;
        color: #fff;
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  @media (max-width: $sm-down) {
    margin: 0 -8px;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  @media (min-width: $sm-down) {
    &:not(:first-of-type) {
      margin-top: 42px;
    }
  }
  &__header {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 32px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    transition: all .3s;
    @media (max-width: $sm-down) {
      display: block;
      margin-bottom: 0;
      padding: 25px 16px;
      background-color: #383e50;
    }
    &:hover, &:focus {
      color: #336df1;
      @media (max-width: $sm-down) {
        color: #fff;
        background-color: #808899;
      }
    }
    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 29px;
      right: 17px;
      width: 12px;
      height: 7px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciPjxkZWZzPjxwYXRoIGlkPSIyb2IyYSIgZD0iTTI5NyA0MzIwLjZjLS40MS4wOC0uODYtLjAxLTEuMTktLjMybC00LjQyLTQuMTRhMS4yIDEuMiAwIDAgMSAwLTEuNzcgMS40IDEuNCAwIDAgMSAxLjkgMGwzLjcyIDMuNDkgMy43LTMuNDdhMS40IDEuNCAwIDAgMSAxLjg5IDAgMS4yIDEuMiAwIDAgMSAwIDEuNzdsLTQuNCA0LjEyYy0uMzMuMy0uNzcuNC0xLjIuMzN6Ii8+PC9kZWZzPjxnPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yOTEgLTQzMTQpIj48dXNlIGZpbGw9IiNmZmYiIHhsaW5rOmhyZWY9IiMyb2IyYSIvPjwvZz48L2c+PC9zdmc+');
      transition: all .3s;
      @media (max-width: $sm-down) {
        display: block;
      }
    }
  }
  &__list {
    @media (max-width: $sm-down) {
      display: none;
      padding: 25px 0;
      background-color: #61687a;
      text-align: center;
    }
    &--sub {
      margin: 21px 0 36px 20px;
      @media (max-width: $md-down) {
        display: none;
      }
      .footer-menu {
        &__item {
          margin-bottom: 14px;
        }
        &__link {
          color: #697386;
          font-size: 14px;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  &__item {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__link {
    color: #969faf;
    font-size: 16px;
    transition: all .3s;
    &:hover {
      color: #c2c4c9;
    }
    @media (max-width: $md-down) {
      font-size: 14px;
    }
    @media (max-width: $sm-down) {
      color: #b5b9c1;
    }
  }
}

.footer-info {
  margin-bottom: 68px;
  @media (max-width: $md-down) {
    margin-bottom: 48px;
  }
  &__item {
    margin-bottom: 26px;
    color: #c0c9d9;
    font-size: 16px;
    @media (max-width: $md-down) {
      font-size: 14px;
    }
    .icon {
      float: left;
      margin-right: 14px;
      transform: translateY(-3px);
    }
    a {
      color: #c0c9d9;
    }
  }
}

.footer-social {
  @media (max-width: $sm-down) {
    padding-bottom: 27px;
    margin: 0 -8px 37px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
  }
  &__item {
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    @media (max-width: $sm-down) {
      float: none;
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
    }
    @media (max-width: $xs-down) {
      margin-left: 5px;
      margin-right: 5px;
    }
    svg {
      width: 50px;
      height: 50px;
      transition: all .3s;
      @media (max-width: $xs-down) {
        width: 40px;
        height: 40px;
      }
      &:hover {
        path {
          fill: #4185f4;
        }
      }
    }
  }
}

.footer-service-menu {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  min-width: 100%;
  white-space: nowrap;
  @media (max-width: $md-down) {
    display: block;
  }
  @media (max-width: $sm-down) {
    text-align: center;
  }
  &--sub {
    position: absolute;
    display: block;
    padding: 19px;
    bottom: 100%;
    right: 0;
    transform: translateY(-25px);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    &:after {
      top: 100%;
      right: 10px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 7px;
      margin-left: -7px;
    }
    .footer-service-menu {
      &__item {
        margin-bottom: 15px;
        padding: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__link {
        color: #000;
        white-space: nowrap;
      }
    }
  }
  &__item {
    position: relative;
    padding: 10px 0;
    flex-shrink: 0;
    @media (max-width: $md-down) {
      padding: 0;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:hover {
      & > .footer-service-menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(5px);
      }
    }
  }
  &__link {
    color: #fff;
    font-size: 16px;
    transition: all .3s;
    &:hover, &:focus {
      color: #4185f4;
    }
    @media (max-width: $md-down) {
      font-size: 14px;
    }
  }
}

.jq-selectbox {
  z-index: 100;
}

.modal-social {

}

.get-consultation {
  position: fixed;
  z-index: 999;
  width: 215px;
  height: 60px;
  border-radius: 5px 0 0 5px;
  background-color: #4184f4;
  bottom: 65px;
  right: -155px;
  transition: all 0.3s;
  &:hover, &.-hover {
    right: 0;
  }
  &__link {
    z-index: 1;
    position: relative;
    display: block;
    height: 100%;
    padding: 16px 0 5px 60px;
    color: #fff !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-align: center;
  }
  &:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    left: 0;
    top: 0;
    background: url("/_images/svg/c-consultation-btn.svg") no-repeat center;
    background-size: 60%;
    transition: all 0.3s;
  }
  &.-active {
    &:before {
      background-size: 70%;
    }
  }
}

.custom-dropdown-menu {
  overflow: hidden;
  &.custom-dropdown-active {
    overflow: visible;
    transition: overflow .1s ease-in;
    & > .custom-dropdown-menu-wrapper {
      background: white;
      transform: none !important;
      box-shadow: 0px 0px 100px rgba(65, 133, 244, 0.4);
      .header__button {
        text-align: left;
      }
      &::before {
        transform: rotate(180deg);
        top: 19px;
        transform-origin: center;
      }
    }
  }
  &-wrapper {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: column;
    &::before {
      content: '';
      width: 16px;
      height: 10px;
      position: absolute;
      right: 16px;
      top: calc(21px);
      background-image: url('/_images/svg/dropdown-arrow.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: transform .1s ease-in, top .1s ease-in;
    }
    & > .header__button {
      border-top: 1px solid #ebeef3;
    }
  }
}
