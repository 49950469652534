$RobotoCondensed: 'Roboto Condensed', sans-serif;
$Roboto: 'Roboto', sans-serif;

$xs-down: 479px;
$xs: 480px;
$sm-down: 767px;
$sm: 768px;
$md-down: 991px;
$md: 992px;
$lg-down: 1454px;
$lg: 1455px;