.checkout_box {
  margin-top: 30px;
  background-color: #F0F5F9;
  .caption {
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 22px;
    &-link {
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      letter-spacing: 1.71429px;
      text-transform: uppercase;
      color: #4185F4;
    }
  }
  &__goods {
    display: flex;
    margin-top: 25px;
  }
  &__img {
    max-width: 74px;
    height: 74px;
    width: 20%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__info_good {
    width: 80%;
    margin-left: 16px;
    line-height: 20px;
  }
  &__wrap {
    padding: 30px;
    &--first {
      padding-bottom: 12px;
    }
    &--two {
      padding-bottom: 20px;
    }
    &--three {
      padding-top: 19px;
      padding-bottom: 40px;
    }
    &--four {
      margin: 50px 30px 0;
      padding: 0;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        border: 1px solid rgba(208,215,226,.5);
      }
    }
  }
  b {
    font-weight: 400;
    &.red {
      color: red;
    }
  }
  hr {
    border: 1px solid rgba(208, 215, 226, 0.5);
    margin: 0;
  }
  &__caption {
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    color: #000000;
    font-size: 16px;
  }
  &__txt14 {
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    color: #000000;
    margin: 4px 0 20px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__collect {
    background-color: rgba(65, 133, 244, .1);
    padding: 14px 30px;
    display: flex;
    justify-content: space-between;
  }
  &__checkbox_button {
    .checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
      margin: 0;
    }
    .checkbox + label {
      position: relative;
      padding: 0 0 0 32px;
      cursor: pointer;
    }
    .checkbox + label:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      border-radius: 3px;
      height: 18px;
      background: #ffffff;
      border: 1px solid #4185f4;
      transition: .2s;
    }
    .checkbox + label:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 18px;
      height: 18px;
      border-radius: 10px;
      transition: .2s;
      background-position: center;
      background-size: auto;
      background-repeat: no-repeat;
    }
    .checkbox:checked + label:after {
      background-image: url('/_images/svg/check.svg');
    }
    .checkbox:checked + label:before {
      background: #4185f4;
    }
  }
  &__summ {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  &__button {
    p {
      margin-top: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      font-size: 12px;
      text-align: center;
      color: #515B6D;
    }
  }

  .hint_price_info {
    width: 20px;
    height: 20px;
    background-image: url("/_images/svg/exclamation.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  &__tooltip {
    position: absolute;
    background-color: #ffffff;
    width: 274px;
    border-radius: 3px;
    padding: 14px 5px 12px 20px;
    left: -290px;
    top: -25px;
    z-index: 1000;
    font-weight: 300;
    line-height: 16px;
    font-size: 12px;
    color: #515B6D;
    filter: drop-shadow(0px 0px 7px rgba(65, 133, 244, .3));
    display: none;
    &.active {
      display: block;
    }
    &:after {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      background-color: #ffffff;
      top: 50%;
      right: -5px;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
@media (max-width: $sm-down) {
  .checkout_box {
    &__wrap--four {
      margin: 33px 16px 0;
    }
  }
}
@media (max-width: 570px) {
  .checkout_box {
    .button-blue {
      padding: 0 15px;
    }
    &__collect {
      padding: 14px 16px;
    }
    &__wrap {
      padding: 16px;
    }
  }
}