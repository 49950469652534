.scroll-cursor {
  cursor: pointer;
  &__mouse {
    margin-bottom: 6px;
    width: 27px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 13px;
  }
  &__wheel {
    margin: 4px auto;
    width: 2px;
    height: 9px;
    background-color: #fff;
    animation: mouse-wheel 1.2s ease infinite;
  }
  &__arrow {
    span {
      display: block;
      margin: 0 auto 1px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      animation: mouse-scroll 2s infinite;
      &:nth-of-type(1) {
        animation-delay: .1s;
        opacity: 0.3;
      }
      &:nth-of-type(2) {
        animation-delay: .2s;
        opacity: 0.7;
      }
      &:nth-of-type(3) {
        animation-delay: .3s;
      }
    }
  }
}

@keyframes mouse-wheel {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(6px);
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}