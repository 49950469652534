@mixin ap($property, $xs-value, $sm-value, $md-value, $lg-value) {

  @media (max-width: $sm) {
    #{$property}: $xs-value;
  }
  @media (max-width: $md) and (min-width: $sm) {
    #{$property}: $sm-value;
  }
  @media (max-width: $lg) and (min-width: $md){
    #{$property}: $md-value;
  }
  @media (min-width: $lg){
    #{$property}: $lg-value;
  }

}

@mixin xs($property, $value) {
  @media (max-width: $sm) {
    #{$property}: $value;
  }
}

@mixin sm($property, $value) {
  @media (max-width: $md) and (min-width: $sm) {
    #{$property}: $value;
  }
}

@mixin md($property, $value) {
  @media (max-width: $lg) and (min-width: $md) {
    #{$property}: $value;
  }
}

@mixin lg($property, $value) {
  @media (min-width: $lg) {
    #{$property}: $value;
  }
}