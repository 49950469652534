.catalog-result {
  margin-top: 25px;
  &__title {
    text-align: left;
  }

  &__item {
    margin-bottom: 30px;
    &-title {
      margin-bottom: 10px;
    }
    &-text {
      line-height: 24px;
      &--link {
        text-decoration: underline;
      }
      &--disable {
        color: #aaa;
      }
    }
  }
}