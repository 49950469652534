.preloader {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #fff;
  transition: visibility .3s, opacity .3s;
  &.hide-loader {
    visibility: hidden;
    opacity: 0;
  }
  &__image {
    position: relative;
    height: 150px;
    width: 100%;
    margin-bottom: -40px;
    svg {
      position: absolute;
      width: 150px;
      animation: preloader-car 3s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    }
  }
  &__line {
    position: relative;
    width: 100%;
    &:after {
      content: '';
      position: absolute;
      height: 3px;
      background-color: #4185f4;
      animation: preloader-line 3s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    }
  }
}

@keyframes preloader-car {
  0%   {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}

@keyframes preloader-line {
  from {
    left: 0;
    width: 0;
  }
  50% {
    right: 0;
    width: 100%;
  }
  to {
    right: 0;
    width: 0;
  }
}