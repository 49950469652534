.button {
  display: inline-block;
  padding: 0 22px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #4185f4;
  color: #4185f4;
  font-size: 12px;
  line-height: 45px;
  text-transform: uppercase;
  letter-spacing: 1.71px;
  transition: all .3s;
  background-color: transparent;
  &:hover {
    background-color: #4185f4;
    color: #fff;
  }
  &--big {
    min-width: 224px;
  }
}

.flat-btn {
  width: 204px;
  height: 45px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 1.71429px;
  text-transform: uppercase;
  color: #4185F4;
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  background: transparent;
  transition: background-color .1s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  &:nth-of-type(2) ~ .flat-btn {
    width: 184px;
    @media (min-width: $sm-down) {
      margin-right: 15px;
    }
    @media (max-width: $sm-down) {
      margin-bottom: 15px;
    }
  }
  &.red {
    color: #FF6451;
    border: 2px solid #FF6451;
    &:hover {
      background-color: #FF6451;
      color: #fff;
    }
  }
  &.blue {
    background: #4185F4;
    border: 2px solid #4185F4;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: #4185F4;
    }
  }
  &.normal {
    border: 2px solid #4185F4;
    color: #4185F4;
    &:hover {
      background-color: #4185F4;
      color: #fff;
    }
  }
}