.tabs {
  &__header {
    display: flex;
    justify-content: space-between;
    @include ap(height, 46px, 48px, 50px, 54px);
    @include ap(margin-bottom, -4px, -2px, -2px, 0px);
    align-items: center;
    padding: 0px 25px;
    @media (max-width: 776px) {
      padding: 0px 10px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }
  &__header-wrapper {
    border-bottom: 1px solid #EBEAF1;
    @media (max-width: 776px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  &__tab {
    display: none;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    @media (max-width: $sm-down) {
      margin-right: -16px;
      margin-left: -16px;
      width: calc(100% + 32px);
    }
    &.visible {
      display: block;
    }
    & > .active {
      display: block;
      &.fadeToRight {
        animation: fadeInToRight .3s ease-in;
      }
      &.fadeToLeft {
        animation: fadeInToRight .3s ease-in;
      }
    }
    & > div:not(.active) {
      &.fadeToRight {
        animation: fadeOutToRight .3s ease-in;
      }
      &.fadeToLeft {
        animation: fadeOutToRight .3s ease-in;
      }
      display: none;
    }
  }
  &__tab-title {
    user-select: none;
    cursor: pointer;
    @include normal-font();
    line-height: 22px;
    margin-left: 5px;
    margin-right: 5px;
    white-space: nowrap;
    opacity: .7;
    @media (max-width: 776px) {
      &:not(:first-of-type) {
        margin-left: 18px;
      }
      &:not(:last-of-type) {
        margin-right: 18px;
      }
    }
    @include ap(font-size, 14px, 16px, 17px, 20px);
    &::after {
      width: 0%;
      content: '';
      transition: all .3s ease-in;
      height: 3px;
      background-color: #515B6D;
      transform: none;
      position: absolute;
      @include ap(bottom, -10px, -14px, -14px, -18px);
      right: 0;
    }
    position: relative;
    color: #515B6D;
    &.active
    {
      opacity: 1;
      transform: none !important;
      &::after {
        left: 0;
        width: 100%;
      }

    }
  }
  &__content {
    @media (min-width: $sm-down) {
      margin: 60px 0px;
    }
    @media (max-width: $sm-down) {
      margin: 40px 0px;
    }
  }

  &.style-info {
    .tabs__header {
      padding: 0px !important;
      justify-content: flex-start !important;
    }
    .tabs__tab-title {
      letter-spacing: 3px;
      @include ap(font-size, 12px, 13px, 14px, 14px);
      &:not(:first-of-type) {
        margin-left: 30px;
      }
      &::after {
        bottom: -10px !important;
      }
    }
    .tabs__header {
      height: 40px !important;
      margin-left: -5px;
      margin-right: -5px;
      @media (max-width: $sm) {
        margin-bottom: -1px;
      }
    }
    .tabs__header-wrapper {
      margin: 0px !important;
    }
    .tabs__content {
      border-bottom: 1px solid rgba(208, 215, 226, 0.5);
      margin: 0 !important;
    }
    .tabs__tab {
      &:not(.--no-margin-top) {
        margin-top: 20px !important;
      }
      &:not(.--no-margin-bottom) {
        margin-bottom: 20px !important;
      }
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100% !important;
      & > div {
        display: block;
        animation: none !important;
      }
      &:not(.--unlimited-height) {
        @include ap(max-height, 170px, 180px, 195px, 210px);
      }
      overflow-y: auto;
      &::-webkit-scrollbar-thumb {
        background: #515B6D;
        border-radius: 7px;
        width: 5px;
      }
      &::-webkit-scrollbar {
        background: transparent;
        border: none;
        width: 7px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
}