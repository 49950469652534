.cart {
  margin-bottom: 19px;
  &__goods {
    margin-top: 40px;
  }
  &__caption_free {
    color: #515B6D;
    b {
      color: #000000;
      font-weight: 400;
    }
  }
  &__img {
    max-width: 88px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  &__link {
    display: block;
    &:hover {
      cursor: pointer;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 18px 0;
    flex-wrap: wrap;
    &-mob {
      display: none;
    }
    &:first-child {
      &:after {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        height: 1px;
        background-color: rgba(208, 215, 226, 0.5);
        top: 0;
      }
    }
    &:before {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      height: 1px;
      background-color: rgba(208, 215, 226, 0.5);
      bottom: 0;
    }
  }
  &__additionally {
    margin-top: 80px;
  }
  &__delete {
    background-color: #FF6451;
    border-radius: 50%;
    position: relative;
    height: 30px;
    width: 30px;
    border: none;
    background-image: url("/_images/svg/delete.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    color: #ffffff;
    &:hover {
      cursor: pointer;
    }
  }
  &__wrap_fix {
    position: relative;
  }
  &__window {
    position: sticky;
    max-width: 409px;
    width: 100%;
    float: right;
    top: 115px;
    @media (max-width: 991px) {
      position: relative;
      top: 0;
    }
  }
  &__bar {
    margin-top: 7px;
    height: 2px;
    width: 100%;
    background-color: rgba(208, 215, 226, 0.5);
    div {
      background-color: #4185F4;
      height: 2px;
    }
  }
  &__count {
    height: 42px;
    .number_of_goods {
      margin-bottom: 10px;
    }
    .txt-14 {
      color: #515B6D;
    }
  }
  &__flex_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    //max-width: 295px;
    max-width: 344px;
  }
  &__price {
    margin-left: 25px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
  }
  &__description {
    width: 462px;
    padding-right: 15px;
    .p-16 {
      margin-bottom: 10px;
    }
  }
  &__in-stock {
    font-size: 14px;
    padding-left: 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      left: 0;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    &--red {
      color: #FF6451;
      &:before {
        background: #FF6451;
      }
    }
    &--green {
      color: #2ED672;
      &:before {
        background: #2ED672;
      }
    }
  }
  @media (max-width: $lg) {
    &__wrap {
      display: none;
      &-mob {
        display: flex;
      }
    }
    &__window {
      float: initial;
    }
    &__price {
      margin: 0 0 10px 0;
    }
    &__description {
      width: 100%;
      padding: 0 0 14px 0;
      p:first-child {
        padding-bottom: 8px;
      }
    }
    &__flex_wrap {
      justify-content: left;
    }
    &__count {
      margin-right: 23px;
    }
    &__wrap {
      padding: 20px 0 40px;
      justify-content: left;
      flex-wrap: nowrap;
      align-items: end;
      &-min {

      }
    }
    &__img {
      width: 100%;
      max-width: 90px;
      margin-right: 20px;
      img {
        width: inherit;
      }
    }
  }
  @media (max-width: 1091px) {
    &__window {
      margin-top: 28px;
    }
    &__items {
      margin-top: 30px;
    }
  }
}