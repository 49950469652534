.service_info {
  background: #ffffff;
  display: flex;
  align-items: center;
  &--map {
    padding: 10px;
    display: block;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-size: 14px;
    color: #4185F4;
  }
  &__button {
    padding: 0 15px;
    width: 200px;
    border-width: 2px;
    &--map {
      margin-top: 11.74px;
    }
  }
  &__caption {
    font-style: normal;
    font-weight: normal;
    line-height: 26px;
    font-size: 18px;
    color: #40485A;
    margin: 10px 0 11.26px;
  }
  &__tel {
    font-weight: 300;
    line-height: 25px;
    font-size: 14px;
    color: #40485A;
  }
  &__wrap {
    margin-left: 20px;
  }
}
@media (max-width: $sm-down) {
  .service_info {
    img {
      display: none;
    }
    &__wrap {
      margin: 0;
    }
    &__button {
      margin-top: 10px;
      border-width: 2px;
    }
  }
}