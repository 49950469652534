.catalog-banner {
  @include ap(width, calc(100% + 32px), 100%, 100%, 100%);
  @include ap(margin-left, -16px, 0px, 0px, 0px);
  height: auto;
  img {
    width: 100%;
    height: auto;
    @media (min-width: $sm) {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: block;
      }
    }
    @media (max-width: $sm-down) {
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
  }
}