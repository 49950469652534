.delivery {
  margin: 53px 0 60px;
  &__courier {
    margin-top: 40px;
  }
  &__list {
    margin-top: 40px;
    li {
      position: relative;
      padding-left: 46px;
      margin-bottom: 20px;
      &:after {
        content:'';
        position: absolute;
        height: 2px;
        width: 26px;
        background-color: #4185F4;
        left: 0;
        top: 11px;
      }
      &:nth-child(2) {
        max-width: 620px;
      }
      &:last-child {
        max-width: 850px;
      }
    }
  }
  &__pickup {
    margin-bottom: 40px;
  }
  &__paragraph {
    max-width: 790px;
  }
  @media (max-width: $sm) {
    br {
      display: none;
    }
  }
}