.checkout {
  margin-bottom: 60px;
  .ymaps-2-1-72-balloon {
    transform: translate(-30%,-6%);
    &__tail {
      left: 45% !important;
    }
    &:after {
      position: absolute;
      content: url("/_images/svg/blue_map.svg");
      left: 45%;
      bottom: -20%;
    }
  }
  &__caption_info {
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    color: #515B6D;
    margin-bottom: 4px;
  }
  &__information {
    margin-top: 35px;
  }
  &__wrapper {
    display: flex;
    margin: -10px;
    flex-wrap: wrap;
  }
  &__process {
    width: 262px;
    height: 120px;
    &--pay {
      width: 224px;
      height: 100px;
    }
    border-radius: 5px;
    border: 2px solid #4185F4;
    padding: 22px 30px 25px;
    transition: all .2s linear;
    background: #ffffff;
    color: #4185F4;
    margin: 10px;
    .caption {
      font-style: normal;
      font-weight: normal;
      line-height: 17px;
      font-size: 12px;
      letter-spacing: 1.71429px;
      text-transform: uppercase;
      color: #4185F4;
      padding-bottom: 10px;
      display: inline-block;
      text-align: left;
      width: 100%;
    }
    .description {
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      font-size: 14px;
      color: rgba(65, 133, 244, 0.8);
      text-align: left;
      display: inline-block;
      width: 100%;
    }
    &.active {
      background: #4185F4;
      box-shadow: 0px 11px 23px rgba(65, 133, 244, 0.434754);
      .caption,
      .description {
        color: #ffffff;
      }
    }
    &:hover {
      background: #4185F4;
      box-shadow: 0px 11px 23px rgba(65, 133, 244, 0.434754);
      .caption,
      .description {
        color: #ffffff;
      }
    }
  }
  &__poin {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    h4 {
      margin: 0;
    }
  }
  &__switch {
    display: flex;
    justify-content: center;
    button {
      width: 136px;
      text-align: center;
      padding: 6px 0 7px;
      border: 2px solid #4185F4;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 16px;
      background: #ffffff;
      color: #4185F4;
      transition: all .2s linear;
      &.active {
        background: #4185F4;
        color: #FFFFFF;
      }
      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &:hover {
        background: #4185F4;
        color: #ffffff;
      }
    }
  }
  &__section {
    margin-top: 40px;
    h4 {
      margin-bottom: 10px;
    }
  }
  &__form {
    margin-top: 26px;
    max-width: 584px;
    .form__input {
      margin-bottom: 20px;
      position: relative;
    }
  }
  &__map {
    height: 410px;
    &-finish {
      height: 279px;
    }
  }
  &__service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid rgba(208, 215, 226, 0.5);
    &-finish {
      border-bottom: none;
    }
    &:nth-child(n+2) {
      border-top: none;
    }
  }
  &__wrap {
    max-height: 461px;
    .mCS-dark_custom {
      .mCSB_container {
        margin-right: 5px !important;
      }
      &.mCSB_scrollTools_onDrag {
        .mCSB_dragger_bar {
          background-color: #515B6D !important;
        }
      }
      .mCSB_dragger {
        &_bar {
          background-color: #515B6D !important;
          margin: 20px 6px !important;
        }
        &:hover {
          .mCSB_dragger_bar {
            background-color: #515B6D !important;
          }
        }
        &:active {
          .mCSB_dragger_bar {
            background-color: #515B6D !important;
          }
        }
      }
      .mCSB_draggerRail {
        background-color: transparent !important;
      }
    }
  }
  &__warning {
    .caption {
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      color: #FF6451;
      position: relative;
      padding-left: 48px;
      margin-bottom: 30px;
      &:after {
        position: absolute;
        content: '';
        width: 29.21px;
        height: 29.21px;
        background-image: url("/_images/svg/exclamation.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    ul {
      margin-bottom: 38px;
    }
    li {
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      color: #515B6D;
      position: relative;
      padding-left: 46px;
      &:nth-child(n+2) {
        margin-top: 16px;
      }
      &:after {
        position: absolute;
        content: '';
        top: 11px;
        left: 0;
        width: 26px;
        border-top: 2px solid #FF6451;
      }
    }
    a {
      font-weight: normal;
      color: #4185f4;
    }
  }
  hr {
    border-top: 1px solid rgba(208, 215, 226, 0.5);
  }
  &__payment {
    margin-top: 20px;
    h4 {
      margin-bottom: 30px;
    }
  }

}
@media (max-width: $lg) {
  .checkout {
    &__warning {
      br {
        display: none;
      }
    }
  }
}
@media (max-width: $sm-down) {
  .checkout {
    &__caption_info {
      font-size: 12px;
    }
    &__poin {
      display: block;
    }
    &__switch {
      margin-top: 16px;
    }
    &__map {
      height: 199px;
    }
    &__section {
      margin-top: 24px;
    }
    &__form {
      margin-top: 20px;
    }
    &__window {
      margin-top: 10px;
    }
    &__service {
      flex-wrap: wrap;
      border-left: none;
      border-right: none;
    }
    &__wrap {
      max-height: 100%;
    }
  }
}